import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SignaturePad } from "angular2-signaturepad";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "./../../_services/user.service";
import * as moment from "moment";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: "app-nc",
  templateUrl: "./nc.component.html",
  styleUrls: ["./nc.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class NcComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  hippaJson: any = {};
  loggedInUserId: string;
  userSignatures: any = [];
  userSignatureSelected = false;
  userFullSignature = "";
  userInitialSignature = "";
  userChoosedSignature = "";
  userFullSignatureExit = false;
  userInitialSignatureExis = false;
  showPdfModal = false;
  // city = "";
  signatureType: any;

  ssnPattern = "^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$";

  resultJsonObj: any = {};
  clean = "0";
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  @ViewChild("canvasArea", { static: true }) canvasArea: ElementRef;
  canvasElm: any;
  public signaturePadOptions: Object = {
    minWidth: 1,
    canvasWidth:
      window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
    canvasHeight: 450,
  };

  currentDate = new Date();
  dateFormat = "MM-dd-yyyy";

  userWitnessSignatureSelected = false;
  userWitnessChoosedSignature = "";

  signatureFroWhom: any;
  isEmail: any;
  isPracticePhy: any;
  physicianId: any;
  requestId: any;
  tempId: any;
  userOptionId: any;
  emailLogId: any;

  patientId: string;
  fileDesTxt: any = "";
  selectedDelegateId: any;
  assignedDelegateId: any;
  isDelegated: any = 0;
  selectedDocUrl: any;
  selectedPhysician: any = {};
  docInfo: any = {};
  selectedStateCode: any;
  showLoader = false;
  childRoute: string;
  patient_name: any;
  physicianName: any;
  PhysicianOfficeAddress: any;
  patient_dob: any;
  patient_city: any;
  patient_ssn: any;
  provider_phone: any;
  loginUserType: any;
  healthcare_provider: any;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    public userService: UserService
  ) {
    // alert(localStorage.getItem('delegateId'));
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.isEmail = this.activatedRoute.snapshot.paramMap.get("isEmail")
      ? this.activatedRoute.snapshot.paramMap.get("isEmail")
      : 0;
    this.isPracticePhy =
      this.activatedRoute.snapshot.paramMap.get("isPracticePhy");
    this.physicianId = this.activatedRoute.snapshot.paramMap.get("physicianId")
      ? this.activatedRoute.snapshot.paramMap.get("physicianId")
      : 0;
    this.requestId = this.activatedRoute.snapshot.paramMap.get("requestId")
      ? this.activatedRoute.snapshot.paramMap.get("requestId")
      : 0;

    this.tempId = this.activatedRoute.snapshot.paramMap.get("tempId")
      ? this.activatedRoute.snapshot.paramMap.get("tempId")
      : 0;
    this.userOptionId = this.activatedRoute.snapshot.paramMap.get(
      "userOptionId"
    )
      ? this.activatedRoute.snapshot.paramMap.get("userOptionId")
      : 0;
    this.emailLogId = this.activatedRoute.snapshot.paramMap.get("emailLogId")
      ? this.activatedRoute.snapshot.paramMap.get("emailLogId")
      : 0;

    this.hippaJson.healthcare_provider = this.userService.getUS_FromatName(
      localStorage.getItem("providerName")
    );
    this.hippaJson.healthcare_provider_email =
      localStorage.getItem("providerEmail");
    this.hippaJson.healthcare_provider_npi = localStorage.getItem(
      "providerPhysicianNipNo"
    );
    this.hippaJson.street_address = localStorage.getItem(
      "providerOfficeAddress"
    );

    this.hippaJson.city_zip =
      localStorage.getItem("providerCity") +
      ", " +
      localStorage.getItem("PhysicianStateName") +
      ", " +
      localStorage.getItem("providerZipCode");

    this.hippaJson.providerCity = localStorage.getItem("providerCity");
    this.hippaJson.provider_phone = localStorage.getItem("provider_phone");
    this.hippaJson.providerPhysicianNipNo = localStorage.getItem(
      "providerPhysicianNipNo"
    );

    this.hippaJson.healthcare_provider = this.userService.getUS_FromatName(
      localStorage.getItem("providerName")
    );
    this.hippaJson.healthcare_provider_email =
      localStorage.getItem("providerEmail");
    this.hippaJson.healthcare_provider_npi = localStorage.getItem(
      "providerPhysicianNipNo"
    );
    this.hippaJson.street_address = localStorage.getItem(
      "providerOfficeAddress"
    );
    // this.hippaJson.city_zip = "Solapur";
    // this.city = localStorage.getItem("providerCity");
    // this.hippaJson.city_zip = this.city;
    // this.hippaJson.city_zip = localStorage.getItem('providerCity')
    //   ? localStorage.getItem('providerCity')
    //   : '' + ', ' + localStorage.getItem('PhysicianStateName')
    //     ? localStorage.getItem('PhysicianStateName')
    //     : '' + ', ' + localStorage.getItem('providerZipCode')
    //       ? localStorage.getItem('providerZipCode')
    //       : '';
    // this.hippaJson.city_zip = localStorage.getItem('providerCity');
    this.hippaJson.providerCity = localStorage.getItem("providerCity");
    this.hippaJson.providerPhone = localStorage.getItem("providerPhone");
    this.hippaJson.providerPhysicianNipNo = localStorage.getItem(
      "providerPhysicianNipNo"
    );

    // this.hippaJson.patient_name = this.userService.getUS_FromatName(localStorage.getItem('loggedInUserName'));
    // this.fnFindSignature(this.loggedInUserId);
    this.fnFindDemographic(this.patientId);
    this.findUserDetails(this.patientId);

    this.hippaJson.patient_signature_date = this.currentDate;
    this.hippaJson.witness_signature_date = this.currentDate;
    this.hippaJson.pre_to = this.currentDate;
    this.hippaJson.post_to = this.currentDate;
    this.hippaJson.healthcare_provider = localStorage.getItem(
      "selectedPhysicianName"
    );
    this.hippaJson.PhysicianOfficeAddress = localStorage.getItem(
      "selectedPhysicianOfficeAddress"
    );
    this.hippaJson.physicianNPI = localStorage.getItem("selectedPhysicianNPI");
    this.hippaJson.physicianEmail = localStorage.getItem(
      "selectedPhysicianEmail"
    );
    this.hippaJson.physicianPhone = localStorage.getItem("Mobile");
  }

  ngOnInit() {
    // console.log(localStorage);
    this.patientId = this.activatedRoute.snapshot.paramMap.get("patientId");
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    this.hippaJson.checkbox_01 = false;
    this.hippaJson.checkbox_02 = false;
    this.hippaJson.checkbox_03 = false;
    this.hippaJson.checkbox_04 = false;
    this.hippaJson.checkbox_05 = false;
    this.hippaJson.checkbox_06 = false;
    this.hippaJson.checkbox_07 = false;
    this.hippaJson.checkbox_08 = false;
    this.hippaJson.checkbox_09 = false;
    this.hippaJson.checkbox_10 = false;
    this.hippaJson.checkbox_11 = false;
    this.hippaJson.checkbox_12 = false;
    this.hippaJson.checkbox_13 = false;
    this.hippaJson.checkbox_14 = false;
    this.hippaJson.checkbox_15 = false;
    this.hippaJson.checkbox_16 = false;
    this.hippaJson.checkbox_17 = false;
    this.hippaJson.checkbox_18 = false;
    this.hippaJson.checkbox_19 = false;
    this.hippaJson.checkbox_20 = false;
    this.hippaJson.checkbox_21 = false;
    this.hippaJson.checkbox_22 = false;
    this.hippaJson.checkbox_23 = false;
    this.hippaJson.checkbox_24 = false;
    this.hippaJson.checkbox_25 = false;
    this.hippaJson.checkbox_26 = false;
    this.hippaJson.checkbox_27 = false;
    this.hippaJson.checkbox_28 = false;
    this.hippaJson.checkbox_29 = false;
    this.hippaJson.checkbox_30 = false;
    this.hippaJson.checkbox_31 = false;
    this.hippaJson.checkbox_32 = false;
    this.hippaJson.checkbox_33 = false;
    this.hippaJson.checkbox_34 = false;
    this.hippaJson.checkbox_35 = false;
    this.hippaJson.checkbox_36 = false;
    this.hippaJson.checkbox_37 = false;
    this.hippaJson.checkbox_38 = false;
    this.hippaJson.checkbox_39 = false;
    this.hippaJson.checkbox_40 = false;
    this.hippaJson.checkbox_41 = false;
    this.hippaJson.checkbox_42 = false;
    this.hippaJson.checkbox_43 = false;
    this.hippaJson.checkbox_44 = false;
    this.hippaJson.checkbox_45 = false;
    this.hippaJson.checkbox_46 = false;
    this.hippaJson.checkbox_47 = false;
    this.hippaJson.checkbox_48 = false;
    this.hippaJson.checkbox_49 = false;
    this.hippaJson.checkbox_50 = false;
    this.hippaJson.checkbox_51 = false;
    this.hippaJson.checkbox_52 = false;
    this.hippaJson.checkbox_53 = false;
    this.hippaJson.checkbox_54 = false;
    this.hippaJson.checkbox_55 = false;
    this.hippaJson.checkbox_56 = false;
    this.hippaJson.checkbox_57 = false;

    this.hippaJson.full_street_address = "";
    this.hippaJson.patient_signature = "";
    this.hippaJson.witness_signature = "";
    this.hippaJson.patient_name = "";
    this.hippaJson.physicianName = "";
    this.hippaJson.physicianAddress = "";
    this.hippaJson.providerPhone = "";
    this.hippaJson.patient_dob = "";
    this.hippaJson.patient_ssn = "";
    this.hippaJson.patient_address = "";
    this.hippaJson.patient_city = "";
    this.hippaJson.patient_state = "";
    this.hippaJson.patient_zip = "";
    this.hippaJson.patient_phone = "";
    this.hippaJson.patient_fax = "";
    this.hippaJson.release_info = "";
    this.hippaJson.mailing_address = "";
    this.hippaJson.mailaddress = "";
    this.hippaJson.mail_address = "";
    this.findUserDetails(this.patientId);
    this.fnFindDemographic(this.patientId);
    (this.hippaJson.physicianName = localStorage.getItem(
      "selectedPhysicianName"
    )),
      (this.hippaJson.street_address = this.hippaJson.PhysicianOfficeAddress);
      this.hippaJson.healthcare_provider_npi = localStorage.getItem('selectedPhysicianNPI');

    //this.hippaJson.providerPhone=this.hippaJson.physicianPhone;
    //this.hippaJson.city_zip = this.hippaJson.physicianCity + "," + this.hippaJson.physicianState;
  }

  findUserDetails(patientId) {
    const finalData = {
      company_slug: null,
      id: patientId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.hippaJson.patient_name =
              apiResponse.commandResult.data.userInfo.LastName +
              ", " +
              apiResponse.commandResult.data.userInfo.FirstName;
            this.hippaJson.dob = apiResponse.commandResult.data.userInfo.DOB;
            this.hippaJson.patient_phone =
              apiResponse.commandResult.data.userInfo.Mobile;
            this.hippaJson.patient_email =
              apiResponse.commandResult.data.userInfo.Email;
            // this.hippaJson.patient_city=apiResponse.commandResult.data.userInfo.city;

            //console.log("patient phone ",this.hippaJson.patient_phone);
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }


  async onSubmit() {
    // if (
    //   confirm(
    //    'PLEASE CONFIRM: to print generated  State HIPAA Form.'
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.onSubmit
    );
    if(alertStatus){
      const finalData = {
        selectedDocUrl: this.selectedDocUrl,
        fileDesTxt: this.fileDesTxt,
        loggedInUserId: this.loggedInUserId,
        userId: this.patientId,

        patient_name: this.hippaJson.patient_name,
        dob: this.hippaJson.dob,
        patient_address: this.hippaJson.patient_address,
        patient_city: this.hippaJson.patient_city,
        patient_state: this.hippaJson.patient_state,
        patient_phone: this.hippaJson.patient_phone,
        patient_fax: this.hippaJson.patient_fax,
        patient_email: this.hippaJson.patient_email,
        physicianNPI: this.hippaJson.PhysicianNipNo,
        healthcare_provider: this.hippaJson.physicianName,
        street_address: this.hippaJson.PhysicianOfficeAddress,
        city_zip: this.hippaJson.city_zip,
        physicianState: this.selectedStateCode,
        assignedDelegateId: this.assignedDelegateId,
        isDelegated: this.isDelegated,
        selectedDelegateId: this.selectedDelegateId,
        selectedEmail: this.selectedPhysician.altEmail,
        selectedPhysician: this.selectedPhysician.physicianName,
        provider_phone: this.hippaJson.provider_phone,
        health_info: this.hippaJson.health_info,
        release_info: this.hippaJson.release_info,
        date: this.hippaJson.date,
        ssn_no: this.hippaJson.ssn_no,
        healthcare_provider_email: this.hippaJson.healthcare_provider_email,
        healthcare_provider_npi: this.hippaJson.healthcare_provider_npi,
        other_specify: this.hippaJson.other_specify,
        other: this.hippaJson.other,
        agency_representative: this.hippaJson.agency_representative,
        other_info: this.hippaJson.other_info,
        expire_on: this.hippaJson.expire_on,
        period_begining: this.hippaJson.period_begining,
        ending: this.hippaJson.ending,
        request_date: this.hippaJson.request_date,
        mail_address: this.hippaJson.patient_email,
        social_security_no: this.hippaJson.social_security_no,
        purposes: this.hippaJson.purposes,
        patient_zip: this.hippaJson.patient_zip,
        patient_Fax: this.hippaJson.patient_Fax,
        providerFax: this.hippaJson.providerFax,
        treatment_start_date: this.hippaJson.treatment_start_date,
        treatment_end_date: this.hippaJson.treatment_end_date,
        other_purpose_of_release: this.hippaJson.other_purpose_of_release,
        other_facility: this.hippaJson.other_facility_may_apply,
        other_care: this.hippaJson.other_care_may_apply,
        other_behavioral: this.hippaJson.other_behavioral_may_apply,
        other_format: this.hippaJson.other_format,
        other_delivery_method: this.hippaJson.other_delivery_method,
        other_relation_sign: this.hippaJson.other_relation_sign,
        release_via_other: this.hippaJson.release_via_other,
        other_id: this.hippaJson.other_id,
        expire_date: this.hippaJson.expire_date,
        release_date: this.hippaJson.release_date,
        minor_info: this.hippaJson.minor_info,
        medical_id: this.hippaJson.medical_id,
        acc_id: this.hippaJson.acc_id,
        representative_city_zip: this.hippaJson.representative_city_zip,

        checkbox_01: this.hippaJson.checkbox_01,
        checkbox_02: this.hippaJson.checkbox_02,
        checkbox_03: this.hippaJson.checkbox_03,
        checkbox_04: this.hippaJson.checkbox_04,
        checkbox_05: this.hippaJson.checkbox_05,
        checkbox_06: this.hippaJson.checkbox_06,
        checkbox_07: this.hippaJson.checkbox_07,
        checkbox_08: this.hippaJson.checkbox_08,
        checkbox_09: this.hippaJson.checkbox_09,
        checkbox_10: this.hippaJson.checkbox_10,
        checkbox_11: this.hippaJson.checkbox_11,
        checkbox_12: this.hippaJson.checkbox_12,
        checkbox_13: this.hippaJson.checkbox_13,
        checkbox_14: this.hippaJson.checkbox_14,
        checkbox_15: this.hippaJson.checkbox_15,
        checkbox_16: this.hippaJson.checkbox_16,
        checkbox_17: this.hippaJson.checkbox_17,
        checkbox_18: this.hippaJson.checkbox_18,
        checkbox_19: this.hippaJson.checkbox_19,
        checkbox_20: this.hippaJson.checkbox_20,
        checkbox_21: this.hippaJson.checkbox_21,
        checkbox_22: this.hippaJson.checkbox_22,
        checkbox_23: this.hippaJson.checkbox_23,
        checkbox_24: this.hippaJson.checkbox_24,
        checkbox_25: this.hippaJson.checkbox_25,
        checkbox_26: this.hippaJson.checkbox_26,
        checkbox_27: this.hippaJson.checkbox_27,
        checkbox_28: this.hippaJson.checkbox_28,
        checkbox_29: this.hippaJson.checkbox_29,
        checkbox_30: this.hippaJson.checkbox_30,
        checkbox_31: this.hippaJson.checkbox_31,
        checkbox_32: this.hippaJson.checkbox_32,
        checkbox_33: this.hippaJson.checkbox_33,
        checkbox_34: this.hippaJson.checkbox_34,
        checkbox_35: this.hippaJson.checkbox_35,
        checkbox_36: this.hippaJson.checkbox_36,
        checkbox_37: this.hippaJson.checkbox_37,
        checkbox_38: this.hippaJson.checkbox_38,
        checkbox_39: this.hippaJson.checkbox_39,
        checkbox_40: this.hippaJson.checkbox_40,
        checkbox_41: this.hippaJson.checkbox_41,
        checkbox_42: this.hippaJson.checkbox_42,
        checkbox_43: this.hippaJson.checkbox_43,
        checkbox_44: this.hippaJson.checkbox_44,
        checkbox_45: this.hippaJson.checkbox_45,
        checkbox_46: this.hippaJson.checkbox_46,
        checkbox_47: this.hippaJson.checkbox_47,
        checkbox_48: this.hippaJson.checkbox_48,
        checkbox_49: this.hippaJson.checkbox_49,
        checkbox_50: this.hippaJson.checkbox_50,
        checkbox_51: this.hippaJson.checkbox_51,
        checkbox_52: this.hippaJson.checkbox_52,
        checkbox_53: this.hippaJson.checkbox_53,
        checkbox_54: this.hippaJson.checkbox_54,
        checkbox_55: this.hippaJson.checkbox_55,
        checkbox_56: this.hippaJson.checkbox_56,
        checkbox_57: this.hippaJson.checkbox_57,
        checkbox_58: this.hippaJson.checkbox_58,
        checkbox_59: this.hippaJson.checkbox_59,
        checkbox_60: this.hippaJson.checkbox_60,

        isEmail: this.isEmail,
        requestId: this.requestId,
      };
      this.showLoader=true;
      return this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.generateHipaaNC,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            if (this.hippaJson.loggedInUserRoleId == 1) {
              this.router.navigate([
                "/doctor/" +
                  "/demographics/" +
                  this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
                  "/1/",
              ]);
            } else if (this.hippaJson.loggedInUserRoleId == 4) {
              this.router.navigate([
                "/doctor/" +
                  "/demographics/" +
                  this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
                  "/1/",
              ]);
            } else {
              if (window.open(apiResponse.commandResult.data, "_blank")) {
                this.router.navigate([
                  "/doctor/" +
                    "/ushf/" +
                    this.activatedRoute.snapshot.paramMap.get(
                      "loggedInUserId"
                    ) +
                    "/" +
                    this.activatedRoute.snapshot.paramMap.get("patientId") +
                    "/" +
                    this.activatedRoute.snapshot.paramMap.get("demographicId"),
                ]);
              }
            }
            this.showLoader=false;
          },
          (err) => {}
        );
    }
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        "/doctor/" +
          "/dashboard/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    }
  }

  async goBack() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the USHF Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.goBack
    );
    if (alertStatus) {
      this.router.navigate([
        "/doctor/" +
          "/ushf/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.activatedRoute.snapshot.paramMap.get("patientId") +
          "/" +
          this.activatedRoute.snapshot.paramMap.get("demographicId"),
      ]);
    }
  }

  closePdfModal() {
    this.showPdfModal = false;
    this.hippaJson.patient_signature = "";
  }


  ngAfterViewInit() {
    // this.signaturePad is now available
    // this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
    // this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }


  fnFindDemographic(patientId) {
    const finalData = {
      id: patientId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiresponse: any) => {
          this.hippaJson.patient_city=apiresponse.commandResult.data.userInfo.form1City;
          this.hippaJson.patient_state=apiresponse.commandResult.data.userInfo.form1State;
          this.hippaJson.patient_zip=apiresponse.commandResult.data.userInfo.form1ZIP;
          this.hippaJson.patient_address =
            apiresponse.commandResult.data.userInfo.form1StreetName +
            ", " +
            apiresponse.commandResult.data.userInfo.form1City +
            ", " +
            apiresponse.commandResult.data.userInfo.form1State+","+
            apiresponse.commandResult.data.userInfo.form1ZIP;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  autoFormatSSN() {
    // alert('Amit');
    let val = this.hippaJson.patient_ssn.replace(/\D/g, "");
    // val = val.replace(/^(\d{3})/, "$1-");
    // val = val.replace(/-(\d{2})/, "-$1-");
    // val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    this.hippaJson.patient_ssn = val;
  }
  openNCBlankpdf() {
    window.open("https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/North Carolina-HIPAA-Medical-Release-Form.pdf", "_blank")
  }

}
