import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import * as React from "react";

import * as ReactDOM from "react-dom";

// import MyComponent from './Mycomponent';
import AHSChatBot from "./AHSChatBot";
@Component({
  selector: "app-chatBot-window",
  template: '<div [id]="rootId"></div>',
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnChanges, AfterViewInit {
  @Input() propDetails: any;
  patientId: any;
  isPopUp: any;

  public rootId = "rootId";
  

  // ngOnChanges(changes: SimpleChanges) {
  //   this.render();
  // }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['propDetails'] && !changes['propDetails'].firstChange) {
      this.render();
    }
  }
  // ngAfterViewInit() {
  //   // this.patientId = this.propDetails.patientId || "";
  //   // this.isPopUp = this.propDetails.isPopupValue || "";
  //   // alert(this.patientId)
  //   // alert(this.isPopup)
  //   // alert(`Showing chat bot with prop: ${JSON.stringify(this.propDetails)}`);
  //   console.log("propDetails:", this.propDetails); // Check the value in console
    
  //   // AHSChatBot(this.propDetails);
  //   this.render();
  // }

  ngAfterViewInit() {
    this.render();
  }


  // ngOnDestroy() {}
  // ngOnDestroy() {
  //   this.unmountReactComponent();
  // }
  private render() {
    // ReactDOM.render(
    //   React.createElement(AHSChatBot),
    //   document.getElementById(this.rootId)
    // );
    if (this.propDetails) {
      ReactDOM.render(
        React.createElement(AHSChatBot, this.propDetails),
        document.getElementById(this.rootId)
      );
    } else {
      ReactDOM.render(
        React.createElement(AHSChatBot),
        document.getElementById(this.rootId)
      );
    }
  }
  // private unmountReactComponent() {
  //   const element = document.getElementById(this.rootId);
  //   if (element) {
  //     ReactDOM.unmountComponentAtNode(element);
  //   }
  // }
}
