import { Injectable } from '@angular/core';
 
@Injectable({
  providedIn: 'root'
})
export class DataService {
  jsonData: any;

  constructor() {
    this.jsonData = null;
  }

  setData(newData) {
    // Ensure newData is an object
    if (typeof newData !== 'object' || newData === null) {
      throw new Error('newData must be a non-null object');
    }

    // Retrieve existing data
    let existingData: any = sessionStorage.getItem("dataServiceData");
    existingData = existingData ? JSON.parse(existingData) : {};

    // Ensure existingData is an object
    if (typeof existingData !== 'object' || existingData === null) {
      existingData = {};
    }

    // Merge the new data with the existing data
    const mergedData = { ...existingData, ...newData };

    // Save the merged data back to sessionStorage
    sessionStorage.setItem("dataServiceData", JSON.stringify(mergedData));
    this.jsonData = mergedData;
  }
 
  getData() {
    const data = sessionStorage.getItem("dataServiceData");
    return data ? JSON.parse(data) : null;
  }
}