import {Component, OnInit, Input} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiHelper} from '../../_helpers/api.helper';
import {CommonHelper} from '../../_helpers/common.helper';
import {ConstantHelper} from '../../_helpers/constant.helper';
import {AppConfig} from '../../app.config';
import {UserService} from '../../_services/user.service';
import {SweetAlertHelper} from 'src/app/_helpers/sweet.alert.helper';
import {Message} from 'src/app/_locale/message';


@Component({
  selector: 'referral-received',
  templateUrl: './referral-received.component.html',
  styleUrls: ['./referral-received.component.scss'],
})

export class ReferralReceived implements OnInit {
  @Input() selectedEntries!: any;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  childRoute: string;
  loggedInUserId: string;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  showDataVal: string;
  logOne: any = [];
  selectedPatient: any = {};
  selectedRequesting: any = {};
  showPatientModal = false;
  showEmailModal = false;
  showRequestingModal = false;
  loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');
  message = Message;
  secondOpinionLogExpertNotRespondedArray: any = [];
  secondOpinionLogExpertReadArray: any = [];
  secondOpinionLogExpertNotRespondedFlag = false;
  secondOpinionLogExpertRespondedFlag = false;
  secondOpinionLogExpertReadFlag = false;

  referralReceivedDecisionRequired: any = [];
  referralReceivedAccepted: any = [];
  referralReceivedNewUnread: any = [];

  showTableData = 0;
  appointmentList: any = [];
  showLoader = false;
  delegateId = localStorage.getItem('delegateId');
  aknowledgmentEmail = '';
  aknowledgmentLogId = '';
  maxChars = 300;
  emailContent: any = '';
  emailSubject: any = '';
  dtOptions: DataTables.Settings = {
    order: [[2, 'desc']]
  };
  dtOptions1: DataTables.Settings = {};
  

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    public sweetAlertHelper: SweetAlertHelper,
    private router: Router,
    private http: HttpClient,
    public userService: UserService
  ) {
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.showDataVal = this.activatedRoute.snapshot.paramMap.get('showDataVal');
    if (this.delegateId) {
      this.findTransferLog(this.delegateId, this.showDataVal);
    } else {
      this.findTransferLog(this.loggedInUserId, this.showDataVal);
    }
    this.secondOpinionLogExpertReadFlag = true;
    this.dtOptions = {
      searching: false,
      paging: false,
      pageLength: 5,
      info: false
    };
    this.showData(1);
  }


  showData(selectedVal) {
    this.showDataVal = selectedVal;
    if (selectedVal != 5) {
      this.findTransferLog(this.loggedInUserId, this.showDataVal);
    }
  }


  findTransferLog(userId, logType) {
    if (this.delegateId) {
      userId = this.delegateId;
    }
    const finalData = {
      userId,
      logType,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findOpinionLogApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          console.log('mama',res);
          this.showLoader = false;
          if (res.commandResult.data.logs.length > 0) {
              this.logOne = res.commandResult.data.logs;
              const separatedArrays = res.commandResult.data.logs.reduce(
                (acc: any, item: any) => {
                  if (item.CurrentStatus == 1 || item.CurrentStatus == 0) {
                    acc.accepted.push(item);
                  } else if (item.CurrentStatus == 2 || item.CurrentStatus == 4) {
                    acc.decisionRequired.push(item);
                  } else if (item.CurrentStatus == 3) {
                    acc.newUnread.push(item);
                  } else {
                    acc.allArray.push(item);
                  }
                  return acc;
                },
                {accepted: [], decisionRequired: [], newUnread: []}
              );
              this.referralReceivedAccepted = separatedArrays.accepted;
              this.referralReceivedDecisionRequired = separatedArrays.decisionRequired;
              this.referralReceivedNewUnread = separatedArrays.newUnread;
          }
        },
        (err) => {}
      );
  }


  sendAcknowledgement(logDetails, showDataVal, behaviourId) {
    this.router.navigate([
      '/' +
        this.childRoute +
        '/view-docs-acknowledgement/' +
        logDetails.UniqueKey +
        '/' +
        logDetails.RecordId +
        '/' +
        this.loggedInUserId +
        '/' +
        logDetails.UserOptionId +
        '/' +
        behaviourId +
        '/' +
        showDataVal,
    ]);
  }

  reviewLog(logDetails, showDataVal, behaviourId) {
    if(showDataVal == 1) {
      const reqData = {
      recordId: logDetails.RecordId,
      hasReadFlag: 1
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_sendD2dResponseApi,
        reqData,
        this.httpOptions
      )
      .subscribe(
        async (res: any) => {
          this.showLoader = false;
        },
        (err) => {}
      );
    }

    this.router.navigate([
      "/" +
        this.childRoute +
        "/reply-d2d/" +
        logDetails.UniqueKey +
        "/" +
        logDetails.RecordId +
        "/" +
        this.loggedInUserId +
        "/" +
        logDetails.UserOptionId +
        "/" +
        behaviourId +
        "/" +
        showDataVal,
    ]);
  }

  async fnTransferLog(logDetail) {
    let cnfMsg = "";
    let altMsg = "";

    if (logDetail.IsTransferred == 0) {
      cnfMsg = this.message.confirmMessages.adminTransferLogComp.fnTransferLog;
    } else {
      cnfMsg = this.message.confirmMessages.adminTransferLogComp.fnTransferLog1;
    }

    const confirmStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      cnfMsg
    );

    if (logDetail.IsTransferred == 0) {
      altMsg = this.message.alertMessages.adminTransferLogComp.fnTransferLog;
    } else {
      altMsg = this.message.alertMessages.adminTransferLogComp.fnTransferLog1;
    }

    if (confirmStatus) {
      const finalData = {
        logId: logDetail.RecordId,
        loggedInUserId: this.loggedInUserId,
        isTransferred: logDetail.IsTransferred,
      };
      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_transferOpinionLogApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (res: any) => {
            this.showLoader = false;
            this.sweetAlertHelper.alertPopUp(altMsg);
            window.location.reload();
          },
          (err) => {}
        );
    }
  }


  showSecondOpinionLogExpert(value) {
    if (value == 0) {
      this.showTableData = value;
      this.secondOpinionLogExpertReadFlag = false;
      this.secondOpinionLogExpertNotRespondedFlag = false;
      this.secondOpinionLogExpertRespondedFlag = false;
    } else if (value == 1) {
      this.showTableData = value;
      this.secondOpinionLogExpertReadFlag = true;
      this.secondOpinionLogExpertNotRespondedFlag = false;
      this.secondOpinionLogExpertRespondedFlag = false;
    } else if (value == 2) {
      this.showTableData = value;
      this.secondOpinionLogExpertReadFlag = false;
      this.secondOpinionLogExpertNotRespondedFlag = true;
      this.secondOpinionLogExpertRespondedFlag = false;
    } else if (value == 3) {
      this.showTableData = value;
      this.secondOpinionLogExpertReadFlag = false;
      this.secondOpinionLogExpertNotRespondedFlag = false;
      this.secondOpinionLogExpertRespondedFlag = true;
    }
  }

  calculateDaysDifference(requestDate: string): number {
    const providedDate = new Date(requestDate);
  providedDate.setHours(0, 0, 0, 0); 
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const differenceInTime = currentDate.getTime() - providedDate.getTime();
  const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24));
  return differenceInDays;

  }

}
