import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';

import {environment} from './../../../../environments/environment';
import {ApiHelper} from './../../../_helpers/api.helper';
import {CommonHelper} from './../../../_helpers/common.helper';
import {AppConfig} from './../../../../app/app.config';
import {ConstantHelper} from './../../../_helpers/constant.helper';
import {SweetAlertHelper} from '../../../_helpers/sweet.alert.helper';
import { Message } from 'src/app/_locale/message';
import {UserService} from './../../../_services/user.service';
import { DataService } from 'src/app/_helpers/data.service.helper';

interface DocumentData {
  name: string;
  url: string;
}


declare let $: any;

@Component({
  selector: 'app-review-hipaa',
  templateUrl: './review-hipaa.component.html',
  styleUrls: ['./review-hipaa.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class ReviewHipaaComponent implements OnInit {
  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  showDocThumbnails = true;
  ahsPdfUrl: SafeResourceUrl = '';
  documentsList: any = [];
  docGroupId: any;
  docGroupTitle: any;
  docSet: any;
  docTyep: any;
  companyDefaultGroupTitle: any;
  companyDefaultGroupId: any;
  activeGroupId: any;
  selectedDocId: any;
  signedGroups: any = [];
  signedGroupsCount: any;
  showLoader = false;
  isUpdate: any;
  childRoute: string;


  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  profileObj: any;
  tempProfileObj: any;
  showDeleteButton = false;
  showHippaUpdateBtn: string;
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public userService: UserService,
    public sweetAlertHelper: SweetAlertHelper,
    private dataService: DataService,
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    $(document).ready(function() {
      $(window).resize(function() {
        setHeightResizeFunc();
      });
      setHeightResizeFunc();
    });

    function setHeightResizeFunc() {
      const rightHeight =
        $('.right-box .ibox-title').height() +
        $('.right-box .ibox-content').height() -
        45;
      $('.docLists').height(rightHeight);
    }

    this.showHippaUpdateBtn = localStorage.getItem('showHippaUpdateBtn');
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.loggedInUserId = localStorage.getItem('delegateId') ? localStorage.getItem('delegateId') : this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.demographicId = localStorage.getItem('MinorDemographicId') ? localStorage.getItem('MinorDemographicId') : this.activatedRoute.snapshot.paramMap.get('demographicId');
    this.isUpdate = localStorage.getItem('isUpdate');

    this.findDocumentsFromSession()

  }


  async editSignature() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      'Are you sure you want to update your signature?'
    );
    if (alertStatus) {
      this.router.navigate([ this.childRoute +
        '/signature/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId')
      ]);
    }
  }

  showDocument(documentUrl) {
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
    this.showDeleteButton = true;
  }

  async onReset() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      'Are you sure you want to cancel your changes and return to the homepage?'
    );
    if (alertStatus) {
      this.router.navigate([ this.childRoute + '/dashboard/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),]);
    }
  }



  async clearViewr() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      'Are you sure you want to clear the preview?'
    );
    if (alertStatus) {
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
      this.showDeleteButton = false;
    }
  }


  async signOff() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      'Are you sure you want to update your demographics docs?'
    );
    if(localStorage.getItem('loggedInUserRoleId') == '1'){
      if (alertStatus) {
        this.showDocThumbnails = false;
        const httpHeaders = new HttpHeaders({
          'Content-Type': 'application/json',
        });
        const options = {
          headers: httpHeaders,
        };
        
        this.http
          .post(
            this.appConfig.getLambdaUrl06() + ApiHelper.updateAdminDemographicFiles,
            this.dataService.getData(),
            options
          )
          .subscribe(
            (apiResponse) => {
              
              this.resultJsonObj = apiResponse;
              this.router.navigate([ this.childRoute + '/dashboard/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId')]);
              // sessionStorage.removeItem('dataServiceData')
            },
            (err) => {
              // console.log(err);
            }
          );
      }
    } else if (localStorage.getItem('loggedInUserRoleId') == '4'){
      if (alertStatus) {
        this.showDocThumbnails = false;
        const httpHeaders = new HttpHeaders({
          'Content-Type': 'application/json',
        });
        const options = {
          headers: httpHeaders,
        };
        
        this.http
          .post(
            this.appConfig.getLambdaUrl06() + ApiHelper.updatePhysicianDemographicFiles,
            this.dataService.getData(),
            options
          )
          .subscribe(
            (apiResponse) => {
              
              this.resultJsonObj = apiResponse;
              this.router.navigate([ this.childRoute + '/dashboard/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId')]);
              // sessionStorage.removeItem('dataServiceData')
            },
            (err) => {
              // console.log(err);
            }
          );
      }
    }
  }



  

  findDocumentsFromSession() {
    const data = this.dataService.getData().docs;
    
    const documents: DocumentData[] = [
      { name: data.demographicSheetName, url: data.demographicSheetUrl },
      { name: data.ahsHipaaFormName, url: data.ahsHipaaFormUrl },
      { name: data.disclaimerName, url: data.disclaimerUrl }
    ];
    this.documentsList = documents;
    this.getDefaultDocuments();
    return documents;
  }

  getDefaultDocuments() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };

    this.showLoader = true;
  
    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_getSignatureDocsByCompanyGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          
          this.showLoader = false;
  
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            const mappedDocuments = this.resultJsonObj.commandResult.data.documentList.map(doc => ({
              name: doc.DocumentName,
              url: doc.DocumentUrl
            }));
            
            this.documentsList = [...this.documentsList, ...mappedDocuments];
          }
        },
        (err) => {
          // Handle error here
        }
      );
  }
  
}
