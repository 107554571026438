import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {AppConfig} from './../../../app/app.config';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {ArrayHelper} from './../../_helpers/array.helper';
import {SweetAlertHelper} from 'src/app/_helpers/sweet.alert.helper';
import {Message} from 'src/app/_locale/message';
import {async} from '@angular/core/testing';
import { UserService } from 'src/app/_services/user.service';
import * as md5 from 'md5';

@Component({
  selector: 'app-admin-change-password',
  templateUrl: './admin-change-password.component.html',
  styleUrls: ['./admin-change-password.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class AdminChangePasswordComponent implements OnInit {
  loggedInUserId: string;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  profileObj: any = {};
  firstQues: any = [];
  secondQues: any = [];
  showLoader = false;
  message = Message;
  loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');
  password: any;
  cnfPassword: any;
  passwordForm: any;
  fieldTextType: boolean = false;
  newPassword: any = '';
  confirmNewPassword: any = '';
  passwordMismatch: boolean = false;
  cnFieldTextType: boolean = false;
  oldFieldTextType : boolean = false;
  showBackButton: boolean = false;
  showHeader : boolean =false;
  showFooter : boolean =false;

  zone: any;
    childRoute: string;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    public sweetAlertHelper: SweetAlertHelper,
    private router: Router,
    private http: HttpClient,
    public userService: UserService,
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.findUserDetails(this.loggedInUserId);
    this.findFirstSecurityQues();
    this.findSecondSecurityQues();
    this.checkRoute();
  }

  checkRoute() {
    const currentRoute = this.router.url;
    // Assuming '/profile' is the route where you don't want to show the back button
    if(this.loggedInUserRoleId == '1')
      {
        const checkrout =  '/admin/admin-profile/' + this.loggedInUserId
        if(currentRoute !== checkrout){
          this.showBackButton = true;
          this.showFooter = true;
          this.showHeader = true;
        }
      }else {
        const checkrout =  '/doctor/doctor-profile/' + this.loggedInUserId
        if(currentRoute !== checkrout){
          this.showBackButton = true;
          this.showFooter = true;
          this.showHeader = true;
        }
      }

  }

  hashPassword(password: string): string {
    return md5(password);
  }

  findFirstSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 1,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.firstQues = apiResponse.commandResult.data.questionsList;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findSecondSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 2,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.secondQues = apiResponse.commandResult.data.questionsList;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDetails(userId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      id: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status === 1) {
            this.profileObj = apiResponse.commandResult.data.userInfo;
            this.showLoader = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onReset() {
    // if (confirm('PLEASE CONFIRM: Are you certain you want to return to the Home Page?'))

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      if (this.loggedInUserRoleId == '1') {
        this.router.navigate(['admin/dashboard/' + this.loggedInUserId]);
      } else if (this.loggedInUserRoleId == '4') {
        this.router.navigate(['doctor/dashboard/' + this.loggedInUserId]);
      }
    }
  }

  toggleCnFieldTextType() {
    this.cnFieldTextType = !this.cnFieldTextType;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleoldFieldTextType() {
    this.oldFieldTextType = !this.oldFieldTextType;
  }
  checkPasswords(): void {
    // This will be triggered on every change in either the new password or confirm new password
    this.passwordMismatch = this.newPassword !== this.confirmNewPassword;
  }
 
  async checkPasswordHistory(userId, password) {
    try {
        const response = await this.http.post(
            this.appConfig.getLambdaUrl05() + ApiHelper.checkPasswordHistory,
            { userId, password },
            this.httpOptions
        ).toPromise();
        return response as any;
    } catch (error) {
        console.error('Error checking password history:', error);
        throw error;
    }
  }
  
  async onSubmit() {
    if (this.profileObj.password !== this.profileObj.cnfPassword) {
      // alert("Password must match.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp('Password doesn\'t match!');
      return;
    }
    // if (confirm("PLEASE CONFIRM: Are you sure you want to update password?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      'Are you sure you want to update your password?'  
    );
    if (alertStatus) {
      if (this.hashPassword(this.profileObj.old_password) !== this.profileObj.OldPassword) {
        // alert("Incorrect old password");
        const alertStatus = await this.sweetAlertHelper.alertPopUp('Old Password must match!');
        return; 
      }else {

        const apiResponse = await this.checkPasswordHistory(this.loggedInUserId, this.hashPassword(this.newPassword));
        if (apiResponse.commandResult.status == 1) {
          console.log("Is Password Old: Ays", apiResponse.commandResult.status)
          await this.sweetAlertHelper.alertPopUp("Previous Password cannot be used. Please choose a different password.");  

          // Clear the new password and confirm password fields
          this.profileObj.password = '';
          this.profileObj.cnfPassword = '';

          return;
        }

        const finalData = {
          userId: this.loggedInUserId,
          password: this.profileObj.password,
        };
        this.showLoader = true;
        return this.http
          .post(
            this.appConfig.getLambdaUrl05() + ApiHelper.updateUserPassword,
            finalData,
            this.httpOptions
          )
          .subscribe(
            async (apiResponse: any) => {
              // alert("Password updated successfully.");
              this.showLoader = false;
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
               'Password updated successfully! Please log in with your New Password'
              );
              if (alertStatus) {
                localStorage.clear();
                window.open(this.userService.parentSiteUrl() + '/', '_self');
              }
            },
            (err) => {}
          );
      }
    }
  }
  async goBack() {
    if (this.loggedInUserRoleId == '4')
      {
        this.router.navigate([ "/"+ this.childRoute +"/doctor-profile/" +this.loggedInUserId ]);
      }
      else{
        this.router.navigate([ "/"+ this.childRoute +"/admin-profile/" +this.loggedInUserId ]);
      }
  }

  async goToDashboad() {
    this.router.navigate([
      "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
    ]);  }

}
