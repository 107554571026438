import { Injectable } from "@angular/core";
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { LoaderService } from "./loader.service";
import { SweetAlertHelper } from "../_helpers/sweet.alert.helper";
import { Message } from "../_locale/message";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  message = Message;

  constructor(
    private loaderService: LoaderService,
    public sweetAlertHelper: SweetAlertHelper
  ) {}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requests.push(req);
    this.loaderService.isLoading.next(true);

    return new Observable((observer) => {
      const subscription = next.handle(req).subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        async (err: HttpErrorResponse) => {
          this.removeRequest(req);

          if (err.status !== 400) {
            setTimeout(async () => {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.httpinterCepter.removeRequest
              );
              if (alertStatus) {
                window.location.reload();
              }
            }, 300);
          }
          
          observer.error(err);
        },
        () => {
          this.removeRequest(req);
          observer.complete();
        }
      );

      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
