import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';

declare let $: any;
import {AppConfig} from './../../../../app/app.config';
import { Message } from 'src/app/_locale/message';
import {SweetAlertHelper} from './../../../_helpers/sweet.alert.helper';
import {CommonHelper} from './../../../_helpers/common.helper';
import {ApiHelper} from './../../../_helpers/api.helper';
import { DataService } from 'src/app/_helpers/data.service.helper';

@Component({
  selector: 'app-review-signed-docs',
  templateUrl: './review-signed-docs.component.html',
  styleUrls: ['./review-signed-docs.component.scss']
})
export class ReviewSignedDocsComponent implements OnInit {


  isChecked = false;
  loggedInUserId: string;
  resultJsonObj: any = {};
  documentsLength = false;
  documentsList: any = [];
  ahsPdfUrl: SafeResourceUrl = '';
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  showDeleteButton = false;
  showLoader = false;
  message = Message;
  jsonData: any;
  childRoute: string;
  loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    private dataService: DataService,
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    $(document).ready(function() {
      $(window).resize(function() {
        setHeightResizeFunc();
      });
      setHeightResizeFunc();
    });

    function setHeightResizeFunc() {
      const rightHeight =
        $('.right-box .ibox-title').height() +
        $('.right-box .ibox-content').height() -
        45;
      $('.docLists').height(rightHeight);
    }
    this.findSignedDocuments();
  }


  loadHippaDoc(documentUrl, i) {
    // Handle the document URL
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
    this.showDeleteButton = true;
  }


  findSignedDocuments() {

    this.getDefaultDocuments();
    const finalData = {
      userId: this.loggedInUserId
    };
  
    // Determine the API endpoint based on userRoleId
    let apiEndpoint;
    if (localStorage.getItem('loggedInUserRoleId') == '1') {
      apiEndpoint = ApiHelper.getDemographicAdminFiles;
    } else if (localStorage.getItem('loggedInUserRoleId') == '4') {
      apiEndpoint = ApiHelper.getDemographicPhysicianFiles;
    } else {
      return;
    }
  
    this.http
      .post(
        this.appConfig.getLambdaUrl06() + apiEndpoint,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.showLoader = false;
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.physicianDocs.length > 0) {
            this.documentsLength = true;
            this.documentsList = this.resultJsonObj.commandResult.data.physicianDocs;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }
  

  async clearViewr() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      'Are you sure you want to clear the preview?'
    );
    if (alertStatus) {
      $('.selectAll').prop('checked', false);
      $('.docChecks').each(function() {
        $(this).prop('checked', false);
      });
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
      this.showDeleteButton = false;
    }
  }

  async homePage() {
    this.router.navigate([this.childRoute + '/dashboard/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId')]);
  }

  async back() {
    if (this.loggedInUserRoleId == '4')
      {
        this.router.navigate([ "/"+ this.childRoute +"/doctor-profile/" +this.loggedInUserId ]);
      }
      else{
        this.router.navigate([ "/"+ this.childRoute +"/admin-profile/" +this.loggedInUserId ]);
      }
  }

  getDefaultDocuments() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };

    this.showLoader = true;
  
    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_getSignatureDocsByCompanyGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          
          this.showLoader = false;
  
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            const mappedDocuments = this.resultJsonObj.commandResult.data.documentList.map(doc => ({
              documentName: doc.DocumentName,
              documentUrl: doc.DocumentUrl
            }));
            
            this.documentsList = [...this.documentsList, ...mappedDocuments];
          }
        },
        (err) => {
          // Handle error here
        }
      );
  }

}
