export const  ApiHelper = {
  ///////////////////////////////////// Set 01 Starts////////////////////////////////////////////////////////////////////
  name_loginApi: 'login',
  name_verifyLoginOtpApi: 'verifyLoginOtp',
  name_registrationApi: 'registration',
  name_verifyRegistrationOtpApi: 'verifyRegistrationOtp',
  name_checkExistingEmailApi: 'checkExistingEmail',
  name_sendPasswordLinkApi: 'sendPasswordLink',
  name_modifyPasswordApi: 'modifyPassword',
  name_AdminloginApi: 'adminLogin',
  name_verifyAdminLoginOtpApi: 'verifyAdminLoginOtp',
  name_checkExistingAdminEmailApi: 'checkExistingAdminEmail',
  name_saveDemographicFormApi: 'saveDemographicForm',
  name_updateDemographicDetailsApi: 'updateDemographicDetails',
  name_findDemographicFormApi: 'findDemographicForm',
  name_findUserDetailsApi: 'findUserDetails',
  name_findUserDemographicApi: 'findUserDemographic',
  name_generateDemographicPdfApi: 'generateDemographicPdf',
  name_generateHippaRealeasPdfApi: 'generateHippaRealeasPdf',
  name_generateWaiverDisclaimerPdfApi: 'generateWaiverDisclaimerPdf',
  name_generateSignatureImageApi: 'generateSignatureImage',
  name_getUsersListApi: 'getUsersList',
  name_verifyRecaptachaApi: 'verifyRecaptacha',
  name_getAdminUsersListApi: 'getAdminUsersList',
  name_getSignatureDocsByCompanyApi: 'getSignatureDocsByCompany',
  name_getCitiesByStateCodeApi: 'getCitiesByStateCode',
  name_getAllQuestionsListApi: 'getAllQuestionsList',
  name_updateNewpassword: 'updateNewPassword',
  ///////////////////////////////////// Set 01 Ends//////////////////////////////////////////////////////////////////////
  ///////////////////////////////////// Set 02 Starts////////////////////////////////////////////////////////////////////
  name_saveAdminUserApi: 'saveAdminUser',
  name_verifyAddressApi: 'verifyAddress',
  name_superAdminLoginApi: 'superAdminLogin',
  name_verifySuperAdminLoginOtpApi: 'verifySuperAdminLoginOtp',
  name_getCompaniesListApi: 'getCompaniesList',
  name_checkExistingSuperAdminEmailApi: 'checkExistingSuperAdminEmail',
  name_getAdminFaqListApi: 'getAdminFaqList',
  name_getAllUsersListApi: 'getAllUsersList',
  name_getAllUsersListByCompanyApi: 'getAllUsersListByCompany',
  name_getAllFaqListApi: 'getAllFaqList',
  name_getAllFaqListByCompanyApi: 'getAllFaqListByCompany',
  name_getAllAdminUsersListApi: 'getAllAdminUsersList',
  name_getAllAdminUsersListByCompanyApi: 'getAllAdminUsersListByCompany',
  name_deactivateUserApi: 'deactivateUser',
  name_activateUserApi: 'activateUser',
  name_getAdminDocumentsListApi: 'getAdminDocumentsList',
  name_deactivateDocApi: 'deactivateDoc',
  name_activateDocApi: 'activateDoc',
  name_saveAdminDocApi: 'saveAdminDoc',
  name_modifyAdminDocApi: 'modifyAdminDoc',
  name_getAdminLogsListApi: 'getAdminLogsList',
  name_changeShowOnConfirmationStatusApi: 'changeShowOnConfirmationStatus',
  name_getActiveAdminDocumentsListApi: 'getActiveAdminDocumentsList',
  ///////////////////////////////////// Set 02 Ends//////////////////////////////////////////////////////////////////////
  ///////////////////////////////////// Set 03 Starts////////////////////////////////////////////////////////////////////
  name_addDocGroupApi: 'addDocGroup',
  name_findDocGroupsByCompanyApi: 'findDocGroupsByCompany',
  name_getPhysiciansByStateCodeApi: 'getPhysiciansByStateCode',
  name_getStateHippaApi: 'getStateHippa',
  name_getEmailLogsApi: 'getEmailLogs',
  name_getEmailLogsByCompanySlugApi: 'getEmailLogsByCompanySlug',
  name_findDocsByGroupIdApi: 'findDocsByGroupId',
  name_getDocsByEmailLogIdApi: 'getDocsByEmailLogId',
  name_getPhysiciansByCityCodeApi: 'getPhysiciansByCityCode',
  name_getUserDetailApi: 'getUserDetail',
  name_findPhysicianCitiesApi: 'findPhysicianCities',
  name_findLogDetailApi: 'findLogDetail',
  name_getDocumentByIdApi: 'getDocumentById',
  name_findAllDocumentsApi: 'findAllDocuments',
  name_findDefaultDocumentsApi: 'findDefaultDocuments',
  name_findDocsBySubGroupApi: 'findDocsBySubGroup',
  name_findTempDocsBySubGroupApi: 'findTempDocsBySubGroup',
  name_getUploadedDocumentsList: 'getUploadedDocumentsList',
  name_deactivateUserDocsApi: 'deactivateUserDocs',
  name_getCompanyDefaultGroupApi: 'getCompanyDefaultGroup',
  name_getSignatureGroupsApi: 'getSignatureGroups',
  name_getNIPDetailsApi: 'getNIPDetails',
  name_doctorRregistrationApi: 'doctorRregistration',
  name_verifyDoctorRegistrationOtpApi: 'verifyDoctorRegistrationOtp',
  name_checkDocActiveUrlApi: 'checkDocActiveUrl',
  name_findExistingDocApi: 'findExistingDoc',
  name_findDoctorByUserIdApi: 'findDoctorByUserId',
  name_getAllPhysiciansApi: 'getAllPhysicians',
  getAdminDetailsById: 'getAdminDetailsById',
  ///////////////////////////////////// Set 03 Ends//////////////////////////////////////////////////////////////////////
  ///////////////////////////////////// Set 04 Starts//////////////////////////////////////////////////////////////////////
  name_generateDocSignatureImageApi: 'generateDocSignatureImage',
  name_getAcknowledgmentDocumentsList: 'getAcknowledgmentDocumentsList',
  name_authenticateDoctorApi: 'authenticateDoctor',
  name_registrationNormalApi: 'registrationNormal',
  name_verifyRegistrationOtpNormalApi: 'verifyRegistrationOtpNormal',
  name_invitePhysicainApi: 'invitePhysicain',
  name_doctorRregistrationNormalApi: 'doctorRregistrationNormal',
  name_doctorRregistrationNormalNoInvitationApi:
    'doctorRregistrationNormalNoInvitation',
  name_verifyDoctorRegistrationOtpNormalApi:
    'verifyDoctorRegistrationOtpNormal',
  name_updateDemographicDetailsGeneralApi: 'updateDemographicDetailsGeneral',
  name_saveDemographicFormApiGeneral: 'saveDemographicFormGeneral',
  name_updateDemographicDetailsApiGeneral: 'updateDemographicDetailsGeneral',
  name_getSignatureDocsByCompanyGeneralApi: 'getSignatureDocsByCompanyGeneral',
  name_findUserDoctorsApi: 'findUserDoctors',
  name_getAllAdminDocumentsListApi: 'getAllAdminDocumentsList',
  name_getAllAdminDocumentsListByCompanyApi:
    'getAllAdminDocumentsListByCompany',
  name_saveBulkPhysiciansApi: 'saveBulkPhysicians',
  name_findCompaniesListApi: 'findCompaniesList',
  name_findUserDemographicGeneralApi: 'findUserDemographicGeneral',
  name_esignEmailLogApi: 'esignEmailLog',
  name_findPhysicianByIdApi: 'findPhysicianById',
  name_findAllRegisteredPhysiciansApi: 'findAllRegisteredPhysicians',
  name_findUserRegisteredPhysiciansApi: 'findUserRegisteredPhysicians',
  name_findPracticePhysiciansApi: 'findPracticePhysicians',
  name_findPhysiciansByCompanySlug: 'findPhysiciansByCompanySlug',
  name_saveSignatureImageApi: 'saveSignatureImage',
  name_findPatientHippaDocsApi: 'findPatientHippaDocs',
  name_findD2dDocumentsApi: 'findD2dDocuments',
  name_findPractices: 'findPractices',
  get_SpecialtyByPractices: 'findSpecialitiesByCompany',
  get_SubspecialtyBySpecialtyAndPractice: 'findSubSpecialitiesByCompany',
  get_PhysicianByCompany: 'findPhysiciansByCompanySlug',
  name_findParticipatingDoctorsApi: 'findParticipatingDoctors',
  name_findPhysicainHippaApi: 'findPhysicainHippa',
  name_findAllAllowedDocumentsApi: 'findAllAllowedDocuments',
  name_findAllAllowedUploadedDocumentsApi: 'findAllAllowedUploadedDocuments',
  name_findAllAllowedDoctorAcknowledgmentsApi:
    'findAllAllowedDoctorAcknowledgments',
  name_findAllAllowedGroupDocsApi: 'findAllAllowedGroupDocs',
  name_getPhyAcknowledgmentDocumentsList: 'getPhyAcknowledgmentDocuments',
  name_findPhysicianDetailsByUserIdApi: 'findPhysicianDetailsByUserId',
  name_expertOpinionApi: 'expertOpinion',
  name_findVideoDocsByUserApi: 'findVideoDocsByUser',
  name_findDelegationsByAdminApi: 'findDelegationsByAdmin',
  ///////////////////////////////////// Set 04 Ends//////////////////////////////////////////////////////////////////////
  ///////////////////////////////////// PHP Starts///////////////////////////////////////////////////////////////////////
  // php_generateDemographicPdfApi: 'generateDemographicPdf.php',
  php_generateHippaRealeasPdfApi: 'generateHippaRealeasPdf.php',
  php_generateWaiverDisclaimerPdfApi: 'generateWaiverDisclaimerPdf.php',
  php_generateSignatureImageApi: 'generateSignatureImage.php',
  php_sendSignOffMailApi: 'sendSignOffMail.php',
  //php_sendSignOffMailDocGroupApi: 'sendSignOffMailDocGroup.php',
  php_sendOtpApi: 'sendOtp.php',
  ahs_uploadFile2ElemrexApi: 'uploadFile2Elemrex.php',
  php_showHippaDocApi: 'showHippaDoc.php',
  php_showDisclaimerDocApi: 'showDisclaimerDoc.php',
  php_uploadFileApi: 'uploadFile.php',
  php_upload5FilesApi: 'upload5Files.php',
  php_uploadEsignFileApi: 'uploadEsignFile.php',
  php_findMailingDocsApi: 'findMailingDocs.php',
  php_sendDocsApi: 'sendDocs.php',

  php_adminSendDocsApi: 'adminSendDocs.php',
  php_resendDocsApi: 'resendDocs.php',
  php_adminResendDocsApi: 'adminResendDocs.php',
  // php_saveDocGroupApi: 'saveDocGroup.php',
  php_updateProfileApi: 'updateProfile.php',
  php_updateUserDocGroupApi: 'updateUserDocGroup.php',
  php_saveAdminDocNewApi: 'saveAdminDocNew.php',
  php_modifyAdminDocNewApi: 'modifyAdminDocNew.php',
  php_setDefaultGroupApi: 'setDefaultGroup.php',
  php_generateSignatureFilesPdfApi: 'generateSignatureFilesPdf.php',
  // php_generateSignatureFilesPdfPhysicianApi: 'generateSignatureFilesPdfPhysician.php',
  php_deactivateUserDocsApi: 'deactivateUserDocs.php',
  // php_sendDoctorSignUpMainApi: 'sendDoctorSignUpMain.php',
  // php_cleanTempDocsApi: 'cleanTempDocs.php',
  php_deleteDocApi: 'deleteDoc.php',
  // php_allotDocGroupApi: 'allotDocGroup.php',
  php_sendInvitationApi: 'sendInvitation.php',
  php_checkInvitationApi: 'checkInvitation.php',
  php_findUserSignDocsApi: 'findUserSignDocs.php',
  // php_updateDoctorProfileApi: 'updateDoctorProfile.php',
  php_sendUSHFApi: 'sendUSHF.php',
  php_findSecondarySpeByNPI: 'findSecondarySpeByNPI.php',
  // php_findUserDetailsApi: 'findUserDetails.php',
  // php_updateAdminProfileApi: 'updateAdminProfile.php',
  php_findUserDetailsByUserHashApi: 'findUserDetailsByUserHash.php',
  php_generateDocSignatureFilePdfApi: 'generateDocSignatureFilePdf.php',
  php_generateViewDocSignatureFilePdfApi: 'generateViewDocSignatureFilePdf.php',
  php_invitePhysicainApi: 'invitePhysicain.php',
  php_saveUserDoctorsApi: 'saveUserDoctors.php',
  // php_setUserDocGroupApi: 'setUserDocGroup.php', 
  php_sendEmailApi: 'sendEmail.php',
  // php_sendUHFApi: 'sendUHF.php',
  // php_sendUHFUserApi: 'sendUHFUser.php',
  // php_sendEmail2AllApi: 'sendEmail2All.php',
  // php_docGroupRequestApi: 'docGroupRequest.php',
  // php_coeNameChangeRequestApi: 'coeNameChangeRequest.php',
  php_findPhysiciansBySubSpecialityApi: 'findPhysiciansBySubSpeciality.php',
  php_expertOpinionApi: 'expertOpinion.php',
  // php_updateParticipatingCenterApi: 'updateParticipatingCenter.php',
  // php_findPhysicianEmailApi: 'findPhysicianEmail.php',
  // php_getSpecialitiesByComapnSlugApi: 'getSpecialitiesByComapnSlug.php',
  // php_saveSpecialityByComapnSlugApi: 'saveSpecialitiesByComapnSlug.php',
  // php_updateSpecialityByComapnSlugApi: 'updateSpecialitiesByComapnSlug.php',
  // php_changeSpecialityStatusByIdApi: 'changeSpecialityStatusById.php',
  // php_getSubSpecialitiesByComapnSlugApi: 'getSubSpecialitiesByComapnSlug.php',
  // php_saveSubSpecialityByComapnSlugApi: 'saveSubSpecialitiesByComapnSlug.php',
  // php_updateSubSpecialityByComapnSlugApi: 'updateSubSpecialitiesByComapnSlug.php',
  // php_changeSubSpecialityStatusByIdApi: 'changeSubSpecialityStatusById.php',
  // php_addParticipatingPhysicianApi: 'addParticipatingPhysician.php',
  php_findParticipatingPhysiciansByCompanySlugApi: 'findParticipatingPhysiciansByCompanySlug.php',
  // php_changeParticipatingPhysicianStatusByIdApi: 'changeParticipatingPhysicianStatusById.php',
  php_docotr2doctorEmail05Api: 'docotr2doctorEmail05.php',
  //php_docotr2doctorEmail04Api: 'docotr2doctorEmail04.php',
  php_docotr2doctorEmail03Api: 'docotr2doctorEmail03.php',
  php_docotr2doctorEmail02Api: 'docotr2doctorEmail02.php',
  php_docotr2doctorEmail01Api: 'docotr2doctorEmail01.php',
  // php_findTransferLogApi: 'findTransferLog.php',
  // php_findOpinionLogApi: 'findOpinionLog.php',
  // php_transferOpinionLogApi: 'transferOpinionLog.php',
  // php_sendHippaNoMatchMailApi: 'sendHippaNoMatchMail.php',
  // php_generateD2DDocSignatureFilePdfApi: 'generateD2DDocSignatureFilePdf.php',
  // php_findUserDetailsByD2DIdApi: 'findUserDetailsByD2DId.php',
  php_findD2DLogDetailApi: 'findD2DLogDetail.php',
  // php_sendD2dResponseApi: 'sendD2dResponse.php',
  // php_findParticipatingPhysiciansBySubSpecialityApi: 'findParticipatingPhysiciansBySubSpeciality.php',

  // php_findCallSchedulesByhysicianIdApi: 'findCallSchedulesByhysicianId.php',
  // php_changePhysicianScheduleStatusByIdApi: 'changePhysicianScheduleStatusById.php',
  // php_savePhysicianCallScheduleApi: 'savePhysicianCallSchedule.php',
  // php_modifyPhysicianCallScheduleApi: 'modifyPhysicianCallSchedule.php',
  // php_findCOEDetailsApi: 'findCOEDetails.php',
  // php_changeOldEventStatusApi: 'changeOldEventStatus.php',
  // php_changeSubspecialtyDivisionStatusApi: 'changeSubspecialtyDivisionStatus.php',
  php_findParticipatingDoctorsBySubSpecialityApi: 'findParticipatingDoctorsBySubSpeciality.php',
  php_findTransferLogByDateApi: 'findTransferLogByDate.php',
  php_findDoctorByCityStateApi: 'findDoctorByCityState.php',
  // php_changeCOEStatusApi: 'changeCOEStatus.php',
  php_sendMailToUserApi: 'sendMailToUser.php',
  php_searchPhysicianByNameStateApi: 'searchPhysicianByNameState.php',
  // php_findLogDetailApi: 'findLogDetail.php',
  php_getPhysiciansByCityCodeApi: 'getPhysiciansByCityCode.php',
  // php_findOnCallLogByMonthApi: 'findOnCallLogByMonth.php',
  php_getDemographicsPrimaryPhysiciansApi: 'getDemographicsPrimaryPhysicians.php',
  php_findD2DUserDetailsByUserHashApi: 'findD2DUserDetailsByUserHash.php',
  // php_delegateAccessApi: 'delegateAccess.php',
  php_findDelegationsByAdminApi: 'findDelegationsByAdmin.php',
  // php_getDelegationLogApi: 'getDelegationLog.php',

  // php_findAllDocumentsApi: 'findAllDocumentsApi.php',
  php_findPhysicainHippaApi: 'findPhysicainHippa.php',

  php_getUsersListByPhysicianIdApi: 'getUsersListByPhysicianId.php',
  php_getAllUsersListByPhysicianIdApi: 'getAllUsersListByPhysicianId.php',

  php_addRemovepataintFromDoctorListApi: 'addRemovepataintFromDoctorList.php',
  // php_findInvitationDetailsApi: 'findInvitationDetails.php',
  php_findUserDocumentGroupsApi: 'findUserDocumentGroups.php',
  php_findDoctorsWithUserApi: 'findDoctorsWithUser.php',
  // php_getAdminEmailLogApi: 'getAdminEmailLog.php',

  // php_searchNPIRegistryApi: 'searchNPIRegistry.php',
  php_searchNPIDetailsApi: 'searchNPIDetails.php',
  php_logoutMailApi: 'logoutMail.php',
  php_uploadAdminReviewFileApi: 'uploadAdminReviewFile.php',
  // php_getAdminUsersListApi: 'getAdminUsersList.php',
  php_getAdminInvitationLogsApi: 'getAdminInvitationLogs.php',
  php_getAppointmentList: 'get_appointments.php',
  php_visitAppointment: 'visit_appointment.php',
  // php_getLastCommentAdmin: 'get_last_comment_admin.php',
  // php_resendAdminInvite: 'resendAdminInvite.php',
  // php_sendD2dResponse: 'sendD2dResponse.php',
  php_uploadRecording: 'uploadRecording.php',
  // php_d2dEmail: 'd2dEmail.php',
  php_uploadEsignMultipleFiles:'uploadEsignMultipleFiles.php',
  // php_fetchAllDocumentsForPatientHistory: 'fetchAllDocumentsForPatientHistory.php',
  php_uploadDocumentToPatient: 'uploadDocumentToPatient.php',
  php_getUserDetail_SLS05Api: 'getUserDetail_SLS05.php',

  ///////////////////////////////////// PHP Ends/////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////// Set 05 Starts//////////////////////////////////////////////////////////////////////

  name_generateViewDocSignatureImageApi: 'generateViewDocSignatureImage',

  getPatientUploadedDocumentsList: 'getUploadedDocumentsList',
  getPatientHippaDocumentsList: 'getHippaDocumentsList',
  getPatientImageDocumentsList: 'getImageDocumentsList',
  getPatientVideoDocumentsList: 'getVideoDocumentsList',
  getPatientAllDocumentsList: 'getAllDocumentsList',
  getPatientAcknowledgmentDocumentsList: 'getAcknowledgmentDocumentsList',

  getAdminEmailLogs: 'getAdminEmailLogs',
  getPractices: 'getPractices',
  getPhysicianEmail: 'getPhysicianEmail',
  getPhysicianEmailLogs: 'getPhysicianEmailLogs',
  getPhysicianUploadDocuments: 'getPhysicianUploadDocuments',
  getPhysicianByCompany: 'getPhysiciansByCompanySlug',

  getSpecialties: 'getSpecialties',
  getSubSpecialties: 'getSubSpecialties',
  getCoeBySubSpeciality: 'getCoeBySubSpeciality',
  getCoePhysicians: 'getCoePhysicians',

  sendUserQuery: 'sendUserQuery',
  updateUserPassword: 'updateUserPassword',
  changeUploadDocumentStatus: 'changeUploadDocumentStatus',
  findComments: 'findComments',
  findNotes: 'findNotes',
  patientInviteLog: 'patientInviteLog',
  getTopDocumentsList: 'getTopDocumentsList',
  saveComment: 'saveComment',
  getUsersListByPhysicianId: 'getUsersListByPhysicianId',
  getPatientDetailsById : 'getPatientDetailsById',
  updatePatientDetailsByPhysician : 'updatePatientDetailsByPhysician',
  getPatientsTreatingDoctorsList : 'getPatientsTreatingDoctorsList',
  getPatientsNonTreatingDoctorsList : 'getPatientsNonTreatingDoctorsList',
  documentConfirmObjectionPatientHistory : 'documentConfirmObjectionPatientHistory',
  getDocumentConfirmObjectionPatientHistory: 'getDocumentConfirmObjectionPatientHistory',
  generateAiSummaryByClick: 'generateAiSummaryByClick',
  findPracticeDocuments: 'findPracticeDocuments',
  getCompanyDetailsByCompSlug: 'getCompanyDetailsByCompSlug',
  findDictationFiles: 'findDictationFiles',
  updateDictationSummary: 'updateDictationSummary',
  postPhysicianSharedDictationSummary: 'postPhysicianSharedDictationSummary',
  fetchPhysicianSharedDictationSummary: 'fetchPhysicianSharedDictationSummary',
  searchParents: 'searchParents',
  generateTempSignatureImage: 'generateTempSignatureImage',
  generateSignatureImagePhyAdmin:'generateSignatureImagePhyAdmin',
  getDemographicId_phy:'getDemographicId_phy',
  updateComment: 'updateComment',
  getInactiveAndPendingPatientList: 'getInactiveAndPendingPatientList',
  getAdminDesignation: 'getAdminDesignation',
  checkPasswordHistory : 'checkPasswordHistory',
  findUserDetails : 'findUserDetails',
  resendPatientInvite: 'resendPatientInvite',
  findPracticeDocsCountByPhysicianId: 'findPracticeDocsCountByPhysicianId',
  ///////////////////////////////////// Set 05 Ends//////////////////////////////////////////////////////////////////////

  ///////////////// PHP files for States////////////////////////////////////////////
  // php_findUserSignatureApi: 'findUserSignature.php',
  name_saveParentSignatureImageApi: 'saveParentSignatureImage',
  php_generateHippaNJApi: 'generateHippaNJ_Admin.php',
  php_generateHippaPAApi: 'generateHippaPA_Admin.php',
  php_generateHippaNYApi: 'generateHippaNY_Admin.php',
  php_generateHippaFLApi: 'generateHippaFL_Admin.php',
  php_generateHippaCTApi: 'generateHippaCT_Admin.php',
  php_generateHippaOHApi: 'generateHippaOH_Admin.php',
  php_generateHippaCOApi: 'generateHippaCO_Admin.php',
  php_generateHippaCAApi: 'generateHippaCA_Admin.php',
  php_generateHippaALApi: 'generateHippaAL_Admin.php',
  php_generateHippaAZApi: 'generateHippaAZ_Admin.php',
  php_generateHippaARApi: 'generateHippaAR_Admin.php',
  php_generateHippaDEApi: 'generateHippaDE_Admin.php',
  php_generateHippaGAApi: 'generateHippaGA_Admin.php',
  php_generateHippaHIApi: 'generateHippaHI_Admin.php',
  php_generateHippaIDApi: 'generateHippaID_Admin.php',
  php_generateHippaILApi: 'generateHippaIL_Admin.php',
  php_generateHippaAKApi: 'generateHippaAK_Admin.php',
  php_generateHippaTXApi: 'generateHippaTX_Admin.php',
  php_generateHippaSDApi: 'generateHippaSD_Admin.php',
  php_generateHippaNDApi: 'generateHippaND_Admin.php',
  php_generateHippaVAApi: 'generateHippaVA_Admin.php',
  php_generateHippaVTApi: 'generateHippaVT_Admin.php',
  php_generateHippaKYApi: 'generateHippaKY_Admin.php',
  php_generateHippaWIApi: 'generateHippaWI_Admin.php',
  php_generateHippaWAApi: 'generateHippaWA_Admin.php',
  php_generateHippaWVApi: 'generateHippaWV_Admin.php',
  php_generateHippaTNApi: 'generateHippaTN_Admin.php',
  php_generateHippaLAApi: 'generateHippaLA_Admin.php',
  php_generateHippaMDApi: 'generateHippaMD_Admin.php',
  php_generateHippaMNApi: 'generateHippaMN_Admin.php',
  php_generateHippaMOApi: 'generateHippaMO_Admin.php',
  php_generateHippaMSApi: 'generateHippaMS_Admin.php',
  php_generateHippaNVApi: 'generateHippaNV_Admin.php',
  php_generateHippaOKApi: 'generateHippaOK_Admin.php',
  php_generateHippaMAApi: 'generateHippaMA_Admin.php',
  php_generateHippaNHApi: 'generateHippaNH_Admin.php',
  php_generateHippaKSApi: 'generateHippaKS_Admin.php',
  php_generateHippaIAApi: 'generateHippaIA_Admin.php',
  php_generateHippaMTApi: 'generateHippaMT_Admin.php',
  php_generateHippaNEApi: 'generateHippaNE_Admin.php',
  php_generateHippaORApi: 'generateHippaOR_Admin.php',
  php_generateHippaNCApi: 'generateHippaNC_Admin.php',
  php_generateHippaSCApi: 'generateHippaSC_Admin.php',
  php_generateHippaMEApi: 'generateHippaME_Admin.php',
  php_generateHippaRIApi: 'generateHippaRI_Admin.php',
  php_generateHippaUTApi: 'generateHippaUT_Admin.php',
  php_generateHippaNMApi: 'generateHippaNM_Admin.php',
  php_generateHippaINApi: 'generateHippaIN_Admin.php',
  // php_uploadAzureFile: 'uploadAzureFile.php',

  php_findUserDoctorsApi: 'findUserDoctors.php',
  php_sendUSHFUser: 'sendUSHFUser',
  php_findUserPhysicianById: 'findUserPhysicianById',
  // php_sendEmailToPrimaryAdminApi: 'sendEmailToPrimaryAdmin.php',
  // php_saveCommentApi: 'saveComment.php',
  // php_saveCommentRemarkApi: 'saveCommentRemark.php',
  // php_resendPatientInvite: 'resendPatientInvite.php',
  php_gptIsPatientExists: 'gptIsPatientExists.php',
  // php_findUserPracticeApi: 'findUserPractice.php',
  php_findUserDoctorsByPracticeApi: 'findUserDoctorsByPractice.php',
  php_generate_demographic_temp_Api: 'generate_demographic_temp.php',
  php_generate_signature_files_pdf_temp_Api: 'generate_signature_files_pdf_temp.php',
  php_get_demographic_temp_Api: 'get_demographic_temp.php',
  // php_sendSignOffMail_updateApi: 'sendSignOffMail_update.php',
  // php_get_user_docs_temp_Api: 'get_user_docs_temp.php',
  updateSecurityQuestions: 'updateSecurityQuestions',
  php_sendSignOffMailDocGroupApi: 'sendSignOffMailDocGroupApi.php',
///////////////////////////////////// Set 06 start//////////////////////////////////////////////////////////////////////

  bulkInviteAPI: 'bulkInvite',
  sendInvitation : 'sendInvitation',
  practicePreOpNoteDoc:'preOpNote',
  enableElectronicallyFill:'enableElecFill',
  disableElectronicallyFill:'disableElecFill',
  practicehealtHistoryForm:'healthHistoryForm',
  practicePreOpInstruction:'preOpInst',
  practiceDemographicForm:'demographics',
  practiceReliantDisclaimer:'reliantDisclaimer',
  generateHipaaME: 'generateHipaaME',
  generateHipaaKY: 'generateHipaaKY',
  generateHipaaNV: 'generateHipaaNV',
  generateHipaaLA: 'generateHipaaLA',
  generateHipaaOR: 'generateHipaaOR',
  generateHipaaMA: 'generateHipaaMA',
  generateHipaaMS: 'generateHipaaMS',
  generateHipaaNM: 'generateHipaaNM',
  generateHipaaNY: 'generateHipaaNY',
  generateHipaaOK: 'generateHipaaOK',
  generateHipaaPA: 'generateHipaaPA',
  generateHipaaUT: 'generateHipaaUT',
  generateHipaaWA: 'generateHipaaWA',
  generateHipaaWV: 'generateHipaaWV',
  generateHipaaWI: 'generateHipaaWI',
  generateHipaaVT: 'generateHipaaVT',
  generateHipaaVA: 'generateHipaaVA',
  generateHipaaTX: 'generateHipaaTX',
  generateHipaaTN: 'generateHipaaTN',
  generateHipaaDE: 'generateHipaaDE',
  generateHipaaFL: 'generateHipaaFL',
  generateHipaaGA: 'generateHipaaGA',
  generateHipaaID: 'generateHipaaID',
  generateHipaaIL: 'generateHipaaIL',
  generateHipaaKS: 'generateHipaaKS',
  generateHipaaMD: 'generateHipaaMD',
  generateHipaaNJ: 'generateHipaaNJ',
  generateHipaaRI: 'generateHipaaRI',
  generateHipaaSC: 'generateHipaaSC',
  generateHipaaOH: 'generateHipaaOH',
  generateHipaaNH: 'generateHipaaNH',
  generateHipaaNE: 'generateHipaaNE',
  generateHipaaSD: 'generateHipaaSD',
  generateHipaaCO: 'generateHipaaCO',
  generateHipaaAK: 'generateHipaaAK',
  generateHipaaAL: 'generateHipaaAL',
  generateHipaaAR: 'generateHipaaAR',
  generateHipaaAZ: 'generateHipaaAZ',  
  generateHipaaCA: 'generateHipaaCA', 
  generateHipaaMN: 'generateHipaaMN', 
  generateHipaaMO: 'generateHipaaMO', 
  generateHipaaMT: 'generateHipaaMT',
  generateHipaaNC: 'generateHipaaNC',
  generateHipaaND: 'generateHipaaND',
  generateHipaaIN: 'generateHipaaIN',
  generateHipaaCT: 'generateHipaaCT',
  generateHipaaIA: 'generateHipaaIA',
  reliantSurgicalCenterAnesthesiaQuestionnariePg1:'reliantSurgicalCenterAnesthesiaQuestionnariePg1',
  reliantSurgicalCenterAnesthesiaQuestionnariePg2:'reliantSurgicalCenterAnesthesiaQuestionnariePg2',
  princetonSurgicalSpecialtiesHealthHistoryForm2:'princetonSurgicalSpecialtiesHealthHistoryForm2',
  priNewPatientIntakeSignaturePage:'priNewPatientIntakeSignaturePage',
  greenbergCosmeticSurgeryORScheduling:'greenbergCosmeticSurgeryORScheduling',
  priAestheticHistoryForm:'priAestheticHistoryForm',
  priHealthHistoryForm1:'priHealthHistoryForm1',
  priHealthHistoryForm2:'priHealthHistoryForm2',
  greenbergCosmeticSurgeryDemographics:'greenbergCosmeticSurgeryDemographics',
  greenbergCosmeticSurgeryHealthHistoryPage2:'greenbergCosmeticSurgeryHealthHistoryPage2',
  greenbergCosmeticSurgeryHealthHistoryPage1 : 'greenbergCosmeticSurgeryHealthHistoryPage1',
  getAdminUsersList: 'getAdminUsersList',
  generate_demographic_phy_temp:'generate_demographic_phy_temp',
  generate_signature_files_pdf_phy:'generate_signature_files_pdf_phy',
  generateDemographicAdmin: 'generateDemographicAdmin',
  generateSignatureFilesPdfAdmin: 'generateSignatureFilesPdfAdmin',
  generateDemographicPhysician: 'generateDemographicPhysician',
  // findUserDetails: 'findUserDetails',
  updateAdminProfile : 'updateAdminProfile',
  getDemographicPhysicianDetails:'getDemographicPhysicianDetails',
  updateAdminProfileDetails: 'updateAdminProfileDetails',
  updateDoctorProfile:'updateDoctorProfile',
  updatePhysicianDemographicFiles:'updatePhysicianDemographicFiles',
  getDemographicPhysicianFiles:'getDemographicPhysicianFiles',
  updateAdminDemographicFiles: 'updateAdminDemographicFiles',
  getDemographicAdminFiles: 'getDemographicAdminFiles',
  uploadRecording: 'uploadRecording',
  addRemovepatientFromDoctorList: 'addRemovepatientFromDoctorList',
  emailForAddingDoctorToPatientList: 'emailForAddingDoctorToPatientList',
  allotDocGroup: 'allotDocGroup',
  getAdminInvitationLogs: 'getAdminInvitationLogs',
  sendD2dResponse : 'sendD2dResponse',
  findD2DUserDetailsByUserHash: 'findD2DUserDetailsByUserHash',
  referralEmailToPatient : 'referralEmailToPatient',
  getRequestingPhysicianDetailsByLogID : 'getRequestingPhysicianDetailsByLogID',
  docotr2doctorEmail04 : 'docotr2doctorEmail04',
  fetchAllDocumentsForPatientHistory: 'fetchAllDocumentsForPatientHistory',
  sendSignOffMailDocGroup: 'sendSignOffMailDocGroup',
  changeDocSignFlag: 'changeDocSignFlag',
  getLastCommentAdmin: 'getLastCommentAdmin',
  findDoctorsWithUser: 'findDoctorsWithUser',
  findUserDetails06: 'findUserDetails',
  delegateAccess: 'delegateAccess',
  changeCOEStatus: 'changeCOEStatus',
  saveDocGroup: 'saveDocGroup',
  findUserPractice: 'findUserPractice',
  searchNPIRegistry: 'searchNPIRegistry',
  updateParticipatingCenter: 'updateParticipatingCenter',
  getSubSpecialitiesByComapnSlug: 'getSubSpecialitiesByComapnSlug',
  findParticipatingPhysiciansBySubSpeciality: 'findParticipatingPhysiciansBySubSpeciality',
  findCallSchedulesByhysicianId: 'findCallSchedulesByhysicianId',
  addParticipatingPhysician: 'addParticipatingPhysician',
  changeParticipatingPhysicianStatusById: 'changeParticipatingPhysicianStatusById',
  changePhysicianScheduleStatusById:'changePhysicianScheduleStatusById',
  changeSpecialityStatusById: 'changeSpecialityStatusById',
  changeSubSpecialityStatusById: 'changeSubSpecialityStatusById',
  changeSubspecialtyDivisionStatus: 'changeSubspecialtyDivisionStatus',
  updateSpecialitiesByComapnSlug: 'updateSpecialitiesByComapnSlug',
  updateSubSpecialitiesByComapnSlug:'updateSubSpecialitiesByComapnSlug',
  sendEmail2All: 'sendEmail2All',
  findCOEDetails: 'findCOEDetails',
  findOnCallLogByMonth: 'findOnCallLogByMonth',
  d2dEmail: 'd2dEmail',
  findTransferLogByDate: 'findTransferLogByDate',
  docGroupRequest: 'docGroupRequest',
  findAllDocumentsApi: 'findAllDocumentsApi',
  findUserDoctorsByPractice: 'findUserDoctorsByPractice',
  getSpecialitiesByCompanySlug: 'getSpecialitiesByCompanySlug',
  saveSpecialitiesByComapnSlug: 'saveSpecialitiesByComapnSlug',
  saveSubSpecialitiesByComapnSlug: 'saveSubSpecialitiesByComapnSlug',
  findD2DLogDetail: 'findD2DLogDetail',
  checkInvitation: 'checkInvitation',
  findInvitationDetails: 'findInvitationDetails',
  getDelegationLog: 'getDelegationLog',
  modifyPhysicianCallSchedule: 'modifyPhysicianCallSchedule',
  findUserDocumentGroups: 'findUserDocumentGroups',
  savePhysicianCallSchedule: 'savePhysicianCallSchedule',
  docotr2doctorEmail01: 'docotr2doctorEmail01',
  findLogDetails: 'findLogDetails',
  docotr2doctorEmail02: 'docotr2doctorEmail02',
  docotr2doctorEmail03: 'docotr2doctorEmail03',
  findPhysicianEmail: 'findPhysicianEmail',
  findUserDetailsByD2DId: 'findUserDetailsByD2DId',
  findUserSignature: 'findUserSignature',
  addminSendDocs: 'addminSendDocs',
  changeOldEventStatus: 'changeOldEventStatus',
  modifyAdminDocNew: 'modifyAdminDocNew',
  sendMailToUser: 'sendMailToUser',
  saveAdminDocNew: 'saveAdminDocNew',
  saveCommentPat: 'saveComment',
  saveCommentRemark: 'saveCommentRemark',
  sendUHFUser: 'sendUHFUser',
  sendEmailToPrimaryAdmin: 'sendEmailToPrimaryAdmin',
  generateD2DDocSignatureFilePdf:'generateD2DDocSignatureFilePdf',
  cleanTempDocs: 'cleanTempDocs',
  transferOpinionLog: 'transferOpinionLog',
  findTransferLog: 'findTransferLog',
  findOpinionLog: 'findOpinionLog',
  getPhysiciansByCityCode_sls06: 'getPhysiciansByCityCode',
  uploadDocumentToPatient: 'uploadDocumentToPatient',
  generateSignatureFilesPdfPhysician: 'generateSignatureFilesPdfPhysician',
  fileUploadService: 'fileUploadService',
  sendHippaNoMatchMail: 'sendHippaNoMatchMail',
  generateDemographicPdf: 'generateDemographicPdf',
  princetonSurgicalSpecialtiesAssigmentofBenefitsDAR: 'princetonSurgicalSpecialtiesAssigmentofBenefitsDAR',
  pssfMedicalRecordInsurance: 'pssfMedicalRecordInsurance',
  priPrivacyPractices: 'priPrivacyPractices',
  sendDoctorSignUpMain: 'sendDoctorSignUpMain',
  ///////////////////////////////////// Set 06 Ends//////////////////////////////////////////////////////////////////////

};
