import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-screen-capture',
  templateUrl: './screen-capture.component.html',
  styleUrls: ['./screen-capture.component.css']
})
export class ScreenCaptureComponent implements OnInit {



  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }


  startExtension() {
    const url = 'AHSScreenCaptureApp://';
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;
    document.body.appendChild(iframe);
    let isAppOpened = false;
    
    window.addEventListener('blur', () => {
      isAppOpened = true;
    });
    
    setTimeout(async () => {
      document.body.removeChild(iframe);
      if (!isAppOpened) {
        const alertStatus = await Swal.fire({
          title: 'AHS Screen Capture Application Not Installed',
          text: 'Please select your operating system to download and install the application.',
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'Download for Windows',
          confirmButtonColor: '#3085d6',
          showDenyButton: true,
          denyButtonText: 'Download for macOS',
          denyButtonColor: '#4CAF50'
        });

        if (alertStatus.isConfirmed) {
          // Windows download link
          window.open('https://usesigndevrg9fa8.blob.core.windows.net/application/AHS-Screen-Capture-App.exe', '_blank');
        } else if (alertStatus.isDenied) {
          // macOS download link
          window.open('https://usesigndevrg9fa8.blob.core.windows.net/application/AHS_Screen_Capture_App2 0.0.4.dmg', '_blank');
        } else {
          return false;
        }
      }
    }, 2000);
}

  


  // 
  
}
