import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScreenRecorderService {
  private recorder: MediaRecorder | undefined;
  private combinedStream: MediaStream | undefined;
  public videoUrl: string | undefined;
//   public recordFlag: boolean = false;

  async startRecording(patientName: string, physicianName: string): Promise<void> {
    // this.recordFlag = true;
    try {
      const displayStream = await this.getDisplayStream();
      const micStream = await this.getMicrophoneStream();

      this.combinedStream = new MediaStream([
        ...displayStream.getTracks(),
        ...micStream.getAudioTracks()
      ]);

      // Listen for when the user manually stops sharing the screen
      displayStream.getTracks().forEach(track => {
        track.onended = () => this.stopRecording();
      });

      this.recorder = new MediaRecorder(this.combinedStream);
      const chunks: Blob[] = [];
      this.recorder.ondataavailable = (e) => chunks.push(e.data);
      this.recorder.onstop = () => this.handleRecordingStop(chunks, patientName, physicianName);

      this.recorder.start();
    } catch (err) {
      console.error('Error starting recording: ', err);
    //   this.recordFlag = false;
    }
  }

  stopRecording(): void {
    // this.recordFlag = false;
    if (this.recorder) {
      this.recorder.stop();
    }
    this.cleanupStreams();
  }

  private async getDisplayStream(): Promise<MediaStream> {
    const displayMediaOptions = {
      video: true,
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        sampleRate: 44100
      }
    };
    return await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
  }

  private async getMicrophoneStream(): Promise<MediaStream> {
    const micOptions = { audio: true };
    return await navigator.mediaDevices.getUserMedia(micOptions);
  }

  private handleRecordingStop(chunks: Blob[], patientName: string, physicianName: string): void {
    if (chunks.length === 0) {
      console.warn('No data recorded.');
      return;
    }

    const completeBlob = new Blob(chunks, { type: 'video/mp4' });
    this.videoUrl = URL.createObjectURL(completeBlob);
    this.downloadVideo(completeBlob, patientName, physicianName);
    this.cleanupStreams();
  }

  private downloadVideo(blob: Blob, patientName: string, physicianName: string): void {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${this.generateFileName(patientName, physicianName)}.mp4`;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }, 100);
  }

  private generateFileName(patientName: string, physicianName: string): string {
    const parts = patientName.split(', ');
    const parts2 = physicianName.split(', ');
    const formattedPatientName = parts.join('-');
    const formattedPhysicianName = parts2.join('-');
    const now = new Date();
    const formattedDate = `${this.pad(now.getMonth() + 1)}${this.pad(now.getDate())}${now.getFullYear()}${this.pad(now.getHours())}${this.pad(now.getMinutes())}${this.pad(now.getSeconds())}`;
    return `${formattedPatientName}_${formattedPhysicianName}_${formattedDate}`;
  }

  private pad(number: number): string {
    return number < 10 ? `0${number}` : `${number}`;
  }

  private cleanupStreams(): void {
    if (this.combinedStream) {
      this.combinedStream.getTracks().forEach(track => track.stop());
      this.combinedStream = undefined;
    }
  }
}
