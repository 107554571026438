import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {AppConfig} from './../../../app/app.config';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {ArrayHelper} from './../../_helpers/array.helper';
import {SweetAlertHelper} from 'src/app/_helpers/sweet.alert.helper';
import {Message} from 'src/app/_locale/message';
import {async} from '@angular/core/testing';
import { UserService } from 'src/app/_services/user.service';
import * as md5 from 'md5';

@Component({
  selector: 'app-admin-security-questions',
  templateUrl: './admin-security-questions.component.html',
  styleUrls: ['./admin-security-questions.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class AdminSecurityQuestions implements OnInit {
  loggedInUserId: string;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  profileObj: any = {};
  firstQues: any = [];
  secondQues: any = [];
  showLoader = false;
  message = Message;
  loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');
  password: any;
  cnfPassword: any;
  passwordForm: any;
  fieldTextType: boolean = false;
  newPassword: any = '';
  confirmNewPassword: any = '';
  passwordMismatch: boolean = false;
  cnFieldTextType: boolean = false;
  zone: any;
  childRoute : string;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    public sweetAlertHelper: SweetAlertHelper,
    private router: Router,
    private http: HttpClient,
    public userService: UserService,
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  
  }

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.findUserDetails(this.loggedInUserId);
    this.findFirstSecurityQues();
    this.findSecondSecurityQues();
  }

  hashPassword(password: string): string {
    return md5(password);
  }

  findFirstSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 1,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.firstQues = apiResponse.commandResult.data.questionsList;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findSecondSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 2,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.secondQues = apiResponse.commandResult.data.questionsList;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDetails(userId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      id: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status === 1) {
            this.profileObj = apiResponse.commandResult.data.userInfo;
            this.showLoader = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onReset() {
    // if (confirm('PLEASE CONFIRM: Are you certain you want to return to the Home Page?'))

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      if (this.loggedInUserRoleId == '1') {
        this.router.navigate(['admin/dashboard/' + this.loggedInUserId]);
      } else if (this.loggedInUserRoleId == '4') {
        this.router.navigate(['doctor/dashboard/' + this.loggedInUserId]);
      }
    }
  }

  // async onSubmit() {
  //   if (this.profileObj.password !== this.profileObj.cnfPassword) {
  //     await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.adminChangePassComp.onSubmit);
  //     return;
  //   }
  //   await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.adminChangePassComp.onSubmit);
  //   {
  //       const finalData = {
  //         userId: this.loggedInUserId,
  //         newPassword: this.profileObj.password,
  //       };
  //       this.showLoader = true;
  //       return this.http
  //         .post(
  //           this.appConfig.getLambdaUrl05() + ApiHelper.name_updateNewpassword,
  //           finalData,
  //           this.httpOptions
  //         )
  //         .subscribe(
  //           async (apiResponse: any) => {
  //             this.showLoader = false;
  //             await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.adminChangePassComp.onSubmit3);
  //             window.location.reload();
  //           },
  //           (err) => {
  //             // console.log(err);
  //           }
  //         );
      
  //   }
  // }
  toggleCnFieldTextType() {
    this.cnFieldTextType = !this.cnFieldTextType;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  checkPasswords(): void {
    // This will be triggered on every change in either the new password or confirm new password
    this.passwordMismatch = this.newPassword !== this.confirmNewPassword;
  }
 
  
  async onSubmit() {
    // if (this.profileObj.password !== this.profileObj.cnfPassword) {
    //   // alert("Password must match.");
    //   const alertStatus = await this.sweetAlertHelper.alertPopUp('Password doesn\'t match!');
    //   return;
    // }
    // // if (confirm("PLEASE CONFIRM: Are you sure you want to update password?"))
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   'Are you sure you want to update your password?'  
    // );
    // if (alertStatus) {
    //   if (this.profileObj.Ans01 !== this.profileObj.Ans_01) {
    //     // alert("Incorrect secirity answer 01.");
    //     const alertStatus = await this.sweetAlertHelper.alertPopUp(
    //      'Incorrect security answer 01! — Please try again'
    //     );
    //     return;
    //   } else if (this.profileObj.Ans02 !== this.profileObj.Ans_02) {
    //     // alert("Incorrect secirity answer 02.");
    //     const alertStatus = await this.sweetAlertHelper.alertPopUp(
    //       'Incorrect security answer 02! — Please try again'
    //     );
    //     return;
    //   } else if (this.hashPassword(this.profileObj.old_password) !== this.profileObj.OldPassword) {
    //     // alert("Incorrect old password");
    //     const alertStatus = await this.sweetAlertHelper.alertPopUp('Old Password must match!');
    //     return; 
    //   }else {
        const finalData = {
          userId: this.loggedInUserId,
          que_id_01 : this.profileObj.QueId_01,
          que_id_02 : this.profileObj.QueId_02,
          ans_01 : this.profileObj.Ans_01,
          ans_02 : this.profileObj.Ans_02,
        };
        this.showLoader = true;
        return this.http
          .post(
            this.appConfig.getLambdaUrl05() + ApiHelper.updateSecurityQuestions,
            finalData,
            this.httpOptions
          )
          .subscribe(
            async (apiResponse: any) => {
              // alert("Password updated successfully.");
              this.showLoader = false;
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
               'Security Questions updated successfully!'
              );
              window.location.reload();
              this.showLoader = false;
            },
            (err) => {}
          );
      }
    
  
  async goBack() {

    if (this.loggedInUserRoleId == '4')
      {
        this.router.navigate([ "/"+ this.childRoute +"/doctor-profile/" +this.loggedInUserId ]);
      }
      else{
        this.router.navigate([ "/"+ this.childRoute +"/admin-profile/" +this.loggedInUserId ]);
      }

  }

  async homePage() {
    this.router.navigate([
      "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
    ]);  }
}
