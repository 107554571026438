import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';


import {environment} from './../../environments/environment';
import {ApiHelper} from './../_helpers/api.helper';
import {CommonHelper} from './../_helpers/common.helper';
import {ConstantHelper} from './../_helpers/constant.helper';
import {AppConfig} from './../../app/app.config';

@Injectable({
    providedIn: 'root'
})
export class UploadService {
    constructor(private http: HttpClient,
                public commonHelper: CommonHelper,
                private appConfig: AppConfig) {
    }


    uploadFile(fileToUpload, userId, demographicId, docType, companySlug, comapnyId, docName, isHippByAdmin) {
        const formData = new FormData();
        formData.append('fileKey', fileToUpload, fileToUpload.name);
        formData.append('loggedInUserId', userId);
        formData.append('demographicId', demographicId);
        formData.append('docType', docType);
        formData.append('comapny_slug', companySlug);
        formData.append('comapnyId', comapnyId);
        formData.append('docName', docName);
        formData.append('isHippByAdmin', isHippByAdmin);

        return this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_uploadFileApi, formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map((event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const progress = Math.round(100 * event.loaded / event.total);
                        return {status: 'progress', message: progress};
                    case HttpEventType.Response:
                        return event.body;
                    default:
                        return `Unhandled event: ${event.type}`;

                    // case HttpEventType.Response:
                    //   return event.body;
                }


            })
        );
    }


    sendDoc(personName, personEmail, selectedDocsId, fileToUpload, userId, selectedHippaUrls, companySlug, altEmail, selectedStateCode, selectedCityName, selectedPhysicianId, emailLogTxt, fileDesTxt, docType, comapnyId, showNewFile, attachmentDocObj) {

        const formData = new FormData();
        if (fileToUpload) {
            formData.append('fileKey', fileToUpload, fileToUpload.name);
        }
        formData.append('loggedInUserId', userId);
        formData.append('personName', personName);
        formData.append('personEmail', personEmail);
        formData.append('selectedDocsId', selectedDocsId);
        formData.append('selectedHippaUrls', selectedHippaUrls);
        formData.append('companySlug', companySlug);
        formData.append('altEmail', altEmail);
        formData.append('selectedStateCode', selectedStateCode);
        formData.append('selectedCityName', selectedCityName);
        formData.append('selectedPhysicianId', selectedPhysicianId);
        formData.append('emailLogTxt', emailLogTxt);
        formData.append('fileDesTxt', fileDesTxt);
        formData.append('docType', docType);
        formData.append('comapnyId', comapnyId);
        formData.append('showNewFile', showNewFile);
        formData.append('attachmentDocObj', attachmentDocObj);
        return this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_sendDocsApi, formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map((event) => {
                switch (event.type) {
                    case HttpEventType.Response:
                        return event.body;
                }
            })
        );
    }


    // tslint:disable-next-line: max-line-length
    adminSendDoc(personName, personEmail, selectedDocsId, fileToUpload, loggedInUserId, userId, selectedHippaUrls, companySlug, altEmail, selectedStateCode, selectedCityName, selectedPhysicianId, emailLogTxt, fileDesTxt, docType, comapnyId, showNewFile, attachmentDocObj) {
        const formData = new FormData();
        if (fileToUpload) {
            formData.append('fileKey', fileToUpload, fileToUpload.name);
        }
        formData.append('loggedInUserId', loggedInUserId);
        formData.append('userId', userId);
        formData.append('personName', personName);
        formData.append('personEmail', personEmail);
        formData.append('selectedDocsId', selectedDocsId);
        formData.append('selectedHippaUrls', selectedHippaUrls);
        formData.append('companySlug', companySlug);
        formData.append('altEmail', altEmail);
        formData.append('selectedStateCode', selectedStateCode);
        formData.append('selectedCityName', selectedCityName);
        formData.append('selectedPhysicianId', selectedPhysicianId);
        formData.append('emailLogTxt', emailLogTxt);
        formData.append('fileDesTxt', fileDesTxt);
        formData.append('docType', docType);
        formData.append('comapnyId', comapnyId);

        formData.append('showNewFile', showNewFile);
        formData.append('attachmentDocObj', attachmentDocObj);

        return this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_adminSendDocsApi, formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map((event) => {
                switch (event.type) {
                    case HttpEventType.Response:
                        return event.body;
                }


            })
        );
    }


    resendDoc(personName, personEmail, userId, logId, companySlug) {
        const formData = new FormData();
        formData.append('loggedInUserId', userId);
        formData.append('personName', personName);
        formData.append('personEmail', personEmail);
        formData.append('logId', logId);
        formData.append('companySlug', companySlug);

        return this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_resendDocsApi, formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map((event) => {
                switch (event.type) {
                    case HttpEventType.Response:
                        return event.body;
                }


            })
        );
    }


    adminResendDoc(personName, personEmail, altEmail, userId, logId, companySlug, createdById, selectedStateCode, selectedCityName, selectedPhysicianId, emailLogTxt, fileToUpload, fileDesTxt, docType, comapnyId, showNewFile, attachmentDocObj) {
        const formData = new FormData();
        if (fileToUpload) {
            formData.append('fileKey', fileToUpload, fileToUpload.name);
        }

        formData.append('loggedInUserId', userId);
        formData.append('personName', personName);
        formData.append('personEmail', personEmail);
        formData.append('altEmail', altEmail);
        formData.append('logId', logId);
        formData.append('companySlug', companySlug);
        formData.append('createdById', createdById);
        formData.append('selectedStateCode', selectedStateCode);
        formData.append('selectedCityName', selectedCityName);
        formData.append('selectedPhysicianId', selectedPhysicianId);
        formData.append('emailLogTxt', emailLogTxt);
        formData.append('fileDesTxt', fileDesTxt);
        formData.append('docType', docType);
        formData.append('comapnyId', comapnyId);

        formData.append('showNewFile', showNewFile);
        formData.append('attachmentDocObj', attachmentDocObj);

        return this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_adminResendDocsApi, formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map((event) => {
                switch (event.type) {
                    case HttpEventType.Response:
                        return event.body;
                }


            })
        );
    }


    saveDocGroup(userId, companySlug, groupId, docsId, newGroup) {
        const formData = new FormData();

        if (groupId <= 0) {
            formData.append('newGroup', newGroup);
        }
        formData.append('loggedInUserId', userId);
        formData.append('companySlug', companySlug);
        formData.append('groupId', groupId);
        formData.append('docsId', docsId);
        // return this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_saveDocGroupApi,
        return this.http.post(this.appConfig.getPhpUrl() + ApiHelper.saveDocGroup,
         formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map((event) => {
                switch (event.type) {
                    case HttpEventType.Response:
                        return event.body;
                }


            })
        );
    }


    saveAdminDoc(loggedInUserId, comapnySlug, docName, docDescription, confirmationStatus, selectedFile, docType, comapnyId, uploadAllowed, docMassage) {
        const formData = new FormData();
        formData.append('fileKey', selectedFile, selectedFile.name);
        formData.append('comapnySlug', comapnySlug);
        formData.append('docName', docName);
        formData.append('docDescription', docDescription);
        formData.append('confirmationStatus', confirmationStatus);
        formData.append('loggedInUserId', loggedInUserId);
        formData.append('docType', docType);
        formData.append('comapnyId', comapnyId);
        formData.append('uploadAllowed', uploadAllowed);
        formData.append('docMassage', docMassage);
        return this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_saveAdminDocNewApi, formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map((event) => {
                switch (event.type) {
                    // case HttpEventType.UploadProgress:
                    //   const progress = Math.round(100 * event.loaded / event.total);
                    //   return { status: 'progress', message: progress };
                    // case HttpEventType.Response:
                    //   return event.body;
                    // default:
                    //   return `Unhandled event: ${event.type}`;

                    case HttpEventType.Response:
                        return event.body;
                }


            })
        );
    }


    modifyAdminDoc(loggedInUserId, comapnySlug, docName, docDescription, confirmationStatus, blob, docId, hasDoc, oldDoc, docType, comapnyId, uploadAllowed, docMassage, docEditReason) {
        const formData = new FormData();
        formData.append('fileKey', blob);
        formData.append('comapny_slug', comapnySlug);
        formData.append('docName', docName);
        formData.append('docDescription', docDescription);
        formData.append('confirmationStatus', confirmationStatus);
        formData.append('loggedInUserId', loggedInUserId);
        formData.append('docId', docId);
        formData.append('hasDoc', hasDoc);
        formData.append('oldDoc', oldDoc);
        formData.append('docType', docType);
        formData.append('comapnyId', comapnyId);
        formData.append('uploadAllowed', uploadAllowed);
        formData.append('docMassage', docMassage);
        formData.append('docEditReason', docEditReason);
        return this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_modifyAdminDocNewApi, formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map((event) => {
                switch (event.type) {
                    // case HttpEventType.UploadProgress:
                    //   const progress = Math.round(100 * event.loaded / event.total);
                    //   return { status: 'progress', message: progress };
                    // case HttpEventType.Response:
                    //   return event.body;
                    // default:
                    //   return `Unhandled event: ${event.type}`;

                    case HttpEventType.Response:
                        return event.body;
                }


            })
        );
    }


    // doctor2DoctorEmail04(loggedInUserId, companySlug, physicianType, stateCode, cityName, physicianId, fileDesTxt, docType, userOptionId, fileToUpload1, fileToUpload2, fileToUpload3, selectedEmail, assignedDelegateId, isDelegated, selectedDelegateId,selectedPhysicianName) {

    //     const formData = new FormData();
    //     if (fileToUpload1) {
    //         formData.append('fileKey1', fileToUpload1, fileToUpload1.name);
    //     }

    //     if (fileToUpload2) {
    //         formData.append('fileKey2', fileToUpload2, fileToUpload2.name);
    //     }

    //     if (fileToUpload3) {
    //         formData.append('fileKey3', fileToUpload3, fileToUpload3.name);
    //     }

    //     formData.append('requestedPhysicianEmail', selectedEmail);
    //     formData.append('loggedInUserId', loggedInUserId);
    //     formData.append('companySlug', companySlug);
    //     formData.append('physicianType', physicianType);
    //     formData.append('selectedStateCode', stateCode);
    //     formData.append('selectedCityName', cityName);
    //     formData.append('selectedPhysicianId', physicianId);
    //     formData.append('emailTxt', fileDesTxt);
    //     formData.append('docType', docType);
    //     formData.append('userOptionId', userOptionId);

    //     formData.append('assignedDelegateId', assignedDelegateId);
    //     formData.append('isDelegated', isDelegated);
    //     formData.append('selectedDelegateId', selectedDelegateId);
    //     formData.append('selectedPhysicianName', selectedPhysicianName);

    //     return this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_docotr2doctorEmail04Api, formData, {
    //         reportProgress: true,
    //         observe: 'events'
    //     }).pipe(map((event) => {
    //             switch (event.type) {
    //                 case HttpEventType.Response:
    //                     return event.body;
    //             }
    //         })
    //     );
    // }


    sendMailToUser(loggedInUserId, emailSubject, fileDesTxt, patientId, fileToUpload1, fileToUpload2, fileToUpload3, assignedDelegateId, isDelegated, selectedDelegateId) {
        const formData = new FormData();
        if (fileToUpload1) {
            formData.append('fileKey1', fileToUpload1, fileToUpload1.name);
        }

        if (fileToUpload2) {
            formData.append('fileKey2', fileToUpload2, fileToUpload2.name);
        }
        if (fileToUpload3) {
            formData.append('fileKey3', fileToUpload3, fileToUpload3.name);
        }
        formData.append('loggedInUserId', loggedInUserId);
        formData.append('emailTxt', fileDesTxt);
        formData.append('emailSub', emailSubject);
        formData.append('patientId', patientId);
        formData.append('assignedDelegateId', assignedDelegateId);
        formData.append('isDelegated', isDelegated);
        formData.append('selectedDelegateId', selectedDelegateId);

        return this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_sendMailToUserApi, formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map((event) => {
                switch (event.type) {
                    case HttpEventType.Response:
                        return event.body;
                }
            })
        );
    }


    upload5Files(loggedInUserId, userId, demographicId, companySlug, companyId, fileToUpload1, docType1, docName1, fileToUpload2, docType2, docName2, fileToUpload3, docType3, docName3, fileToUpload4, docType4, docName4, fileToUpload5, docType5, docName5
    ) {

        const formData = new FormData();
        formData.append('loggedInUserId', loggedInUserId);
        formData.append('userId', userId);
        formData.append('demographicId', demographicId);
        formData.append('comapny_slug', companySlug);
        formData.append('comapnyId', companyId);

        if (fileToUpload1) {
            formData.append('fileKey1', fileToUpload1, fileToUpload1.name);
            formData.append('docType1', docType1);
            formData.append('docName1', docName1);
        }

        if (fileToUpload2) {
            formData.append('fileKey2', fileToUpload2, fileToUpload2.name);
            formData.append('docType2', docType2);
            formData.append('docName2', docName2);
        }
        if (fileToUpload3) {
            formData.append('fileKey3', fileToUpload3, fileToUpload3.name);
            formData.append('docType3', docType3);
            formData.append('docName3', docName3);
        }
        if (fileToUpload4) {
            formData.append('fileKey4', fileToUpload4, fileToUpload4.name);
            formData.append('docType4', docType4);
            formData.append('docName4', docName4);
        }
        if (fileToUpload5) {
            formData.append('fileKey5', fileToUpload5, fileToUpload5.name);
            formData.append('docType5', docType5);
            formData.append('docName5', docName5);
        }
        return this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_upload5FilesApi, formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map((event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const progress = Math.round(100 * event.loaded / event.total);
                        return {status: 'progress', message: progress};
                    case HttpEventType.Response:
                        return event.body;
                    default:
                        return `Unhandled event: ${event.type}`;

                    // case HttpEventType.Response:
                    //   return event.body;
                }


            })
        );
    }

    uploadDocument(loggedInUserId, userId, demographicId, companySlug, companyId, fileToUpload1, docType1, docName1, associatedDocName, associatedDocUrl, aiSummary, facilityName, facilityAddress, facilityContact, facilityDoctorName, orderedDoctorName, testingDoneDate, associatedFileDesc, selectedPhysiciansIds) {
    
            const formData = new FormData();
            formData.append('loggedInUserId', loggedInUserId);
            formData.append('physicianId', loggedInUserId);
            formData.append('patientId', userId);
            formData.append('demographicId', demographicId);
            formData.append('company_slug', companySlug);
            formData.append('companyId', companyId);
            formData.append('associatedDocName', associatedDocName);
            formData.append('associatedDocUrl', associatedDocUrl);
            formData.append('aiSummary', aiSummary);
            formData.append('facilityName', facilityName);
            formData.append('facilityAddress', facilityAddress);
            formData.append('facilityContact', facilityContact);
            formData.append('facilityDoctorName', facilityDoctorName);
            formData.append('orderedDoctorName', orderedDoctorName);
            formData.append('testingDoneDate', testingDoneDate);
            formData.append('associatedFileDesc', associatedFileDesc);
            formData.append('selectedPhysiciansIds', selectedPhysiciansIds);
    
            if (fileToUpload1) {
                formData.append('fileKey1', fileToUpload1, fileToUpload1.name);
                formData.append('docType1', docType1);
                formData.append('docName1', docName1);
            }
            return this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_uploadDocumentToPatient, formData, {
                reportProgress: true,
                observe: 'events'
            }).pipe(map((event) => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            const progress = Math.round(100 * event.loaded / event.total);
                            return {status: 'progress', message: progress};
                        case HttpEventType.Response:
                            return event.body;
                        default:
                            return `Unhandled event: ${event.type}`;
                    }
                })
            );
        }





    uploadAdminReviewFile(loggedInUserId,  demographicId, fileToUpload, docType, docName, physicianId, documentId) {

        const formData = new FormData();
        formData.append('loggedInUserId', loggedInUserId);
        formData.append('demographicId', demographicId);
        formData.append('fileKey', fileToUpload, fileToUpload.name);
        formData.append('docType', docType);
        formData.append('docName', docName);
        formData.append('physicianId', physicianId);
        formData.append('documentId', documentId);
        return this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_uploadAdminReviewFileApi, formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map((event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const progress = Math.round(100 * event.loaded / event.total);
                        return {status: 'progress', message: progress};
                    case HttpEventType.Response:
                        return event.body;
                    default:
                        return `Unhandled event: ${event.type}`;

                    // case HttpEventType.Response:
                    //   return event.body;
                }


            })
        );
    }


}
