import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { from } from "rxjs";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { SignaturePad } from "angular2-signaturepad";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "../../_services/user.service";
import * as moment from "moment";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: "app-ct",
  templateUrl: "./ct.component.html",
  styleUrls: ["./ct.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class CtComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  hippaJson: any = {};
  loggedInUserId: string;
  userSignatures: any = [];
  userSignatureSelected = false;
  userFullSignature = "";
  userInitialSignature = "";
  userChoosedSignature = "";
  userFullSignatureExit = false;
  userInitialSignatureExis = false;
  showPdfModal = false;
  signatureType: any;
  ssnPattern = "^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$";
  resultJsonObj: any = {};
  clean: string = "0";
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  @ViewChild("canvasArea", { static: true }) canvasArea: ElementRef;
  canvasElm: any;
  public signaturePadOptions: Object = {
    minWidth: 1,
    canvasWidth:
      window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
    canvasHeight: 450,
  };
  isEmail: any;
  isPracticePhy: any;
  physicianId: any;
  requestId: any;
  tempId: any;
  userOptionId: any;
  emailLogId: any;
  currentDate = new Date();
  dateFormat = "MM-dd-yyyy";
  message = Message;
  patientId: string;
  fileDesTxt: any = '';
  selectedDelegateId: any;
  assignedDelegateId: any;
  isDelegated: any = 0;
  selectedDocUrl: any;
  selectedPhysician: any = {};
  docInfo: any = {};
  selectedStateCode: any;
  showLoader = false;
  childRoute: string;
  patient_name: any;
  physicianName: any;
  PhysicianOfficeAddress: any;  
  patient_city:any;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    public userService: UserService
  ) {
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.isEmail = this.activatedRoute.snapshot.paramMap.get("isEmail")
      ? this.activatedRoute.snapshot.paramMap.get("isEmail")
      : 0;
    this.isPracticePhy =
      this.activatedRoute.snapshot.paramMap.get("isPracticePhy");
    this.physicianId = this.activatedRoute.snapshot.paramMap.get("physicianId")
      ? this.activatedRoute.snapshot.paramMap.get("physicianId")
      : 0;
    this.requestId = this.activatedRoute.snapshot.paramMap.get("requestId")
      ? this.activatedRoute.snapshot.paramMap.get("requestId")
      : 0;
    this.tempId = this.activatedRoute.snapshot.paramMap.get("tempId")
      ? this.activatedRoute.snapshot.paramMap.get("tempId")
      : 0;
    this.userOptionId = this.activatedRoute.snapshot.paramMap.get(
      "userOptionId"
    )
      ? this.activatedRoute.snapshot.paramMap.get("userOptionId")
      : 0;
    this.emailLogId = this.activatedRoute.snapshot.paramMap.get("emailLogId")
      ? this.activatedRoute.snapshot.paramMap.get("emailLogId")
      : 0;

    this.hippaJson.healthcare_provider = this.userService.getUS_FromatName(
      localStorage.getItem("providerName")
    );
    this.hippaJson.healthcare_provider_email =
      localStorage.getItem("providerEmail");
    this.hippaJson.healthcare_provider_npi = localStorage.getItem(
      "providerPhysicianNipNo"
    );
    this.hippaJson.patient_name = this.userService.getUS_FromatName(
      localStorage.getItem("loggedInUserName")
    );
    //this.fnFindSignature(this.loggedInUserId);
    this.fnFindDemographic(this.patientId);
    this.findUserDetails(this.patientId);
    this.hippaJson.facility_service_date = this.currentDate;
    //this.hippaJson.patient_signature_date = this.currentDate;
    this.hippaJson.healthcare_provider = localStorage.getItem('selectedPhysicianName');
    this.hippaJson.PhysicianOfficeAddress = localStorage.getItem('selectedPhysicianOfficeAddress');
    this.hippaJson.physicianNPI = localStorage.getItem('selectedPhysicianNPI');
    this.hippaJson.physicianEmail = localStorage.getItem('selectedPhysicianEmail');    
    this.hippaJson.physicianPhone=localStorage.getItem('Mobile');
    this.hippaJson.facility_service_date = this.currentDate;
    this.hippaJson.patient_signature_date = this.currentDate;
  }

  ngOnInit() {
    this.patientId = this.activatedRoute.snapshot.paramMap.get('patientId');
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.hippaJson.check_facility_disclosed = false;
    this.hippaJson.check_facility_obtained = false;
    this.hippaJson.check_facility_hand_candy = false;
    this.hippaJson.check_facility_fax = false;
    this.hippaJson.check_facility_purpose_medical = false;
    this.hippaJson.check_facility_purpose_legal = false;
    this.hippaJson.check_facility_purpose_disability = false;
    this.hippaJson.check_facility_purpose_insurance = false;
    this.hippaJson.check_facility_purpose_representative = false;
    this.hippaJson.check_facility_purpose_other = false;
    this.hippaJson.check_facility_inpatient = false;
    this.hippaJson.check_facility_outpatient = false;
    this.hippaJson.check_facility_emergencyt = false;
    this.hippaJson.check_facility_req_complete = false;
    this.hippaJson.check_facility_req_abstract = false;
    this.hippaJson.check_facility_report_history = false;
    this.hippaJson.check_facility_report_laboratory = false;
    this.hippaJson.check_facility_report_discharge = false;
    this.hippaJson.check_facility_report_xray = false;
    this.hippaJson.check_facility_report_operative = false;
    this.hippaJson.check_facility_report_ekg = false;
    this.hippaJson.check_facility_report_consultations = false;
    this.hippaJson.check_facility_report_radiology = false;
    this.hippaJson.check_facility_report_billing = false;
    this.hippaJson.check_facility_report_other = false;
    this.hippaJson.check_not_patient_parent = false;
    this.hippaJson.check_not_patient_guardian = false;
    this.hippaJson.check_not_patient_conservatorn = false;
    this.hippaJson.check_not_patient_executor = false;
    this.hippaJson.check_not_patient_attorney = false;
    this.hippaJson.check_not_patient_other = false;
     this.findUserDetails(this.patientId);  
     this.fnFindDemographic(this.patientId);  
    this.hippaJson.treatment_by=localStorage.getItem('selectedPhysicianName'),
    this.hippaJson.person_address = this.hippaJson.PhysicianOfficeAddress;
    this.hippaJson.physicianCity_Zip = this.hippaJson.physicianCity + "," + this.hippaJson.physicianState;
   
    this.hippaJson.healthcare_provider_npi = localStorage.getItem('selectedPhysicianNPI');
  }


  findUserDetails(patientId) {
    const finalData = {
      company_slug: null,
      id: patientId,
    };

    this.showLoader=true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.hippaJson.patient_name = apiResponse.commandResult.data.userInfo.LastName + ', ' + apiResponse.commandResult.data.userInfo.FirstName;
            this.hippaJson.information_relating=apiResponse.commandResult.data.userInfo.LastName + ', ' + apiResponse.commandResult.data.userInfo.FirstName;
            this.hippaJson.facility_name=apiResponse.commandResult.data.userInfo.LastName + ', ' + apiResponse.commandResult.data.userInfo.FirstName;
            this.hippaJson.dob = apiResponse.commandResult.data.userInfo.DOB;
            this.hippaJson.patient_phone=apiResponse.commandResult.data.userInfo.Mobile;
            this.hippaJson.facility_phone=apiResponse.commandResult.data.userInfo.Mobile;
            this.hippaJson.facility_address=apiResponse.commandResult.data.userInfo.Email;
            this.hippaJson.notifying_by=apiResponse.commandResult.data.userInfo.LastName + ', ' + apiResponse.commandResult.data.userInfo.FirstName;
           // console.log("patient email ",this.hippaJson.patient_email);
            //console.log("patient ph: ", this.hippaJson.tele_no);
            }
            this.showLoader= false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }



  async onSubmit() {
    this.hippaJson.dob = moment(new Date(this.hippaJson.dob.toString())).format(
      "MM-DD-YYYY"
    );
    this.hippaJson.facility_service_date = moment(
      new Date(this.hippaJson.facility_service_date.toString())
    ).format("MM-DD-YYYY");
    this.hippaJson.patient_signature_date = moment(
      new Date(this.hippaJson.patient_signature_date.toString())
    ).format("MM-DD-YYYY");
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:   By clicking ‘OK’:The information you have entered will be used to electronically generate a completed State HIPAA Form."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.onSubmit
    );
   if(alertStatus) {
      const finalData = {
        loggedInUserId: this.loggedInUserId,       
        hippaJson: this.hippaJson,
        isEmail: this.isEmail,
        requestId: this.requestId,
        selectedDocUrl: this.selectedDocUrl,
        fileDesTxt: this.fileDesTxt,       
        userId: this.patientId,
        patient_name: this.hippaJson.patient_name, 
        information_relating:this.hippaJson.information_relating, 
        facility_name:this.hippaJson.facility_name, 
        facility_city_state:this.hippaJson.facility_city_state,  
        facility_address:this.hippaJson.facility_address,  
        facility_phone:this.hippaJson.facility_phone, 
        dob: this.hippaJson.dob,  
        patient_address:this.hippaJson.patient_address,    
        patient_city:this.hippaJson.patient_city,
        patient_state:this.hippaJson.patient_state,
        patient_phone:this.hippaJson.patient_phone,
        patient_email:this.hippaJson.patient_email,
        patient_fax:this.hippaJson.patient_fax,        
        physicianNPI: this.hippaJson.PhysicianNipNo,
        healthcare_provider:this.hippaJson.treatment_by, 
        notifying_by:this.hippaJson.notifying_by,       
        person_address: this.hippaJson.person_address,      
        physicianState: this.selectedStateCode,
        assignedDelegateId: this.assignedDelegateId,
        isDelegated: this.isDelegated,
        selectedDelegateId: this.selectedDelegateId,
        selectedEmail: this.selectedPhysician.altEmail,
        selectedPhysician: this.selectedPhysician.physicianName,
        physicianPhone: this.hippaJson.physicianPhone,   
        healthcare_provider_email: this.hippaJson.healthcare_provider_email,
        healthcare_provider_npi: this.hippaJson.healthcare_provider_npi, 
        patient_zip: this.hippaJson.patient_zip,        
        purpose: this.hippaJson.purpose,        
        
        social_security_no: this.hippaJson.social_security_no,        
        representative_name: this.hippaJson.representative_name,
        representative_capacity: this.hippaJson.representative_capacity,
        representative_street_address: this.hippaJson.representative_street_address,
        representative_city_zip: this.hippaJson.representative_city_zip,
        name_relation: this.hippaJson.name_relation,   
        check_facility_disclosed: this.hippaJson.check_facility_disclosed,
        check_facility_obtained:this.hippaJson.check_facility_obtained,
        check_facility_hand_candy:this.hippaJson.check_facility_hand_candy,
        check_facility_fax:this.hippaJson.check_facility_fax,
        check_facility_purpose_medical:this.hippaJson.check_facility_purpose_medical,
        check_facility_purpose_legal:this.hippaJson.check_facility_purpose_legal,
        check_facility_purpose_disability:this.hippaJson.check_facility_purpose_disability,
        check_facility_purpose_insurance:this.hippaJson.check_facility_purpose_insurance,
        check_facility_purpose_representative:this.hippaJson.check_facility_purpose_representative,
        check_facility_purpose_other:this.hippaJson.check_facility_purpose_other,
        check_facility_inpatient:this.hippaJson.check_facility_inpatient,
        check_facility_outpatient:this.hippaJson.check_facility_outpatient,
        check_facility_emergencyt:this.hippaJson.check_facility_emergencyt,
        check_facility_req_complete:this.hippaJson.check_facility_req_complete,
        check_facility_req_abstract:this.hippaJson.check_facility_req_abstract,
        check_facility_report_history:this.hippaJson.check_facility_report_history,
        check_facility_report_laboratory:this.hippaJson.check_facility_report_laboratory,
        check_facility_report_discharge:this.hippaJson.check_facility_report_discharge,
        check_facility_report_xray:this.hippaJson.check_facility_report_xray,
        check_facility_report_operative:this.hippaJson.check_facility_report_operative,
        check_facility_report_ekg:this.hippaJson.check_facility_report_ekg,
        check_facility_report_consultations:this.hippaJson.check_facility_report_consultations,
        check_facility_report_radiology: this.hippaJson.check_facility_report_radiology,
        check_facility_report_billing:this.hippaJson.check_facility_report_billing,
        check_facility_report_other:this.hippaJson.check_facility_report_other,
        check_not_patient_parent:this.hippaJson.check_not_patient_parent,
        check_not_patient_guardian:this.hippaJson.check_not_patient_guardian,
        check_not_patient_conservatorn:this.hippaJson.check_not_patient_conservatorn,
        check_not_patient_executor:this.hippaJson.check_not_patient_executor,
        check_not_patient_attorney:this.hippaJson.check_not_patient_attorney,
        check_not_patient_other:this.hippaJson.check_not_patient_other
        
      };
      return this.http
        .post(this.appConfig.getLambdaUrl06() + ApiHelper.generateHipaaCT,
          finalData,
          this.httpOptions)
        .subscribe(
          (apiResponse: any) => {
            if (this.hippaJson.loggedInUserRoleId == 1) {
              this.router.navigate([
                '/doctor/' + '/demographics/' +
                this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
                '/1/'
              ]);
            } else if (this.hippaJson.loggedInUserRoleId == 4) {
              this.router.navigate([
                '/doctor/' + '/demographics/' +
                this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
                '/1/'
              ]);
            } else {
              if (window.open(apiResponse.commandResult.data, '_blank')) {
                this.router.navigate([
                  '/doctor/' + '/ushf/' +
                  this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.activatedRoute.snapshot.paramMap.get('patientId') + '/' + this.activatedRoute.snapshot.paramMap.get('demographicId')
                ]);
              }
            }
          },
          (err) => {
          }
        );
    }
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(['/doctor/' +
        '/dashboard/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
      ]);
    }
  }

  async goBack() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the USHF Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.goBack
    );
    if (alertStatus) {
      this.router.navigate([
        '/doctor/' + '/ushf/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.activatedRoute.snapshot.paramMap.get('patientId') + '/' + this.activatedRoute.snapshot.paramMap.get('demographicId')

      ]);
    }
  }

  closePdfModal() {
    this.showPdfModal = false;
    this.hippaJson.patient_signature = "";
  }

  // openSignatureCanvas(selectedValue) {
  //   // console.log(selectedValue);
  //   this.signatureType = selectedValue;
  //   if (selectedValue == "AFS" || selectedValue == "AIS") {
  //     this.showPdfModal = true;
  //   }

  //   if (selectedValue == "FS" || selectedValue == "IS") {
  //     this.userSignatureSelected = true;
  //     if (selectedValue == "FS") {
  //       this.userChoosedSignature = this.userFullSignature;
  //     }
  //     if (selectedValue == "IS") {
  //       this.userChoosedSignature = this.userInitialSignature;
  //     }
  //   }
  // }

  ngAfterViewInit() {
    // this.signaturePad is now available
    //this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
    //this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }

  // async saveCanvas() {
  //   // if (
  //   //   confirm(
  //   //     "PLEASE CONFIRM: This will replace your existing signature. Do you wish to change?"
  //   //   )
  //   // )
  //   const alertStatus = await this.sweetAlertHelper.confirmPopUp(
  //     this.message.confirmTitle,
  //     this.message.confirmMessages.njComp.saveCanvas
  //   );
  //   {
  //     const reqData = {
  //       loggedInUserId: this.loggedInUserId,
  //       signatureBase64: this.signaturePad.toDataURL(),
  //       signatureType: this.signatureType,
  //     };
  //     return this.http
  //       .post(
  //         this.appConfig.getLambdaUrl05() +
  //           ApiHelper.name_saveSignatureImageApi,
  //         reqData,
  //         this.httpOptions
  //       )
  //       .subscribe(
  //         (apiResponse: any) => {
  //           if (apiResponse.commandResult.status == 1) {
  //             this.userSignatureSelected = true;
  //             this.userChoosedSignature =
  //               apiResponse.commandResult.data.fileInfo.signatureUrl;
  //             // console.log(this.userChoosedSignature);
  //             this.fnFindSignature(this.loggedInUserId);
  //             this.showPdfModal = false;
  //             // this.hippaJson.patient_signature = '';
  //           }
  //         },
  //         (err) => {
  //           // console.log(err);
  //         }
  //       );
  //   }
  // }

  // async clearCanvas() {
  //   // if (
  //   //   confirm("PLEASE CONFIRM: Are you sure you want to clear you signature.")
  //   // )
  //   const alertStatus = await this.sweetAlertHelper.confirmPopUp(
  //     this.message.confirmTitle,
  //     this.message.confirmMessages.njComp.clearCanvas
  //   );
  //   {
  //     this.signaturePad.clear();
  //   }
  // }

  drawStart() {}

  fnFindDemographic(patientId) {
    const finalData = {
      id: patientId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (response: any) => {
          // console.log('domoresponse=>', response);
          // this.hippaJson.dob = new Date(
          //   response.commandResult.data.userInfo.form1DOB
          // );
          this.hippaJson.patient_city =
            response.commandResult.data.userInfo.form1City;
          this.hippaJson.patient_zip =
            response.commandResult.data.userInfo.form1ZIP;
          this.hippaJson.patient_state =
            response.commandResult.data.userInfo.form1State;
          this.hippaJson.facility_city_state =
           
            response.commandResult.data.userInfo.form1StreetName +
            ", " +
            response.commandResult.data.userInfo.form1City +
            ", " +
            response.commandResult.data.userInfo.form1State +
            ", " +
            response.commandResult.data.userInfo.form1ZIP;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  openCTBlankpdf() {
    window.open("https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/Connecticut-HIPAA-Medical-Release-Form.pdf", "_blank")
  }

}
