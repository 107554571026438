import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { ArrayHelper } from "./../../_helpers/array.helper";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import Swal from "sweetalert2";
import { DataService } from "src/app/_helpers/data.service.helper";

@Component({
  selector: "app-admin-profile",
  templateUrl: "./admin-profile.component.html",
  styleUrls: ["./admin-profile.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class AdminProfileComponent implements OnInit {
  loggedInUserId: string;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  profileObj: any = {
    company_slug: '',
    userId: '',
    fullName: '',
    mobile: '',
    email: '',
    queId_01: '',
    ans_01: '',
    queId_02: '',
    ans_02: '',
    PracticeName: '',
    streetAddress: '',
    state: '',
    zipCode: '',
    officePhone: '',
    designation: '',
  };
  doctorObj: any = {};
  firstQues: any = [];
  secondQues: any = [];
  childRoute: string;
  que01SelectDisabledOption = true;
  que02SelectDisabledOption = true;
  statesArre = ArrayHelper.STATE_ARRAY;
  showLoader = false;
  message = Message;
  isProfileCompleted: any;
  reviewProfile: boolean = true;
  changeSecQue: boolean = false;
  changePass: boolean = false;
  resignDocs: boolean = false;
  // adminProfile: boolean = false;
  activeTab: string = 'profile';

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    private dataService: DataService,
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.doctorObj.PhysicianSecondaryState = "";
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.findUserDetails(this.loggedInUserId);
    this.findFirstSecurityQues();
    this.findSecondSecurityQues();
  }


  findUserDetails(userId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      id: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.getAdminDetailsById,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status === 1) {
            this.profileObj = apiResponse.commandResult.data.userInfo;
            this.isProfileCompleted = apiResponse.commandResult.data.userInfo.IsProfileCompleted
            this.dataService.setData(this.profileObj);
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }


  async onReset() {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
  }

  onSubmit(f) {
    if (!f.dirty) {
      if (this.isProfileCompleted == 0) {
        this.goToSignHipaaDocuments();
      }else{
      Swal.fire({
        title: "No Change",
        text: "Your profile has not been updated",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          this.router.navigate([
            "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
          ]);
        }
      });
    }
    } else {
      Swal.fire({
        title: "PLEASE CONFIRM",
        text: "Are you sure you want to make these changes?",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          const finalData = {
            company_slug: ConstantHelper.COMPANY_SLUG,
            userId: this.profileObj.RecordId,
            fullName: this.profileObj.FullName,
            mobile: this.profileObj.Mobile,
            email: this.profileObj.Email,
            queId_01: this.profileObj.QueId_01 || null,
            ans_01: this.profileObj.Ans_01,
            queId_02: this.profileObj.QueId_02,
            ans_02: this.profileObj.Ans_02,
            practiceName: this.profileObj.PracticeName,
            streetAddress: this.profileObj.StreetAddress,
            city: this.profileObj.City,
            state: this.profileObj.State,
            zipCode: this.profileObj.ZipCode,
            officePhone: this.profileObj.OfficePhone,
            designation: this.profileObj.Designation || null,
          };
          this.dataService.setData(finalData);
          this.showLoader = true;
          return this.http
            .post(
              this.appConfig.getLambdaUrl06() + ApiHelper.updateAdminProfileDetails,
              finalData,
              this.httpOptions
            )
            .subscribe(
              async (apiResponse: any) => {
                // alert("Profile updated successfully.");
                this.showLoader = false;
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.doctorProfileComp.onSubmit1
                );
                if(alertStatus){
                  const alertStatus1 = await this.sweetAlertHelper.confirmPopUp(
                    this.message.confirmTitle,
                    this.message.confirmMessages.demographicsSign.msgSign
                  );
                  if(alertStatus1){
                    this.goToSignHipaaDocuments();
                  }
                  else{
                    this.router.navigate([
                      "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
                    ]);  
                  }
                }

                // window.location.reload();
              },
              (err) => {
                // console.log(err);
              }
            );
        }
      });
    }
  }

  findFirstSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 1,
    };
    // this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.firstQues = apiResponse.commandResult.data.questionsList;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findSecondSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 2,
    };
    // this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.secondQues = apiResponse.commandResult.data.questionsList;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goToSignHipaaDocuments(){
    this.activeTab = 'sign';
    this.router.navigate(["/" + this.childRoute + "/hipaa/" + this.loggedInUserId,]);
  }

  async openSignedDocs() {
    this.changeSecQue = false;
    this.resignDocs = true;
    this.changePass = false;
    this.reviewProfile = false;
    this.activeTab = 'review';
    return
    this.router.navigate(["/" + this.childRoute + "/review-signed-docs/" + this.loggedInUserId,]);
  }

  goToContacChangeSecurityQuestions() {
    this.changeSecQue = true;
    this.changePass = false;
    this.reviewProfile = false;
    this.resignDocs = false;
    this.activeTab = 'security';
    return
    this.router.navigate([
      '/' + this.childRoute + '/security-question/' + this.loggedInUserId,
    ]);
  }

  goToContacChangePassword() {
    this.changePass = true;
    this.changeSecQue = false;
    this.reviewProfile = false;
    this.resignDocs = false;
    this.activeTab = 'password';
    return
    this.router.navigate([
      '/' + this.childRoute + '/change-password/' + this.loggedInUserId,
    ]);
  }

  goToreviewProfile() {
    this.changePass = false;
    this.changeSecQue = false;
    this.reviewProfile = true;
    this.resignDocs = false;
    return
    this.router.navigate([
      '/' + this.childRoute + '/admin-profile/' + this.loggedInUserId,
    ]);
  }

}
