import { ConfirmationformComponent } from './../../user/confirmationform/confirmationform.component';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { ApiHelper } from './../../_helpers/api.helper';
import { CommonHelper } from './../../_helpers/common.helper';
import { ConstantHelper } from './../../_helpers/constant.helper';
import { AppConfig } from './../../../app/app.config';
import { SweetAlertHelper } from 'src/app/_helpers/sweet.alert.helper';
import { Message } from 'src/app/_locale/message';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent implements OnInit {
  loggedInUserId: string;
  resultJsonObj: any = {};
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  selectedDocId: string;
  selectedDocUrl: string;
  selectedDocName: string;
  showPdfModal = false;
  childRoute: string;

  docDeactivateSuccess = false;
  docActivateSuccess = false;
  docErrorFlashMsg = false;

  docUploadSuccess = false;

  documentsLength = false;
  documentsList: any;

  ahsPdfUrl: SafeResourceUrl = '';
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  showLoader = false;
  message = Message;
  dtOptions: DataTables.Settings = {};
  isPrimary = sessionStorage.getItem('isPrimary');
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.findDocumentsList();
    this.dtOptions = {
      order: [[0, 'desc']],
    };
  }

  findDocumentsList() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl02() +
          ApiHelper.name_getAdminDocumentsListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.showLoader = false;
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList = this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          this.showLoader = false;
        }
      );
  }

  closePdfModal() {
    this.showPdfModal = false;
  }

  loadDocunent(docId, docUrl, docName) {
    this.selectedDocId = docId;
    this.selectedDocUrl = docUrl;
    this.selectedDocName = docName;
    this.showPdfModal = true;
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.selectedDocUrl
    );
  }

  async disableDoc(DocID) {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.documenmnetlistComp.disableDoc
    );
    if (alertStatus) {
      const finalData = {
        doc_id: DocID,
        loggedInUserId: this.loggedInUserId,
        company_slug: ConstantHelper.COMPANY_SLUG,
      };

      this.http
        .post(
          this.appConfig.getLambdaUrl02() + ApiHelper.name_deactivateDocApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse) => {
            this.resultJsonObj = apiResponse;
            if (this.resultJsonObj.commandResult.status == 1) {
              if (
                this.resultJsonObj.commandResult.data.documentList.length > 0
              ) {
                this.documentsLength = true;
                this.documentsList = this.resultJsonObj.commandResult.data.documentList;
              } else {
                this.documentsLength = false;
              }
              this.docDeactivateSuccess = true;
              setTimeout(() => {
                this.docDeactivateSuccess = false;
              }, 2000);
              window.location.reload();
            } else {
              this.docErrorFlashMsg = true;
              setTimeout(() => {
                this.docErrorFlashMsg = false;
              }, 3000);
            }
            window.location.reload();
          },
          (err) => {}
        );
    }
  }

  async enableDoc(DocID) {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.documenmnetlistComp.enableDoc
    );
    if (alertStatus) {
      const finalData = {
        doc_id: DocID,
        loggedInUserId: this.loggedInUserId,
        company_slug: ConstantHelper.COMPANY_SLUG,
      };

      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getLambdaUrl02() + ApiHelper.name_activateDocApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse) => {
            this.showLoader = false;
            this.resultJsonObj = apiResponse;
            if (this.resultJsonObj.commandResult.status == 1) {
              if (
                this.resultJsonObj.commandResult.data.documentList.length > 0
              ) {
                this.documentsLength = true;
                this.documentsList =
                  this.resultJsonObj.commandResult.data.documentList;
              } else {
                this.documentsLength = false;
              }
              this.docActivateSuccess = true;
              setTimeout(() => {
                this.docActivateSuccess = false;
              }, 2000);
              window.location.reload();
            } else {
              this.docErrorFlashMsg = true;
              setTimeout(() => {
                this.docErrorFlashMsg = false;
              }, 3000);
            }
          },
          (err) => {}
        );
    }
  }

  async changeShowOnConfirmationStatus(DocID, statusId) {
    const confirmationMag = '';
    let confMsg = '';
    if (statusId == 1) {
      confMsg = this.message.confirmMessages.documenmnetlistComp.changesShow;
    } else {
      confMsg = this.message.confirmMessages.documenmnetlistComp.changesShow1;
    }

    const confirmStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      confMsg
    );
    if (confirmStatus) {
      this.showLoader = true;
      const finalData = {
        doc_id: DocID,
        status_id: statusId,
        loggedInUserId: this.loggedInUserId,
        company_slug: ConstantHelper.COMPANY_SLUG,
      };

      this.http
        .post(
          this.appConfig.getLambdaUrl02() +
            ApiHelper.name_changeShowOnConfirmationStatusApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse) => {
            this.showLoader = false;
            window.location.reload();

            this.resultJsonObj = apiResponse;
            if (this.resultJsonObj.commandResult.status == 1) {
              if (
                this.resultJsonObj.commandResult.data.documentList.length > 0
              ) {
                this.documentsLength = true;
                this.documentsList =
                  this.resultJsonObj.commandResult.data.documentList;
              } else {
                this.documentsLength = false;
              }
              this.docActivateSuccess = true;
              setTimeout(() => {
                this.docActivateSuccess = false;
              }, 2000);
            } else {
              this.docErrorFlashMsg = true;
              setTimeout(() => {
                this.docErrorFlashMsg = false;
              }, 3000);
            }
          },
          (err) => {}
        );
    }
  }

  async openAddDocPage() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.documenmnetlistComp.openAddDocPage
    );
    if (alertStatus) {
      this.router.navigate([
        '/' + this.childRoute + '/add-admin-doc/' + this.loggedInUserId,
      ]);
    }
  }

  async openModifyDocPage(doc_id, doc_name, doc_description, ShowConfirmation) {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.documenmnetlistComp.openModifyDoc
    );
    if (alertStatus) {
      localStorage.setItem('doc_description', doc_description);
      localStorage.setItem('ShowConfirmation_status', ShowConfirmation);
      this.router.navigate([
        '/' +
          this.childRoute +
          '/modify-admin-doc/' +
          this.loggedInUserId +
          '/' +
          doc_id +
          '/' +
          doc_name,
      ]);
    }
  }

  async viewDocGroups() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.documenmnetlistComp.viewDocGroups
    );
    if (alertStatus) {
      this.router.navigate([
        '/' + this.childRoute + '/add-doc-group/' + this.loggedInUserId,
      ]);
    }
  }

  goToLogs() {
    this.router.navigate([
      '/' + this.childRoute + '/activity-logs/' + this.loggedInUserId,
    ]);
  }

   async disableUploadDocument(DocID) {
    let confMsg = '';
    confMsg = this.message.confirmMessages.documenmnetlistComp.disableUploadDocument;
    const confirmStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      confMsg
    );
  
    if (confirmStatus) { 
      const finalData = {
        doc_id: DocID,
        loggedInUserId: this.loggedInUserId,
        company_slug: ConstantHelper.COMPANY_SLUG,
      };
      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getLambdaUrl05() + ApiHelper.changeUploadDocumentStatus,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse) => {
            this.showLoader = false;
            this.resultJsonObj = apiResponse;
            if (this.resultJsonObj.commandResult.status == 1) {
              if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
                this.documentsLength = true;
                this.documentsList = this.resultJsonObj.commandResult.data.documentList;
              } else {
                this.documentsLength = false;
              }
              this.docUploadSuccess = true;
              setTimeout(() => {
                this.docUploadSuccess = false;
              }, 2000);
              window.location.reload();
            } else {
              this.docErrorFlashMsg = true;
              setTimeout(() => {
                this.docErrorFlashMsg = false;
              }, 3000);
            }
          },
          (err) => {
            this.showLoader = false;
          }
        );
    }
  }
 

  async enableElecFill(DocID) {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.documenmnetlistComp.elctronicallyFill
    );
    if (alertStatus) {
      const finalData = {
        doc_id: DocID
      };
      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.enableElectronicallyFill,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse) => {
            this.showLoader = false;
            this.resultJsonObj = apiResponse;
            if (this.resultJsonObj.commandResult.status == 1) {
              window.location.reload();
            } else {
              this.docErrorFlashMsg = true; 
              setTimeout(() => {
                this.docErrorFlashMsg = false;
              }, 3000);
            }
          },
          (err) => {
            this.showLoader = false;
          }
        );
      
    }
}
  async disableElecFill(DocID) {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.documenmnetlistComp.disableelctrFill
    );
    if (alertStatus) {
      const finalData = {
        doc_id: DocID
      };

      this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.disableElectronicallyFill,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse) => {
            this.resultJsonObj = apiResponse;
            if (this.resultJsonObj.commandResult.status == 1) {
              window.location.reload();
              }else {
              this.docErrorFlashMsg = true;
              setTimeout(() => {
                this.docErrorFlashMsg = false;
              }, 3000);
            }
            window.location.reload();
          },
          (err) => {}
        );
    }
  }

  async goToDocuments() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.addDocComp.goToDocuments
    );
    if (alertStatus) {
      this.router.navigate([
        '/' + this.childRoute + '/documents/' + this.loggedInUserId,
      ]);
    }
  }

}
