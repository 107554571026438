import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { from } from "rxjs";
import { DatePipe } from "@angular/common";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { SignaturePad } from "angular2-signaturepad";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "./../../_services/user.service";
import * as moment from "moment";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
@Component({
  selector: "app-wi",
  templateUrl: "./wi.component.html",
  styleUrls: ["./wi.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class WiComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  hippaJson: any = {};
  loggedInUserId: string;
  // userSignatures: any = [];
  // userSignatureSelected = false;
  // userFullSignature = "";
  // userInitialSignature = "";
  // userChoosedSignature = "";
  // userFullSignatureExit = false;
  // userInitialSignatureExis = false;
  showPdfModal = false;
  // signatureType: any;

  ssnPattern = "^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$";

  resultJsonObj: any = {};
  clean = "0";
  // @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  // @ViewChild("canvasArea", { static: true }) canvasArea: ElementRef;
  // canvasElm: any;
  // public signaturePadOptions: Object = {
  //   minWidth: 1,
  //   canvasWidth:
  //     window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
  //   canvasHeight: 450,
  // };

  currentDate = new Date();
  dateFormat = "MM-dd-yyyy";

  // userWitnessSignatureSelected = false;
  // userWitnessChoosedSignature = "";

  // signatureFroWhom: any;
  isEmail: any;
  isPracticePhy: any;
  physicianId: any;
  requestId: any;
  tempId: any;
  userOptionId: any;
  emailLogId: any;
  message = Message;
  patientId: string;
  fileDesTxt: any = '';
  selectedDelegateId: any;
  assignedDelegateId: any;
  isDelegated: any = 0;
  selectedDocUrl: any;
  selectedPhysician: any = {};
  docInfo: any = {};
  selectedStateCode: any;
  showLoader = false;
  childRoute: string;
  patient_name: any;
  physicianName: any;
  PhysicianOfficeAddress: any;  
  patient_city:any;
  city_zip:any;
 

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    public userService: UserService
  ) {
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    this.isEmail = this.activatedRoute.snapshot.paramMap.get("isEmail")
      ? this.activatedRoute.snapshot.paramMap.get("isEmail")
      : 0;
    this.isPracticePhy =
      this.activatedRoute.snapshot.paramMap.get("isPracticePhy");
    this.physicianId = this.activatedRoute.snapshot.paramMap.get("physicianId")
      ? this.activatedRoute.snapshot.paramMap.get("physicianId")
      : 0;
    this.requestId = this.activatedRoute.snapshot.paramMap.get("requestId")
      ? this.activatedRoute.snapshot.paramMap.get("requestId")
      : 0;

    this.tempId = this.activatedRoute.snapshot.paramMap.get("tempId")
      ? this.activatedRoute.snapshot.paramMap.get("tempId")
      : 0;
    this.userOptionId = this.activatedRoute.snapshot.paramMap.get(
      "userOptionId"
    )
      ? this.activatedRoute.snapshot.paramMap.get("userOptionId")
      : 0;
    this.emailLogId = this.activatedRoute.snapshot.paramMap.get("emailLogId")
      ? this.activatedRoute.snapshot.paramMap.get("emailLogId")
      : 0;

    this.hippaJson.healthcare_provider = this.userService.getUS_FromatName(
      localStorage.getItem("providerName")
    );
    this.hippaJson.healthcare_provider_email =
      localStorage.getItem("providerEmail");
    this.hippaJson.healthcare_provider_npi = localStorage.getItem(
      "providerPhysicianNipNo"
    );
    this.hippaJson.street_address = localStorage.getItem(
      "providerOfficeAddress"
    );
    
    this.hippaJson.city_zip = localStorage.getItem("providerCity")
      ? localStorage.getItem("providerCity")
      : "" + ", " + localStorage.getItem("PhysicianStateName")
      ? localStorage.getItem("PhysicianStateName")
      : "" + ", " + localStorage.getItem("providerZipCode")
      ? localStorage.getItem("providerZipCode")
      : "";

    this.hippaJson.providerCity = localStorage.getItem("providerCity");
    this.hippaJson.providerPhone = localStorage.getItem("providerPhone");
    this.hippaJson.providerPhysicianNipNo = localStorage.getItem(
      "providerPhysicianNipNo"
    );

    this.hippaJson.patient_name = this.userService.getUS_FromatName(
      localStorage.getItem("loggedInUserName")
    );
    //this.fnFindSignature(this.loggedInUserId);
    this.fnFindDemographic(this.loggedInUserId);
    // this.hippaJson.patient_signature_date = this.currentDate;
    // this.hippaJson.witness_signature_date = this.currentDate;
    // this.hippaJson.pre_to = this.currentDate;
    // this.hippaJson.post_to = this.currentDate;
    this.fnFindDemographic(this.patientId);
    this.findUserDetails(this.patientId);

    this.hippaJson.patient_signature_date = this.currentDate;
    this.hippaJson.healthcare_provider = localStorage.getItem('selectedPhysicianName');
    this.hippaJson.PhysicianOfficeAddress = localStorage.getItem('selectedPhysicianOfficeAddress');
    this.hippaJson.physicianNPI = localStorage.getItem('selectedPhysicianNPI');
    this.hippaJson.physicianEmail = localStorage.getItem('selectedPhysicianEmail');    
    this.hippaJson.physicianPhone=localStorage.getItem('Mobile');
    this.hippaJson.physicianCity = localStorage.getItem('selectedPhysicianCity');
    this.hippaJson.physicianState = localStorage.getItem('selectedPhysicianState');
    this.hippaJson.physicianZip=localStorage.getItem('providerZipCode');    
  }

  ngOnInit() {
    // console.log(localStorage);
    this.patientId = this.activatedRoute.snapshot.paramMap.get('patientId');
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.hippaJson.checkbox_01 = false;
    this.hippaJson.checkbox_02 = false;
    this.hippaJson.checkbox_03 = false;
    this.hippaJson.checkbox_04 = false;
    this.hippaJson.checkbox_05 = false;
    this.hippaJson.checkbox_06 = false;
    this.hippaJson.checkbox_07 = false;
    this.hippaJson.checkbox_08 = false;
    this.hippaJson.checkbox_09 = false;
    this.hippaJson.checkbox_10 = false;
    // this.hippaJson.patient_signature = "";
    // this.hippaJson.witness_signature = "";
    this.findUserDetails(this.patientId); 
    this.fnFindDemographic(this.patientId);  
    this.hippaJson.physicianName=localStorage.getItem('selectedPhysicianName'),
    this.hippaJson.healthcare_provider_npi = localStorage.getItem(
      "providerPhysicianNipNo"
    );
    this.hippaJson.street_address = this.hippaJson.PhysicianOfficeAddress;   
    this.hippaJson.healthcare_provider = this.hippaJson.physicianName;  
    this.hippaJson.city_zip= this.hippaJson.physicianCity + "," + this.hippaJson.physicianState+","+this.hippaJson.physicianZip;
  }

  findUserDetails(patientId) {
    const finalData = {
      company_slug: null,
      id: patientId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.hippaJson.patient_name = apiResponse.commandResult.data.userInfo.LastName + ', ' + apiResponse.commandResult.data.userInfo.FirstName;
            this.hippaJson.dob = apiResponse.commandResult.data.userInfo.DOB;
            this.hippaJson.patient_phone=apiResponse.commandResult.data.userInfo.Mobile;
            this.hippaJson.patient_email=apiResponse.commandResult.data.userInfo.Email;
         
           // console.log("patient email ",this.hippaJson.patient_email);
            //console.log("patient ph: ", this.hippaJson.tele_no);
            }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onSubmit() {
    // if (confirm('PLEASE CONFIRM:   By clicking ‘OK’:The information you have entered will be used to electronically generate a completed State HIPAA Form.'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.onSubmit
    );
    if(alertStatus){
      const finalData = {
        selectedDocUrl: this.selectedDocUrl,
        fileDesTxt: this.fileDesTxt,
        loggedInUserId: this.loggedInUserId,
        userId: this.patientId,
        patient_name: this.hippaJson.patient_name,        
        dob: this.hippaJson.dob,  
        patient_address:this.hippaJson.patient_address,    
        patient_city:this.hippaJson.patient_city,
        patient_city_zip:this.hippaJson.patient_city_zip,
        patient_state:this.hippaJson.patient_state,
        patient_phone:this.hippaJson.patient_phone,
        patient_email:this.hippaJson.patient_email,
        patient_fax:this.hippaJson.patient_fax,  
        other_need:this.hippaJson.other_need,
        other_specify:this.hippaJson.other_specify,
        event_date:this.hippaJson.event_date,
        health_description:this.hippaJson.health_description,      
        physicianNPI: this.hippaJson.PhysicianNipNo,
        healthcare_provider:this.hippaJson.physicianName, 
        from_date:this.hippaJson.from_date,
        to_date:this.hippaJson.to_date,
        physicianState: this.selectedStateCode,
        assignedDelegateId: this.assignedDelegateId,
        isDelegated: this.isDelegated,
        selectedDelegateId: this.selectedDelegateId,
        selectedEmail: this.selectedPhysician.altEmail,
        selectedPhysician: this.selectedPhysician.physicianName,        
        healthcare_provider_email: this.hippaJson.healthcare_provider_email,
        healthcare_provider_npi: this.hippaJson.healthcare_provider_npi,
        street_address: this.hippaJson.street_address,
        city_zip: this.hippaJson.city_zip,  
        street_address1: this.hippaJson.street_address1,
        city_zip1: this.hippaJson.city_zip1,
        healthcare_provider1:this.hippaJson.healthcare_provider1,              
        social_security_no: this.hippaJson.social_security_no,
        purposes: this.hippaJson.purposes,
        representative_name: this.hippaJson.representative_name,
        representative_capacity: this.hippaJson.representative_capacity,
        representative_street_address:
          this.hippaJson.representative_street_address,
        representative_city_zip: this.hippaJson.representative_city_zip,
        name_relation: this.hippaJson.name_relation,
        checkbox_01: this.hippaJson.checkbox_01,
        checkbox_02: this.hippaJson.checkbox_02,
        checkbox_03: this.hippaJson.checkbox_03,
        checkbox_04: this.hippaJson.checkbox_04,
        checkbox_05: this.hippaJson.checkbox_05,
        checkbox_06: this.hippaJson.checkbox_06,
        checkbox_07: this.hippaJson.checkbox_07,
        checkbox_08: this.hippaJson.checkbox_08,
        checkbox_09: this.hippaJson.checkbox_09,
        checkbox_10: this.hippaJson.checkbox_10,        
        isEmail: this.isEmail,
        requestId: this.requestId,
      };
      // console.log(reqData);
      this.showLoader=true;
      return this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.generateHipaaWI,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            if (this.hippaJson.loggedInUserRoleId == 1) {
              this.router.navigate([
                '/doctor/' + '/demographics/' +
                this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
                '/1/'
              ]);
            } else if (this.hippaJson.loggedInUserRoleId == 4) {
              this.router.navigate([
                '/doctor/' + '/demographics/' +
                this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
                '/1/'
              ]);
            } else {
              if (window.open(apiResponse.commandResult.data, '_blank')) {
                this.router.navigate([
                  '/doctor/' + '/ushf/' +
                  this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.activatedRoute.snapshot.paramMap.get('patientId') + '/' + this.activatedRoute.snapshot.paramMap.get('demographicId')
  
                ]);
              }
            }
            this.showLoader=false;
          },
          (err) => {
          }
        );
    }
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(['/doctor/' +
        '/dashboard/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
      ]);
    }
  }

  async goBack() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the USHF Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.goBack
    );
    if (alertStatus) {
      this.router.navigate([
        '/doctor/' + '/ushf/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.activatedRoute.snapshot.paramMap.get('patientId') + '/' + this.activatedRoute.snapshot.paramMap.get('demographicId')

      ]);
    }
  }

  closePdfModal() {
    this.showPdfModal = false;
   // this.hippaJson.patient_signature = "";
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    // this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
    // this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }

  clearCanvas() {
    if (
      confirm("PLEASE CONFIRM: Are you sure you want to clear you signature.")
    ) {
      // this.signaturePad.clear();
    }
  }

  drawStart() {}

  fnFindDemographic(patientId) {
    const finalData = {
      id: patientId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (response: any) => {
          // this.hippaJson.dob = new Date(
          //   response.commandResult.data.userInfo.form1DOB
          // );
          this.hippaJson.patient_city =
            response.commandResult.data.userInfo.form1City;
            console.log(this.hippaJson.patient_city)
          this.hippaJson.patient_zip =
            response.commandResult.data.userInfo.form1ZIP;
          this.hippaJson.patient_state =
            response.commandResult.data.userInfo.form1State;


          this.hippaJson.patient_address = response.commandResult.data.userInfo.form1StreetName;

         this.hippaJson.patient_city_zip = this.hippaJson.patient_city + "," +  this.hippaJson.patient_state + "," +  this.hippaJson.patient_zip

            console.log("pat address:", this.hippaJson.patient_address );


          // this.hippaJson.city_zip = response.commandResult.data.userInfo.form1PrimaryPhysicianCity + ', ' + response.commandResult.data.userInfo.form1State + ', ' + response.commandResult.data.userInfo.form1ZIP;

          // this.hippaJson.street_address = response.commandResult.data.userInfo.form1StreetName;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  autoFormatSSN() {
    // alert('Amit');
    let val = this.hippaJson.social_security_no.replace(/\D/g, "");
    val = val.replace(/^(\d{3})/, "$1-");
    val = val.replace(/-(\d{2})/, "-$1-");
    val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    this.hippaJson.social_security_no = val;
  }

  openWIBlankpdf() {
    window.open("https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/Wisconsin-HIPAA-Medical-Release-Form.pdf", "_blank")
  }
    

}
