import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { SignaturePad } from "angular2-signaturepad";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: "app-user-docs-acknowledgement",
  templateUrl: "./user-docs-acknowledgement.component.html",
  styleUrls: ["./user-docs-acknowledgement.component.scss"],
})
export class UserDocsAcknowledgementComponent implements OnInit {
  loggedInUserId: string;
  urlType: string;
  demographicId: string;

  pId: string;
  userId: string;
  pUserId: string;
  docId: string;
  doctorName: string;
  patientName: string;
  loggedInUserObj: any = {};
  patientObj: any = {};
  imageObj: any = {};
  imageUrl: string;

  resultJsonObj: any = {};
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  @ViewChild("canvasArea", { static: true }) canvasArea: ElementRef;
  canvasElm: any;
  // tslint:disable-next-line: ban-types
  public signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    minWidth: 1,
    canvasWidth:
      window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
    canvasHeight: 450,
  };

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public sweetAlertHelper: SweetAlertHelper,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.urlType = this.activatedRoute.snapshot.paramMap.get("urlType");
    this.pId = this.activatedRoute.snapshot.paramMap.get("pId");
    this.pUserId = this.activatedRoute.snapshot.paramMap.get("pUserId");
    this.docId = this.activatedRoute.snapshot.paramMap.get("docId");
    this.userId = this.activatedRoute.snapshot.paramMap.get("userId");
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    this.findLoggedInUserDetails(this.loggedInUserId);

    // tslint:disable-next-line: triple-equals
    if (this.urlType) {
      if (this.urlType === "review") {
        // console.log('review');
        this.findPatientUserInfo(this.pId);
      }
      if (this.urlType === "sign") {
        // console.log('sign');
        this.findPatientUserDetails(this.pUserId);
      }
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }

  async clearCanvas() {
    // if (
    //   confirm("PLEASE CONFIRM: Are you sure you want to clear you signature.")
    // )

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.addAcknowd2dDocComp.clearCanvas
    );
    {
      this.signaturePad.clear();
    }
  }
  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log("begin drawing");
    // this.router.navigate(['/admin/dashboard/' + this.userId]);
  }

  findLoggedInUserDetails(loggedInUserId) {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
      profileId: loggedInUserId,
    };

    return this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findUserDetails06,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.loggedInUserObj = apiResponse;
          this.doctorName = apiResponse.commandResult.data.UserInfo.FullName;
          // console.log('doctor', apiResponse);
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPatientUserDetails(patientUserId) {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
      profileId: patientUserId,
    };

    return this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findUserDetails06,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.patientObj = apiResponse;
          // console.log('patient', apiResponse.commandResult.data.UserInfo);
          this.patientName = apiResponse.commandResult.data.UserInfo.FullName;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPatientUserInfo(patientUserHashId) {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
      profileId: patientUserHashId,
    };
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findUserDetailsByUserHashApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.patientObj = apiResponse;
          // console.log('patienthash', apiResponse.commandResult.data.UserInfo);
          this.patientName = apiResponse.commandResult.data.UserInfo.FullName;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async saveCanvas() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: By clicking 'OK', you are signing the document and now will be able to review each."
    //   )
    // )

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.signaturewaiverComp.saveCanvas
    );
    {
      const finalData = {
        signatureBase64: this.signaturePad.toDataURL(),
        urlType: this.urlType,
        pId: this.pId,
        pUserId: this.pUserId,
        docId: this.docId,
        userId: this.userId,
        loggedInUserId: this.loggedInUserId,
      };
      return this.http
        .post(
          this.appConfig.getLambdaUrl04() +
            ApiHelper.name_generateDocSignatureImageApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            this.imageObj = apiResponse;
            this.imageUrl =
              apiResponse.commandResult.data.fileInfo.signatureUrl;
            // console.log('imageUrl', this.imageUrl);
            this.http
              .post(
                this.appConfig.getPhpUrl() +
                  ApiHelper.php_generateDocSignatureFilePdfApi,
                finalData,
                this.httpOptions
              )
              .subscribe(
                // tslint:disable-next-line: variable-name
                async (_apiResponse: any) => {
                  // console.log(_apiResponse);
                  // alert("Acknowledgement sent to the patient.");
                  const alertStatus = await this.sweetAlertHelper.alertPopUp(
                    this.message.alertMessages.userDocAcknowledgeComp.saveCanvas
                  );
                  // tslint:disable-next-line: triple-equals
                  if (this.urlType == "review") {
                    this.router.navigate([
                      "/admin/user-docs/" + this.pId + "/" + this.userId,
                    ]);
                  }

                  // tslint:disable-next-line: triple-equals
                  if (this.urlType == "sign") {
                    this.router.navigate([
                      "/admin/user-sign-docs/" +
                        this.pUserId +
                        "/" +
                        this.docId,
                    ]);
                  }
                },
                (err) => {
                  // console.log(err);
                }
              );
          },
          (err) => {
            // console.log(err);
          }
        );

      // alert("Acknowledgement sent to the patient.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.userDocAcknowledgeComp.saveCanvas
      );
      if (this.urlType == "review") {
        this.router.navigate([
          "/admin/user-docs/" + this.pId + "/" + this.userId,
        ]);
      }

      if (this.urlType == "sign") {
        this.router.navigate([
          "/admin/user-sign-docs/" + this.pUserId + "/" + this.docId,
        ]);
      }
    }
  }
}
