import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    Renderer2,
  } from "@angular/core";
  import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
  import { Router, ActivatedRoute } from "@angular/router";
  import { FormBuilder, FormGroup } from "@angular/forms";
  import { from } from "rxjs";
  import { DatePipe } from "@angular/common";
  import {
    HttpClient,
    HttpParams,
    HttpRequest,
    HttpHeaders,
  } from "@angular/common/http";
  import { SignaturePad } from "angular2-signaturepad";
  import { environment } from "src/environments/environment";
  // import { ApiHelper } from "../_helpers/api.helper";
  // import { CommonHelper } from "../_helpers/common.helper";
  // import { ConstantHelper } from "../_helpers/constant.helper";
  import { CommonHelper } from "src/app/_helpers/common.helper";
  import { ConstantHelper } from "src/app/_helpers/constant.helper";
  
  import { ApiHelper } from "src/app/_helpers/api.helper";
  // import { AppConfig } from "../app.config";
  // import { UserService } from "../_services/user.service";
  import { AppConfig } from "src/app/app.config";
  import { UserService } from "src/app/_services/user.service";
  import * as moment from "moment";
  // import { SweetAlertHelper } from "../_helpers/sweet.alert.helper";
  // import { Message } from "../_locale/message";
  import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
  import { Message } from "src/app/_locale/message";
  
  //  import { navigateToRoute } from "./../../_helpers/emailDoctorExpertOpinionRouteBack.helper";
  
  @Component({
    selector: "app-doc-five",
    templateUrl: "./doc-five.component.html",
    styleUrls: ["./doc-five.component.scss"],
    providers: [AppConfig, CommonHelper],
  })
  export class DocFive implements OnInit {
    httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    httpOptions = {
      headers: this.httpHeaders,
    };
    hippaJson: any = {};
    loggedInUserId: string;
    userSignatures: any = [];
    userSignatureSelected = false;
    userFullSignature = "";
    userInitialSignature = "";
    userChoosedSignature = "";
    userSignatureSelected1 = false;
    userChoosedSignature1 = "";
    userFullSignatureExit = false;
    userInitialSignatureExis = false;
    showPdfModal = false;
    signatureType: any;
  
    ssnPattern = "^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$";
  
    resultJsonObj: any = {};
    clean = "0";
    @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
    @ViewChild("canvasArea", { static: true }) canvasArea: ElementRef;
    canvasElm: any;
    public signaturePadOptions: Object = {
      minWidth: 1,
      canvasWidth:
        window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
      canvasHeight: 450,
    };
  
    currentDate = new Date();
    dateFormat = "MM-dd-yyyy";
  
    userWitnessSignatureSelected = false;
    userWitnessChoosedSignature = "";
  
    signatureFroWhom: any;
    isEmail: any;
    isPracticePhy: any;
    physicianId: any;
    requestId: any;
    tempId: any;
    userOptionId: any;
    emailLogId: any;
    message = Message;
    sendDocRoute = sessionStorage.getItem("sendDocRoute");
    loggedInrName: string;
    loggedInName: string;
    currentDay: string;
    operativeProcedure: string;
    demographicId: string;
    phyicianId: string;
    invitationId: string;
    parentId: string | number;
    childRoute: string;
  
    constructor(
      public commonHelper: CommonHelper,
      private appConfig: AppConfig,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private http: HttpClient,
      private sanitizer: DomSanitizer,
      public sweetAlertHelper: SweetAlertHelper,
      public userService: UserService
    ) {
      localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
      this.childRoute = activatedRoute.snapshot.url[0].path;
      this.loggedInUserId =
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
      this.demographicId =
        this.activatedRoute.snapshot.paramMap.get("demographicId");
      this.phyicianId = this.activatedRoute.snapshot.paramMap.get("phyicianId");
      this.invitationId =
        this.activatedRoute.snapshot.paramMap.get("invitationId");
      this.parentId = this.activatedRoute.snapshot.paramMap.get("parentId")
        ? this.activatedRoute.snapshot.paramMap.get("parentId")
        : 0;
  
      // this.loggedInUserId = localStorage.getItem("delegateId")
      //   ? localStorage.getItem("delegateId")
      //   : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
      // this.isEmail = this.activatedRoute.snapshot.paramMap.get("isEmail")
      //   ? this.activatedRoute.snapshot.paramMap.get("isEmail")
      //   : 0;
      this.isPracticePhy =
        this.activatedRoute.snapshot.paramMap.get("isPracticePhy");
      this.physicianId = this.activatedRoute.snapshot.paramMap.get("physicianId")
        ? this.activatedRoute.snapshot.paramMap.get("physicianId")
        : 0;
      this.requestId = this.activatedRoute.snapshot.paramMap.get("requestId")
        ? this.activatedRoute.snapshot.paramMap.get("requestId")
        : 0;
  
      this.tempId = this.activatedRoute.snapshot.paramMap.get("tempId")
        ? this.activatedRoute.snapshot.paramMap.get("tempId")
        : 0;
      this.userOptionId = this.activatedRoute.snapshot.paramMap.get(
        "userOptionId"
      )
        ? this.activatedRoute.snapshot.paramMap.get("userOptionId")
        : 0;
      this.emailLogId = this.activatedRoute.snapshot.paramMap.get("emailLogId")
        ? this.activatedRoute.snapshot.paramMap.get("emailLogId")
        : 0;
  
      this.hippaJson.healthcare_provider = this.userService.getUS_FromatName(
        localStorage.getItem("providerName")
      );
      this.hippaJson.healthcare_provider_email =
        localStorage.getItem("providerEmail");
      this.hippaJson.healthcare_provider_npi = localStorage.getItem(
        "providerPhysicianNipNo"
      );
      this.hippaJson.street_address = localStorage.getItem(
        "providerOfficeAddress"
      );
      this.hippaJson.city_zip = localStorage.getItem("providerCity")
        ? localStorage.getItem("providerCity")
        : "" + ", " + localStorage.getItem("PhysicianStateName")
        ? localStorage.getItem("PhysicianStateName")
        : "" + ", " + localStorage.getItem("providerZipCode")
        ? localStorage.getItem("providerZipCode")
        : "";
  
      this.hippaJson.providerCity = localStorage.getItem("providerCity");
      this.hippaJson.providerPhone = localStorage.getItem("providerPhone");
      this.hippaJson.providerPhysicianNipNo = localStorage.getItem(
        "providerPhysicianNipNo"
      );
  
      
      this.loggedInUserId =
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
      this.demographicId =
        this.activatedRoute.snapshot.paramMap.get("demographicId");
      this.phyicianId = this.activatedRoute.snapshot.paramMap.get("phyicianId");
      this.invitationId =
        this.activatedRoute.snapshot.paramMap.get("invitationId");
      this.parentId = this.activatedRoute.snapshot.paramMap.get("parentId")
        ? this.activatedRoute.snapshot.paramMap.get("parentId")
        : 0;
  
      this.fnFindSignature(this.loggedInUserId);
      this.fnFindDemographic(this.loggedInUserId);
      this.findUserDetails(this.loggedInUserId);
      this.hippaJson.patient_signature_date = this.currentDate;
      this.hippaJson.patient_signature1_date = this.currentDate;
      this.hippaJson.first_witness_signature_date = this.currentDate;
      this.hippaJson.pre_to = this.currentDate;
      this.hippaJson.post_to = this.currentDate;
      const today = new Date();
      this.currentDay = today.toISOString().split('T')[0];
    }
    ngOnInit() {
      // console.log(localStorage);
      this.hippaJson.checkbox_01 = false;
      this.hippaJson.checkbox_02 = false;
      this.hippaJson.checkbox_03 = false;
      this.hippaJson.checkbox_04 = false;
      this.hippaJson.checkbox_05 = false;
      this.hippaJson.checkbox_06 = false;
      this.hippaJson.checkbox_07 = false;
      this.hippaJson.patient_signature = "";
      this.hippaJson.patient_signature1 = "";
      this.hippaJson.first_witness_signature = "";
      this.loggedInName = localStorage.getItem('loggedInUserName');
  
      
    }
    formatDate(arg0: Date, dateFormat: string) {
      throw new Error("Method not implemented.");
    }
  
    fnFindSignature(user_id) {
      const reqData = {
        user_id,
      };
      return this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.findUserSignature,
          reqData,
          this.httpOptions
        )
        .subscribe(
          (response: any) => {
             {
              this.userSignatures = response.commandResult.data.userSignatures;
              this.userFullSignature =
                response.commandResult.data.userSignatures.full_signature_url;
              this.userInitialSignature =
                response.commandResult.data.userSignatures.initial_signature_url;
  
              if (response.commandResult.data.userSignatures.full_signature_url) {
                this.userFullSignatureExit = true;
              }
  
              if (
                response.commandResult.data.userSignatures.initial_signature_url
              ) {
                this.userInitialSignatureExis = true;
              }
            } 
            // console.log(this.userSignatures);
          },
          (err) => {}
        );
    }
  
    async onSubmit() {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.njComp.preop 
      );
      if (alertStatus) {
        const reqData = {
          loggedInUserId: this.loggedInUserId,
          patientName: this.loggedInName,
          date:this.currentDay,
          signature_url:this.userFullSignature
  
        };
    
        return this.http
          .post(
            this.appConfig.getLambdaUrl06() + ApiHelper.practiceReliantDisclaimer,
            reqData,
            this.httpOptions
          )
          .subscribe(
            (response: any) => {
              if (response.success == 1) {
                window.open(response.message, "_blank");
  
                this.router.navigate([
                  "/hippa-acceptance/" +
                    this.activatedRoute.snapshot.paramMap.get("loggedInUserId") + "/" +
                    this.demographicId + "/" +
                    this.phyicianId + "/" +
                    this.invitationId + "/" +
                    this.parentId
                ]);
              } else {
                if (window.open(response.commandResult.data, "_blank")) {
                  this.router.navigate([
                    "/dashboard/" +
                      this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
                  ]);
                }
              }
            },
            (err) => {}
          );
      }
    }
  
    
  
    closePdfModal() {
      this.showPdfModal = false;
      this.hippaJson.patient_signature = "";
    }
  
    openSignatureCanvas(selectedValue, signatureFroWhom) {
      this.signatureFroWhom = signatureFroWhom;
      // console.log(selectedValue);
      this.signatureType = selectedValue;
      if (
        selectedValue == "AFS" ||
        selectedValue == "AFS1" ||
        selectedValue == "AIS" ||
        selectedValue == "AWTSG"
      ) {
        this.showPdfModal = true;
      }
      if (selectedValue == "FS" || selectedValue == "IS") {
        this.userSignatureSelected = true;
        if (selectedValue == "FS") {
          this.userChoosedSignature = this.userFullSignature;
        }
        if (selectedValue == "IS") {
          this.userChoosedSignature = this.userInitialSignature;
        }
      }
    }
  
    ngAfterViewInit() {
      // this.signaturePad is now available
      this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
      this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    }
  
    drawComplete() {
      // will be notified of szimek/signature_pad's onEnd event
      // console.log(this.signaturePad.toDataURL());
    }
  
    async saveCanvas() {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.njComp.saveCanvas
      );
      // if (
      //   confirm(
      //     "PLEASE CONFIRM: This will replace your existing signature. Do you wish to change?"
      //   )
      // )
      if (alertStatus) {
        const reqData = {
          loggedInUserId: this.loggedInUserId,
          signatureBase64: this.signaturePad.toDataURL(),
          signatureType: this.signatureType,
        };
        return this.http
          .post(
            this.appConfig.getLambdaUrl05() +
              ApiHelper.name_saveSignatureImageApi,
            reqData,
            this.httpOptions
          )
          .subscribe(
            (apiResponse: any) => {
              if (apiResponse.commandResult.status == 1) {
                this.userSignatureSelected = true;
                this.userChoosedSignature =
                  apiResponse.commandResult.data.fileInfo.signatureUrl;
                // console.log(this.userChoosedSignature);
                this.fnFindSignature(this.loggedInUserId);
                this.showPdfModal = false;
                // this.hippaJson.patient_signature = '';
              }
            },
            (err) => {
              // console.log(err);
            }
          );
      }
    }
  
    async clearCanvas() {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.njComp.clearCanvas
      );
      // if (
      //   confirm("PLEASE CONFIRM: Are you sure you want to clear you signature.")
      // )
      if (alertStatus) {
        this.signaturePad.clear();
      }
    }
  
    drawStart() {}
  
    findUserDetails(userId) {
      const finalData = {
        company_slug: null,
        id: userId,
      };
      return this.http
        .post(
          this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            if (apiResponse.commandResult.status == 1) {
              this.hippaJson.patient_name =
                apiResponse.commandResult.data.userInfo.LastName +
                ", " +
                apiResponse.commandResult.data.userInfo.FirstName;
  
              // this.editDdemographicsformDataObj.fName = this.userService.getFirstName(
              //   apiResponse.commandResult.data.userInfo.FullName
              // );
              // this.editDdemographicsformDataObj.lName = this.userService.getLastName(
              //   apiResponse.commandResult.data.userInfo.FullName
              // );
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  
    fnFindDemographic(user_id) {
      const finalData = {
        id: user_id,
      };
      this.http
        .post(
          this.appConfig.getLambdaUrl04() +
            ApiHelper.name_findUserDemographicGeneralApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (response: any) => {
            this.hippaJson.dob = new Date(
              response.commandResult.data.userInfo.form1DOB
            );
  
            // this.hippaJson.city_zip = response.commandResult.data.userInfo.form1PrimaryPhysicianCity + ', ' + response.commandResult.data.userInfo.form1State + ', ' + response.commandResult.data.userInfo.form1ZIP;
  
            // this.hippaJson.street_address = response.commandResult.data.userInfo.form1StreetName;
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  
    autoFormatSSN() {
      // alert('Amit');
      let val = this.hippaJson.social_security_no.replace(/\D/g, "");
      // val = val.replace(/^(\d{3})/, "$1-");
      // val = val.replace(/-(\d{2})/, "-$1-");
      // val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
      this.hippaJson.social_security_no = val;
    }
  
    saveParentCanvas(signFor) {
      const reqData = {
        loggedInUserId: this.loggedInUserId,
        signatureBase64: this.signaturePad.toDataURL(),
        signatureType: this.signatureType,
        signFor,
      };
      return this.http
        .post(
          this.appConfig.getLambdaUrl05() +
            ApiHelper.name_saveParentSignatureImageApi,
          reqData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            if (apiResponse.commandResult.status == 1) {
              if (signFor == "WTSG") {
                this.userWitnessSignatureSelected = true;
                this.userWitnessChoosedSignature =
                  apiResponse.commandResult.data.fileInfo.signatureUrl;
              }
              if (signFor == "AFS1") {
                this.userSignatureSelected1 = true;
                this.userChoosedSignature1 =
                  apiResponse.commandResult.data.fileInfo.signatureUrl;
              }
              this.showPdfModal = false;
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
   
  
    
    
  }
  