import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';

declare let $: any;
import {AppConfig} from './../../../../app/app.config';
import { Message } from 'src/app/_locale/message';
import {SweetAlertHelper} from './../../../_helpers/sweet.alert.helper';
import {CommonHelper} from './../../../_helpers/common.helper';
import {ApiHelper} from './../../../_helpers/api.helper';
import { DataService } from 'src/app/_helpers/data.service.helper';

@Component({
  selector: 'app-accept-hipaa',
  templateUrl: './accept-hipaa.component.html',
  styleUrls: ['./accept-hipaa.component.scss']
})
export class AcceptHipaaComponent implements OnInit {


  isChecked = false;
  loggedInUserId: string;
  resultJsonObj: any = {};
  documentsLength = false;
  documentsList: any;
  ahsPdfUrl: SafeResourceUrl = '';
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  showDeleteButton = false;
  showLoader = false;
  message = Message;
  jsonData: any;
  childRoute: string;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    private dataService: DataService,
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
        ApiHelper.name_getSignatureDocsByCompanyGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.showLoader = false;

          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );

    $(document).ready(function() {
      $(window).resize(function() {
        setHeightResizeFunc();
      });
      setHeightResizeFunc();
    });

    function setHeightResizeFunc() {
      const rightHeight =
        $('.right-box .ibox-title').height() +
        $('.right-box .ibox-content').height() -
        45;
      $('.docLists').height(rightHeight);
    }

    this.jsonData = this.dataService.getData();
    console.log("Akshay", this.jsonData)
  }


  goToDemographicForm() {
    this.router.navigate([
      '/demographicsform/' +
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
    ]);
  }

  async goToSignatureForm() {
    if ($('.com').not(':checked').length > 0) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        'To proceed, please select all or check all the boxes'
      );
    } else {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        'By clicking \'Accept\' you will now be directed to sign all documents.'
      );

      if (alertStatus) {
        this.router.navigate([this.childRoute + '/signature/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId')]);
      }
    }
  }

  loadHippaDoc(event, documentUrl: any, i) {
    // console.log(event.target.checked);
    if (event.target.checked) {
      document.getElementById('hipaaAccepted' + i).removeAttribute('disabled');
      document
        .getElementById('hipaaAccepted' + i)
        .setAttribute('checked', 'checked');

      this.ahsPdfUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
      this.showDeleteButton = true;
    } else {
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
      this.isChecked = false;
    }
  }


  async checkAllHippaDoc(isChecked) {
    if (isChecked == 1) {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        'Are you sure you want to select all? Doing this will skip the requirement to review each document individually before signing. '
      );
      if (alertStatus) {
        $('.docChecks').each(function() {
          $(this).prop('checked', true);
        });
        document.getElementById('selectAllH5').innerHTML = 'Unselect All';
      } else {
        $('.selectAll').prop('checked', false);
        $('.docChecks').each(function() {
          $(this).prop('checked', false);
        });
      }
    } else {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        'Are you sure you want to unselect all? Doing this will require you to review each document individually before signing.'
      );
      if (alertStatus) {
        document.getElementById('selectAllH5').innerHTML = 'Select All';
        $('.selectAll').prop('checked', false);
        $('.docChecks').each(function() {
          $(this).prop('checked', false);
        });
      }
    }

  }

  async goToWelcome() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      'Are you sure you want to reject signing? Doing so will return you to the homepage. '
    );
    if (alertStatus) {
      this.router.navigate([this.childRoute + '/dashboard/' + this.activatedRoute.snapshot.paramMap.get('loggedInUserId')]);
    }
  }

  async clearViewr() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      'Are you sure you want to clear the preview?'
    );
    if (alertStatus) {
      $('.selectAll').prop('checked', false);
      $('.docChecks').each(function() {
        $(this).prop('checked', false);
      });
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
      this.showDeleteButton = false;
    }
  }

}
