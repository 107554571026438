import {SenddocumentComponent} from './user/senddocument/senddocument.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {from} from 'rxjs';
import {AuthGuard} from './_guards/auth.guard';
import {AuthRoleGuard} from './_guards/auth-role.guard';
/////////////////////////// User Components Start////////////////////////////////////////////////////////////////////////
import {LoginComponent} from './auth/login/login.component';
import {RegisterComponent} from './auth/register/register.component';
import {ForgotpasswordComponent} from './auth/forgotpassword/forgotpassword.component';
import {ChangepasswordComponent} from './auth/changepassword/changepassword.component';
import {WelcomeComponent} from './auth/welcome/welcome.component';
import {DemographicsformComponent} from './user/demographicsform/demographicsform.component';
import {HippoReleaseNavigationComponent} from './user/hippo-release-navigation/hippo-release-navigation.component';
import {HippoReleaseFormComponent} from './user/hippo-release-form/hippo-release-form.component';
import {WaiverDisclaimerFormComponent} from './user/waiver-disclaimer-form/waiver-disclaimer-form.component';
import {WaiverDisclaimerNavigationComponent} from './user/waiver-disclaimer-navigation/waiver-disclaimer-navigation.component';
import {SignatureWaiverComponent} from './user/signature-waiver/signature-waiver.component';
import {DocumentThoubsComponent} from './user/document-thoubs/document-thoubs.component';
import {Editform2Component} from './user/editform2/editform2.component';
import {Editform3Component} from './user/editform3/editform3.component';
import {Editform4Component} from './user/editform4/editform4.component';
import {Editform5Component} from './user/editform5/editform5.component';
import {ThankyouComponent} from './user/thankyou/thankyou.component';
import {ConfirmationformComponent} from './user/confirmationform/confirmationform.component';
import {ThumbMobileComponent} from './user/thumb-mobile/thumb-mobile.component';
import {DashboardComponent} from './user/dashboard/dashboard.component';
import {USHFComponent} from './user/ushf/ushf.component';
import {DocReviewComponent} from './user/doc-review/doc-review.component';
import {UploaddocComponent} from './user/uploaddoc/uploaddoc.component';
import {ProfieComponent} from './user/profie/profie.component';
import {EmailLogComponent} from './user/email-log/email-log.component';
import {ResendDocsComponent} from './user/resend-docs/resend-docs.component';
import {HippaAcceptanceComponent} from './user/hippa-acceptance/hippa-acceptance.component';
import {FaqComponent} from './user/faq/faq.component';
/////////////////////////// User Components End//////////////////////////////////////////////////////////////////////////
/////////////////////////// Admin Components Start///////////////////////////////////////////////////////////////////////
import {AdminWelcomeComponent} from './auth/admin-welcome/admin-welcome.component';
import {AdminForgotpasswordComponent} from './auth/admin-forgotpassword/admin-forgotpassword.component';
import {AdminChangepasswordComponent} from './auth/admin-changepassword/admin-changepassword.component';
import {AdminLoginComponent} from './auth/admin-login/admin-login.component';
import {UsersListComponent} from './admin/users-list/users-list.component';
import {AdminEmailLogComponent} from './admin/admin-email-log/admin-email-log.component';
import {AdminResendDocsComponent} from './admin/admin-resend-docs/admin-resend-docs.component';
import {AdminDualListComponent} from './admin/admin-dual-list/admin-dual-list.component';
import {DemographicsComponent} from './admin/demographics/demographics.component';
import {AddAdminUserComponent} from './admin/add-admin-user/add-admin-user.component';
import {DocGroupComponent} from './admin/doc-group/doc-group.component';
import {AddDocGroupComponent} from './admin/add-doc-group/add-doc-group.component';
import {FaqListComponent} from './admin/faq-list/faq-list.component';
import {DocumentListComponent} from './admin/document-list/document-list.component';
import {AddDocumentComponent} from './admin/add-document/add-document.component';
import {ModifyDocumentComponent} from './admin/modify-document/modify-document.component';
import {ActivityLogComponent} from './admin/activity-log/activity-log.component';
import {DoctorLoginComponent} from './auth/doctor-login/doctor-login.component';
import {DoctorSignupComponent} from './auth/doctor-signup/doctor-signup.component';
import {AdminDashboardComponent} from './admin/admin-dashboard/admin-dashboard.component';
import {AdminEmailDocComponent} from './admin/admin-email-doc/admin-email-doc.component';
import {AllotDocGroupComponent} from './admin/allot-doc-group/allot-doc-group.component';
import {ViewUserDocsComponent} from './admin/view-user-docs/view-user-docs.component';
import {ViewDocsComponent} from './user/view-docs/view-docs.component';
import {AdminInvitePatientComponent} from './admin/admin-invite-patient/admin-invite-patient.component';
import {ViewSignDocsComponent} from './admin/view-sign-docs/view-sign-docs.component';
import {DoctorProfileComponent} from './admin/doctor-profile/doctor-profile.component';
import {AdminProfileComponent} from './admin/admin-profile/admin-profile.component';

import {AdminUshfComponent} from './admin/admin-ushf/admin-ushf.component';
import {EditAdminUserComponent} from './admin/edit-admin-user/edit-admin-user.component';
import {UserDocsAcknowledgementComponent} from './admin/user-docs-acknowledgement/user-docs-acknowledgement.component';
import {DoctorViewDocsComponent} from './auth/doctor-view-docs/doctor-view-docs.component';
import {ViewDocsAcknowledgementComponent} from './admin/view-docs-acknowledgement/view-docs-acknowledgement.component';
import {AddPhysicianUserComponent} from './admin/add-physician-user/add-physician-user.component';
import {AdminDocReviewComponent} from './admin/admin-doc-review/admin-doc-review.component';
import {AdminSendEmailComponent} from './admin/admin-send-email/admin-send-email.component';
import {AdminUploaddocComponent} from './admin/admin-uploaddoc/admin-uploaddoc.component';
import {DoctorDocReviewComponent} from './doctor/doctor-doc-review/doctor-doc-review.component';
import {EmailAllPatientComponent} from './admin/email-all-patient/email-all-patient.component';
import {AdminSecondOpinionComponent} from './admin/admin-second-opinion/admin-second-opinion.component';
import {AdminUshf2Component} from './admin/admin-ushf2/admin-ushf2.component';
import {PracticeGroupsComponent} from './admin/practice-groups/practice-groups.component';
import {PracticeSpecialitiesComponent} from './admin/practice-specialities/practice-specialities.component';
import {PracticeSubSpecialitiesComponent} from './admin/practice-sub-specialities/practice-sub-specialities.component';
import {AdminSecondOptionWfComponent} from './admin/admin-second-option-wf/admin-second-option-wf.component';
import {AdminTransferLogComponent} from './admin/admin-transfer-log/admin-transfer-log.component';
import {AdminViewD2dDocsComponent} from './admin/admin-view-d2d-docs/admin-view-d2d-docs.component';
import {AdminAcknowledgementD2dDocsComponent} from './admin/admin-acknowledgement-d2d-docs/admin-acknowledgement-d2d-docs.component';
import {AdminReplyD2dMailComponent} from './admin/admin-reply-d2d-mail/admin-reply-d2d-mail.component';
import {AdminD2dReviewLogComponent} from './admin/admin-d2d-review-log/admin-d2d-review-log.component';
import {AdminPhysicianSchedulingComponent} from './admin/admin-physician-scheduling/admin-physician-scheduling.component';
import {Email2userComponent} from './admin/email2user/email2user.component';
import {AdminTransferReportComponent} from './admin/admin-transfer-report/admin-transfer-report.component';
import {AdminOnCallScheduleLogComponent} from './admin/admin-on-call-schedule-log/admin-on-call-schedule-log.component';
import {AdminDelegationLogComponent} from './admin/admin-delegation-log/admin-delegation-log.component';
import {ReviewSignComponent} from './user/review-sign/review-sign.component';
import {Confirmationform1Component} from './user/confirmationform1/confirmationform1.component';
import {AdminContactUsComponent} from './admin/admin-contact-us/admin-contact-us.component';
import {AdminChangePasswordComponent} from './admin/admin-change-password/admin-change-password.component';
import {AdminSecurityQuestions} from './admin/admin-security-questions/admin-security-questions.component';
import {NjComponent} from './hipp/nj/nj.component';
import {NyComponent} from './hipp/ny/ny.component';
import {FlComponent} from './hipp/fl/fl.component';
import {PaComponent} from './hipp/pa/pa.component';
import {CtComponent} from './hipp/ct/ct.component';
import {OhComponent} from './hipp/oh/oh.component';
import {CoComponent} from './hipp/co/co.component';
import {CaComponent} from './hipp/ca/ca.component';
import {AlComponent} from './hipp/al/al.component';
import {AzComponent} from './hipp/az/az.component';
import {ArComponent} from './hipp/ar/ar.component';
import {DeComponent} from './hipp/de/de.component';
import {GaComponent} from './hipp/ga/ga.component';
import {HiComponent} from './hipp/hi/hi.component';
import {IdComponent} from './hipp/id/id.component';
import {IlComponent} from './hipp/il/il.component';
import {AkComponent} from './hipp/ak/ak.component';
import {TxComponent} from './hipp/tx/tx.component';
import {SdComponent} from './hipp/sd/sd.component';
import {NdComponent} from './hipp/nd/nd.component';
import {VaComponent} from './hipp/va/va.component';
import {VtComponent} from './hipp/vt/vt.component';
import {KyComponent} from './hipp/ky/ky.component';
import {WiComponent} from './hipp/wi/wi.component';
import {WvComponent} from './hipp/wv/wv.component';
import {TnComponent} from './hipp/tn/tn.component';
import {LaComponent} from './hipp/la/la.component';
import {MdComponent} from './hipp/md/md.component';
import {MnComponent} from './hipp/mn/mn.component';
import {MoComponent} from './hipp/mo/mo.component';
import {MsComponent} from './hipp/ms/ms.component';
import {NvComponent} from './hipp/nv/nv.component';
import {OkComponent} from './hipp/ok/ok.component';
import {WaComponent} from './hipp/wa/wa.component';
import {IaComponent} from './hipp/ia/ia.component';
import {NhComponent} from './hipp/nh/nh.component';
import {KsComponent} from './hipp/ks/ks.component';
import {OrComponent} from './hipp/or/or.component';
import {NeComponent} from './hipp/ne/ne.component';
import {MtComponent} from './hipp/mt/mt.component';
import {NcComponent} from './hipp/nc/nc.component';
import {ScComponent} from './hipp/sc/sc.component';
import {InComponent} from './hipp/in/in.component';
import {MeComponent} from './hipp/me/me.component';
import {RiComponent} from './hipp/ri/ri.component';
import {UtComponent} from './hipp/ut/ut.component';
import {NmComponent} from './hipp/nm/nm.component';
import {MaComponent} from './hipp/ma/ma.component';
import { AdminCommentsComponent } from './admin/admin-comments/admin-comments.component';
import { AddCommentComponent } from './admin/add-comment/add-comment.component';
import { AdminInvitationLogComponent } from './admin/admin-invitation-log/admin-invitation-log.component';
import { AhsaichatgptComponent } from './ahsaichatgpt/ahsaichatgpt.component';
import { PatientHistory } from './admin/patient-history/patient-history.component';
import { UploadDocument } from './admin/upload-document/upload-document.component';
import { PrincetonSurgicalSpecialtiesPreopNote } from './practice-documents/princeton-surgical-specialties-forms/princeton-surgical-specialties-preop-note/princeton-surgical-specialties-preop-note.component';
import { PrincetonSurgicalSpecialtiesHealthHistoryForm1 } from './practice-documents/princeton-surgical-specialties-forms/princeton-surgical-specialties-health-history-form-1/princeton-surgical-specialties-health-history-form-1.component';
import { PrincetonSurgicalSpecialtiesPreopInstructions } from './practice-documents/princeton-surgical-specialties-forms/princeton-surgical-specialties-preop-instructions/princeton-surgical-specialties-preop-instructions.component';
import { PrincetonSurgicalSpecialtiesDemographics } from './practice-documents/princeton-surgical-specialties-forms/princeton-surgical-specialties-demographics/princeton-surgical-specialties-demographics.component';
import {DocFive} from './practice-documents/doc-five/doc-five.component';
import { PracticeDemographicComponent } from './practice-documents/pract-demographic/pract-demographic.component';
import { ReliantSurgicalCenterAnesthesiaQuestionnariePg2} from './practice-documents/reliant-practice-docs/reliant-surgical-center-anesthesia-questionnarie-pg2/reliant-surgical-center-anesthesia-questionnarie-pg2.comonent';
import { ReliantSurgicalCenterAnesthesiaQuestionnariePg1 } from './practice-documents/reliant-practice-docs/reliant-surgical-center-anesthesia-questionnarie-pg1/reliant-surgical-center-anesthesia-questionnarie-pg1.component';
import { PrincetonSurgicalSpecialtiesHealthHistoryForm2 } from './practice-documents/princeton-surgical-specialties-forms/princeton-surgical-specialties-health-history-form-2/princeton-surgical-specialties-health-history-form-2.component';
import { PriNewPatientIntakeSignaturePage } from './practice-documents/princeton-rejuventation-institute-forms/pri-new-patient-intake-signature-page/pri-new-patient-intake-signature-page.component'
import { PriAestheticHistoryForm } from './practice-documents/princeton-rejuventation-institute-forms/pri-aesthetic-history-form/pri-aesthetic-history-form.component';
import { GreenbergCosmeticSurgeryORSchedulingForm } from './practice-documents/greenberg-cosmetic-surgery-forms/greenberg-cosmetic-surgery-or-scheduling-form/greenberg-cosmetic-surgery-or-scheduling-form.component'
import { GreenbergCosmeticSurgeryDemographics } from './practice-documents/greenberg-cosmetic-surgery-forms/greenberg-cosmetic-surgery-demographics/greenberg-cosmetic-surgery-demographics.component'
import { PriHealthHistoryForm2 } from './practice-documents/princeton-rejuventation-institute-forms/pri-health-history-form-2/pri-health-history-form-2.component';
import { PriHealthHistoryForm1 } from './practice-documents/princeton-rejuventation-institute-forms/pri-health-history-form-1/pri-health-history-form-1.component';
import { GreenbergCosmeticSurgeryHealthHistoryPage2 } from './practice-documents/greenberg-cosmetic-surgery-forms/greenberg-cosmetic-surgery-health-history-page-2/greenberg-cosmetic-surgery-health-history-page-2.component';
import { GreenbergCosmeticSurgeryHealthHistoryPage1 } from './practice-documents/greenberg-cosmetic-surgery-forms/greenberg-cosmetic-surgery-health-history-page1/greenberg-cosmetic-surgery-health-history-page1.component';
import { AdminPracticeDictationComponent } from './admin/admin-practice-dictation/admin-practice-dictation.component';
import { AcceptHipaaComponent } from './user/profile/accept-hipaa/accept-hipaa.component';
import { SignHipaaComponent } from './user/profile/sign-hipaa/sign-hipaa.component';
import { ReviewHipaaComponent } from './user/profile/review-hipaa/review-hipaa.component';
import {ReviewSignedDocsComponent} from './user/profile/review-signed-docs/review-signed-docs.component';
import { DoctorDashboardComponent } from './admin/doctor-dashboard/doctor-dashboard.component';
import { ScreenCaptureComponent } from './screen-capture/screen-capture.component';
import { DocToDocEmail } from './doctor/d2d-email/d2d-email.component';
import { PrincetonSurgicalSpecialtiesAssignmentBenefitDAR } from './practice-documents/princeton-surgical-specialties-forms/princeton-surgical-specialties-assigment-of-benefits-DAR/princeton-surgical-specialties-assigment-of-benefits-DAR.component';
import { MedicalRecordInsuranceReleaseAndFinancialResponsibility } from './practice-documents/princeton-surgical-specialties-forms/medical-record-insurance-release-and-financial-responsibility/medical-record-financial-responsibility.component';
import { PriPrivacyPractice } from './practice-documents/princeton-rejuventation-institute-forms/pri-privacy-practices/pri-privacy-practices.component';

/////////////////////////// Admin Components End////////////////////////////////////////////////////////////////////////
const routes: Routes = [
  /////////////////////////// user Routes Start//////////////////////////////////////////////////////////////////////////
  {path: '', component: WelcomeComponent, pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'viewMail/:pId', component: LoginComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'forgate-password', component: ForgotpasswordComponent},
  {path: 'view-docs/:pId/:loggedInUserId', component: ViewDocsComponent, },
  {path: 'showMail1/:pId/:docEmail', component: DoctorLoginComponent},
  {path: 'showMail1/:pId/:docEmail/:userOptionId', component: DoctorLoginComponent, },
  {path: 'viewDocs/:pId/:docEmail', component: DoctorViewDocsComponent},
  {path: 'viewSignatureMail/:pUserId/:docId', component: DoctorLoginComponent, },
  {path: 'viewSignatures/:pUserId/:docId', component: AdminLoginComponent},
  {path: 'dashboard/:loggedInUserId', component: DashboardComponent},
  {path: 'user-faq/:loggedInUserId', component: FaqComponent, canActivate: [AuthGuard]},
  {path: 'ushf/:loggedInUserId', component: USHFComponent, canActivate: [AuthGuard]},
  {path: 'user-profile/:loggedInUserId', component: ProfieComponent, canActivate: [AuthGuard]},
  {path: 'email-log/:loggedInUserId', component: EmailLogComponent, canActivate: [AuthGuard]},
  {path: 'demographicsform/:loggedInUserId', component: DemographicsformComponent, canActivate: [AuthGuard]},
  {path: 'dashboard/:loggedInUserId/:clean', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'hippa-acceptance/:loggedInUserId/:demographicId/:phyicianId', component: ConfirmationformComponent},
  {path: 'resend-docs/:loggedInUserId/:logId', component: ResendDocsComponent, canActivate: [AuthGuard]},
  {path: 'send-doc/:loggedInUserId/:demographicId', component: SenddocumentComponent, canActivate: [AuthGuard]},
  {path: 'hipporeleasenavigation/:loggedInUserId/:demographicId', component: HippoReleaseNavigationComponent, canActivate: [AuthGuard]},
  {path: 'hippoReleaseform/:loggedInUserId/:demographicId', component: HippoReleaseFormComponent, canActivate: [AuthGuard]},
  {
    path: 'waiverdisclaimernavigation/:loggedInUserId/:demographicId',
    component: WaiverDisclaimerNavigationComponent,
    canActivate: [AuthGuard]
  },
  {path: 'waiverdisclaimerform/:loggedInUserId/:demographicId', component: WaiverDisclaimerFormComponent, canActivate: [AuthGuard]},
  {path: 'signaturewaiver/:loggedInUserId/:demographicId', component: SignatureWaiverComponent, canActivate: [AuthGuard]},
  {path: 'signaturewaiver/:loggedInUserId/:demographicId/:clean', component: SignatureWaiverComponent, canActivate: [AuthGuard]},
  {path: 'documentthoumbs/:loggedInUserId/:demographicId', component: DocumentThoubsComponent, canActivate: [AuthGuard]},
  {path: 'hippa-acceptance/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId', component: Confirmationform1Component},
  {
    path: 'signaturewaiver1/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId',
    component: SignatureWaiverComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'documentthoumbs1/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId',
    component: ReviewSignComponent,
    canActivate: [AuthGuard]
  },
  {path: 'edit-demographic-2/:loggedInUserId/:demographicId', component: Editform2Component, canActivate: [AuthGuard]},
  {path: 'edit-demographic-3/:loggedInUserId/:demographicId', component: Editform3Component, canActivate: [AuthGuard]},
  {path: 'edit-demographic-4/:loggedInUserId/:demographicId', component: Editform4Component, canActivate: [AuthGuard]},
  {path: 'edit-demographic-5/:loggedInUserId/:demographicId', component: Editform5Component, canActivate: [AuthGuard]},
  {path: 'doc-review/:loggedInUserId/:demographicId', component: DocReviewComponent, canActivate: [AuthGuard]},
  {path: 'thankyou/:loggedInUserId', component: ThankyouComponent, canActivate: [AuthGuard]},
  {path: 'reset-password/:loggedInUserId', component: ChangepasswordComponent, canActivate: [AuthGuard]},
  {path: 'thumb-mobile/:loggedInUserId/:demographicId', component: ThumbMobileComponent, canActivate: [AuthGuard]},
  {path: 'upload-doc/:loggedInUserId/:demographicId', component: UploaddocComponent, canActivate: [AuthGuard]},
  /////////////////////////// user Routes End//////////////////////////////////////////////////////////////////////////////
  /////////////////////////// Admin Routes Start///////////////////////////////////////////////////////////////////////////
  {path: 'admin/forgate-password', component: AdminForgotpasswordComponent},
  {path: 'admin/user-docs/:pId/:loggedInUserId', component: ViewUserDocsComponent, },
  {path: 'admin/user-sign-docs/:pUserId/:docId', component: ViewSignDocsComponent, },
  {path: 'admin/user-sign-acknowledgement/:pUserId/:docId/:urlType/:loggedInUserId', component: UserDocsAcknowledgementComponent, },
  {path: 'admin/view-docs-acknowledgement/:pId', component: ViewDocsAcknowledgementComponent, },
  {path: 'admin/login', component: AdminLoginComponent},
  {path: 'admin', component: AdminWelcomeComponent},
  {path: 'admin', component: AdminWelcomeComponent, pathMatch: 'full'},
  {path: 'admin/contact-us/:loggedInUserId', component: AdminContactUsComponent},
  {path: 'doctor/contact-us/:loggedInUserId', component: AdminContactUsComponent},
  {path: 'admin/change-password/:loggedInUserId', component: AdminChangePasswordComponent, canActivate: [AuthGuard]},
  {path: 'doctor/change-password/:loggedInUserId', component: AdminChangePasswordComponent, canActivate: [AuthGuard]},
  {path: 'admin/security-question/:loggedInUserId', component: AdminSecurityQuestions, canActivate: [AuthGuard]},
  {path: 'doctor/security-question/:loggedInUserId', component: AdminSecurityQuestions, canActivate: [AuthGuard]},

  {path: 'admin/upload-doc/:loggedInUserId/:userId/:demographicId', component: AdminUploaddocComponent, canActivate: [AuthGuard]},
  {path: 'admin/demographics/:loggedInUserId', component: DemographicsComponent, canActivate: [AuthGuard]},
  {path: 'admin/demographics/:loggedInUserId/:isHipaa', component: DemographicsComponent, canActivate: [AuthGuard]},
  {path: 'admin/users/:loggedInUserId', component: UsersListComponent, canActivate: [AuthGuard]},
  {path: 'admin/users/:loggedInUserId/:userRoleVal', component: UsersListComponent, canActivate: [AuthGuard]},
  {path: 'admin/dashboard/:loggedInUserId', component: AdminDashboardComponent},
  {path: 'admin/add-admin-user/:loggedInUserId', component: AddAdminUserComponent, canActivate: [AuthGuard]},
  {path: 'admin/add-admin-user/:loggedInUserId/:userRoleVal', component: AddAdminUserComponent, canActivate: [AuthGuard]},
  {path: 'admin/add-physician-user/:loggedInUserId', component: AddPhysicianUserComponent, canActivate: [AuthGuard]},
  {path: 'admin/add-physician-user/:loggedInUserId/:userRoleVal', component: AddPhysicianUserComponent, canActivate: [AuthGuard]},
  {path: 'admin/edit-admin-user/:profileId/:loggedInUserId', component: EditAdminUserComponent, canActivate: [AuthGuard]},
  {path: 'admin/edit-admin-user/:profileId/:loggedInUserId/:userRoleVal', component: EditAdminUserComponent, canActivate: [AuthGuard]},
  {path: 'admin/faq/:loggedInUserId', component: FaqListComponent, canActivate: [AuthGuard]},
  {path: 'admin/documents/:loggedInUserId', component: DocumentListComponent, canActivate: [AuthGuard]},
  {path: 'admin/add-admin-doc/:loggedInUserId', component: AddDocumentComponent, canActivate: [AuthGuard]},
  {path: 'admin/modify-admin-doc/:loggedInUserId/:docId/:documentName', component: ModifyDocumentComponent, canActivate: [AuthGuard]},
  {path: 'admin/activity-logs/:loggedInUserId', component: ActivityLogComponent, canActivate: [AuthGuard]},
  {path: 'admin/reset-password/:loggedInUserId', component: AdminChangepasswordComponent, canActivate: [AuthGuard]},
  {path: 'admin/doc-groups/:loggedInUserId', component: DocGroupComponent, canActivate: [AuthGuard]},
  {path: 'admin/add-doc-group/:loggedInUserId', component: AdminDualListComponent, canActivate: [AuthGuard]},
  {path: 'admin/email-logs/:loggedInUserId/:demographyUserId', component: AdminEmailLogComponent, canActivate: [AuthGuard]},
  {path: 'admin/email-docs/:loggedInUserId/:userId/:demographicId', component: AdminEmailDocComponent, canActivate: [AuthGuard]},
  {path: 'admin/send-email/:loggedInUserId/:userId/:demographicId', component: AdminSendEmailComponent, canActivate: [AuthGuard]},
  {path: 'admin/resend-docs/:loggedInUserId/:demographyUserId/:logId', component: AdminResendDocsComponent, canActivate: [AuthGuard]},
  {path: 'admin/dual-list/:loggedInUserId', component: AddDocGroupComponent, canActivate: [AuthGuard]},
  {path: 'admin/invite-patient/:loggedInUserId', component: AdminInvitePatientComponent, canActivate: [AuthGuard]},
  {path: 'admin/email2all/:loggedInUserId', component: EmailAllPatientComponent, canActivate: [AuthGuard]},
  {path: 'admin/doctor-profile/:loggedInUserId', component: DoctorProfileComponent, canActivate: [AuthGuard]},
  {path: 'admin/admin-profile/:loggedInUserId', component: AdminProfileComponent, canActivate: [AuthGuard]},

  {path: 'admin/assign-doc-group/:loggedInUserId/:userId/:demographicId', component: AllotDocGroupComponent, canActivate: [AuthGuard]},
  {path: 'admin/ushf/:loggedInUserId', component: AdminUshf2Component, canActivate: [AuthGuard]},
  {path: 'admin/ushf/:loggedInUserId/:userId/:demographicId', component: AdminUshfComponent, canActivate: [AuthGuard]},
  {path: 'admin/doc-review/:loggedInUserId/:userId/:demographicId', component: AdminDocReviewComponent, canActivate: [AuthGuard]},
  {path: 'admin/second-opinion/:loggedInUserId', component: AdminSecondOpinionComponent, canActivate: [AuthGuard]},
  {path: 'admin/second-opinion-wf/:loggedInUserId/:userOptionId', component: AdminSecondOptionWfComponent, canActivate: [AuthGuard]},
  {path: 'admin/transfer-reports/:loggedInUserId', component: AdminTransferReportComponent, canActivate: [AuthGuard]},
  {
    path: 'admin/second-opinion-wf/:loggedInUserId/:demographicUserId/:userOptionId',
    component: AdminSecondOptionWfComponent,
    canActivate: [AuthGuard]
  },
  {path: 'admin/transfer-log/:loggedInUserId/:showDataVal', component: AdminTransferLogComponent, canActivate: [AuthGuard]},
  {path: 'admin/email2user/:loggedInUserId/:patientId', component: Email2userComponent, canActivate: [AuthGuard]},
  {path: 'admin/delegation-log/:loggedInUserId', component: AdminDelegationLogComponent, canActivate: [AuthGuard]},
  {path: 'admin/practice-groups/:loggedInUserId', component: PracticeGroupsComponent, canActivate: [AuthGuard]},
  {
    path: 'admin/physician-scheduling/:loggedInUserId/:companyId/:specialityId/:subSpecialityId/:coeId/:physicianId',
    component: AdminPhysicianSchedulingComponent,
    canActivate: [AuthGuard]
  },
  {path: 'admin/practice-specialities/:loggedInUserId', component: PracticeSpecialitiesComponent, canActivate: [AuthGuard]},
  {path: 'admin/practice-sub-specialities/:loggedInUserId', component: PracticeSubSpecialitiesComponent, canActivate: [AuthGuard]},
  {path: 'admin/on-call-log/:loggedInUserId', component: AdminOnCallScheduleLogComponent, canActivate: [AuthGuard]},
  {path: 'admin/comments/:loggedInUserId/:demographyUserId', component: AdminCommentsComponent, canActivate: [AuthGuard]},
  {path: 'admin/add-comment/:loggedInUserId/:demographyUserId', component: AddCommentComponent, canActivate: [AuthGuard]},
  {path: 'admin/invitation-log/:loggedInUserId', component: AdminInvitationLogComponent, canActivate: [AuthGuard]},
  {path: 'admin/transfer-log-delegated/:loggedInUserId/:showDataVal', component: AdminTransferLogComponent, canActivate: [AuthGuard]},
  {path: "admin/ahsgpt/:loggedInUserId", component: AhsaichatgptComponent, canActivate: [AuthGuard],},
  {path: "admin/patient-history/:loggedInUserId/:demographyUserId/:showDataVal", component: PatientHistory, canActivate: [AuthGuard],},
  {path: 'admin/upload-document/:loggedInUserId/:userId/:demographicId', component: UploadDocument, canActivate: [AuthGuard]},
  {path: 'admin/admin-practice-dictation/:loggedInUserId/:demographyUserId', component: AdminPracticeDictationComponent, canActivate: [AuthGuard]},

  /////////////////////////// Admin Routes End///////////////////////////////////////////////////////////////////////////
  /////////////////////////// Doctor Routes Start///////////////////////////////////////////////////////////////////////////
  {path: 'doctor/register/:pId/:docEmail', component: DoctorSignupComponent},
  {path: 'doctor/login', component: DoctorLoginComponent},
  {path: 'doctor/register', component: DoctorSignupComponent},
  {path: 'doctor/forgate-password', component: AdminForgotpasswordComponent},
  {path: 'doctor/user-docs/:pId/:loggedInUserId', component: ViewUserDocsComponent, },
  {path: 'doctor/user-sign-docs/:pUserId/:docId', component: ViewSignDocsComponent, },
  {path: 'doctor/user-sign-acknowledgement/:pUserId/:docId/:urlType/:loggedInUserId', component: UserDocsAcknowledgementComponent, },
  {path: 'doctor/view-docs-acknowledgement/:pId/:logId/:loggedInUserId/:userOptionId', component: AdminAcknowledgementD2dDocsComponent, },
  {path: 'doctor/view-docs-acknowledgement/:pId/:logId/:loggedInUserId/:userOptionId/:behaviourId/:showDataVal', component: AdminAcknowledgementD2dDocsComponent,},

  {path: 'admin/view-docs-acknowledgement/:pId/:logId/:loggedInUserId/:userOptionId', component: AdminAcknowledgementD2dDocsComponent, },
  {path: 'admin/view-docs-acknowledgement/:pId/:logId/:loggedInUserId/:userOptionId/:behaviourId/:showDataVal', component: AdminAcknowledgementD2dDocsComponent,},


  {path: 'doctor/user-docs/:pId/:logId/:loggedInUserId/:userOptionId/:showDataVal', component: AdminViewD2dDocsComponent, },
  {path: 'doctor/user-docs/:pId/:logId/:loggedInUserId/:userOptionId/:behaviourId/:showDataVal', component: AdminViewD2dDocsComponent, },



  {path: 'admin/user-docs/:pId/:logId/:loggedInUserId/:userOptionId/:showDataVal', component: AdminViewD2dDocsComponent, },
  {path: 'admin/user-docs/:pId/:logId/:loggedInUserId/:userOptionId/:behaviourId/:showDataVal', component: AdminViewD2dDocsComponent, },

  {path: 'doctor/upload-doc/:loggedInUserId/:userId/:demographicId', component: AdminUploaddocComponent, canActivate: [AuthGuard]},
  {path: 'doctor/doc-review/:loggedInUserId/:userId/:demographicId', component: DoctorDocReviewComponent, canActivate: [AuthGuard]},
  {path: 'doctor/demographics/:loggedInUserId', component: DemographicsComponent, canActivate: [AuthGuard]},
  {path: 'doctor/demographics/:loggedInUserId/:isHipaa', component: DemographicsComponent, canActivate: [AuthGuard]},
  {path: 'doctor/users/:loggedInUserId', component: UsersListComponent, canActivate: [AuthGuard]},
  {path: 'doctor/users/:loggedInUserId/:userRoleVal', component: UsersListComponent, canActivate: [AuthGuard]},
  {path: 'doctor/dashboard/:loggedInUserId', component: DoctorDashboardComponent},
  {path: 'doctor/add-admin-user/:loggedInUserId', component: AddAdminUserComponent, canActivate: [AuthGuard]},
  {path: 'doctor/add-admin-user/:loggedInUserId/:userRoleVal', component: AddAdminUserComponent, canActivate: [AuthGuard]},
  {path: 'doctor/add-physician-user/:loggedInUserId', component: AddPhysicianUserComponent, canActivate: [AuthGuard]},
  {path: 'doctor/add-physician-user/:loggedInUserId/:userRoleVal', component: AddPhysicianUserComponent, canActivate: [AuthGuard]},
  {path: 'doctor/edit-admin-user/:profileId/:loggedInUserId', component: EditAdminUserComponent, canActivate: [AuthGuard]},
  {path: 'doctor/edit-admin-user/:profileId/:loggedInUserId/:userRoleVal', component: EditAdminUserComponent, canActivate: [AuthGuard]},
  {path: 'doctor/faq/:loggedInUserId', component: FaqListComponent, canActivate: [AuthGuard]},
  {path: 'doctor/second-opinion/:loggedInUserId', component: AdminSecondOpinionComponent, canActivate: [AuthGuard]},
  {path: 'doctor/second-opinion-wf/:loggedInUserId/:userOptionId', component: AdminSecondOptionWfComponent, canActivate: [AuthGuard]},
  {
    path: 'doctor/second-opinion-wf/:loggedInUserId/:demographicUserId/:userOptionId',
    component: AdminSecondOptionWfComponent,
    canActivate: [AuthGuard]
  },
  {path: 'doctor/transfer-reports/:loggedInUserId', component: AdminTransferReportComponent, canActivate: [AuthGuard]},
  {path: 'doctor/transfer-log/:loggedInUserId/:showDataVal', component: AdminTransferLogComponent, canActivate: [AuthGuard]},
  {path: 'doctor/email2user/:loggedInUserId/:patientId', component: Email2userComponent, canActivate: [AuthGuard]},
  {path: 'doctor/delegation-log/:loggedInUserId', component: AdminDelegationLogComponent, canActivate: [AuthGuard]},
  {path: 'doctor/practice-groups/:loggedInUserId', component: PracticeGroupsComponent, canActivate: [AuthGuard]},
  {
    path: 'doctor/physician-scheduling/:loggedInUserId/:companyId/:specialityId/:subSpecialityId/:coeId/:physicianId',
    component: AdminPhysicianSchedulingComponent,
    canActivate: [AuthGuard]
  },
  {path: 'doctor/practice-specialities/:loggedInUserId', component: PracticeSpecialitiesComponent, canActivate: [AuthGuard]},
  {path: 'doctor/practice-sub-specialities/:loggedInUserId', component: PracticeSubSpecialitiesComponent, canActivate: [AuthGuard]},
  {path: 'doctor/documents/:loggedInUserId', component: DocumentListComponent, canActivate: [AuthGuard]},
  {path: 'doctor/add-admin-doc/:loggedInUserId', component: AddDocumentComponent, canActivate: [AuthGuard]},
  {path: 'doctor/modify-admin-doc/:loggedInUserId/:docId/:documentName', component: ModifyDocumentComponent, canActivate: [AuthGuard]},
  {path: 'doctor/activity-logs/:loggedInUserId', component: ActivityLogComponent, canActivate: [AuthGuard]},
  {path: 'doctor/reset-password/:loggedInUserId', component: AdminChangepasswordComponent, canActivate: [AuthGuard]},
  {path: 'doctor/doc-groups/:loggedInUserId', component: DocGroupComponent, canActivate: [AuthGuard]},
  {path: 'doctor/add-doc-group/:loggedInUserId', component: AdminDualListComponent, canActivate: [AuthGuard]},
  {path: 'doctor/email-logs/:loggedInUserId/:demographyUserId', component: AdminEmailLogComponent, canActivate: [AuthGuard]},
  {path: 'doctor/email-docs/:loggedInUserId/:userId/:demographicId', component: AdminEmailDocComponent, canActivate: [AuthGuard]},
  {path: 'doctor/send-email/:loggedInUserId/:userId/:demographicId', component: AdminSendEmailComponent, canActivate: [AuthGuard]},
  {path: 'doctor/resend-docs/:loggedInUserId/:demographyUserId/:logId', component: AdminResendDocsComponent, canActivate: [AuthGuard]},
  {path: 'doctor/dual-list/:loggedInUserId', component: AddDocGroupComponent, canActivate: [AuthGuard]},
  {path: 'doctor/invite-patient/:loggedInUserId', component: AdminInvitePatientComponent, canActivate: [AuthGuard]},
  {path: 'doctor/email2all/:loggedInUserId', component: EmailAllPatientComponent, canActivate: [AuthGuard]},
  {path: 'doctor/doctor-profile/:loggedInUserId', component: DoctorProfileComponent, canActivate: [AuthGuard]},
  {path: 'doctor/admin-profile/:loggedInUserId', component: AdminProfileComponent, canActivate: [AuthGuard]},
  {path: 'doctor/assign-doc-group/:loggedInUserId/:userId/:demographicId', component: AllotDocGroupComponent, canActivate: [AuthGuard]},
  {path: 'doctor/ushf/:loggedInUserId', component: AdminUshf2Component, canActivate: [AuthGuard]},
  {path: 'doctor/ushf/:loggedInUserId/:userId/:demographicId', component: AdminUshfComponent, canActivate: [AuthGuard]},
  {path: 'doctor/reply-d2d/:pId/:logId/:loggedInUserId/:userOptionId', component: AdminReplyD2dMailComponent, canActivate: [AuthGuard]},
  {
    path: 'doctor/reply-d2d/:pId/:logId/:loggedInUserId/:userOptionId/:behaviourId/:showDataVal',
    component: AdminD2dReviewLogComponent,
    canActivate: [AuthGuard]
  },
  {path: 'doctor/on-call-log/:loggedInUserId', component: AdminOnCallScheduleLogComponent, canActivate: [AuthGuard]},
  {path: 'doctor/comments/:loggedInUserId/:demographyUserId', component: AdminCommentsComponent, canActivate: [AuthGuard]},
  {path: 'doctor/add-comment/:loggedInUserId/:demographyUserId', component: AddCommentComponent, canActivate: [AuthGuard]},
  {path: 'doctor/admin-practice-dictation/:loggedInUserId/:demographyUserId', component: AdminPracticeDictationComponent, canActivate: [AuthGuard]},

  ////////////////////////////// Routes for Hipaa States/////////////////////////////////////////////////////////
  {path: 'hippa-nj/:loggedInUserId/:patientId/:demographicId', component: NjComponent, canActivate: [AuthGuard]},
  {path: 'hippa-ny/:loggedInUserId/:patientId/:demographicId', component: NyComponent, canActivate: [AuthGuard]},
  {path: 'hippa-fl/:loggedInUserId/:patientId/:demographicId', component: FlComponent, canActivate: [AuthGuard], },
  {path: 'hippa-pa/:loggedInUserId/:patientId/:demographicId', component: PaComponent, canActivate: [AuthGuard], },
  {path: 'hippa-ct/:loggedInUserId/:patientId/:demographicId', component: CtComponent, canActivate: [AuthGuard], },
  {path: 'hippa-oh/:loggedInUserId/:patientId/:demographicId', component: OhComponent, canActivate: [AuthGuard], },
  {path: 'hippa-co/:loggedInUserId/:patientId/:demographicId', component: CoComponent, canActivate: [AuthGuard], },
  {path: 'hippa-ca/:loggedInUserId/:patientId/:demographicId', component: CaComponent, canActivate: [AuthGuard], },
  {path: 'hippa-al/:loggedInUserId/:patientId/:demographicId', component: AlComponent, canActivate: [AuthGuard], },
  {path: 'hippa-az/:loggedInUserId/:patientId/:demographicId', component: AzComponent, canActivate: [AuthGuard], },
  {path: 'hippa-ar/:loggedInUserId/:patientId/:demographicId', component: ArComponent, canActivate: [AuthGuard], },
  {path: 'hippa-de/:loggedInUserId/:patientId/:demographicId', component: DeComponent, canActivate: [AuthGuard], },
  {path: 'hippa-ga/:loggedInUserId/:patientId/:demographicId', component: GaComponent, canActivate: [AuthGuard], },
  {path: 'hippa-hi/:loggedInUserId/:patientId/:demographicId', component: HiComponent, canActivate: [AuthGuard], },
  {path: 'hippa-id/:loggedInUserId/:patientId/:demographicId', component: IdComponent, canActivate: [AuthGuard], },
  {path: 'hippa-il/:loggedInUserId/:patientId/:demographicId', component: IlComponent, canActivate: [AuthGuard], },
  {path: 'hippa-ak/:loggedInUserId/:patientId/:demographicId', component: AkComponent, canActivate: [AuthGuard], },
  {path: 'hippa-tx/:loggedInUserId/:patientId/:demographicId', component: TxComponent, canActivate: [AuthGuard], },
  {path: 'hippa-sd/:loggedInUserId/:patientId/:demographicId', component: SdComponent, canActivate: [AuthGuard], },
  {path: 'hippa-nd/:loggedInUserId/:patientId/:demographicId', component: NdComponent, canActivate: [AuthGuard], },
  {path: 'hippa-va/:loggedInUserId/:patientId/:demographicId', component: VaComponent, canActivate: [AuthGuard], },
  {path: 'hippa-vt/:loggedInUserId/:patientId/:demographicId', component: VtComponent, canActivate: [AuthGuard], },
  {path: 'hippa-ky/:loggedInUserId/:patientId/:demographicId', component: KyComponent, canActivate: [AuthGuard], },
  {path: 'hippa-wi/:loggedInUserId/:patientId/:demographicId', component: WiComponent, canActivate: [AuthGuard], },
  {path: 'hippa-wv/:loggedInUserId/:patientId/:demographicId', component: WvComponent, canActivate: [AuthGuard], },
  {path: 'hippa-tn/:loggedInUserId/:patientId/:demographicId', component: TnComponent, canActivate: [AuthGuard], },
  {path: 'hippa-la/:loggedInUserId/:patientId/:demographicId', component: LaComponent, canActivate: [AuthGuard], },
  {path: 'hippa-md/:loggedInUserId/:patientId/:demographicId', component: MdComponent, canActivate: [AuthGuard], },
  {path: 'hippa-mn/:loggedInUserId/:patientId/:demographicId', component: MnComponent, canActivate: [AuthGuard], },
  {path: 'hippa-mo/:loggedInUserId/:patientId/:demographicId', component: MoComponent, canActivate: [AuthGuard], },
  {path: 'hippa-ms/:loggedInUserId/:patientId/:demographicId', component: MsComponent, canActivate: [AuthGuard], },
  {path: 'hippa-nv/:loggedInUserId/:patientId/:demographicId', component: NvComponent, canActivate: [AuthGuard], },
  {path: 'hippa-ok/:loggedInUserId/:patientId/:demographicId', component: OkComponent, canActivate: [AuthGuard], },
  {path: 'hippa-wa/:loggedInUserId/:patientId/:demographicId', component: WaComponent, canActivate: [AuthGuard], },
  {path: 'hippa-ia/:loggedInUserId/:patientId/:demographicId', component: IaComponent, canActivate: [AuthGuard], },
  {path: 'hippa-nh/:loggedInUserId/:patientId/:demographicId', component: NhComponent, canActivate: [AuthGuard], },
  {path: 'hippa-ks/:loggedInUserId/:patientId/:demographicId', component: KsComponent, canActivate: [AuthGuard], },
  {path: 'hippa-or/:loggedInUserId/:patientId/:demographicId', component: OrComponent, canActivate: [AuthGuard], },
  {path: 'hippa-ne/:loggedInUserId/:patientId/:demographicId', component: NeComponent, canActivate: [AuthGuard], },
  {path: 'hippa-mt/:loggedInUserId/:patientId/:demographicId', component: MtComponent, canActivate: [AuthGuard], },
  {path: 'hippa-nc/:loggedInUserId/:patientId/:demographicId', component: NcComponent, canActivate: [AuthGuard], },
  {path: 'hippa-sc/:loggedInUserId/:patientId/:demographicId', component: ScComponent, canActivate: [AuthGuard], },
  {path: 'hippa-in/:loggedInUserId/:patientId/:demographicId', component: InComponent, canActivate: [AuthGuard], },
  {path: 'hippa-me/:loggedInUserId/:patientId/:demographicId', component: MeComponent, canActivate: [AuthGuard], },
  {path: 'hippa-ri/:loggedInUserId/:patientId/:demographicId', component: RiComponent, canActivate: [AuthGuard], },
  {path: 'hippa-ut/:loggedInUserId/:patientId/:demographicId', component: UtComponent, canActivate: [AuthGuard], },
  {path: 'hippa-nm/:loggedInUserId/:patientId/:demographicId', component: NmComponent, canActivate: [AuthGuard], },
  {path: 'hippa-ma/:loggedInUserId/:patientId/:demographicId', component: MaComponent, canActivate: [AuthGuard], },
  {path: 'doctor/invitation-log/:loggedInUserId', component: AdminInvitationLogComponent, canActivate: [AuthGuard],},
  {path: "doctor/ahsgpt/:loggedInUserId", component: AhsaichatgptComponent, canActivate: [AuthGuard],},
  {path: "doctor/patient-history/:loggedInUserId/:demographyUserId/:showDataVal", component: PatientHistory, canActivate: [AuthGuard],},
  {path: 'doctor/upload-document/:loggedInUserId/:userId/:demographicId', component: UploadDocument, canActivate: [AuthGuard]},
  {path: 'princeton-surgical-specialties-forms/princeton-surgical-specialties-preop-note/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId/:documentId', component: PrincetonSurgicalSpecialtiesPreopNote, canActivate: [AuthGuard]},
  {path: 'princeton-surgical-specialties-forms/princeton-surgical-specialties-health-history-form-1/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId/:documentId', component: PrincetonSurgicalSpecialtiesHealthHistoryForm1, canActivate: [AuthGuard]},
  {path: 'princeton-surgical-specialties-forms/princeton-surgical-specialties-preop-instructions/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId/:documentId', component: PrincetonSurgicalSpecialtiesPreopInstructions, canActivate: [AuthGuard]},
  {path: 'princeton-surgical-specialties-forms/princeton-surgical-specialties-demographics/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId/:documentId', component: PrincetonSurgicalSpecialtiesDemographics, canActivate: [AuthGuard]},
  {path: 'doc-five/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId', component: DocFive, canActivate: [AuthGuard]},
  {path: 'pract-demographic/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId', component: PracticeDemographicComponent, canActivate: [AuthGuard]},
  {path: 'reliant-practice-docs/reliant-surgical-center-anesthesia-questionnarie-pg2/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId/:documentId', component: ReliantSurgicalCenterAnesthesiaQuestionnariePg2, canActivate: [AuthGuard]},
  {path: 'reliant-practice-docs/reliant-surgical-center-anesthesia-questionnarie-pg1/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId/:documentId', component: ReliantSurgicalCenterAnesthesiaQuestionnariePg1, canActivate: [AuthGuard]},
  {path: 'princeton-surgical-specialties-forms/princeton-surgical-specialties-health-history-form-2/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId/:documentId', component: PrincetonSurgicalSpecialtiesHealthHistoryForm2, canActivate: [AuthGuard]},
  {path: 'princeton-rejuventation-institute-forms/pri-new-patient-intake-signature-page/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId/:documentId', component: PriNewPatientIntakeSignaturePage, canActivate: [AuthGuard]},
  {path: 'greenberg-cosmetic-surgery-forms/greenberg-cosmetic-surgery-or-scheduling-form/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId/:documentId', component: GreenbergCosmeticSurgeryORSchedulingForm, canActivate: [AuthGuard]},
  {path: 'princeton-rejuventation-institute-forms/pri-aesthetic-history-form/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId/:documentId', component: PriAestheticHistoryForm, canActivate: [AuthGuard]},
  {path: 'greenberg-cosmetic-surgery-forms/greenberg-cosmetic-surgery-demographics/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId/:documentId', component: GreenbergCosmeticSurgeryDemographics, canActivate: [AuthGuard]},
  {path: 'princeton-rejuventation-institute-forms/pri-health-history-form-2/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId/:documentId', component: PriHealthHistoryForm2, canActivate: [AuthGuard]},
  {path: 'princeton-rejuventation-institute-forms/pri-health-history-form-1/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId/:documentId', component: PriHealthHistoryForm1, canActivate: [AuthGuard]},
  {path: 'greenberg-cosmetic-surgery-forms/greenberg-cosmetic-surgery-health-history-page-2/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId/:documentId', component: GreenbergCosmeticSurgeryHealthHistoryPage2, canActivate: [AuthGuard]},
  {path: 'greenberg-cosmetic-surgery-forms/greenberg-cosmetic-surgery-health-history-page1/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId/:documentId', component: GreenbergCosmeticSurgeryHealthHistoryPage1, canActivate: [AuthGuard]},
  {path: 'admin/hipaa/:loggedInUserId',component: AcceptHipaaComponent,canActivate: [AuthGuard]},
  {path: 'admin/signature/:loggedInUserId',component: SignHipaaComponent,canActivate: [AuthGuard]},
  {path: 'doctor/hipaa/:loggedInUserId',component: AcceptHipaaComponent,canActivate: [AuthGuard]},
  {path: 'doctor/signature/:loggedInUserId',component: SignHipaaComponent,canActivate: [AuthGuard]},
  {path: 'doctor/review-doc/:loggedInUserId',component: ReviewHipaaComponent,canActivate: [AuthGuard]},
  {path: 'admin/review-doc/:loggedInUserId',component: ReviewHipaaComponent,canActivate: [AuthGuard]},
  {path: 'doctor/review-signed-docs/:loggedInUserId',component:ReviewSignedDocsComponent,canActivate: [AuthGuard]},
  {path: 'admin/review-signed-docs/:loggedInUserId',component:ReviewSignedDocsComponent,canActivate: [AuthGuard]},
  {path: 'doctor/dashboard/:loggedInUserId', component: DoctorDashboardComponent,canActivate: [AuthGuard]},
  {path: 'doctor/screen-capture/:loggedInUserId', component: ScreenCaptureComponent,canActivate: [AuthGuard]},
  {path: 'doctor/d2d-email/:pId/:logId/:loggedInUserId/:userOptionId/:behaviourId/:showDataVal/:patientId', component: DocToDocEmail,canActivate: [AuthGuard] },
  {path: 'princeton-surgical-specialties-forms/princeton-surgical-specialties-assigment-of-benefits-DAR/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId/:documentId', component: PrincetonSurgicalSpecialtiesAssignmentBenefitDAR, canActivate: [AuthGuard]},
  {path: 'princeton-surgical-specialties-forms/medical-record-insurance-release-and-financial-responsibility/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId/:documentId', component: MedicalRecordInsuranceReleaseAndFinancialResponsibility, canActivate: [AuthGuard]},
  {path: 'princeton-rejuventation-institute-forms/pri-privacy-practices/:loggedInUserId/:demographicId/:phyicianId/:invitationId/:parentId/:documentId', component: PriPrivacyPractice, canActivate: [AuthGuard]},

  /////////////////////////// Doctor Routes End///////////////////////////////////////////////////////////////////////////
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],

})
export class AppRoutingModule {
}
