import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { DoctorMailService } from "../../_services/doctor-mail.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

import { UserService } from "../../_services/user.service";
import { ArrayHelper } from "./../../_helpers/array.helper";
import { async } from "@angular/core/testing";
import { id } from "@swimlane/ngx-datatable";
import { Location } from '@angular/common';

@Component({
  selector: "app-admin-second-option-wf",
  templateUrl: "./admin-second-option-wf.component.html",
  styleUrls: ["./admin-second-option-wf.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class AdminSecondOptionWfComponent implements OnInit {
  @ViewChild("fileUploaded1", { static: false }) fileUploaded1: ElementRef;
  @ViewChild("fileUploaded2", { static: false }) fileUploaded2: ElementRef;
  @ViewChild("fileUploaded3", { static: false }) fileUploaded3: ElementRef;
  @ViewChild("fileUploadedHippa", { static: false })
  fileUploadedHippa: ElementRef;
  showLoader = false;

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  childRoute: string;
  loggedInUserId: string;
  userOptionId: string;
  demographicUserId: string;
  hasPhysicianEmail = 1;
  maxChars = 300;
  documentFolderType = "A";
  showNewFile = 0;
  hippaAvailable = false;
  hasHippaFile = false;
  isPracticePhy = false;
  showUserCheckVal = true;
  fileDesTxt: any = "";
  practicingCenterId: any = "";
  hippaFileDesTxt: any = "";
  selectedEmail: any;
  practiceSlug: any = "";
  physicianType = "";
  physicianId: any = "";
  specialtyId: any = "";
  subSpecialtyId: any = "";
  consultantId: any = "";
  company_slug = "";
  stateName = "";
  cityName = "";
  PhysicianName = '';
  PhysicianEmail = '';
  PhysicianNipNo = '';
  PhysicianOfficeAddress = '';
  PhysicianOfficePhone = '';
  PhysicianSpecialty = '';
  PhysicianSubSpecialty = '';
  PhysicianDegree = '';
  stateCode = "";
  attachmentDocment: any = "";
  selectedNPI: any;
  selectedPhyName: any;
  requestingPhyEmailVal = localStorage.getItem("loggedInUserEmail");
  requestingPhyMobileVal = localStorage.getItem("loggedInUserMobile");
  reuestTimeVal: any = "1";
  isWithHipaaVal: any = "1";
  selectedDocumentId: any;
  selectedPhysician: any = {};
  attachmentDoc: any = {};
  docSelected: any = {};
  selected_spacialty_id: any = {};
  selected_sub_specialty_id: any = {};
  selectedDocumentIds: string[] = [];
  selected_spacialty: any = {};
  selected_sub_specialty: any = "";
  checkAltEmail = false;
  statesArre: any = [];
  citiesList: any = [];
  physiciansList: any = [];
  practices: any = [];
  specialityArr: any = [];
  practicingCenterArr: any = [];
  documentsList: any = [];
  hippaDocumentsList: any = [];
  subSpecialtyArr: any = [];
  consultantArr: any = [];
  selectedDocsId: any = [];
  physiciansArr: any = [];
  patientName: any = "";
  fileToUpload1: File = null;
  fSize1: any;
  fileToUpload2: File = null;
  fSize2: any;
  fileToUpload3: File = null;
  fSize3: any;
  fileToUploadHippa: File = null;
  fSizeHippa: any;
  showComfirmationModal = false;
  isOnCall = false;
  showSearchPhysicianModal = false;
  physicianSearchObj: any = {};
  doctorTableArr: any = [];
  doctorTableArrTemp: any = [];
  isSearched = false;
  searchStateList: any = ArrayHelper.STATE_ARRAY;
  selectedStateCode: any;

  selectedDelegateId: any;
  assignedDelegateId: any;
  isDelegated: any = 0;
  selectedFiles: any = [];
  physicianCount = 1;
  searchType: any = "1";
  message = Message;
  selectedSpecialityObj: any = {};
  delegateId = localStorage.getItem('delegateId');
  loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');
  selectedPhysicianName: any = "";
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private uploadService: UploadService,
    private doctorMailService: DoctorMailService,
    public sweetAlertHelper: SweetAlertHelper,
    private userService: UserService,
    private location: Location,
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.userOptionId =
      this.activatedRoute.snapshot.paramMap.get("userOptionId");
    this.demographicUserId =
      this.activatedRoute.snapshot.paramMap.get("demographicUserId");
    this.selectedDelegateId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.loggedInUserId;
  }
  ngOnInit() {
    this.findAllParticipatingCenters();
    this.findSpecialtyByCompanySlug(ConstantHelper.COMPANY_SLUG);

    this.findSpecialty(this.loggedInUserId);
    this.findAllStates();
    if (this.userOptionId == "1" || this.userOptionId == "3") {
      this.fnFindAllDocuments(this.demographicUserId);
      this.fnFindHippaDocs(this.demographicUserId, 0);
      this.findUserDetails(this.demographicUserId);
    }
  }

  setSearchTypeVal(e) {
    this.searchType = e.target.value;
  }

  findUserDetails(demographicUserId) {
    const finalData = {
      id: demographicUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.patientName = this.userService.getUS_FromatName(
            apiResponse.commandResult.data.userInfo.FullName
          );
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  findAllStates() {
    const finalCityData = {
      id: this.loggedInUserId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findPhysicianCitiesApi,
        finalCityData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.statesArre = apiResponse.commandResult.data.statesList;
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  async onReset() {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.activatedRoute.snapshot.paramMap.get("loggedInUserId")
      ]);
  }

  async goToDemographics() {
    this.location.back();
  }

  findAllParticipatingCenters() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findPractices,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.data.companiesList.length > 0) {
            this.practices = apiResponse.commandResult.data.companiesList;
          } else {
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp
                .findAllParticipatingCenters
            );
          }
          this.showLoader = false;
        },
        (err) => {
          this.showLoader = false;
        }
      );
  }

  findCoeBySubspecialty1(specialtyId, subSpecialtyId) {
    const finalData = {
      specialtyId,
      subSpecialtyId,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getCoeBySubSpeciality,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.data.practices.length > 0) {
            this.practices = apiResponse.commandResult.data.practices;
          } else {
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp
                .findAllParticipatingCenters
            );
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  findPhysicianByCompanySlug(company_slug) {
    this.physicianId = '';
    this.stateName = '';
    this.cityName = '';
    this.practicingCenterId = '';
    this.selectedPhysician.PhysicianSubSpecialty = '';
    this.selectedPhysician.PhysicianSpecialty = '';
    this.selectedPhysician.PhysicianDegree = '';
    this.selectedPhysician.PhysicianOfficePhone = '';
    this.selectedPhysician.PhysicianOfficeAddress = '';
    this.selectedPhysician.PhysicianNipNo = '';
    this.selectedPhysician.PhysicianEmail = '';
    this.requestingPhyMobileVal = '';
    this.requestingPhyEmailVal = '';
    this.checkDelegateStatus();
    this.company_slug = company_slug;
    const finalData = {
      company_slug,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.get_PhysicianByCompany,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.data.companiesList.length > 0) {
            this.consultantArr = this.fnChangePhysicianNameFormat(
              apiResponse.commandResult.data.companiesList
            );
          } else {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findSpecialty
            );
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  updatePhysicianInfo(physicianId) {
    this.physicianId = physicianId;
    this.selectedPhysician = this.physiciansList.find(
      (e) => e.RecordId == physicianId
    );
    const finalData = {
      physicianNPI: this.selectedPhysician.PhysicianNipNo,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findPhysicianEmail,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.physicianType = "2";
          this.practiceSlug = ConstantHelper.COMPANY_SLUG;
          this.selectedEmail = apiResponse.commandResult.data.physicianEmail;
          this.hasPhysicianEmail =
            apiResponse.commandResult.data.hasPhysicianEmail;
          if (apiResponse.commandResult.data.hasPhysicianEmail == 1) {
            this.selectedPhysician.PhysicianEmail =
              apiResponse.commandResult.data.physicianEmail;
          } else {
            this.selectedPhysician.PhysicianEmail =
              apiResponse.commandResult.data.physicianEmail;
            this.showComfirmationModal = true;
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  findPhysicianById(physicianId) {
    this.stateName = '';
    this.cityName = '';
    this.selectedPhysician.PhysicianSubSpecialty = '';
    this.selectedPhysician.PhysicianSpecialty = '';
    this.selectedPhysician.PhysicianDegree = '';
    this.selectedPhysician.PhysicianOfficePhone = '';
    this.selectedPhysician.PhysicianOfficeAddress = '';
    this.selectedPhysician.PhysicianNipNo = '';
   this.selectedPhysician.PhysicianEmail = '';
    const finalDataPhysician = { physicianId };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findPhysicianByIdApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        (apiResponsePhysician: any) => {
          this.isPracticePhy = true;
          this.physicianType = "1";
          this.stateName = apiResponsePhysician.commandResult.data.userInfo.PhysicianStateName;
          this.selectedEmail = apiResponsePhysician.commandResult.data.userInfo.PhysicianEmail;
          this.stateCode = apiResponsePhysician.commandResult.data.userInfo.PhysicianState;
          this.cityName = apiResponsePhysician.commandResult.data.userInfo.PhysicianCity;
          this.selectedPhysician = apiResponsePhysician.commandResult.data.userInfo;
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  fnChangePhysicianNameFormat(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.physician_name = this.userService.getUS_FromatName(e.physician_name);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(
      this.userService.dynamicSort("physician_name")
    );
  }

  fnChangePhysicianNameFormat1(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.PhysicianName = this.userService.getUS_FromatName(e.PhysicianName);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(
      this.userService.dynamicSort("PhysicianName")
    );
  }

  findCitiesByStateCode(stateCode) {
    this.cityName = "";
    this.physicianId = "";
    this.selectedPhysician = {};
    this.stateCode = stateCode;
    this.citiesList = [];
    const finalData = { stateCode };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            if (apiResponse.commandResult.data.cityList.length > 0) {
              this.citiesList = apiResponse.commandResult.data.cityList;
            } else {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminsecondoptwfCOmp
                  .findcitiesbyStateCode
              );
            }
          } else {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp
                .findcitiesbyStateCode
            );
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  findPhysicianByStateCode(cityName) {
    this.physicianId = "";
    this.selectedPhysician = {};
    const finalDataPhysician = { cityName };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          if (apiResponsePhysician.commandResult.status == 1) {
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.physiciansList = this.fnChangePhysicianNameFormat1(
                apiResponsePhysician.commandResult.data.physiciansList
              );
            } else {
              this.showLoader = false;
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminsecondoptwfCOmp
                  .findcoePhysicians
              );
            }
          } else {
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findcoePhysicians
            );
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  onFileSelected1(event) {
    if (this.fileUploaded1.nativeElement.value != "") {
      this.fileToUpload1 = event.target.files.item(0) as File;
      this.fSize1 = Math.round(this.fileToUpload1.size / 1024);
    } else {
    }
  }

  onFileSelected2(event) {
    if (this.fileUploaded2.nativeElement.value != "") {
      this.fileToUpload2 = event.target.files.item(0) as File;
      this.fSize2 = Math.round(this.fileToUpload2.size / 1024);
    } else {
    }
  }

  onFileSelected3(event) {
    if (this.fileUploaded3.nativeElement.value != "") {
      this.fileToUpload3 = event.target.files.item(0) as File;
      this.fSize3 = Math.round(this.fileToUpload3.size / 1024);
    } else {
    }
  }

  onFileSelectedHippa1(event) {
    if (this.fileUploadedHippa.nativeElement.value != "") {
      this.fileToUploadHippa = event.target.files.item(0) as File;
      this.fSizeHippa = Math.round(this.fileToUploadHippa.size / 1024);
    } else {
    }
  }

  onFileSelectedHippa(event) {
    this.showNewFile = 1;
    this.attachmentDocment = "";
    this.attachmentDoc = {};

    this.hippaAvailable = false;

    if (this.fileUploadedHippa.nativeElement.value != "") {
      this.fileToUploadHippa = event.target.files.item(0) as File;
      this.fSizeHippa = Math.round(this.fileToUploadHippa.size / 1024);
    } else {
    }

    if (
      this.hasHippaFile &&
      this.fileUploadedHippa.nativeElement.value == "" &&
      this.selectedDocumentId == ""
    ) {
      this.hippaAvailable = true;
    }
  }

  setAttachment(DocumentId) {
    this.hippaAvailable = false;
    this.fileUploadedHippa.nativeElement.value = "";
    this.selectedDocumentId = DocumentId;
    this.showNewFile = 2;
    const docObj = this.hippaDocumentsList.find(
      (e) => e.RecordId == this.selectedDocumentId
    );

    this.attachmentDoc = docObj;

    if (
      this.hasHippaFile &&
      this.fileUploadedHippa.nativeElement.value == "" &&
      this.selectedDocumentId == ""
    ) {
      this.hippaAvailable = true;
    }
  }
  clearSelectedDocs(event: any, documentInfo: any) {
    const documentId = documentInfo.RecordId;
    if (event.target.checked) {
      this.selectedFiles.push(documentInfo);
      this.selectedDocsId.push(documentId);
    } else {
      const index = this.selectedFiles.findIndex(file => file.RecordId === documentId);
      if (index !== -1) {
        this.selectedFiles.splice(index, 1);
        this.removeSelectedFile(documentInfo);
      }

      const idIndex = this.selectedDocsId.indexOf(documentId);
      if (idIndex !== -1) {
        this.selectedDocsId.splice(idIndex, 1);
      }
    }
  }

  validateFile(name: String) {
    const ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "pdf"
    ) {
      return true;
    } else {
      return false;
    }
  }

  onSubmit() {
    if (this.userOptionId == "4") {
      this.d2d04();
    }

    if (this.userOptionId == "3") {
      this.d2d03();
    }
    if (this.userOptionId == "2") {
      this.d2d02();
    }
    if (this.userOptionId == "1") {
      this.d2d01();
    }
  }

  private encodeFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result == 'string') {
          resolve(reader.result.split(',')[1]);
        } else {
          reject(new Error('FileReader returned a non-string result'));
        }
      };
      reader.onerror = error => reject(error);
    });
  }

  async d2d04() {
    if (!this.physicianId) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1
      );
      return false;
    } else if (
      this.fileUploaded1.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload1.name)
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (
      this.fileUploaded1.nativeElement.value != "" &&
      this.fSize1 > 61440
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_1
      );
      return false;
    } else if (
      this.fileUploaded2.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload2.name)
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (
      this.fileUploaded2.nativeElement.value != "" &&
      this.fSize2 > 61440
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_3
      );
      return false;
    } else if (
      this.fileUploaded3.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload3.name)
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (
      this.fileUploaded3.nativeElement.value != "" &&
      this.fSize3 > 61440
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_5
      );
      return false;
    } else if (this.searchType == 2 && !this.selectedPhysician.altEmail) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_6
      );
      return false;
    } else if (
      this.selectedPhysician.altEmail &&
      this.selectedPhysician.altEmail != this.selectedPhysician.cnfAltEmail
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_6
      );

      return false;
    } else {
      if (this.searchType == 2) {
        this.selectedEmail = this.selectedPhysician.altEmail;
      }
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.adminsecoptwfComp.d2d01
      );
      if (alertStatus) {
        this.showLoader = true;
        if(this.isDelegated == 1){
          this.loggedInUserId = this.delegateId;
        }
        const jsonData: any = {
          requestedPhysicianEmail: this.selectedEmail,
          loggedInUserId: this.loggedInUserId,
          physicianId: this.loggedInUserId,
          companySlug: this.practiceSlug,
          physicianType: this.physicianType,
          selectedStateCode: this.stateCode,
          selectedCityName:  this.cityName,
          selectedPhysicianId: this.physicianId,
          emailTxt: this.fileDesTxt,
          docType: 11,
          userOptionId: this.userOptionId,
          assignedDelegateId: this.assignedDelegateId,
          isDelegated: this.isDelegated,
          selectedDelegateId: this.selectedDelegateId,
          selectedPhysicianName: this.selectedPhysicianName
        };

        if (this.fileToUpload1) {
          console.log("fileToUpload1: ", this.fileToUpload1.name);
          const file1Content = await this.encodeFileToBase64(this.fileToUpload1);
          jsonData.fileKey1 = { name: this.fileToUpload1.name, content: file1Content };
        }    
        if (this.fileToUpload2) {
          console.log("fileToUpload2: ", this.fileToUpload2.name);
          const file2Content = await this.encodeFileToBase64(this.fileToUpload2);
          jsonData.fileKey2 = { name: this.fileToUpload2.name, content: file2Content };
        }
        if (this.fileToUpload3) {
          console.log("fileToUpload3: ", this.fileToUpload3.name);
          const file3Content = await this.encodeFileToBase64(this.fileToUpload3);
          jsonData.fileKey3 = { name: this.fileToUpload3.name, content: file3Content };
        }  
        
        this.showLoader = true;
        this.http
          .post(
            this.appConfig.getLambdaUrl06() + ApiHelper.docotr2doctorEmail04,
            jsonData, {
              reportProgress: true,
              observe: "events",
            }
          )
          .subscribe(
            async (data: any) => {
              this.showLoader = false;
              if (data !== undefined) {
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.adminsecondoptwfCOmp.d2d04_7
                );

                if (this.isDelegated == 0) {
                  this.router.navigate([
                    "/" +
                      this.childRoute +
                      "/demographics/" +
                      this.loggedInUserId,
                  ]);
                } else {
                  this.router.navigate([
                    "/" +
                      this.childRoute +
                      "/demographics/" +
                      this.assignedDelegateId,
                  ]);
                }
              }
            },
            (error) => {}
          );
      }
    }
  }

  async d2d03() {
    if (!this.physicianId) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1
      );
      return false;
    } else if (this.selectedDocsId.length <= 0) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d03
      );
      return false;
    } else if (this.selectedDocsId.length > 5) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_2
      );
      return false;
    } else if (
      this.fileUploadedHippa.nativeElement.value != "" &&
      !this.validateFile(this.fileToUploadHippa.name)
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04
      );
      return false;
    } else if (
      this.fileUploadedHippa.nativeElement.value != "" &&
      this.fSizeHippa > 61440
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_4
      );
      return false;
    } else if (this.searchType == 2 && !this.selectedPhysician.altEmail) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_6
      );
      return false;
    } else if (
      this.selectedPhysician.altEmail &&
      this.selectedPhysician.altEmail != this.selectedPhysician.cnfAltEmail
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_6
      );
      return false;
    } else {
      if (this.searchType == 2) {
        this.selectedEmail = this.selectedPhysician.altEmail;
      }

      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.adminsecoptwfComp.d2d01
      );
      if (alertStatus) {
        {
          this.showLoader = true;
          const jsonData: any = {
            userId: this.loggedInUserId,
            companySlug: this.practiceSlug,
            physicianType: this.physicianType,
            stateCode: this.stateCode,
            cityName: this.cityName,
            physicianId: this.physicianId,
            fileDesTxt: this.fileDesTxt,
            docType: 11,
            userOptionId: this.userOptionId,
            fileToUpload: this.fileToUploadHippa,
            selectedDocsId: this.selectedDocsId,
            hippaFileDesTxt: this.hippaFileDesTxt,
            showNewFile: this.showNewFile,
            attachmentDoc: this.attachmentDoc,
            selectedEmail: this.selectedEmail,
            showUserCheckVal: this.showUserCheckVal,
            demographicUserId: this.demographicUserId,
            assignedDelegateId: this.assignedDelegateId,
            isDelegated: this.isDelegated,
            selectedDelegateId: this.selectedDelegateId
          }

          if (this.fileToUploadHippa) {
            const fileContent = await this.encodeFileToBase64(this.fileToUploadHippa);
            jsonData.fileToUpload = { name: this.fileToUploadHippa.name, content: fileContent };
          }

          this.showLoader = true;
          this.http
            .post(
              this.appConfig.getLambdaUrl06() + ApiHelper.docotr2doctorEmail03,
              jsonData, {
                reportProgress: true,
                observe: "events",
              }
            )
            .subscribe(
              async (data: any) => {
                if (data !== undefined) {
                  this.showLoader = false;
                  const alertStatus = await this.sweetAlertHelper.alertPopUp(
                    this.message.alertMessages.adminsecondoptwfCOmp.d2d04_8
                  );
                  if (this.isDelegated == 0) {
                    this.router.navigate([
                      "/" +
                        this.childRoute +
                        "/demographics/" +
                        this.loggedInUserId,
                    ]);
                  } else {
                    this.router.navigate([
                      "/" +
                        this.childRoute +
                        "/demographics/" +
                        this.assignedDelegateId,
                    ]);
                  }
                }
              },
              (error) => {}
            );
        }
      }
    }
  }

  getCheckedBoxValue() {
    const allCheck: any = document.getElementsByClassName("docChecks");
    for (let index = 0; index < allCheck.length; index++) {
      const element = allCheck[index];
      if (element.checked) {
        this.selectedDocsId.push(element.value);
      }
    }
  }

  fnFindAllDocuments(userId) {
    const finalData = {
      loggedInUserId: userId,
      company_slug: null,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findAllDocumentsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.documentList.length > 0) {
            this.documentsList = res.commandResult.data.documentList;
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  fnFindHippaDocs(userId, addToDocList) {
    const finalData = {
      loggedInUserId: userId,
      company_slug: null,
      doc_group_id: "HD",
      default_group_id: 17,
    };

    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findDocsBySubGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.documentList.length > 0) {
            if (addToDocList == 1) {
              this.documentsList = res.commandResult.data.documentList;
            }
            this.hippaDocumentsList = res.commandResult.data.documentList;
          } else {
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  selectDocGroup(docSet) {
    this.documentsList = [];
    if (docSet == "A") {
      this.fnFindAllDocuments(this.demographicUserId);
    } else if (docSet == "U") {
      this.fnFindUploadedDocs(this.demographicUserId);
    } else if (docSet == "DS") {
      this.fnFindDoctorAcknowledgments(this.demographicUserId);
    } else if (docSet == "HD") {
      this.fnFindHippaDocs(this.demographicUserId, 1);
    } else if (docSet == "ID") {
      this.fnFindPhysicainHippa(this.demographicUserId, 9);
    } else if (docSet == "VD") {
      this.fnFindPhysicainHippa(this.demographicUserId, 10);
    } else {
      // this.activeGroupId = docSet;
      // this.fnFindGroupDocs()this.demographicUserId;
    }
  }

  fnFindPhysicainHippa(userId, docType) {
    const finalData = {
      userId,
      docType,
    };

    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findPhysicainHippaApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.documentsList = apiResponse.commandResult.data.documentList;
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  fnFindUploadedDocs(userId) {
    const finalData = {
      loggedInUserId: userId,
      company_slug: null,
      docType: ConstantHelper.DOC_TYPE_USER_UPLOADED,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getUploadedDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.documentList.length > 0) {
            this.documentsList = res.commandResult.data.documentList;
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  fnFindDoctorAcknowledgments(userId) {
    const finalData = {
      loggedInUserId: userId,
      company_slug: null,
      docType: 7,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_getAcknowledgmentDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          if (res.commandResult.data.documentList.length > 0) {
            this.documentsList = res.commandResult.data.documentList;
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  findSpecialtyByCompanySlug(specialitSlug) {
    this.checkDelegateStatus();

    this.company_slug = specialitSlug;
    this.docSelected = {};
    this.physicianId = "";
    this.subSpecialtyId = "";
    this.specialtyId = "";
    const finalData = {
      companySlug: specialitSlug,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.get_SpecialtyByPractices,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.data.specialities.length > 0) {
            this.specialityArr = apiResponse.commandResult.data.specialities;
            this.docSelected = {};
          } else {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findSpecialty
            );
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  findSubSpecialtyByCompanySlug(specaltyId) {
    this.selected_spacialty_id = specaltyId;
    this.docSelected = {};
    this.physicianId = "";
    this.subSpecialtyId = "";
    const finalData = {
      companySlug: this.company_slug,
      specialityId: this.selected_spacialty_id,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl04() + "findSubSpecialitiesByCompany",
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.data.sub_specialities.length > 0) {
            this.subSpecialtyArr =
              apiResponse.commandResult.data.sub_specialities;
            console.log('SubSpecialties', this.subSpecialtyArr);
            this.docSelected = {};
          } else {
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findSubSpeciality
            );
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  fnChangePraticipatingPhysicianNameFormat(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.full_name = this.userService.getUS_FromatName(e.full_name);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(this.userService.dynamicSort("full_name"));
  }

  updatePracticingPhysicianInfo(physicianId) {
    this.docSelected = this.physiciansArr.find(
      (e) => e.physician_id == physicianId
    );
    this.selected_spacialty_id = this.docSelected.speciality_id;
    this.selected_sub_specialty_id = this.docSelected.sub_speciality_id;
    this.selectedNPI = this.docSelected.nip_no;
    this.selectedPhyName = this.docSelected.full_name;
  }

  setRequetTimeVal(e) {
    this.reuestTimeVal = e.target.value;
    if (e.target.value == 1 || e.target.value == 2) {
      this.isWithHipaaVal = "1";
    } else {
      this.isWithHipaaVal = "1";
    }
  }

  async d2d02() {
    if (
      this.fileUploaded1.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload1.name)
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (
      this.fileUploaded1.nativeElement.value != "" &&
      this.fSize1 > 61440
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_1
      );
      return false;
    } else if (
      this.fileUploaded2.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload2.name)
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (
      this.fileUploaded2.nativeElement.value != "" &&
      this.fSize2 > 61440
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_3
      );
      return false;
    } else if (
      this.fileUploaded3.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload3.name)
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (
      this.fileUploaded3.nativeElement.value != "" &&
      this.fSize3 > 61440
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_5
      );
      return false;
    } else {
      this.showLoader = true;
      const jsonData: any = {
        loggedInUserId: this.loggedInUserId,
        companySlug: this.practicingCenterId,
        physicianType: 1,
        physicianId: this.physicianId,
        specialtyId: this.specialtyId,
        subSpecialtyId: this.subSpecialtyId,
        emailTxt: this.fileDesTxt,
        requestingPhyMobile: this.requestingPhyMobileVal,
        requestingPhyEmail: this.requestingPhyEmailVal,
        reuestTime: this.reuestTimeVal,
        userOptionId: this.userOptionId,
        docType: 11,
        assignedDelegateId: this.assignedDelegateId,
        isDelegated: this.isDelegated,
        selectedDelegateId: this.selectedDelegateId
      }

      if (this.fileToUpload1) {
        console.log("fileToUpload1: ", this.fileToUpload1.name);
        const file1Content = await this.encodeFileToBase64(this.fileToUpload1);
        jsonData.fileKey1 = { name: this.fileToUpload1.name, content: file1Content };
      }    
      if (this.fileToUpload2) {
        console.log("fileToUpload2: ", this.fileToUpload2.name);
        const file2Content = await this.encodeFileToBase64(this.fileToUpload2);
        jsonData.fileKey2 = { name: this.fileToUpload2.name, content: file2Content };
      }
      if (this.fileToUpload3) {
        console.log("fileToUpload3: ", this.fileToUpload3.name);
        const file3Content = await this.encodeFileToBase64(this.fileToUpload3);
        jsonData.fileKey3 = { name: this.fileToUpload3.name, content: file3Content };
      }

      this.showLoader = true;
        this.http
          .post(
            this.appConfig.getLambdaUrl06() + ApiHelper.docotr2doctorEmail02,
            jsonData, {
              reportProgress: true,
              observe: "events",
            }
          )
        .subscribe(
          async (data: any) => {
            this.showLoader = false;
            if (data !== undefined) {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminsecondoptwfCOmp.d2d04_8
              );
              if (this.isDelegated == 0) {
                this.router.navigate([
                  "/" +
                    this.childRoute +
                    "/demographics/" +
                    this.loggedInUserId,
                ]);
              } else {
                this.router.navigate([
                  "/" +
                    this.childRoute +
                    "/demographics/" +
                    this.assignedDelegateId,
                ]);
              }
            }
          },
          (error) => {}
        );
    }
  }

  async removeSelectedFile(id) {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminDocReviewComp.deleteDoc
    );

    {
      this.selectedDocsId = this.selectedDocsId.filter((obj) => obj !== id);
      let removeIndex = this.selectedFiles
        .map(function (item) {
          return item.RecordId;
        })
        .indexOf(id);
      this.selectedFiles.splice(removeIndex, 1);
    }
  }

  async d2d01() {
    if (this.isWithHipaaVal == 1) {
      // this.getCheckedBoxValue();
    }

    if (!this.physicianId) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1
      );
      return false;
    } else if (this.isWithHipaaVal == 1 && this.selectedDocsId.length <= 0) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_1
      );
      return false;
    } else if (this.isWithHipaaVal == 1 && this.selectedDocsId.length > 5) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_2
      );
      return false;
    } else if (
      this.showNewFile == 1 &&
      this.isWithHipaaVal == 1 &&
      this.reuestTimeVal == 3 &&
      this.fileUploadedHippa.nativeElement.value == ""
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_3
      );
      return false;
    } else if (
      this.isWithHipaaVal == 1 &&
      this.fileUploadedHippa.nativeElement.value != "" &&
      !this.validateFile(this.fileToUploadHippa.name)
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04
      );
      return false;
    } else if (
      this.isWithHipaaVal == 1 &&
      this.fileUploadedHippa.nativeElement.value != "" &&
      this.fSizeHippa > 61440
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_4
      );
      return false;
    } else if (this.hasPhysicianEmail == 0) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_6
      );
      return false;
    } else {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.adminsecoptwfComp.d2d01
      );
      if (alertStatus) {
        {
          this.showLoader = true;
          const jsonData: any = {
            loggedInUserId: this.loggedInUserId,
            companySlug: this.companySlug,
            physicianType: 1,
            physicianId: this.physicianId,
            specialtyId: this.specialtyId,
            subSpecialtyId: this.subSpecialtyId,
            fileDesTxt: this.fileDesTxt,
            docType: 11,
            userOptionId: this.userOptionId,
            fileToUpload: this.fileToUploadHippa,
            selectedDocsId: this.selectedDocsId,
            hippaFileDesTxt: this.hippaFileDesTxt,
            showNewFile: this.showNewFile,
            attachmentDocObj: this.attachmentDoc,
            requestingPhyMobile: this.requestingPhyMobileVal,
            requestingPhyEmail: this.requestingPhyEmailVal,
            reuestTime: this.reuestTimeVal,
            demographicUserId: this.demographicUserId,
            assignedDelegateId: this.assignedDelegateId,
            isDelegated: this.isDelegated,
            selectedDelegateId: this.selectedDelegateId
          }

          if (this.fileToUploadHippa) {
            const fileContent = await this.encodeFileToBase64(this.fileToUploadHippa);
            jsonData.fileToUpload = { name: this.fileToUploadHippa.name, content: fileContent };
          }

          this.http
            .post(
              this.appConfig.getLambdaUrl06() + ApiHelper.docotr2doctorEmail01,
              jsonData, {
              reportProgress: true,
              observe: "events",
            }
            )
            .subscribe(
              async (data: any) => {
                this.showLoader = false;
                if (data.body.commandResult.status == 0){
                  const alertStatus = await this.sweetAlertHelper.alertPopUp(
                    data.commandResult.message
                  );
                } else if (data !== undefined) {
                  const alertStatus = await this.sweetAlertHelper.alertPopUp(
                    this.message.alertMessages.adminsecondoptwfCOmp.d2d04_8
                  );
                  if (this.isDelegated == 0) {
                    this.router.navigate([
                      "/" +
                        this.childRoute +
                        "/demographics/" +
                        this.loggedInUserId,
                    ]);
                  } else {
                    this.router.navigate([
                      "/" +
                        this.childRoute +
                        "/demographics/" +
                        this.assignedDelegateId,
                    ]);
                  }
                }
              },
              (error) => {
              }
            );
        }
      }
    }
  }

  setHasHipaaVal(e) {
    if (e.target.value == 2) {
      this.showNewFile = 0;
    }
    this.isWithHipaaVal = e.target.value;
  }

  closeSearchPhysicianModal() {
    this.showSearchPhysicianModal = false;
  }

  closeComfirmationModal() {
    this.showComfirmationModal = false;
  }
  openSearchPhysicianModal() {
    this.showSearchPhysicianModal = true;
    this.physicianSearchObj.physicianSearchLastName = "";
    this.physicianSearchObj.physicianSearchFirstName = "";

    this.physicianSearchObj.physicianSearchState = "";
    this.physicianSearchObj.physicianSearchNPI = "";
    this.doctorTableArr = [];
  }

  resetSearchPhysicianModal() {
    this.physicianSearchObj.physicianSearchLastName = "";
    this.physicianSearchObj.physicianSearchFirstName = "";

    this.physicianSearchObj.physicianSearchState = "";
    this.physicianSearchObj.physicianSearchNPI = "";
    this.doctorTableArr = [];
    this.physicianCount = 1;
  }

  async searchPhysicians() {
    this.checkDelegateStatus();
    if (this.physicianSearchObj.physicianSearchNPI){  
       if(this.physicianSearchObj.physicianSearchNPI.length !== 10 || !/^\d+$/.test(this.physicianSearchObj.physicianSearchNPI)) {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.invalidNPI
            );
            return;
          }}
    if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchFirstName
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.seacrhPhysician1
      );
      return;
    } else if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchLastName
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.searchPhysician
      );
      return;
    } else if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchState
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.seacrhPhysician2
      );
      return;
    } else {
      this.doctorTableArr = [];

      const finalData = {
        loggedInUserId: this.loggedInUserId,
        physicianSearchFirstName: this.physicianSearchObj.physicianSearchFirstName,
        physicianSearchLastName: this.physicianSearchObj.physicianSearchLastName,
        physicianSearchState: this.physicianSearchObj.physicianSearchState,
        physicianSearchNPI: this.physicianSearchObj.physicianSearchNPI,
      };
      this.showLoader = true;

      this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.searchNPIRegistry,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            this.doctorTableArr = apiResponse.commandResult.data.physiciansList;
            this.physicianCount = apiResponse.commandResult.data.physiciansCout;
            this.showLoader = false;
            if (this.doctorTableArr.length === 0) {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                "No Physicians Found for the given Details"
             );}
          },
          (err) => {}
        );
    }
  }
  setStateCode(stateCode) {
    this.selectedStateCode = stateCode;
  }

  selectDoctor(physicianId) {
    this.isSearched = true;
    this.selectedPhysician = this.doctorTableArr.find(
      (e) => e.RecordId == physicianId
    );
    this.showSearchPhysicianModal = false;
    this.stateCode = this.selectedPhysician.PhysicianState;
    this.cityName = this.selectedPhysician.PhysicianCity;
    this.stateName = this.selectedPhysician.PhysicianStateName;
    this.physicianId = this.selectedPhysician.RecordId;
    this.selectedPhysicianName = this.selectedPhysician.PhysicianName;

    const finalData = { physicianNPI: this.selectedPhysician.PhysicianNipNo };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findPhysicianEmail,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.physicianType = "2";
          this.practiceSlug = ConstantHelper.COMPANY_SLUG;
          this.selectedEmail = apiResponse.commandResult.data.physicianEmail;
          this.hasPhysicianEmail =
            apiResponse.commandResult.data.hasPhysicianEmail;
          if (apiResponse.commandResult.data.hasPhysicianEmail == 1) {
            this.selectedPhysician.PhysicianEmail =
              apiResponse.commandResult.data.physicianEmail;
          } else {
            this.selectedPhysician.PhysicianEmail =
              apiResponse.commandResult.data.physicianEmail;
            this.showComfirmationModal = true;
          }

          this.showLoader = false;
        },
        (err) => {}
      );
  }

  goToLink(url: string) {
    let extUrl = "http://" + url.toLowerCase();
    console.log(extUrl);
    window.open(extUrl, "_blank");
  }

  checkDelegateStatus() {
    this.selectedDelegateId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.loggedInUserId;
    if (this.selectedDelegateId != this.loggedInUserId) {
      this.loggedInUserId = this.selectedDelegateId;
      this.assignedDelegateId =
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
      this.isDelegated = 1;
    } else {
      this.loggedInUserId =
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    }
  }

  findSpecialty(loggedInUserId) {
    const finalData = {
      loggedInUserId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getSpecialties,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.data.specialities.length > 0) {
            this.specialityArr = apiResponse.commandResult.data.specialities;
            this.docSelected = {};
          } else {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findSpecialty
            );
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }
  findSubSpecialtyBySpeciality(speciality) {
    this.selectedSpecialityObj = this.specialityArr.find(
      (e) => e.id == speciality
    );
    this.selected_spacialty = this.selectedSpecialityObj.speciality;
    this.docSelected = {};
    this.physicianId = "";
    this.subSpecialtyId = "";
    const finalData = {
      speciality,
      companySlug: this.companySlug
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getSubSpecialties,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.data.sub_specialities.length > 0) {
            this.subSpecialtyArr = apiResponse.commandResult.data.sub_specialities;
            this.docSelected = {};
          } else {
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findSubSpeciality
            );
            this.resetCoeSelection();
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }
  findCoeBySubspecialty(subSpecialty) {
    this.selected_sub_specialty = subSpecialty;
    const finalData = {
      speciality: this.selected_spacialty,
      subSpecialty,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getCoeBySubSpeciality,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.data.practices.length > 0) {
            this.practices = apiResponse.commandResult.data.practices;
          } else {
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp
                .findAllParticipatingCenters
            );
            this.resetCoeSelection();
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }
  findCoePhysicians(companySlug) {
    this.company_slug = companySlug;
    this.docSelected = {};
    this.physicianId = "";
    const finalData = {
      companySlug: this.company_slug,
      speciality: this.selected_spacialty,
      subSpeciality: this.selected_sub_specialty,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getCoePhysicians,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.data.physicians.length > 0) {
            this.physiciansArr = this.fnChangePraticipatingPhysicianNameFormat(
              apiResponse.commandResult.data.physicians
            );
            this.physiciansArr = this.physiciansArr.filter(
              physician => physician.physician_id != this.loggedInUserId
            );
          } else {
            this.physiciansArr = {};
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findcoePhysicians
            );
          }

          this.showLoader = false;
        },
        (err) => {}
      );
  }
  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }
  isSelected(documentId: string): boolean {
    return this.selectedFiles.some(file => file.RecordId === documentId);
  }
  toggleSelectedDoc(documentId: string) {
    if (this.isSelected(documentId)) {
      this.selectedFiles = this.selectedFiles.filter(id => id !== documentId);
    } else {
      this.selectedFiles.push(documentId);
    }
  }
  resetCoeSelection() {
    this.practicingCenterId = "";
  }

  checkEmail() {
    if (this.selectedPhysician.altEmail && localStorage.getItem("loggedInUserEmail")) {
      if (this.selectedPhysician.altEmail.toLowerCase() === localStorage.getItem("loggedInUserEmail").toLowerCase()) {
        this.checkAltEmail = true;
      } else {
        this.checkAltEmail = false;
      }
    } else {
      this.checkAltEmail = false;
    }
  }
  resetFormControl() {
    this.hippaAvailable = true;
    this.attachmentDocment = '';
    this.fileUploadedHippa.nativeElement.value = '';
  }
  isButtonDisabled(): boolean {
    this.checkEmail();
    return this.checkAltEmail;
  }
}
