import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-practice-sub-specialities",
  templateUrl: "./practice-sub-specialities.component.html",
  styleUrls: ["./practice-sub-specialities.component.scss"],
})
export class PracticeSubSpecialitiesComponent implements OnInit {
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  companySlug: string = ConstantHelper.COMPANY_SLUG;

  childRoute: string;
  loggedInUserId: string;

  addSubSpecialityObj: any = {};
  specialities: any = [];
  selectedSpecialityId = "";
  subSpecialities: any = [];

  showAddSubSpecialityModal = false;
  showEditSubSpecialityModal = false;
  newSubSpeciality: string;

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.getSpecialitiesByComapnSlug(this.companySlug);
    this.getSubSpecialitiesByComapnSlug(this.companySlug);
  }

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
  }

  openAddSubSpecialityModal() {
    this.showAddSubSpecialityModal = true;
  }
  closeAddSubSpecialityModal() {
    this.showAddSubSpecialityModal = false;
    this.addSubSpecialityObj.newSubSpeciality = "";
  }

  openEditSubSpecialityModal(id, subSpeciality) {
    this.showEditSubSpecialityModal = true;
    this.addSubSpecialityObj.oldSubSpeciality = subSpeciality;
    this.addSubSpecialityObj.oldId = id;
  }

  closeEditSubSpecialityModal() {
    this.showEditSubSpecialityModal = false;
    this.addSubSpecialityObj.oldSubSpeciality = "";
    this.addSubSpecialityObj.oldId = "";
  }

  async addSubSpeciality() {
    if (!this.addSubSpecialityObj.newSubSpeciality) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.prcaticeGroupComp.addSubSpeciality
      );

      return;
    }
    const isValidInput = /^[a-zA-Z ]+$/.test(
      this.addSubSpecialityObj.newSubSpeciality.trim()
    );

    if (!isValidInput) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        "Invalid Input ,only alphabets "
      );
      return;
    }
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.prcaticeGroupComp.addSubSpeciality
    );
    {
      const finalData = {
        companySlug: this.companySlug,
        specialityId: this.selectedSpecialityId,
        subSpeciality: this.addSubSpecialityObj.newSubSpeciality,
      };
      return this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.saveSubSpecialitiesByComapnSlug,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            if (apiResponse.commandResult.success == -1) {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.practiceSubSpecialitiesComp
                  .addSubSpeciality
              );
            } else {
              this.subSpecialities =
                apiResponse.commandResult.data.sub_specialities;
              this.showAddSubSpecialityModal = false;
              this.addSubSpecialityObj.newSubSpeciality = "";
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.practiceSpecialitiesComp
                  .saveSubSpeciality
              );
            }
          },
          (err) => {}
        );
    }
  }

  async editSubSpeciality() {
    if (!this.addSubSpecialityObj.oldSubSpeciality) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.prcaticeGroupComp.addSubSpeciality
      );
      return;
    }
    const isValidInput = /^[a-zA-Z ]+$/.test(
      this.addSubSpecialityObj.oldSubSpeciality.trim()
    );

    if (!isValidInput) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        "Invalid Input ,only alphabets "
      );
      return;
    }
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.prcaticeGroupComp.editSubSpeciality
    );
    {
      const finalData = {
        companySlug: this.companySlug,
        specialityId: this.selectedSpecialityId,
        subSpeciality: this.addSubSpecialityObj.oldSubSpeciality,
        id: this.addSubSpecialityObj.oldId,
      };
      return this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.updateSubSpecialitiesByComapnSlug,

            finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            if (apiResponse.commandResult.success == -1) {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.practiceSubSpecialitiesComp
                  .addSubSpeciality
              );
            } else {
              this.subSpecialities =
                apiResponse.commandResult.data.sub_specialities;
              this.showEditSubSpecialityModal = false;
              this.addSubSpecialityObj.oldSubSpeciality = "";
              this.addSubSpecialityObj.oldId = "";
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.practiceSubSpecialitiesComp
                  .editSubSpeciality
              );
            }
          },
          (err) => {}
        );
    }
  }

  selectSubSpeciality(specialityId) {
    this.getSubSpecialitiesByComapnSlug(this.companySlug);
  }

  goToPracticeGroup() {
    this.router.navigate([
      "/" + this.childRoute + "/practice-groups/" + this.loggedInUserId,
    ]);
  }

  getSpecialitiesByComapnSlug(companySlug) {
    const finalData = {
      companySlug: companySlug,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.getSpecialitiesByCompanySlug,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.specialities = apiResponse.commandResult.data.specialities;
        },
        (err) => {}
      );
  }

  getSubSpecialitiesByComapnSlug(companySlug) {
    this.subSpecialities = []
    const finalData = {
      companySlug: companySlug,
      specialityId: this.selectedSpecialityId,
      all: 1,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.getSubSpecialitiesByComapnSlug,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.subSpecialities =
            apiResponse.commandResult.data.sub_specialities;
        },
        (err) => {}
      );
  }
  async changeStatus(id) {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.prcaticeGroupComp.ChangeStatus
    );
    if (!alertStatus) {
      return;
    }
    const originalSubSpecialities = [...this.subSpecialities];
    this.subSpecialities = this.subSpecialities.map((sub) => {
      if (sub.id === id) {
        return {
          ...sub,
          is_active: sub.is_active === 1 ? 0 : 1,
        };
      }
      return sub;
    });
    const finalData = {
      companySlug: this.companySlug,
      specialityId: this.selectedSpecialityId,
      id: id,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.changeSubSpecialityStatusById,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          const alertStatus = await this.sweetAlertHelper.alertPopUp(
            this.message.alertMessages.adminphysicianSchedulingComp
              .channgeStatus
          );
          if (alertStatus) {
            window.location.reload();
          }
        },
        (err) => {
          this.subSpecialities = originalSubSpecialities;
          console.error("Error changing status:", err);
          this.sweetAlertHelper.alertPopUp(
            "An error occurred. Please try again."
          );
        }
      );
  }
}
