import { Component, Input } from '@angular/core';
import { ScreenRecorderService } from 'src/ScreenRecorderService';

@Component({
  selector: 'app-screen-recorder',
  templateUrl: './screen-recorder.component.html',
  styleUrls: ['./screen-recorder.component.css']
})


export class ScreenRecorderComponent {
  @Input() patientName: string;
  @Input() physicianName: string;


  constructor(private screenRecorderService: ScreenRecorderService) {}

  startRecording(): void {
    this.screenRecorderService.startRecording(this.patientName, this.physicianName);
  }

  stopRecording(): void {
    this.screenRecorderService.stopRecording();
  }


}
