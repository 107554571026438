import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {AppConfig} from './../../../app/app.config';
import {UserService} from './../../_services/user.service';
import {SweetAlertHelper} from 'src/app/_helpers/sweet.alert.helper';
import {Message} from 'src/app/_locale/message';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class UsersListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  loggedInUserId: string;
  resultJsonObj: any = {};
  usersList: any = [];
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  userDeactivateSuccess = false;
  userActivateSuccess = false;
  userErrorFlashMsg = false;
  childRoute: string;
  loggedInUserRoleId: any;
  userRoleVal: any = 0;
  userMessage: any = {};
  maxChars = 500;
  emailLogTxt: any = '';
  toEmailUserId: any;
  isPrimary: any = sessionStorage.getItem('isPrimary');
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;
  showFirstAdminMessageModal = false;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    public userService: UserService
  ) {
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'simple_numbers',
      stateSave: true
    };
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.userRoleVal = this.activatedRoute.snapshot.paramMap.get('userRoleVal') ? this.activatedRoute.snapshot.paramMap.get('userRoleVal') : 0;
    this.findUserDetails();
    this.findAdminList();
  }

  async sendEmailToPrimaryAdmin() {
    if (this.emailLogTxt.length <= 0) {
      await this.sweetAlertHelper.alertPopUp('Please enter email content.');
      return;
    } else {
      const finalData = {
        loggedInUserId: this.loggedInUserId,
        toEmailUserId: this.toEmailUserId,
        emailContent: this.emailLogTxt,
      };
      this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.sendEmailToPrimaryAdmin,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse) => {
            this.showFirstAdminMessageModal = false;
            await this.sweetAlertHelper.alertPopUp('Email send successfully.');
            if (this.sweetAlertHelper.alertPopUp) {
              location.reload();
            }
          },
          (err) => {
            console.error(err);
          }
        );
    }
  }

  findAdminList() {
    this.usersList = [];
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: this.loggedInUserId,
      userRoleVal: this.userRoleVal,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.getAdminUsersList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          this.usersList = this.resultJsonObj.commandResult.data.adminUsers;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  closeFirstAdminModal() {
    this.showFirstAdminMessageModal = false;
    this.emailLogTxt= ''
  }

  openModal(user: any) {
    console.log(user);
    if (user.UserRoleId == 1 && user.IsPrimary == 1 || user.UserRoleId == 4 && user.IsPrimary == 1) {
      this.toEmailUserId = user.RecordId;
      this.showFirstAdminMessageModal = true;
    }
  }
  async openAddUserPage() {
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.userListComp.openAddUserPage
    // );
    // if (alertStatus) {
      if (this.userRoleVal > 0) {
        this.router.navigate(['/' + this.childRoute + '/add-admin-user/' + this.loggedInUserId + '/' + this.userRoleVal]);
      } else {
        this.router.navigate(['/' + this.childRoute + '/add-admin-user/' + this.loggedInUserId]);
      }
    // }
  }
  async openAddPhysicianPage() {
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.userListComp.openAddPhysicianPage
    // );
    // if (alertStatus) {
      if (this.userRoleVal > 0) {
        this.router.navigate([
          '/' +
          this.childRoute +
          '/add-physician-user/' +
          this.loggedInUserId +
          '/' +this.userRoleVal]);
      } else {
        this.router.navigate([
          '/' + this.childRoute + '/add-physician-user/' + this.loggedInUserId,
        ]);
      }
    // }
  }

  async openEditUserPage(profileID) {
    if (this.isPrimary == 1) {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.userListComp.openEditUserPage
      );
      if (alertStatus) {
        if (this.userRoleVal > 0) {
          this.router.navigate([
            '/' +
            this.childRoute +
            '/edit-admin-user/' +
            profileID +
            '/' +
            this.loggedInUserId +
            '/' +
            this.userRoleVal,
          ]);
        } else {
          this.router.navigate([
            '/' +
            this.childRoute +
            '/edit-admin-user/' +
            profileID +
            '/' +
            this.loggedInUserId,
          ]);
        }
      }
    } else {
      await this.sweetAlertHelper.alertPopUp(this.message.confirmMessages.userListComp.actionNotAllowed);
    }
  }

  async disableUser(userID) {
    // if (confirm("PLEASE CONFIRM: Are you sure to disable this admin?"))
    if (this.isPrimary == 1) {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.userListComp.disableUser
      );
      if (alertStatus) {
        const finalData = {
          user_id: userID,
          loggedInUserId: this.loggedInUserId,
          company_slug: ConstantHelper.COMPANY_SLUG,
        };

        this.http
          .post(
            this.appConfig.getLambdaUrl02() + ApiHelper.name_deactivateUserApi,
            finalData,
            this.httpOptions
          )
          .subscribe(
            (apiResponse) => {
              this.resultJsonObj = apiResponse;
              if (this.resultJsonObj.commandResult.status == 1) {
                this.findAdminList();
                this.userDeactivateSuccess = true;
                setTimeout(() => {
                  this.userDeactivateSuccess = false;
                }, 7000);
              } else {
                this.userErrorFlashMsg = true;
                setTimeout(() => {
                  this.userErrorFlashMsg = false;
                }, 7000);
              }
            },
            (err) => {
              // console.log(err);
            }
          );
      }
    } else {
      await this.sweetAlertHelper.alertPopUp(this.message.confirmMessages.userListComp.actionNotAllowed);
    }
  }

  async enableUser(userID) {
    // if (confirm("PLEASE CONFIRM: Are you sure to enable this admin?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.userListComp.enableUser
    );
    if (alertStatus) {
      const finalData = {
        user_id: userID,
        loggedInUserId: this.loggedInUserId,
        company_slug: ConstantHelper.COMPANY_SLUG,
      };

      this.http
        .post(
          this.appConfig.getLambdaUrl02() + ApiHelper.name_activateUserApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse) => {
            this.resultJsonObj = apiResponse;
            if (this.resultJsonObj.commandResult.status == 1) {
              this.findAdminList();
              this.userActivateSuccess = true;
              setTimeout(() => {
                this.userActivateSuccess = false;
              }, 7000);
            } else {
              this.userErrorFlashMsg = true;
              setTimeout(() => {
                this.userErrorFlashMsg = false;
              }, 7000);
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  getMaskedString(email) {
    return email.substring(0, 4);
  }

  async changeCOEStatue(userId, status) {
    let confMsg = '';
    let alrtMsg = '';
    if (this.isPrimary == 1) {
      if (status == 1) {
        // confMsg = "PLEASE CONFIRM: Are you sure to enable COE status.";
        confMsg = this.message.confirmMessages.userListComp.enableCOE;
        // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        //   this.message.confirmTitle,
        //   this.message.confirmMessages.userListComp.enableCOE
        // );

        // alrtMsg = "COE status enabled successfully.";
        alrtMsg = this.message.alertMessages.usersListComp.changeCoeStatue1;
      } else {
        // confMsg = "PLEASE CONFIRM: Are you sure to disable COE status";
        confMsg = this.message.confirmMessages.userListComp.disableCOE;
        // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        //   this.message.confirmTitle,
        //   this.message.confirmMessages.userListComp.disableCOE
        // );

        // alrtMsg = "COE status disabled successfully.";
        alrtMsg = this.message.alertMessages.usersListComp.changeCoeStatue1;
      }


      const confirmStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        confMsg
      );

      // const alertStatus = await this.sweetAlertHelper.alertPopUp(alrtMsg);

      if (confirmStatus) {
        const finalData = {
          userId,
          status,
        };
        this.http
          .post(
            this.appConfig.getLambdaUrl06() + ApiHelper.changeCOEStatus,
            finalData,
            this.httpOptions
          )
          .subscribe(
            (apiResponse) => {
              this.sweetAlertHelper.alertPopUp(alrtMsg);

              this.findAdminList();
            },
            (err) => {
              // console.log(err);
            }
          );
      }
    } else {
      await this.sweetAlertHelper.alertPopUp(this.message.confirmMessages.userListComp.actionNotAllowed);
    }
  }

  findUserDetails() {
    const finalData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log("userd d", apiResponse);
          this.loggedInUserRoleId =
            this.resultJsonObj.commandResult.data.userInfo.UserRoleId;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  searchAdmins() {
  }

  filterPhysician(userRoleVal) {
    this.userRoleVal = userRoleVal;
     this.findAdminList();
  }

  resetSearch() {
    this.userRoleVal = 0;
    this.findAdminList();

    // this.usersList = this.tempUsersList;
  }

  async delegateAccess(adminId, status) {
    let confMsg = '';
    let alrtMsg = '';
    if (status === 1) {
      // confMsg = "Are you sure to enable on behalf of.";
      confMsg = this.message.confirmMessages.userListComp.enableDelegate;

      // alrtMsg = "On behalf of enabled successfully.";
      alrtMsg = this.message.alertMessages.usersListComp.enableDelegate;
    } else {
      //confMsg = "Are you sure to disable on behalf of";
      confMsg = this.message.confirmMessages.userListComp.disableDelegate;

      // alrtMsg = "On behalf of disabled successfully.";
      alrtMsg = this.message.alertMessages.usersListComp.disableDelegate;
    }

    const confirmStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      confMsg
    );

    if (confirmStatus) {
      const finalData = {
        adminId,
        physicianId: this.loggedInUserId,
      };
      this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.delegateAccess,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse) => {
            this.sweetAlertHelper.alertPopUp(alrtMsg);
            this.findAdminList();
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async onReset() {
    //if (
    //confirm("Are you certain you want to return to the Physician Home Page?")
    //)
    // {
    // }
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminChangePassComp.onReset
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/' + this.childRoute + '/dashboard/' + this.loggedInUserId,
      ]);
    // }
  }

  async goToInvitationLog() {
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.invitationLog.listLog);
    // if (alertStatus) {
      this.router.navigate(['/' + this.childRoute + '/invitation-log/' + this.loggedInUserId]);
    // }
  }
}
