import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-admin-resend-docs",
  templateUrl: "./admin-resend-docs.component.html",
  styleUrls: ["./admin-resend-docs.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class AdminResendDocsComponent implements OnInit {
  @ViewChild("fileUploaded", { static: false }) fileUploaded: ElementRef;
  showLoader = false;

  loggedInUserId: string;
  demographyUserId: string;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  logId: string;
  docInfo: any = {};
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  documentsList: any;
  documentsLength: any;
  statesArre: any = [];

  stateSelectDisabledOption = true;
  citySelectDisabledOption = true;
  physicianSelectDisabledOption = true;

  cityLength = false;
  noCityLength = false;
  citiesList: any;

  physicianLength = false;
  noPhysicianLength = false;
  physiciansList: any = [];
  selectedPhysician: any = {};
  fileToUpload: File = null;
  fSize: any;

  selectedPhysicianEmail: any;
  selectedPhysicianNipNo: any;
  selectedPhysicianCity: any;
  selectedPhysicianState: any;
  selectedPhysicianOfficeAddress: any;
  selectedPhysicianDegree: any;
  selectedPhysicianSpecialty: any;
  selectedPhysicianSubSpecialty: any;
  emailLogTxt: any;
  fileDesTxt: any;
  docType = ConstantHelper.DOC_TYPE_ADMIN_EMAILED;

  hippaAvailable = false;
  hasHippaFile = false;

  logDetail: any = {};
  selectedStateCode: any;
  selectedCityName: any;
  selectedCityId: any;
  selectedPhysicianId: any;
  showNewFile = 0;
  attachmentDoc: any = {};
  selectedDocumentId: any;
  attachmentDocment: any = "";
  childRoute: string;
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    private uploadService: UploadService
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.docInfo.cnfAltEmail = "";
    this.docInfo.altEmail = "";
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.logId = this.activatedRoute.snapshot.paramMap.get("logId");
    this.demographyUserId =
      this.activatedRoute.snapshot.paramMap.get("demographyUserId");
    this.findPhysicianCitie();
  }

  getDocsByEmailLogId() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      logId: this.logId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getDocsByEmailLogIdApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.data.documentsList.length > 0) {
            this.documentsLength = true;
            this.documentsList = apiResponse.commandResult.data.documentsList;
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  findPhysicianCitie() {
    const finalCityData = {
      id: this.loggedInUserId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findPhysicianCitiesApi,
        finalCityData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.statesArre = apiResponse.commandResult.data.statesList;
          // this.showLoader = false;

          this.findEmailLogDetail(this.logId);
          this.getDocsByEmailLogId();
        },
        (err) => {}
      );
  }

  onFileSelected(event) {
    this.showNewFile = 1;
    this.attachmentDocment = "";
    this.hippaAvailable = false;
    if (this.fileUploaded.nativeElement.value != "") {
      this.fileToUpload = event.target.files.item(0) as File;
      this.fSize = Math.round(this.fileToUpload.size / 1024);
    } else {
    }
    if (
      this.hasHippaFile &&
      this.fileUploaded.nativeElement.value == "" &&
      this.selectedDocumentId == ""
    ) {
      this.hippaAvailable = true;
    }
  }

  findEmailLogDetail(logId) {
    const finalLogData = {
      logId: logId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findLogDetailApi,
        finalLogData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.logDetail = apiResponse;
          this.docInfo.state = apiResponse.commandResult.data.logInfo.PhysicianStateCode;
          this.docInfo.city = apiResponse.commandResult.data.logInfo.PhysicianCityId;
          this.docInfo.altEmail = this.commonHelper.cleanEmail(
            apiResponse.commandResult.data.logInfo.PersonAltEmail
          );
          this.docInfo.cnfAltEmail = this.commonHelper.cleanEmail(
            apiResponse.commandResult.data.logInfo.PersonAltEmail
          );

          this.emailLogTxt = apiResponse.commandResult.data.logInfo.EmailTxt;
          this.findCitiesByStateCode1(
            this.docInfo.state,
            apiResponse.commandResult.data.logInfo.PhysicianCity,
            apiResponse.commandResult.data.logInfo.PhysicianId,
            apiResponse.commandResult.data.logInfo.PhysicianCityId
          );
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  findCitiesByStateCode(stateCode) {
    this.selectedStateCode = stateCode;
    const finalData = { stateCode };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.showLoader = false;
          if (apiResponse.commandResult.status === 1) {
            if (apiResponse.commandResult.data.cityList.length > 0) {
              this.citiesList = apiResponse.commandResult.data.cityList;
              // if (this.citiesList.length === 1) {
              //     this.findPhysicianByStateCode(this.citiesList[0].City);
              // } else {
              //     this.docInfo.physicianName = '';
              //     this.physiciansList = [];
              //     this.selectedPhysician = {};
              // }
              this.docInfo.city = "";
              this.docInfo.physicianName = "";
              this.physiciansList = [];
              this.selectedPhysician = {};

              this.showLoader = false;
            } else {
              this.citiesList = [];
            }
          } else {
            this.citiesList = [];
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  findPhysicianByStateCode(cityName) {
    // alert(cityName);
    // this.selectedCityName = cityName;
    // this.selectedPhysician = {};

    const selectedCity = this.citiesList.filter((e) => e.RecordId == cityName);

    const finalDataPhysician = {
      cityName: selectedCity[0].City,
      state: this.docInfo.state,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_getPhysiciansByCityCodeApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          if (apiResponsePhysician.commandResult.success === 1) {
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.physicianLength = true;
              this.physiciansList =
                apiResponsePhysician.commandResult.data.physiciansList;
              this.docInfo.physicianName = "";
            } else {
              //   alert("No Physicians Found");
              this.showLoader = false;
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminsecondoptwfCOmp
                  .findcoePhysicians
              );

              this.noPhysicianLength = true;
            }
          } else {
            // alert("No Physicians Found");
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findcoePhysicians
            );
            this.noPhysicianLength = true;
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  updatePhysicianInfo(physicianId: any) {
    this.selectedPhysicianId = physicianId;
    this.physicianSelectDisabledOption = false;

    this.selectedPhysician = this.physiciansList.find(
      (e: { RecordId: any }) => e.RecordId == physicianId
    );
  }

  async onSubmit() {
    if (
      this.showNewFile == 1 &&
      this.fileUploaded.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload.name)
    ) {
      //   alert("Selected file format is not supported");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4

      );

      return false;
    } else if (
      this.showNewFile == 1 &&
      this.fileUploaded.nativeElement.value != "" &&
      this.fSize > 61440
    ) {
      //   alert("File too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.modifyDocComp.onSubmit1
      );

      return false;
    } else {
      //   if (
      //     confirm(
      //       "PLEASE CONFIRM: Are you sure you want to deliver the content to the updated email address?"
      //     )
      //   )
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.adminResendDocComp.onsubmit
      );
      if(alertStatus)

      {
        let altEmail = "";
        if (this.docInfo.altEmail) {
          altEmail = this.docInfo.altEmail;
        }
        const selectedCity = this.citiesList.find(
          (e: { RecordId: any }) => e.RecordId === this.selectedCityName
        );
        let cityName = selectedCity?selectedCity.City: "";
        this.uploadService
          .adminResendDoc(
            this.selectedPhysician.PhysicianName,
            this.selectedPhysician.PhysicianEmail,
            altEmail,
            this.demographyUserId,
            this.logId,
            this.companySlug,
            this.loggedInUserId,
            this.selectedStateCode,
            cityName,
            this.selectedPhysicianId,
            this.emailLogTxt,
            this.fileToUpload,
            this.fileDesTxt,
            this.docType,
            localStorage.getItem("loggedInUserComapnyId"),
            this.showNewFile,
            this.attachmentDoc
          )
          .subscribe(
            async (data: any) => {
              if (data !== undefined) {
                // alert("Docs sent successfully!");
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.adminResendDocComp.onSubmit
                );
                if (alertStatus)
                  this.router.navigate([
                    "/" +
                      this.childRoute +
                      "/email-logs/" +
                      this.loggedInUserId +
                      "/" +
                      this.demographyUserId,
                  ]);
              }
            },
            (error) => {}
          );
      }
    }
  }

  async onReset() {
    // if (
    //   confirm("PLEASE CONFIRM: Are you sure, you want to go to email logs?")
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminResendDocComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        "/" +
          this.childRoute +
          "/email-logs/" +
          this.loggedInUserId +
          "/" +
          this.demographyUserId,
      ]);
    }
  }

  findCitiesByStateCode1(stateCode, cityName, physicianId, cityId) {
    this.selectedStateCode = stateCode;
    this.selectedCityName = cityName;
    this.selectedCityId = cityId;
    this.selectedPhysicianId = physicianId;
    this.stateSelectDisabledOption = false;

    const finalData = { stateCode: stateCode };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.showLoader = false,
          this.citySelectDisabledOption = true;
          if (apiResponse.commandResult.status == 1) {
            if (apiResponse.commandResult.data.cityList.length > 0) {
              this.cityLength = true;
              this.citiesList = apiResponse.commandResult.data.cityList;
              this.docInfo.city = this.selectedCityId;
              this.findPhysicianByStateCode1(cityName, physicianId);
            } else {
              this.noCityLength = true;
            }
          } else {
            this.noCityLength = true;
          }
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  findPhysicianByStateCode1(cityName, physicianId) {
    this.selectedCityName = cityName;
    this.selectedPhysicianId = physicianId;

    const finalDataPhysician = {
      cityName: cityName,
      state: this.docInfo.state,
    };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_getPhysiciansByCityCodeApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          if (apiResponsePhysician.commandResult.success == 1) {
            this.physicianSelectDisabledOption = true;
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.physiciansList =
                apiResponsePhysician.commandResult.data.physiciansList;
              this.docInfo.physicianName = physicianId;
              this.selectedPhysician = this.physiciansList.find(
                (e) => e.RecordId == physicianId
              );
            } else {
              //   alert("No Physicians Found1");
              this.showLoader = false;
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminsecondoptwfCOmp.findcoePhysicians
              );

              this.noPhysicianLength = true;
            }
          } else {
            // alert("No Physicians Found2");
            this.showLoader = false;
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findcoePhysicians
            );

            this.noPhysicianLength = true;
          }
        },
        (err) => {}
      );
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "pdf"
    ) {
      return true;
    } else {
      return false;
    }
  }

  setAttachment(DocumentId) {
    this.hippaAvailable = false;
    this.fileUploaded.nativeElement.value = "";
    this.selectedDocumentId = DocumentId;
    this.showNewFile = 2;
    const docObj = this.documentsList.find(
      (e) => e.RecordId == this.selectedDocumentId
    );
    this.attachmentDoc = JSON.stringify(docObj);
    if (
      this.hasHippaFile &&
      this.fileUploaded.nativeElement.value == "" &&
      this.selectedDocumentId == ""
    ) {
      this.hippaAvailable = true;
    }
  }

  resetFormControl() {
    this.hippaAvailable = true;
    this.attachmentDocment = '';
    this.fileUploaded.nativeElement.value = '';
    // this.isFileSelected = false;
  }
}
