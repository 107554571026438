import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnonymousCredential, BlobServiceClient, ContainerClient } from '@azure/storage-blob';


@Injectable({
  providedIn: 'root',
})
export class AzureBlobService {
  private connectionString ='DefaultEndpointsProtocol=https;AccountName=elemrexproductionstorage;AccountKey=46HwvEjTtq/enc84PLkKCNLtj4ss3BO1yL283mgRyhwcDA/y1fq+MUwKpa0ibG9wLmNMWJgKANjB+AStt20RWA;EndpointSuffix=core.windows.net'; // Use your connection string
  private containerName = 'elemrex-esign-01'; // Container name

  private blobServiceClient: BlobServiceClient;
  private containerClient: ContainerClient;

  constructor(private http: HttpClient) {
    // Initialize the BlobServiceClient
    this.blobServiceClient = new BlobServiceClient(`https://${this.connectionString.split(';')[1].split('=')[1]}.blob.core.windows.net`, 
        new AnonymousCredential());
    this.containerClient = this.blobServiceClient.getContainerClient(this.containerName);
  }

  /**
   * Upload multiple files to Azure Blob Storage and return their Blob URLs and file names.
   * @param files An array of files to be uploaded.
   * @returns An Observable with an array of objects containing the Blob URLs and file names.
   */
  uploadFiles(files: File[]): Observable<{ fileUrl: string, fileName: string }[]> {
    const uploadObservables: Observable<{ fileUrl: string, fileName: string }>[] = [];

    for (const file of files) {
      const blobClient = this.containerClient.getBlockBlobClient(file.name);
      const uploadObservable = from(blobClient.uploadData(file))
        .pipe(
          map(() => ({
            fileUrl: blobClient.url,
            fileName: file.name,
          }))
        );
      uploadObservables.push(uploadObservable);
    }

    return combineLatest(uploadObservables);
  }
}
