import { from } from "rxjs";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";

import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

declare let $: any;

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "../../_services/user.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: "app-upload-document",
  templateUrl: "./upload-document.component.html",
  styleUrls: ["./upload-document.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class UploadDocument implements OnInit {
  private pdfApiUrl = 'https://openaitimesheet.azurewebsites.net/reports';
  private videoApiUrl = 'https://app-videosummarize-eastus.azurewebsites.net/analyze_video';
  private imageApiUrl = 'https://app-videosummarize-eastus.azurewebsites.net/analyze_image';
  @ViewChild("fileUploaded1", { static: false }) fileUploaded1: ElementRef;

  fileToUpload1: File = null;
  docType1 = "";
  fileSize1 = 0;
  associatedFileToUpload: File = null;
  associatedFileName = ""
  associatedFileSize = 0;
  
  fileName1 = "";
  
  loggedInUserId: string;
  demographicId: string;
  userId: string;

  companySlug = ConstantHelper.COMPANY_SLUG;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  childRoute: string;
  patientName: any;
  loggedInUserRoleId:any;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  delegateId: any;
  isDelegating: any = 0;
  message = Message;

  selectedOption: boolean = false;
  associatedFileDetails : any = {};
  showLoader = false;
  summaryAnswer: any;
  documentIdToGenerateAiSummary: any;
  documentUrlToGenerateAiSummary: any;
  practices: any;
  uploadedPracticsSettings: any;
  showNameAndFolderName1 = false;
  physicans1: any;
  disabled1 = false;
  physicianLength1 = false;
  isDisableSubmitBtn = true;
  physicianInfo1: any = '';
  tempPractice01: any = [];
  practiceInfo1: any = '';
  finalPhysician01: any = [];
  tempPhysician01: any = [];
  show01 = false;
  fileInput: ElementRef<HTMLInputElement>;
  selectedPhysiciansIds: any = [];

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private uploadService: UploadService,
    private userService: UserService,
    public sweetAlertHelper: SweetAlertHelper,
    private renderer: Renderer2,
    private location: Location,
  ) {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get("demographicId");
    this.userId = this.activatedRoute.snapshot.paramMap.get("userId");
    this.findUserDetails(this.userId);
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.delegateId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.loggedInUserId;
    this.isDelegating = localStorage.getItem("isDelegating")
      ? localStorage.getItem("isDelegating")
      : 0;
      this.loggedInUserRoleId = localStorage.getItem("loggedInUserRoleId");
  }

  ngOnInit() {
    this.getUserPractices(this.userId)
  }

  findUserDetails(demographicUserId) {
    const finalData = {
      id: demographicUserId,
    };
    // console.log(finalData)
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.patientName = this.userService.getUS_FromatName(
            apiResponse.commandResult.data.userInfo.FullName
          );
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  onFileSelected1(event) {
    this.fileToUpload1 = event.target.files.item(0) as File;
    this.fileSize1 = Math.round(this.fileToUpload1.size / 1024);
    this.fileName1 = this.fileToUpload1.name;
  }

  onSelectAssociatedFile(event) {
    this.associatedFileToUpload = event.target.files.item(0) as File;
    this.associatedFileSize = Math.round(this.associatedFileToUpload.size / 1024);
    this.associatedFileName = this.associatedFileToUpload.name;
  }

  private encodeFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result == 'string') {
          resolve(reader.result.split(',')[1]);
        } else {
          reject(new Error('FileReader returned a non-string result'));
        }
      };
      reader.onerror = error => reject(error);
    });
  }

  async uploadFile() {
    if (!this.selectedPhysiciansIds.includes(this.loggedInUserId)) {
      this.selectedPhysiciansIds.push(this.loggedInUserId);
    }
    let confMsg = "";
    if (
      this.fileUploaded1.nativeElement.value == "" 
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return;
    }

    if (this.fileToUpload1 && !this.validateFile(this.fileToUpload1.name) || this.associatedFileToUpload && !this.validateFile(this.associatedFileToUpload.name)) {
      //   alert("File 1 can be only .png, .jpg, .jpeg. or .pdf file formats.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d21_7
      );
      return false;
    } else if (this.fileSize1 > 61440 || this.associatedFileSize > 61440) {
      //   alert("File 1 is too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_1
      );
    } else if (this.fileToUpload1 && this.docType1 == "") {
      //   alert("Please choose File 1 Folder.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminUploadComp.uploadFile3
      );
      return false;
    } else {
      let actingUserId = this.delegateId
        ? this.delegateId
        : this.loggedInUserId;
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.adminUploaddocComp.uploadFile
      );
      
      if (alertStatus) {
        var jsonData: any = {
          loggedInUserId: actingUserId,
          physicianId: actingUserId,
          patientId: this.userId,
          demographicId: this.demographicId,
          company_slug: this.companySlug,
          companyId: +localStorage.getItem("loggedInUserComapnyId"),
          associatedDocName: this.associatedFileName ? this.associatedFileName : '',
          aiSummary: this.associatedFileDetails.aiSummary ? this.associatedFileDetails.aiSummary : '',
          facilityName: this.associatedFileDetails.facilityName ? this.associatedFileDetails.facilityName : '',
          facilityAddress: this.associatedFileDetails.facilityAddress ? this.associatedFileDetails.facilityAddress : '',
          facilityContact: this.associatedFileDetails.facilityContact ? this.associatedFileDetails.facilityContact : '',
          facilityDoctorName: this.associatedFileDetails.facilityDoctorName ? this.associatedFileDetails.facilityDoctorName : '',
          orderedDoctorName: this.associatedFileDetails.orderedDoctorName ? this.associatedFileDetails.orderedDoctorName : '',
          testingDoneDate: this.associatedFileDetails.testingDoneDate ? this.associatedFileDetails.testingDoneDate : '',
          associatedFileDesc: this.associatedFileDetails.associatedFileDescription ? this.associatedFileDetails.associatedFileDescription : '',
          selectedPhysiciansIds: this.selectedPhysiciansIds,
          docType1: this.docType1,
        }
        if (this.fileToUpload1) {
          const file1Content = await this.encodeFileToBase64(this.fileToUpload1);
          jsonData.fileKey1 = { name: this.fileToUpload1.name, content: file1Content };
        }
        if (this.associatedFileToUpload) {
          const file1Content = await this.encodeFileToBase64(this.associatedFileToUpload);
          jsonData.associatedFileKey = { name: this.associatedFileToUpload.name, content: file1Content };
        }

        this.showLoader = true;
          this.http
            .post(
              this.appConfig.getLambdaUrl06() + ApiHelper.uploadDocumentToPatient,
              jsonData, {
                reportProgress: true,
                observe: "events",
              }
            )
          .subscribe(
            async (apiResponse: any) => {
              this.showLoader = false;
              console.log("apiResponse", apiResponse.body.commandResult);
              this.documentIdToGenerateAiSummary = apiResponse.body.commandResult.data.docId;
              this.documentUrlToGenerateAiSummary = apiResponse.body.commandResult.data.docUrl;
              if (apiResponse !== undefined) {
                if (apiResponse.body.commandResult.success == 1) {
                  const alertStatus =
                    await this.sweetAlertHelper.confirmPopUpwithYesOrNo(
                      this.message.confirmTitle,
                      this.message.confirmMessages.adminUploaddocComp
                        .uploadFile1
                    );
                  if (alertStatus) {
                    await this.generateAiSummaryOnDocumentUpload()
                    window.location.reload();
                  } else {
                    this.router.navigate([
                      "/" +
                        this.childRoute +
                        "/demographics/" +
                        this.loggedInUserId,
                    ]);
                    await this.generateAiSummaryOnDocumentUpload()
                  }
                }
              }
            },
            (error) => {
              // console.log(error);
            }
          );
      } else {
        this.router.navigate([
          "/" + this.childRoute + "/demographics/" + this.loggedInUserId,
        ]);
      }
    }
  }

  async gotToDashboard() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
    }
  }

  validateFile(name: String) {
    let ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "mp4"
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkCheckBoxvalue(event) {
    // console.log(event.checked);
  }

  async onReset() {
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminContactUsComp.goToDemographicsComp
    // );
    // if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
    // }
  }

  async goToDemographics() {
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminUploaddocComp.gotoDemgraphics
    // );
    // if (alertStatus) {
      // this.router.navigate([
      //   "/" + this.childRoute + "/demographics/" + this.loggedInUserId,
      // ]);
    // }

    this.location.back();
  }

  isFileInputDisabled(): boolean {
    return this.selectedOption == false;
}

async generateAiSummaryOnDocumentUpload() {
  try {
      const extension = await this.checkDocumentType(this.documentUrlToGenerateAiSummary);
      await this.generateSummary(this.documentUrlToGenerateAiSummary, extension);
      const finalData = {
          documentId: this.documentIdToGenerateAiSummary,
          aiSummary: this.summaryAnswer
      };
      this.showLoader = true;
      await this.http.post(this.appConfig.getLambdaUrl05() + ApiHelper.generateAiSummaryByClick, finalData, this.httpOptions).toPromise();
      this.showLoader = false;
      window.location.reload();
      // Handle the API response here
  } catch (err) {
      console.error(err);
      // Handle error
  }
}

async generateSummary(documentUrl: string, extension: string): Promise<string> {
  try {      
      if (extension === 'PDF' || extension === 'Image' || extension === 'Video') {
        this.showLoader = true;
          const response = await this.summarizeReport(documentUrl, extension).toPromise();
          this.summaryAnswer = response.answer;
          this.showLoader = false;
      } else {
          console.error('Unknown document type');
      }
      return this.summaryAnswer;
  } catch (error) {
      console.error('Error generating summary:', error);
      throw error;
  }
}

async checkDocumentType(url: string): Promise<string> {
  this.showLoader = true;
  try {
    const response = await this.http.get(url, { responseType: 'blob' }).toPromise();
    const contentType = response.type;
    if (contentType === 'application/pdf') {
      this.showLoader = false;
      return 'PDF';
    } else if (contentType.startsWith('image/')) {
      this.showLoader = false;
      return 'Image';
    } else if (contentType.startsWith('video/')) {
      this.showLoader = false;
      return 'Video';
    } else {
      this.showLoader = false;
      return 'Unknown';
    }
  } catch (error) {
    console.error('Error fetching document:', error);
    throw error;
  }
}

summarizeReport(documentUrl: string, extension: string): Observable<any> {
  const Pdfpayload = {
    question: 'Summarize the report',
    pdf_blob_urls: [documentUrl]
  };

  const videoPayload = {
    video_data: documentUrl,
    video_question: "if it is MRI/CT scan or X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points"
  }

  const imagePayload = {
    img_data: documentUrl,
    question: "For the the given image if it is MRI/CT scan or  X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points" 
  }

  if(extension == 'PDF'){
    return this.http.post<any>(this.pdfApiUrl, Pdfpayload);
  } else if (extension == 'Image'){
    return this.http.post<any>(this.imageApiUrl, imagePayload);
  } else if (extension == 'Video'){
    return this.http.post<any>(this.videoApiUrl, videoPayload);
  }
}

getUserPractices(patientId) {
  const finalData = {
    loggedInUserId: this.userId,
  };
  this.showLoader = true;
  this.http
    .post(
      this.appConfig.getLambdaUrl06() + ApiHelper.findUserPractice,
      finalData,
      this.httpOptions
    )
    .subscribe(
      (apiResponse: any) => {
        this.practices = apiResponse.commandResult.data.companiesList;
        this.uploadedPracticsSettings = {
          singleSelection: false,
          idField: 'company_url',
          textField: 'company',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true,
        };
        this.showLoader = false;
      },
      (err) => {
        console.error(err);
      }
    );
}

onChangePractice(companyUrl) {
  const reqData = {
    company_slug: companyUrl,
    loggedInUserId: this.userId,
  };
  // this.showLoader=true;

  this.http
    .post(
      this.appConfig.getLambdaUrl06() + ApiHelper.findUserDoctorsByPractice,
      reqData,
      this.httpOptions
    )
    .subscribe(
      (apiResponse: any) => {
        // if (i == 0) {
            if(companyUrl == 0){
              this.showNameAndFolderName1 = true;
            }
          this.physicans1 = apiResponse.commandResult.data.companiesList;
          this.disabled1 = false;
          if (Array.isArray(this.physicans1) && this.physicans1.length > 0) {
            this.physicianLength1 = true;
          } else {
            this.physicianLength1 = false;
          }
        // }
        this.isDisableSubmitBtn = false;
        // this.showLoader=false;
      },
      (err) => {
      }
    );
}

async listData(file) {
  if (this.physicianInfo1.length <= 0) {
    await this.sweetAlertHelper.alertPopUp('Please select practice or physician');
    return false;
  }
  // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
  //   this.message.confirmTitle,
  //   'Are you sure you want to add this physician to your list of Approved Providers? This decision will be permanent to maintain an accurate log of all document sharing, but this provider can be archived on your profile in the future.'
  // );
  
  // if (alertStatus) {
    if (this.physicianInfo1 == 'all') {
      this.tempPractice01.push(
        this.practices.find((x) => x.company_url == this.practiceInfo1)
      );
    } else {
      this.physicianInfo1.forEach((element) => {
        const existingPhysician = this.finalPhysician01.find((x) => x.physician_id == element);
        if (!existingPhysician) {
          const selectedPhysician = this.physicans1.find((x) => x.physician_id == element);
          if (selectedPhysician) {
            this.finalPhysician01.push(selectedPhysician);
            this.tempPhysician01.push(selectedPhysician);
            this.selectedPhysiciansIds.push(selectedPhysician.physician_id);
          }
        }
      });
    // }
    this.show01 = true;
  }
}



onChangePhysician(value, j) {
  if (value == 'all' && j == 0) {
    // this.disabled1 = true;
  }
  if (value == 'all' && j == 1) {
    // this.disabled2 = true;
  }
  if (value == 'all' && j == 2) {
    // this.disabled3 = true;
  }
  if (value == 'all' && j == 3) {
    // this.disabled4 = true;
  }
  if (value == 'all' && j == 4) {
    // this.disabled5 = true;
  }
}

getMaxDate(): string {
  const currentDate = new Date();
  // Convert to string in yyyy-MM-dd format
  return currentDate.toISOString().split('T')[0];
}

}
