import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { from } from "rxjs";
import { DatePipe } from "@angular/common";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { SignaturePad } from "angular2-signaturepad";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "./../../_services/user.service";
import * as moment from "moment";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
@Component({
  selector: "app-or",
  templateUrl: "./or.component.html",
  styleUrls: ["./or.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class OrComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  hippaJson: any = {};
  loggedInUserId: string;
  userSignatures: any = [];
  userSignatureSelected = false;
  userFullSignature = "";
  userInitialSignature = "";
  userChoosedSignature = "";
  userFullSignatureExit = false;
  userInitialSignatureExis = false;
  showPdfModal = false;
  signatureType: any;
  ssnPattern = "^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$";
  resultJsonObj: any = {};
  clean: string = "0";
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  @ViewChild("canvasArea", { static: true }) canvasArea: ElementRef;
  canvasElm: any;
  public signaturePadOptions: Object = {
    minWidth: 1,
    canvasWidth:
      window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
    canvasHeight: 450,
  };
  currentDate = new Date();
  dateFormat = "MM-dd-yyyy";
  userStaffSignatureSelected = false;
  userStaffChoosedSignature = "";
  signatureFroWhom: any;
  isEmail: any;
  isPracticePhy: any;
  physicianId: any;
  requestId: any;
  tempId: any;
  userOptionId: any;
  emailLogId: any;
  patientId: string;
  fileDesTxt: any = "";
  selectedDelegateId: any;
  assignedDelegateId: any;
  isDelegated: any = 0;
  selectedDocUrl: any;
  selectedPhysician: any = {};
  docInfo: any = {};
  selectedStateCode: any;
  showLoader = false;
  childRoute: string;
  patient_name: any;
  physicianName: any;
  PhysicianOfficeAddress: any;
  patient_dob: any;
  healthcare_provider: any;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    public userService: UserService
  ) {
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.isEmail = this.activatedRoute.snapshot.paramMap.get("isEmail")
      ? this.activatedRoute.snapshot.paramMap.get("isEmail")
      : 0;
    this.isPracticePhy =
      this.activatedRoute.snapshot.paramMap.get("isPracticePhy");
    this.physicianId = this.activatedRoute.snapshot.paramMap.get("physicianId")
      ? this.activatedRoute.snapshot.paramMap.get("physicianId")
      : 0;
    this.requestId = this.activatedRoute.snapshot.paramMap.get("requestId")
      ? this.activatedRoute.snapshot.paramMap.get("requestId")
      : 0;

    this.tempId = this.activatedRoute.snapshot.paramMap.get("tempId")
      ? this.activatedRoute.snapshot.paramMap.get("tempId")
      : 0;
    this.userOptionId = this.activatedRoute.snapshot.paramMap.get(
      "userOptionId"
    )
      ? this.activatedRoute.snapshot.paramMap.get("userOptionId")
      : 0;
    this.emailLogId = this.activatedRoute.snapshot.paramMap.get("emailLogId")
      ? this.activatedRoute.snapshot.paramMap.get("emailLogId")
      : 0;

    this.hippaJson.healthcare_provider = this.userService.getUS_FromatName(
      localStorage.getItem("providerName")
    );
    this.hippaJson.healthcare_provider_email =
      localStorage.getItem("providerEmail");
    this.hippaJson.healthcare_provider_npi = localStorage.getItem(
      "providerPhysicianNipNo"
    );
    this.hippaJson.patient_name = this.userService.getUS_FromatName(
      localStorage.getItem("loggedInUserName")
    );

    //this.fnFindSignature(this.loggedInUserId);
    this.fnFindDemographic(this.patientId);
    this.hippaJson.healthcare_provider = localStorage.getItem(
      "selectedPhysicianName"
    );
    this.hippaJson.PhysicianOfficeAddress = localStorage.getItem(
      "selectedPhysicianOfficeAddress"
    );
    this.hippaJson.physicianNPI = localStorage.getItem("selectedPhysicianNPI");
    this.hippaJson.physicianEmail = localStorage.getItem(
      "selectedPhysicianEmail"
    );
    this.hippaJson.physicianPhone = localStorage.getItem("Mobile");
  }
  ngOnInit() {
    this.patientId = this.activatedRoute.snapshot.paramMap.get("patientId");
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.hippaJson.checkbox_01 = false;
    this.hippaJson.checkbox_02 = false;
    this.hippaJson.all_health_check = false;
    this.hippaJson.alcohol_check = false;
    this.hippaJson.mental_check = false;
    this.hippaJson.patient_name = "";
    this.hippaJson.physicianName = "";
    this.hippaJson.physicianAddress = "";
    this.hippaJson.patient_dob = "";
    this.hippaJson.patient_ssn = "";
    this.hippaJson.patient_address = "";
    this.findUserDetails(this.patientId);
    (this.hippaJson.physicianName = localStorage.getItem(
      "selectedPhysicianName"
    )),
      (this.hippaJson.street_address = this.hippaJson.PhysicianOfficeAddress);
  }


  findUserDetails(patientId) {
    const finalData = {
      company_slug: null,
      id: patientId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.hippaJson.patient_name =
              apiResponse.commandResult.data.userInfo.LastName +
              ", " +
              apiResponse.commandResult.data.userInfo.FirstName;
            this.hippaJson.dob = apiResponse.commandResult.data.userInfo.DOB;
            this.hippaJson.tele_no =
              apiResponse.commandResult.data.userInfo.Mobile;
            this.hippaJson.mailing_address =
              apiResponse.commandResult.data.userInfo.Email;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }
  async onSubmit() {
    // if (
    //   confirm(
    //     'PLEASE CONFIRM: to print generated  State HIPAA Form.'
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.onSubmit
    );
   if(alertStatus) {
      const finalData = {
        selectedDocUrl: this.selectedDocUrl,
        fileDesTxt: this.fileDesTxt,
        loggedInUserId: this.loggedInUserId,
        userId: this.patientId,
        patient_name: this.hippaJson.patient_name,
        dob: this.hippaJson.dob,
        patient_address: this.hippaJson.patient_address,
        healthcare_provider: this.hippaJson.physicianName,
        describe_info: this.hippaJson.describe_info,
        recipient_name_address: this.hippaJson.recipient_name_address,
        the_purpose: this.hippaJson.the_purpose,
        hiv_info: this.hippaJson.hiv_info,
        mental_health_info: this.hippaJson.mental_health_info,
        genetic_test_info: this.hippaJson.genetic_test_info,
        drug_refl_info: this.hippaJson.drug_refl_info,
        purpose_of_disclosure: this.hippaJson.purpose_of_disclosure,
        expiry_date: this.hippaJson.expiry_date,
        personal_description: this.hippaJson.personal_description,
        // patient_city:this.hippaJson.patient_city,
        // patient_state:this.hippaJson.patient_state,
        // patient_phone:this.hippaJson.patient_phone,
        // patient_fax:this.hippaJson.patient_fax,
        //patient_email:this.hippaJson.patient_email,
        // physicianNPI: this.hippaJson.PhysicianNipNo,
        street_address: this.hippaJson.PhysicianOfficeAddress,
        physicianState: this.selectedStateCode,
        assignedDelegateId: this.assignedDelegateId,
        isDelegated: this.isDelegated,
        selectedDelegateId: this.selectedDelegateId,
        selectedEmail: this.selectedPhysician.altEmail,
        selectedPhysician: this.selectedPhysician.physicianName,
        physicianPhone: this.hippaJson.physicianPhone,
        checkbox_01: this.hippaJson.checkbox_01,
        checkbox_02: this.hippaJson.checkbox_02,
        date: this.hippaJson.date,
        ssn_no: this.hippaJson.ssn_no,
        healthcare_provider_email: this.hippaJson.healthcare_provider_email,
        healthcare_provider_npi: this.hippaJson.healthcare_provider_npi,
        isEmail: this.isEmail,
        requestId: this.requestId,
      };
      // console.log(reqData);
      this.showLoader=true;
      return this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.generateHipaaOR,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            if (this.hippaJson.loggedInUserRoleId == 1) {
              this.router.navigate([
                "/doctor/" +
                  "/demographics/" +
                  this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
                  "/1/",
              ]);
            } else if (this.hippaJson.loggedInUserRoleId == 4) {
              this.router.navigate([
                "/doctor/" +
                  "/demographics/" +
                  this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
                  "/1/",
              ]);
            } else {
              if (window.open(apiResponse.commandResult.data, "_blank")) {
                this.router.navigate([
                  "/doctor/" +
                    "/ushf/" +
                    this.activatedRoute.snapshot.paramMap.get(
                      "loggedInUserId"
                    ) +
                    "/" +
                    this.activatedRoute.snapshot.paramMap.get("patientId") +
                    "/" +
                    this.activatedRoute.snapshot.paramMap.get("demographicId"),
                ]);
              }
            }
            this.showLoader=false;
          },
          (err) => {}
        );
    }
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        "/doctor/" +
          "/dashboard/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    }
  }

  async goBack() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the USHF Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.goBack
    );
    if (alertStatus) {
      this.router.navigate([
        "/doctor/" +
          "/ushf/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.activatedRoute.snapshot.paramMap.get("patientId") +
          "/" +
          this.activatedRoute.snapshot.paramMap.get("demographicId"),
      ]);
    }
  }

  closePdfModal() {
    this.showPdfModal = false;
    this.hippaJson.patient_signature = "";
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    //this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
    //this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }


  fnFindDemographic(patientId) {
    const finalData = {
      id: patientId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (response: any) => {
          // console.log('response=>', response);
          this.hippaJson.dob = this.hippaJson.dob = new Date(
            response.commandResult.data.userInfo.form1DOB
          );
          this.hippaJson.patient_city =
            response.commandResult.data.userInfo.form1City;
          this.hippaJson.patient_zip =
            response.commandResult.data.userInfo.form1ZIP;
          this.hippaJson.patient_state =
            response.commandResult.data.userInfo.form1State;
          this.hippaJson.patient_address =
            response.commandResult.data.userInfo.form1ResidenceNumber +
            ", " +
            response.commandResult.data.userInfo.form1StreetName +
            ", " +
            response.commandResult.data.userInfo.form1PrimaryPhysicianCity +
            ", " +
            response.commandResult.data.userInfo.form1State +
            ", " +
            response.commandResult.data.userInfo.form1ZIP;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  autoFormatSSN() {
    // alert('Amit');
    var val = this.hippaJson.social_security_no.replace(/\D/g, "");
    val = val.replace(/^(\d{3})/, "$1-");
    val = val.replace(/-(\d{2})/, "-$1-");
    val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    this.hippaJson.social_security_no = val;
  }

  openORBlankpdf() {
    window.open("https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/Oregon-HIPAA-Medical-Release-Form.pdf", "_blank")
  }

}
