import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "./../../_services/user.service";
import { ArrayHelper } from "./../../_helpers/array.helper";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

import * as moment from "moment";
import { DatePipe } from "@angular/common";
import * as XLSX from "xlsx";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-admin-on-call-schedule-log",
  templateUrl: "./admin-on-call-schedule-log.component.html",
  styleUrls: ["./admin-on-call-schedule-log.component.scss"],
})
export class AdminOnCallScheduleLogComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  childRoute: string;
  loggedInUserId: string;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  comapnySlug: string = ConstantHelper.COMPANY_SLUG;
  logYears: any = ArrayHelper.CALL_LOG_YEAR_ARRAY;
  logMonths: any = ArrayHelper.FULL_MONTH_ARRAY;

  currentDate = new Date();
  dateFormat = "MM-dd-yyyy";

  yearVal = "";
  monthVal = "";

  logsArr: any = [];
  tempLog: any = [];
  specialtyVal = "";
  subSpecialtyVal = "";
  physicianVal = "";

  specialtyArr: any = [];
  subSpecialtyArr: any = [];
  physicianArr: any = [];
  fileName = "ExcelSheet.xlsx";
  message = Message;
  noDataFound = false;
  dateFlag = true;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
  }

  ngOnInit() {}

  async onReset() {
    // if (
    //   confirm("Are you certain you want to return to the Physician Home Page?")
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminChangePassComp.onReset
    // );
    // if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
    // }
  }

  goBack() {
    this.router.navigate([
      "/" + this.childRoute + "/practice-groups/" + this.loggedInUserId,
    ]);
  }

  searchReport() {
    const finalData = {
      companySlug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: this.loggedInUserId,
      year: this.yearVal,
      month: this.monthVal,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findOnCallLogByMonth,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (res: any) => {
          if (res.commandResult.data.logs.length > 0) {
            this.logsArr = res.commandResult.data.logs;
            this.tempLog = res.commandResult.data.logs;
            this.physicianArr = res.commandResult.data.physicians;

            this.specialtyArr = res.commandResult.data.specialties;
            this.subSpecialtyArr = res.commandResult.data.subSpecialties;
          } else {
            // alert("No Logs Found.");
            this.noDataFound = true;
            this.logsArr = [];
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminOnCallScheduleLogComp.searchReport
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  filterSpecialty(specilatyId) {
    this.logsArr = this.tempLog.filter(
      (log) => log.speciality_id == specilatyId
    );
  }

  filterSubSpecialty(subSpecilatyId) {
    this.logsArr = this.tempLog.filter(
      (log) => log.sub_speciality_id == subSpecilatyId
    );
  }

  filterPhysician(physicianId) {
    this.logsArr = this.tempLog.filter((log) => log.user_id == physicianId);
  }

  reset() {
    this.logsArr = [];
    this.specialtyVal = "";
    this.subSpecialtyVal = "";
    this.physicianVal = "";
    this.monthVal = "";
    this.yearVal = "";
    this.dateFlag = false;
    this.noDataFound = false;
  }

  exportexcel(): void {
    /* table id is passed over here */
    const element = document.getElementById("example");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  printData() {
    const divToPrint = document.getElementById("example");
    const newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  fnChangePhysicianNameFormat1(physicians1) {
    const formattedNameArray1: any = [];
    physicians1.forEach((e) => {
      e.full_name = this.userService.getUS_FromatName(e.full_name);
      formattedNameArray1.push(e);
    });
    return formattedNameArray1.sort(this.userService.dynamicSort("full_name"));
  }

  fnChangePhysicianNameFormat3(physicians3) {
    const formattedNameArray3: any = [];
    physicians3.forEach((e) => {
      e.full_name = this.userService.getUS_FromatName(e.full_name);
      formattedNameArray3.push(e);
    });
    // return formattedNameArray3.sort(this.userService.dynamicSort('full_name'));
    return formattedNameArray3;
  }
}
