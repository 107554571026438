import { from } from "rxjs";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UserService } from "./../../_services/user.service";
import { UploadService } from "./../../_services/upload.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { Location } from '@angular/common';
@Component({
  selector: "app-email2user",
  templateUrl: "./email2user.component.html",
  styleUrls: ["./email2user.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class Email2userComponent implements OnInit {
  @ViewChild("fileUploaded1", { static: false }) fileUploaded1: ElementRef;
  @ViewChild("fileUploaded2", { static: false }) fileUploaded2: ElementRef;
  @ViewChild("fileUploaded3", { static: false }) fileUploaded3: ElementRef;
  showLoader = false;

  maxChars = 300;
  fileDesTxt: any = "";
  emailSubject: any = "";
  childRoute: string;
  loggedInUserId: string;
  patientId: string;
  patientObj: any = {};
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  fileToUpload1: File = null;
  fSize1: any;
  fileToUpload2: File = null;
  fSize2: any;
  fileToUpload3: File = null;
  fSize3: any;

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  selectedDelegateId: any;
  assignedDelegateId: any;
  isDelegated: any = 0;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService,
    public uploadService: UploadService,
    private location: Location
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;

    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.patientId = this.activatedRoute.snapshot.paramMap.get("patientId");
    this.selectedDelegateId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.loggedInUserId;

    this.findUserDetails(this.patientId);
  }

  ngOnInit() {}

  async onReset() {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
  }
  async goToDemographics() {
    this.location.back();
  }

  findUserDetails(userId) {
    const finalData = {
      id: userId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.patientObj = res.commandResult.data.userInfo;
          this.showLoader = false;
        },
        (err) => {}
      );
  }

  checkDelegateStatus() {
    this.selectedDelegateId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.loggedInUserId;
    if (this.selectedDelegateId != this.loggedInUserId) {
      this.loggedInUserId = this.selectedDelegateId;
      this.assignedDelegateId =
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
      this.isDelegated = 1;
    } else {
      this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    }
  }

  onFileSelected1(event) {
    if (this.fileUploaded1.nativeElement.value != "") {
      this.fileToUpload1 = event.target.files.item(0) as File;
      this.fSize1 = Math.round(this.fileToUpload1.size / 1024);
    } else {
    }
  }

  onFileSelected2(event) {
    if (this.fileUploaded2.nativeElement.value != "") {
      this.fileToUpload2 = event.target.files.item(0) as File;
      this.fSize2 = Math.round(this.fileToUpload2.size / 1024);
    } else {
    }
  }

  onFileSelected3(event) {
    if (this.fileUploaded3.nativeElement.value != "") {
      this.fileToUpload3 = event.target.files.item(0) as File;
      this.fSize3 = Math.round(this.fileToUpload3.size / 1024);
    } else {
    }
  }

  validateFile(name: String) {
    const ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "pdf"
    ) {
      return true;
    } else {
      return false;
    }
  }

  private encodeFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result == 'string') {
          resolve(reader.result.split(',')[1]);
        } else {
          reject(new Error('FileReader returned a non-string result'));
        }
      };
      reader.onerror = error => reject(error);
    });
  }

  async onSubmit() {
    this.checkDelegateStatus();

    if (
      this.fileUploaded1.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload1.name)
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (
      this.fileUploaded1.nativeElement.value != "" &&
      this.fSize1 > 61440
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_1
      );
      return false;
    } else if (
      this.fileUploaded2.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload2.name)
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (
      this.fileUploaded2.nativeElement.value != "" &&
      this.fSize2 > 61440
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_3
      );
      return false;
    } else if (
      this.fileUploaded3.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload3.name)
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (
      this.fileUploaded3.nativeElement.value != "" &&
      this.fSize3 > 61440
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_5
      );
      return false;
    } else {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.demographicsComp.email2All
      );
      if (alertStatus) {
        {
          this.showLoader = true;

          const jsonData: any = {
            loggedInUserId: this.loggedInUserId,
            emailTxt: this.fileDesTxt,
            emailSub: this.emailSubject,
            patientId: this.patientId,
            assignedDelegateId: this.assignedDelegateId,
            isDelegated: this.isDelegated,
            selectedDelegateId: this.selectedDelegateId,
          }

          if (this.fileToUpload1) {
            const file1Content = await this.encodeFileToBase64(this.fileToUpload1);
            jsonData.fileKey1 = { name: this.fileToUpload1.name, content: file1Content };
          }
      
          if (this.fileToUpload2) {
            const file2Content = await this.encodeFileToBase64(this.fileToUpload2);
            jsonData.fileKey2 = { name: this.fileToUpload2.name, content: file2Content };
          }
      
          if (this.fileToUpload3) {
            const file3Content = await this.encodeFileToBase64(this.fileToUpload3);
            jsonData.fileKey3 = { name: this.fileToUpload3.name, content: file3Content };
          }
          this.showLoader = true;
          this.http
            .post(
              this.appConfig.getLambdaUrl06() + ApiHelper.sendMailToUser,
              jsonData, {
                reportProgress: true,
                observe: "events",
              }
            )
            .subscribe(
              async (data: any) => {
                this.showLoader = false;
                if (data !== undefined) {
                  this.showLoader = false;
                  const alertStatus = await this.sweetAlertHelper.alertPopUp(
                    this.message.alertMessages.adminsecondoptwfCOmp.d2d04_9
                  );
                  if (alertStatus) {
                    this.router.navigate([
                      "/" +
                        this.childRoute +
                        "/demographics/" +
                        localStorage.getItem("loggedInUserId") +
                        "/" +
                        0,
                    ]);
                  }
                }
                this.showLoader = false;
              },
              (error) => {}
            );
        }
      }
    }
  }
}
