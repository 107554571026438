import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {throwError} from 'rxjs';
import {retry, catchError} from 'rxjs/operators';
import {ApiHelper} from './../_helpers/api.helper';
import {CommonHelper} from './../_helpers/common.helper';
import {ConstantHelper} from './../_helpers/constant.helper';
import {AppConfig} from './../../app/app.config';
import {environment} from './../../environments/environment';


@Injectable({
  providedIn: 'root',
})
export class UserService {
  baseUrl01 = this.appConfig.getLambdaUrl01();
  baseUrl02 = this.appConfig.getLambdaUrl02();
  baseUrl03 = this.appConfig.getLambdaUrl03();
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private http: HttpClient,
    public commonHelper: CommonHelper,
    private appConfig: AppConfig
  ) {
  }

  findHippaByStateCode(stateDoce) {
    const finalData = {stateCode: stateDoce};
    return this.http
      .post(
        this.baseUrl03 + ApiHelper.name_getStateHippaApi,
        finalData,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  getUS_FromatName(nameString) {
    if (nameString) {
      let tempFN = nameString;
      let tempLS = nameString;
      const lastIndex = tempFN.lastIndexOf(' ');
      tempFN = tempFN.substring(0, lastIndex);
      const n = tempLS
        .replace(/[\[\]?.,\/#!$%\^&\*;:{}=\\|_~()]/g, '')
        .split(' ');
      tempLS = n[n.length - 1];

      // Capitalize the first letter of the last name
        tempLS = this.commonHelper.capitalizeFirstLetter(tempLS);

      // Capitalize the first letter of the first and middle names
      tempFN = tempFN
            .split(' ')
            .map(word => this.commonHelper.capitalizeFirstLetter(word))
            .join(' ');
      const finalName = tempLS + ', ' + tempFN;
      return finalName.replace(/^\s+|\s+$/g, '');
    } else {
      return '';
    }
  }

  getRefromatName(nameString) {
    if (nameString) {
      if (nameString.lastIndexOf(',') === -1) {
        return nameString.replace(/^\s+|\s+$/g, '');
      } else {
        const nameArr = nameString.split(',');
        const finalName = nameArr[1] + ' ' + nameArr[0];
        return finalName.replace(/^\s+|\s+$/g, '');
      }
    } else {
      return '';
    }
  }

  autoFormatSSN(ssn) {
    let val = ssn.replace(/\D/g, '');
    val = val.replace(/^(\d{3})/, '$1-');
    val = val.replace(/-(\d{2})/, '-$1-');
    val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
    return val;
  }

  getFirstName(nameString) {
    let tempFN = nameString;
    let tempLS = nameString;
    const lastIndex = tempFN.lastIndexOf(' ');
    tempFN = tempFN.substring(0, lastIndex);
    const n = tempLS
      .replace(/[\[\]?.,\/#!$%\^&\*;:{}=\\|_~()]/g, '')
      .split(' ');
    tempLS = n[n.length - 1];
    return tempFN.replace(/^\s+|\s+$/g, '');
  }

  getLastName(nameString) {
    let tempFN = nameString;
    let tempLS = nameString;
    const lastIndex = tempFN.lastIndexOf(' ');
    tempFN = tempFN.substring(0, lastIndex);
    const n = tempLS
      .replace(/[\[\]?.,\/#!$%\^&\*;:{}=\\|_~()]/g, '')
      .split(' ');
    tempLS = n[n.length - 1];
    return tempLS.replace(/^\s+|\s+$/g, '');
  }

  dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function(a: any, b: any) {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }

  checkWorkingEmail(email) {
    if (
      email === 'support@actualhs.com' ||
      email === 'physician@actualhs.com'
    ) {
      return '';
    } else {
      return email;
    }
  }

  parentSiteUrl() {
    if (environment.environmentName === ConstantHelper.ENV_NAME.LOCAL) {
      return 'http://localhost:4200';
    } else if (environment.environmentName === ConstantHelper.ENV_NAME.PRODUCTION) {
      return 'https://www.' + ConstantHelper.MAIN_SITE;
      // return 'http://localhost:4300';

    } else {
      return 'https://' + environment.environmentName + '.' + ConstantHelper.MAIN_SITE;
    }
  }


}
