import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { from } from "rxjs";
import { DatePipe } from "@angular/common";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { SignaturePad } from "angular2-signaturepad";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "./../../_services/user.service";
import * as moment from "moment";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: "app-ny",
  templateUrl: "./ny.component.html",
  styleUrls: ["./ny.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class NyComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  hippaJson: any = {};
  loggedInUserId: string;
  userSignatures: any = [];
  userSignatureSelected = false;
  userFullSignature = "";
  userInitialSignature = "";
  userChoosedSignature = "";
  userFullSignatureExit = false;
  userInitialSignatureExis = false;
  showPdfModal = false;
  signatureType: any;
  ssnPattern = "^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$";
  resultJsonObj: any = {};
  clean: string = "0";
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  @ViewChild("canvasArea", { static: true }) canvasArea: ElementRef;
  canvasElm: any;
  public signaturePadOptions: Object = {
    minWidth: 1,
    canvasWidth:
      window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
    canvasHeight: 450,
  };

  currentDate = new Date();
  dateFormat = "MM-dd-yyyy";

  userStaffSignatureSelected = false;
  userStaffChoosedSignature = "";

  signatureFroWhom: any;
  isEmail: any;
  isPracticePhy: any;
  physicianId: any;
  requestId: any;
  tempId: any;
  userOptionId: any;
  emailLogId: any;
  message = Message;
  patientId: string;
  fileDesTxt: any = '';
  selectedDelegateId: any;
  assignedDelegateId: any;
  isDelegated: any = 0;
  selectedDocUrl: any;
  selectedPhysician: any = {};
  docInfo: any = {};
  selectedStateCode: any;
  showLoader = false;
  childRoute: string;
  patient_name: any;
  physicianName: any;
  PhysicianOfficeAddress: any;  
  patient_city:any;
  city_zip:any;
 

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    public userService: UserService
  ) {
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.isEmail = this.activatedRoute.snapshot.paramMap.get("isEmail")
      ? this.activatedRoute.snapshot.paramMap.get("isEmail")
      : 0;
    this.isPracticePhy =
      this.activatedRoute.snapshot.paramMap.get("isPracticePhy");
    this.physicianId = this.activatedRoute.snapshot.paramMap.get("physicianId")
      ? this.activatedRoute.snapshot.paramMap.get("physicianId")
      : 0;
    this.requestId = this.activatedRoute.snapshot.paramMap.get("requestId")
      ? this.activatedRoute.snapshot.paramMap.get("requestId")
      : 0;

    this.tempId = this.activatedRoute.snapshot.paramMap.get("tempId")
      ? this.activatedRoute.snapshot.paramMap.get("tempId")
      : 0;
    this.userOptionId = this.activatedRoute.snapshot.paramMap.get(
      "userOptionId"
    )
      ? this.activatedRoute.snapshot.paramMap.get("userOptionId")
      : 0;
    this.emailLogId = this.activatedRoute.snapshot.paramMap.get("emailLogId")
      ? this.activatedRoute.snapshot.paramMap.get("emailLogId")
      : 0;

    this.hippaJson.healthcare_provider = this.userService.getUS_FromatName(
      localStorage.getItem("providerName")
    );
    this.hippaJson.healthcare_provider_npi = localStorage.getItem(
      "providerPhysicianNipNo"
    );
    this.hippaJson.healthcare_provider_email =
      localStorage.getItem("providerEmail");
    this.hippaJson.healthcare_provider_npi = localStorage.getItem(
      "providerPhysicianNipNo"
    );
    this.hippaJson.patient_name = this.userService.getUS_FromatName(
      localStorage.getItem("loggedInUserName")
    );

   // this.fnFindSignature(this.loggedInUserId);
    this.fnFindDemographic(this.patientId);
    this.findUserDetails(this.patientId);

    this.hippaJson.patient_signature_date = this.currentDate;
    this.hippaJson.disclose_start_date = this.currentDate;
    this.hippaJson.disclose_end_date = this.currentDate;
    this.hippaJson.staff_sign_date = this.currentDate;
    this.hippaJson.healthcare_provider = localStorage.getItem('selectedPhysicianName');
    this.hippaJson.PhysicianOfficeAddress = localStorage.getItem('selectedPhysicianOfficeAddress');
    this.hippaJson.physicianNPI = localStorage.getItem('selectedPhysicianNPI');
    this.hippaJson.physicianEmail = localStorage.getItem('selectedPhysicianEmail');    
    this.hippaJson.physicianPhone=localStorage.getItem('Mobile');
    this.hippaJson.physicianCity = localStorage.getItem('selectedPhysicianCity');
    this.hippaJson.physicianState = localStorage.getItem('selectedPhysicianState');
    this.hippaJson.physicianZip=localStorage.getItem('providerZipCode');    
   
  }

  ngOnInit() {
    // this.hippaJson.patient_signature = '';
    // this.hippaJson.staff_sign = '';
    this.patientId = this.activatedRoute.snapshot.paramMap.get('patientId');
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');

    this.hippaJson.all_health_check = false;
    this.hippaJson.alcohol_check = false;
    this.hippaJson.mental_check = false;
    this.hippaJson.hiv_check = false;
    this.hippaJson.patient_name = '';
    this.hippaJson.physicianName = '';
    this.hippaJson.physicianAddress = '';      
    this.hippaJson.patient_ssn = '';
    this.hippaJson.patient_address='';
    this.hippaJson.patient_city='';
    this.hippaJson.patient_state='';
    this.hippaJson.patient_zip='';
    this.hippaJson.patientPhone='';
    this.hippaJson.patient_fax='';
    this.hippaJson.release_info='';
    this.findUserDetails(this.patientId);   
    this.fnFindDemographic(this.patientId); 
    this.hippaJson.physicianName=localStorage.getItem('selectedPhysicianName'),
    this.hippaJson.person_address = this.hippaJson.PhysicianOfficeAddress;
   // this.hippaJson.physicianCity_Zip = this.hippaJson.physicianCity + "," + this.hippaJson.physicianState;
    this.hippaJson.healthcare_provider = this.hippaJson.physicianName;
   // this.hippaJson.street_address = this.hippaJson.PhysicianOfficeAddress;
    this.hippaJson.city_zip= this.hippaJson.physicianCity + "," + this.hippaJson.physicianState+","+this.hippaJson.physicianZip;

    }
  findUserDetails(patientId) {
    const finalData = {
      company_slug: null,
      id: patientId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.hippaJson.patient_name = apiResponse.commandResult.data.userInfo.LastName + ', ' + apiResponse.commandResult.data.userInfo.FirstName;
            this.hippaJson.dob = apiResponse.commandResult.data.userInfo.DOB;
            this.hippaJson.patient_phone=apiResponse.commandResult.data.userInfo.Mobile;
            this.hippaJson.patient_email=apiResponse.commandResult.data.userInfo.Email;
           // console.log("patient email ",this.hippaJson.patient_email);
            //console.log("patient ph: ", this.hippaJson.tele_no);
            }
        },
        (err) => {
          // console.log(err);
        }
      );
  }



  async onSubmit() {
    // if (confirm('PLEASE CONFIRM:   By clicking ‘OK’:The information you have entered will be used to electronically generate a completed State HIPAA Form.'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.onSubmit
    );
    if(alertStatus){
      const finalData = {
        selectedDocUrl: this.selectedDocUrl,
        fileDesTxt: this.fileDesTxt,
        loggedInUserId: this.loggedInUserId,
        userId: this.patientId,
        patient_name: this.hippaJson.patient_name,        
        dob: this.hippaJson.dob,  
        patient_address:this.hippaJson.patient_address,    
        patient_city:this.hippaJson.patient_city,
        patient_state:this.hippaJson.patient_state,
        patient_phone:this.hippaJson.patient_phone,
        patient_email:this.hippaJson.patient_email,
        patient_fax:this.hippaJson.patient_fax,        
        physicianNPI: this.hippaJson.PhysicianNipNo,
        healthcare_provider:this.hippaJson.physicianName,        
        person_address: this.hippaJson.person_address,      
        physicianState: this.selectedStateCode,
        assignedDelegateId: this.assignedDelegateId,
        isDelegated: this.isDelegated,
        selectedDelegateId: this.selectedDelegateId,
        selectedEmail: this.selectedPhysician.altEmail,
        selectedPhysician: this.selectedPhysician.physicianName,
        physicianPhone: this.hippaJson.physicianPhone,   
        healthcare_provider_email: this.hippaJson.healthcare_provider_email,
        healthcare_provider_npi: this.hippaJson.healthcare_provider_npi, 
        alcohol_check: this.hippaJson.alcohol_check,
        alcohol_info: this.hippaJson.alcohol_info,
        alcohol_initial: this.hippaJson.alcohol_initial,
        all_health_check: this.hippaJson.all_health_check,
        all_health_text: this.hippaJson.all_health_text,
        city_zip:this.hippaJson.city_zip,
        disclose_end_date: moment(new Date(this.hippaJson.disclose_end_date.toString())).format('MM-DD-YYYY'),
        disclose_start_date: moment(new Date(this.hippaJson.disclose_start_date.toString())).format('MM-DD-YYYY'),       
        hiv_check: this.hippaJson.hiv_check,
        hiv_info: this.hippaJson.hiv_info,
        hiv_initial: this.hippaJson.hiv_initial,
        mental_check: this.hippaJson.mental_check,
        mental_info: this.hippaJson.mental_info,
        mental_initial: this.hippaJson.mental_initial,
        patient_zip: this.hippaJson.patient_zip,        
        purpose: this.hippaJson.purpose,        
        staff_name: this.hippaJson.staff_name, 
        social_security_no: this.hippaJson.social_security_no,        
        representative_name: this.hippaJson.representative_name,
        representative_capacity: this.hippaJson.representative_capacity,
        representative_street_address: this.hippaJson.representative_street_address,
        representative_city_zip: this.hippaJson.representative_city_zip,
        name_relation: this.hippaJson.name_relation,   
        isEmail: this.isEmail,
        requestId: this.requestId,
        no_patient_name: this.hippaJson.no_patient_name,

      };
      this.showLoader=true;
      // console.log(reqData);
      return this.http
        .post(this.appConfig.getLambdaUrl06() + ApiHelper.generateHipaaNY,
          finalData,
          this.httpOptions)
        .subscribe(
          (apiResponse: any) => {
            if (this.hippaJson.loggedInUserRoleId == 1) {
              this.router.navigate([
                '/doctor/' + '/demographics/' +
                this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
                '/1/'
              ]);
            } else if (this.hippaJson.loggedInUserRoleId == 4) {
              this.router.navigate([
                '/doctor/' + '/demographics/' +
                this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
                '/1/'
              ]);
            } else {
              if (window.open(apiResponse.commandResult.data, '_blank')) {
                this.router.navigate([
                  '/doctor/' + '/ushf/' +
                  this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.activatedRoute.snapshot.paramMap.get('patientId') + '/' + this.activatedRoute.snapshot.paramMap.get('demographicId')
  
                ]);
              }
            }
            this.showLoader= false;
          },
          (err) => {
          }
        );
    }
  }



  async onReset() {
    // if (confirm('PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’.'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(['/doctor/' +
        '/dashboard/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
      ]);
    }
  }

  async goBack() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the USHF Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.goBack
    );
    if(alertStatus)
    {
      this.router.navigate([
        '/doctor/' + '/ushf/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.activatedRoute.snapshot.paramMap.get('patientId') + '/' + this.activatedRoute.snapshot.paramMap.get('demographicId')

      ]);
    }
  }

  closePdfModal() {
    this.showPdfModal = false;
    this.hippaJson.patient_signature = "";
  }


  ngAfterViewInit() {
    // this.signaturePad is now available
   // this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
    //this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }

  
  clearCanvas() {
    if (
      confirm("PLEASE CONFIRM: Are you sure you want to clear you signature.")
    ) {
      this.signaturePad.clear();
    }
  }

  drawStart() {}

  fnFindDemographic(patientId) {
    const finalData = {
      id: patientId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (response: any) => {
          // console.log('response=>', response);
          // this.hippaJson.dob = this.hippaJson.dob = new Date(
          //   response.commandResult.data.userInfo.form1DOB
          // );
          this.hippaJson.patient_city =
            response.commandResult.data.userInfo.form1City;
          this.hippaJson.patient_zip =
            response.commandResult.data.userInfo.form1ZIP;
          this.hippaJson.patient_state =
            response.commandResult.data.userInfo.form1State;
          this.hippaJson.patient_address =           
            response.commandResult.data.userInfo.form1StreetName
             +
            ", " +
            response.commandResult.data.userInfo.form1City +
            ", " +
            response.commandResult.data.userInfo.form1State +
            ", " +
            response.commandResult.data.userInfo.form1ZIP;
            // console.log("add",this.hippaJson.patient_city );
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  autoFormatSSN() {
    // alert('Amit');
    var val = this.hippaJson.social_security_no.replace(/\D/g, "");
    // val = val.replace(/^(\d{3})/, "$1-");
    // val = val.replace(/-(\d{2})/, "-$1-");
    // val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    this.hippaJson.social_security_no = val;
  }
  openNYBlankpdf() {
    window.open(
      "https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/New-York-HIPAA-Medical-Release-Form.pdf", "_blank");
    }

}
