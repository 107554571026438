import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { ApiHelper } from "src/app/_helpers/api.helper";
import { CommonHelper } from "src/app/_helpers/common.helper";
import { AppConfig } from "src/app/app.config";
import { UserService } from "src/app/_services/user.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: "app-medical-record-financial-responsibility",
  templateUrl: "./medical-record-financial-responsibility.component.html",
  styleUrls: ["./medical-record-financial-responsibility.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class MedicalRecordInsuranceReleaseAndFinancialResponsibility implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  hippaJson: any = {};
  loggedInUserId: string;
  userSignatures: any = [];
  userSignatureSelected = false;
  userFullSignature = "";
  userInitialSignature = "";
  userChoosedSignature = "";
  userSignatureSelected1 = false;
  userChoosedSignature1 = "";
  userFullSignatureExit = false;
  userInitialSignatureExis = false;
  showPdfModal = false;
  signatureType: any;


  resultJsonObj: any = {};
  clean = "0";
  canvasElm: any;

  currentDate = new Date();
  dateFormat = "MM-dd-yyyy";

  userWitnessSignatureSelected = false;
  userWitnessChoosedSignature = "";

  signatureFroWhom: any;
  isEmail: any;
  isPracticePhy: any;
  physicianId: any;
  requestId: any;
  tempId: any;
  userOptionId: any;
  emailLogId: any;
  message = Message;
  sendDocRoute = sessionStorage.getItem("sendDocRoute");
  loggedInrName: string;
  loggedInName: string;
  currentDay: string;
  demographicId: string;
  phyicianId: string;
  invitationId: string;
  parentId: string | number;
  childRoute: string;
  documentId: any;


  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService
  ) {
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.demographicId = this.activatedRoute.snapshot.paramMap.get("demographicId");
    this.phyicianId = this.activatedRoute.snapshot.paramMap.get("phyicianId");
    this.invitationId = this.activatedRoute.snapshot.paramMap.get("invitationId");
    this.parentId = this.activatedRoute.snapshot.paramMap.get("parentId") ? this.activatedRoute.snapshot.paramMap.get("parentId") : 0;
    this.isPracticePhy = this.activatedRoute.snapshot.paramMap.get("isPracticePhy");
    this.physicianId = this.activatedRoute.snapshot.paramMap.get("physicianId") ? this.activatedRoute.snapshot.paramMap.get("physicianId") : 0;
    this.requestId = this.activatedRoute.snapshot.paramMap.get("requestId") ? this.activatedRoute.snapshot.paramMap.get("requestId") : 0;
    this.tempId = this.activatedRoute.snapshot.paramMap.get("tempId") ? this.activatedRoute.snapshot.paramMap.get("tempId") : 0;
    this.userOptionId = this.activatedRoute.snapshot.paramMap.get("userOptionId") ? this.activatedRoute.snapshot.paramMap.get("userOptionId") : 0;
    this.emailLogId = this.activatedRoute.snapshot.paramMap.get("emailLogId") ? this.activatedRoute.snapshot.paramMap.get("emailLogId") : 0;
    this.hippaJson.healthcare_provider = this.userService.getUS_FromatName(localStorage.getItem("providerName"));
    this.hippaJson.healthcare_provider_email = localStorage.getItem("providerEmail");
    this.hippaJson.healthcare_provider_npi = localStorage.getItem("providerPhysicianNipNo");
    this.hippaJson.street_address = localStorage.getItem("providerOfficeAddress");
    this.hippaJson.city_zip = localStorage.getItem("providerCity") ? localStorage.getItem("providerCity") : "" + ", " + localStorage.getItem("PhysicianStateName") ? localStorage.getItem("PhysicianStateName") : "" + ", " + localStorage.getItem("providerZipCode") ? localStorage.getItem("providerZipCode") : "";
    this.hippaJson.providerCity = localStorage.getItem("providerCity");
    this.hippaJson.providerPhone = localStorage.getItem("providerPhone");
    this.hippaJson.providerPhysicianNipNo = localStorage.getItem("providerPhysicianNipNo");
    this.documentId = this.activatedRoute.snapshot.paramMap.get("documentId");
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.demographicId = this.activatedRoute.snapshot.paramMap.get("demographicId");
    this.phyicianId = this.activatedRoute.snapshot.paramMap.get("phyicianId");
    this.invitationId = this.activatedRoute.snapshot.paramMap.get("invitationId");
    this.parentId = this.activatedRoute.snapshot.paramMap.get("parentId") ? this.activatedRoute.snapshot.paramMap.get("parentId") : 0;
    this.fnFindSignature(this.loggedInUserId);
    this.fnFindDemographic(this.loggedInUserId);
    this.findUserDetails(this.loggedInUserId);
    const today = new Date();
    this.currentDay = today.toISOString().split('T')[0];
  }
  ngOnInit() {
    this.hippaJson.patient_signature = "";
    this.hippaJson.patient_signature1 = "";
    this.hippaJson.first_witness_signature = "";
    this.loggedInName = localStorage.getItem('loggedInUserName');
  }
  formatDate(arg0: Date, dateFormat: string) {
    throw new Error("Method not implemented.");
  }

  fnFindSignature(user_id) {
    const reqData = {
      user_id,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findUserSignature,
        reqData,
        this.httpOptions
      )
      .subscribe(
        (response: any) => {
          {
            this.userSignatures = response.commandResult.data.userSignatures;
            this.userFullSignature = response.commandResult.data.userSignatures.full_signature_url;
            this.userInitialSignature = response.commandResult.data.userSignatures.initial_signature_url;
            if (response.commandResult.data.userSignatures.full_signature_url) {
              this.userFullSignatureExit = true;
            }
            if (response.commandResult.data.userSignatures.initial_signature_url) {
              this.userInitialSignatureExis = true;
            }
          }
        },
        (err) => { }
      );
  }

  async onSubmit() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.preop
    );
    if (alertStatus) {
      const reqData = {
        loggedInUserId: this.loggedInUserId,
        patientName: this.loggedInName,
        date: this.currentDay,
        signature_url: this.userFullSignature,
        physicianId: this.phyicianId,
        documentId: this.documentId,
        docType: "12",
      };

      return this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.pssfMedicalRecordInsurance,
          reqData,
          this.httpOptions
        )
        .subscribe(
          (response: any) => {
            if (response.success == 1) {
              const docId = response.docId;
              const docName = response.docName;
              const docUrl = response.docUrl;
              console.log("docName: ", docName, "docUrl: ", docUrl);
              let docIds = JSON.parse(sessionStorage.getItem("documentIds")) || [];
              if (!docIds.includes(docId)) {
                docIds.push(docId);
              }
              sessionStorage.setItem("documentIds", JSON.stringify(docIds));
              let docGroupArrayId = JSON.parse(sessionStorage.getItem("groupDocIds")) || [];
              if (!docGroupArrayId.includes(Number(this.documentId))) {
                docGroupArrayId.push(Number(this.documentId));
                sessionStorage.setItem("groupDocIds", JSON.stringify(docGroupArrayId));
              }
              sessionStorage.setItem(`${this.documentId}`, "true");
              let docsArray = JSON.parse(sessionStorage.getItem("practiceDocs")) || [];
              const newDoc = {
                docName: docName,
                docUrl: docUrl
              };
              docsArray.push(newDoc);
              sessionStorage.setItem("practiceDocs", JSON.stringify(docsArray));

              this.router.navigate([
                "/hippa-acceptance/" +
                this.activatedRoute.snapshot.paramMap.get("loggedInUserId") + "/" +
                this.demographicId + "/" +
                this.phyicianId + "/" +
                this.invitationId + "/" +
                this.parentId
              ]);
            } else {
              if (window.open(response.commandResult.data, "_blank")) {
                this.router.navigate([
                  "/dashboard/" +
                  this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
                ]);
              }
            }
          },
          (err) => { }
        );
    }
  }

  findUserDetails(userId) {
    const finalData = {
      company_slug: null,
      id: userId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.hippaJson.patient_name = apiResponse.commandResult.data.userInfo.LastName + ", " + apiResponse.commandResult.data.userInfo.FirstName;
          }
        },
        (err) => { }
      );
  }

  fnFindDemographic(user_id) {
    const finalData = {
      id: user_id,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
        ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (response: any) => {
          this.hippaJson.dob = new Date(
            response.commandResult.data.userInfo.form1DOB
          );
        },
        (err) => { }
      );
  }

  goBack() {
    this.router.navigate([
      "/hippa-acceptance/" +
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId") + "/" +
      this.demographicId +
      "/" +
      this.phyicianId +
      "/" +
      this.invitationId +
      "/" +
      this.parentId
    ]);
  }

}
