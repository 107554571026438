import {from} from 'rxjs';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';

import {environment} from './../../../environments/environment';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {AppConfig} from './../../../app/app.config';
import {UploadService} from '../../_services/upload.service';
import {SweetAlertHelper} from 'src/app/_helpers/sweet.alert.helper';
import {Message} from 'src/app/_locale/message';
import {async} from '@angular/core/testing';

@Component({
  selector: 'app-modify-document',
  templateUrl: './modify-document.component.html',
  styleUrls: ['./modify-document.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class ModifyDocumentComponent implements OnInit {
  @ViewChild('document', {static: false}) document: ElementRef;
  loggedInUserId: string;
  docId: string;
  documentName: string;
  resultJsonObj: any = {};
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  addAdminObj: any = {};
  docModified = false;
  selectedFile: File = null;
  fileName: string;
  orderCitySelectDisabledOption = false;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  hasDoc: any = 1;
  oldDoc: any;
  oldShowDoc: any;
  childRoute: string;
  showLoader = false;
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    public sweetAlertHelper: SweetAlertHelper,
    private uploadService: UploadService,
    private renderer: Renderer2
  ) {
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.addAdminObj.uploadAllowed = 0;
    this.addAdminObj.docMassage = '';
    this.addAdminObj.docEditReason = '';
  }

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.docId = this.activatedRoute.snapshot.paramMap.get('docId');
    const finalData = {
      docId: this.docId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getDocumentByIdApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.oldDoc = apiResponse.commandResult.data.docInfo.DocumentUrl;
          console.log('mama', this.oldDoc)
          this.addAdminObj.confirmationStatus =
            apiResponse.commandResult.data.docInfo.ShowConfirmation;
          this.addAdminObj.docName =
            apiResponse.commandResult.data.docInfo.DocumentName;
          this.addAdminObj.docDescription =
            apiResponse.commandResult.data.docInfo.DocumentDescription;
          this.addAdminObj.uploadAllowed =
            apiResponse.commandResult.data.docInfo.isUploadAllowed;
          this.addAdminObj.docMassage =
            apiResponse.commandResult.data.docInfo.documentMessage;
          this.addAdminObj.docEditReason =
            apiResponse.commandResult.data.docInfo.docEditReason;
          this.showLoader = false;
        },
        (err) => {
          this.showLoader = false;
        }
      );
  }

  onFileSelected(event) {
    if (this.document.nativeElement.value != '' && this.fileName != '') {
      this.selectedFile = event.target.files.item(0) as File;
      this.hasDoc = 2;
    } else {
    }
  }

  private encodeFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result == 'string') {
          resolve(reader.result.split(',')[1]);
        } else {
          reject(new Error('FileReader returned a non-string result'));
        }
      };
      reader.onerror = error => reject(error);
    });
  }

  async convertLinkToFile(url: string, filename: string): Promise<File> {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], filename, { type: blob.type });
    return file;
  }

  async onSubmit() {
    if (this.selectedFile) {
      const fSize = Math.round(this.selectedFile.size / 1024);
      if (!this.validateFile(this.selectedFile.name)) {
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
        );
        return false;
      } else if (fSize > 61440) {
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.modifyDocComp.onSubmit1
        );
      } else {
        const alertStatus = await this.sweetAlertHelper.confirmPopUp(
          this.message.confirmTitle,
          this.message.confirmMessages.adminUploaddocComp.uploadFile
        );
        if (alertStatus) {
          {
            const jsonData: any = {
              comapny_slug: ConstantHelper.COMPANY_SLUG,
              docName: this.addAdminObj.docName,
              docDescription: this.addAdminObj.docDescription,
              confirmationStatus: this.addAdminObj.confirmationStatus,
              loggedInUserId: this.loggedInUserId,
              docId: this.docId,
              hasDoc: this.hasDoc,
              oldDoc: this.oldDoc,
              docType: ConstantHelper.DOC_TYPE_ADMIN_UPLOADED,
              comapnyId: localStorage.getItem('loggedInUserComapnyId'),
              uploadAllowed: this.addAdminObj.uploadAllowed,
              docMassage: this.addAdminObj.docMassage,
              docEditReason: this.addAdminObj.docEditReason
            }
            if (this.selectedFile) {
              const fileContent = await this.encodeFileToBase64(this.selectedFile);
              jsonData.fileKey = { name: this.selectedFile.name, content: fileContent };
            }
            this.showLoader = true;
            this.http
              .post(
                this.appConfig.getLambdaUrl06() + ApiHelper.modifyAdminDocNew,
                jsonData, {
                  reportProgress: true,
                  observe: "events",
                }
              )
              .subscribe(
                async (data: any) => {
                  if (data !== undefined) {
                    if (data.commandResult.success == 1) {
                      const alertStatus =
                        await this.sweetAlertHelper.alertPopUp(
                          this.message.alertMessages.modifyDocComp.onSubmit2
                        );
                      setTimeout(() => {
                        this.router.navigate([
                          '/' +
                          this.childRoute +
                          '/documents/' +
                          this.loggedInUserId,
                        ]);
                      }, 2000);
                    }
                  }
                },
                (error) => {}
              );
          }
        }
      }
    } else {
      let blob;
      fetch(this.oldDoc).then(response => response.blob()).then(resultBlob => {
      blob = resultBlob;
    }).catch(error => {
      console.error('Error fetching the file:', error);
    });
      
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.adminUploaddocComp.uploadFile
      );
      if (alertStatus) {
        const file = await this.convertLinkToFile(this.oldDoc, this.addAdminObj.docName);
        {
          const jsonData: any = {
            comapny_slug: ConstantHelper.COMPANY_SLUG,
            docName: this.addAdminObj.docName,
            docDescription: this.addAdminObj.docDescription,
            confirmationStatus: this.addAdminObj.confirmationStatus,
            loggedInUserId: this.loggedInUserId,
            docId: this.docId,
            hasDoc: this.hasDoc,
            oldDoc: this.oldDoc,
            docType: ConstantHelper.DOC_TYPE_ADMIN_UPLOADED,
            comapnyId: localStorage.getItem('loggedInUserComapnyId'),
            uploadAllowed: this.addAdminObj.uploadAllowed,
            docMassage: this.addAdminObj.docMassage,
            docEditReason: this.addAdminObj.docEditReason
          }
          if (file) {
            const fileContent = await this.encodeFileToBase64(file);
            jsonData.fileKey = { name: file.name, content: fileContent };
          }

          this.showLoader = true;
            this.http
              .post(
                this.appConfig.getLambdaUrl06() + ApiHelper.modifyAdminDocNew,
                jsonData, {
                  reportProgress: true,
                  observe: "events",
                }
              )
            .subscribe(
              async (data: any) => {
                this.showLoader = false;
                if (data !== undefined) {
                  if (data.body.commandResult.success == 1) {
                    const alertStatus =
                      await this.sweetAlertHelper.alertPopUp(
                        this.message.alertMessages.modifyDocComp.onSubmit2
                      );
                    setTimeout(() => {
                      this.router.navigate([
                        '/' +
                        this.childRoute +
                        '/documents/' +
                        this.loggedInUserId,
                      ]);
                    }, 2000);
                  }
                }
              },
              (error) => {}
            );
        }
      }
    }
  }

  async goToDocuments() {
      this.router.navigate([
        '/' + this.childRoute + '/documents/' + this.loggedInUserId,
      ]);
  }

  hideDisabledOrderCity() {
    this.orderCitySelectDisabledOption = false;
  }

  base64MimeType(encoded) {
    let result = null;

    if (typeof encoded !== 'string') {
      return result;
    }

    const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
      result = mime[1];
    }
    const splits = result.split('/');

    if (
      splits[1].toLowerCase() == 'png' ||
      splits[1].toLowerCase() == 'jpg' ||
      splits[1].toLowerCase() == 'jpeg' ||
      splits[1].toLowerCase() == 'pdf'
    ) {
      return true;
    } else {
      return false;
    }
  }

  validateFile(name: String) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (
      ext.toLowerCase() == 'png' ||
      ext.toLowerCase() == 'jpg' ||
      ext.toLowerCase() == 'jpeg' ||
      ext.toLowerCase() == 'pdf'
    ) {
      return true;
    } else {
      return false;
    }
  }
}
