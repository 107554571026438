import {Component, OnInit, ViewChild, ElementRef, Renderer2} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpParams, HttpRequest, HttpHeaders} from '@angular/common/http';
import {CommonHelper} from './../../_helpers/common.helper';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {AppConfig} from './../../../app/app.config';
import {UploadService} from '../../_services/upload.service';
import {SweetAlertHelper} from 'src/app/_helpers/sweet.alert.helper';
import {Message} from 'src/app/_locale/message';
import {ApiHelper} from '../../_helpers/api.helper';

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class AddCommentComponent implements OnInit {
  childRoute: string;
  loggedInUserId: string;
  resultJsonObj: any = {};
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  addCommentObj: any = {};
  commentAdded = false;
  showLoader = false;
  loggedInUserRoleId: any;
  demographyUserId: string;
  message = Message;
  httpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
  httpOptions = {headers: this.httpHeaders};

  constructor(public commonHelper: CommonHelper, private appConfig: AppConfig, private activatedRoute: ActivatedRoute, private router: Router, private http: HttpClient, private sanitizer: DomSanitizer, private formBuilder: FormBuilder, public sweetAlertHelper: SweetAlertHelper, private uploadService: UploadService) {
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');
    this.demographyUserId = this.activatedRoute.snapshot.paramMap.get('demographyUserId');
    this.addCommentObj.hasTasks = 0;
  }

  ngOnInit() {
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
  }


  async onSubmit() {
    console.log('onSubmit');
    if (this.addCommentObj.comments === undefined || this.addCommentObj.comments.length <= 0) {
      await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.addCommentForm.commentsRequired);
      return false;
    } else if (this.addCommentObj.hasTasks && this.addCommentObj.tasks === undefined || this.addCommentObj.comments.tasks <= 0) {
      await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.addCommentForm.tasksRequired);
      return false;
    } else {
      const confirmStatus = await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.addCommentForm.addConfirm);
      console.log(confirmStatus);
      if (confirmStatus) {
        this.showLoader = false;
        const finalData = {
          comments: this.addCommentObj.comments,
          tasks: this.addCommentObj.tasks,
          hasTasks: this.addCommentObj.hasTasks,
          loggedInUserId: this.loggedInUserId,
          patientId: this.demographyUserId,
        };
        this.http.post(this.appConfig.getLambdaUrl06() + ApiHelper.saveCommentPat, finalData, this.httpOptions).subscribe(
          async (res: any) => {
            this.showLoader = false;
            await this.sweetAlertHelper.alertPopUp(res.commandResult.message);
            if (res.commandResult.success) {
              setTimeout(() => {
                this.router.navigate(['/' + this.childRoute + '/comments/' + this.loggedInUserId + '/' + this.demographyUserId]);
              }, 2000);
            } else {
              window.location.reload();
            }


          },
          (err) => {
            this.showLoader = false;
            console.log(err);
          }
        );
      }

    }

  }


  async goToAdminComments() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(this.message.confirmTitle, this.message.confirmMessages.addCommentForm.backToComments);
    if (alertStatus) {
      this.router.navigate(['/' + this.childRoute + '/comments/' + this.loggedInUserId + '/' + this.demographyUserId]);
    }

  }

}
