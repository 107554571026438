const message = {
  en: {
    confirmTitle: 'PLEASE CONFIRM',
    alertMessages: {
      adminDashboardComp: {
        editDemographicForm4:
          'Are you sure you want to review/update your demographic information?',
      },
      doctorProfileComp: {
        onSubmit: 'Your profile was updated successfully!',
        onSubmit1: 'Profile updated successfully! ',
        signmsg: 'Do you want to sign now the AHS Demographics'
      },
      usersListComp: {
        // changeCOEStatue: "COE status enabled successfully.",
        changeCoeStatue1: 'Center of Excellence status successfully changed! ',

        enableDelegate: 'Access successfully assigned! ',
        disableDelegate: 'Access has been Revoked!',
      },
      editAdminUserComp: {
        onSubmit: 'Your details were updated successfully! ',
      },
      adminsecondoptwfCOmp: {
        d2d1: ' Before clicking \'OK,\' you must first select the receiving Physician to send an email requesting select file sharing containing a matching, attached HIPAA Form',
        d2d1_1:
          'Before clicking \'OK,\' you must first select the matching State HIPAA Form that lists the doctor’s name and at least one file to be shared ',
        d2d1_2:
          'You cannot select more than 5 document files. You can share more by sending another email',
        d2d1_3: 'Please select or upload a HIPAA file ',
        d2d1_4: 'Please select at least one file.',
        d21_7:
          'We\'re sorry; the selected file format is not supported. Please convert it to a .png, .jpg, .jpeg. or .pdf',
        d2d1_5:
          'File cannot be uploaded! Please select a file that is less than 60mb',
        d2d1_6:
          ' Before clicking \'OK,\' you must first call the doctor’s office to obtain an alternate email address when none is displayed from the NPI database. Their office phone number can be found on their website. Please enter this alternate email address to send the message ',
        searchPhysician:
          'Please enter a last name. You may also search for a physician by their NPI number',
        seacrhPhysician1:
          'Please enter a first name. You may also search for a physician by their NPI number',
        seacrhPhysician2: 'Please select a state',
        findSpecialty:
          'A Physician with this specialty was not found! Please modify your search and try again',
        findSubSpeciality:
          'A Physician with this subspecialty was not found! Please modify your search and try again',
        findcoePhysicians:
          'No Physician could be found! Please modify your search and try again',
        findcoePhysicians1:
          ' No Physicians with this subspecialty were found! Please modify your search and try again',
        findAllParticipatingCenters:
          'No Center of Excellence matching your specifications was found! Please modify your search and try again',
        findcitiesbyStateCode:
          'This city could not be found. Please modify your search and try again',
        d2d04:
          'We\'re sorry; the selected HIPAA file format is not supported. Please convert it to a .png, .jpg, .jpeg. or .pdf',
        d2d04_1:
          'File 1 is too large! Please select a file that is less than 60mb',
        // d2d04_2: "Selected file 2 format is not supported",
        d2d04_3:
          'File 2 is too large! Please select a file that is less than 60mb',
        d2d04_4:
          'The HIPAA file is too large! Please select a file that is less than 60mb',
        d2d04_5:
          'File 3 is too large! Please select a file that is less than 60mb',
        d2d04_6: 'Please confirm the alternate email address',
        d2d04_7: 'Your email sent successfully!  ',
        d2d04_8: 'Email sent successfully! ',
        d2d04_9: 'Email successfully sent to the patient! ',
        d2d03: ' Before clicking \'OK,\' you must first select the matching State HIPAA Form that lists the doctor’s name and at least one file to be shared',
        invalidNPI: 'NPI details not found'
      },
      email2allComp: {
        onSubmit: 'Email successfully sent to all patients!',
      },
      adminInvitePatientComp: {
        onSubmit:
          'The person you are inviting to register is already in our AHS Elemrex Database',
        onSubmit1: 'Invitation successfully sent!',
      },
      allotDocgrpComp: {
        onSubmit: 'Document Group assigned successfully!',
        onSubmi1: 'Oops! An error has occurred; please try again',
      },
      adminushfComp: {
        onPrint: 'No HIPAA for the state found.',
        onSubmit:
          'You need to download HIPAA form manually email to the doctor.',
        onSubmit1: 'No HIPAA form can be found for this state',
        onSubmit2:
          'Please download the HIPAA form and email it to your physician',
      },
      doctorDocComp: {
        finUserDetails: 'An error has occurred!',
      },
      adminUploadComp: {
        uploadFile: 'Please select at least one file',
        // uploadFile1:
        // "File 1 can be only .png, .jpg, .jpeg. or .pdf file formats.",
        // uploadFile2: "File 1 is too Big, please select a file less than 60mb",
        uploadFile3: 'Please choose the File 1 folder',
        // uploadFile4:
        // "File 2 can be only .png, .jpg, .jpeg. or .pdf file formats.",
        // uploadFile5: "File 2 is too Big, please select a file less than 60mb",
        uploadFile6: 'Please choose the File 2 folder',
        // uploadFile7:
        // "File 3 can be only .png, .jpg, .jpeg. or .pdf file formats.",
        // uploadFile8: "File 3 is too Big, please select a file less than 60mb",
        uploadFile9: 'Please choose the File 3 folder',
        //  uploadFile10:
        //  "File 4 can be only .png, .jpg, .jpeg. or .pdf file formats.",
        uploadFile11:
          'File 4 is too large! Please select a file that is less than 60mb',
        uploadFile12: 'Please choose the File 4 folder ',
        // uploadFile13:
        // "File 5 can be only .png, .jpg, .jpeg. or .pdf file formats.",
        uploadFile14:
          'File 5 is too large! Please select a file that is less than 60mb',
        uploadFile15: 'Please choose the File 5 folder',
      },
      demographicsComp: {
        addToMyList: 'Successfully added this patient to My Patient List! ',
        addToMyList1: 'Successfully removed this patient to My Patient List! ',
      },
      adminDuallistComp: {
        saveDocGroupDes: 'Please enter a description.',
        saveDocGroup: 'Please enter a new Group Name.',
        saveDocGroup1:
          'Before you can save, please indicate whether this will become the Default Group. Select \'Yes\' or \'No\' ',
        saveDocGroup2: 'Please select at least one document',
        saveDocGroup3: 'Document Group successfully saved!',
        sentEmail: 'Request sent successfully to AHS Support Team.',
        submitReason: 'Please fill out all required fields.',
        docGrpAlreadyExist: 'Document Group Already Exists!',
        noChangesMade: 'No changes were made to the document group.',
      },
      modifyDocComp: {
        onSubmit: 'We\'re sorry; documents can only be uploaded as .png, .jpg, .jpeg. or .pdf ',
        onSubmit1: 'File cannot be uploaded!',
        onSubmit2: 'Document uploaded successfully!',
        onSubmit3: 'Document is missing!',
      },
      addCommentForm: {
        commentsRequired: 'Comments are required ',
        replyRequired: 'Remarks required ',
        adminRequired: 'Admin is required ',
        tasksRequired: 'Tasks are required ',
      },
      adminChangePassComp: {
        onSubmit: 'Password doesn\'t match',
        onSubmit1: 'Incorrect security answer 01! — Please try again',
        onSubmit2: 'Incorrect security answer 02! — Please try again',
        onSubmit3: 'Password updated successfully!',
      },
      addDocComp: {
        onSubmit: 'File too Big, please select a file less than 2mb',
        onSubmit1: 'File cannot be uploaded! It exceeds the 60mb file size limit. Consider compressing it and try again'
      },
      admind2dreviewlogComp: {
        onSubmit: 'Response sent successfully!',
      },
      adminDocReviewComp: {
        findUserDetails: 'An error has occurred!',
      },
      adminEmailDocComp: {
        // onSubmit: "Please select at least one document",
        // onSubmit1: "We're sorry; the selected file format is not supported. Please convert it to a .png, .jpg, .jpeg. or .pdf",
      },
      adminOnCallScheduleLogComp: {
        searchReport: 'No Logs were found! Please try again',
      },
      adminReplyd2dMailComp: {
        onSubmit: ' Response sent successfully!',
      },
      adminphysicianSchedulingComp: {
        channgeStatus: 'Status changed successfully!',
        startDateTime:
          'End Date/Time has to match or be later than the Start Date/Time',
        addSchedule: 'Call Schedule added successfully!',
        modifySchedule: 'Call Schedule modified successfully!',
        fnShowOldCheck: 'Successfully enabled History',
        fnShowOldCheck1: 'Successfully disabled History',
      },
      adminResendDocComp: {
        // findPhysicianByStateCode1: "No Physicians Found1",
        // findPhysicianByStateCode1_1: "No Physicians Found2",
        onSubmit: ' Documents sent successfully!',
      },
      adminsecondOpinionComp: {
        onSubmit: 'Request sent successfully!',
      },
      adminSendEmailComp: {
        onSubmit: 'Email sent successfully!',
      },
      adminTransferLogComp: {
        fnTransferLog: 'Successfully accessed the Transfer Log!',
        fnTransferLog1: 'Transfer revoked successfully.',
      },
      adminUshf2Comp: {
        onSubmit: 'No HIPAA form can be found for this state',
        searchPhysicians: 'Please enter a last name',
        searchPhysicians1:
          'Last name requires at least 3 characters to perform a search',
      },
      adminViewD2dDocComp: {
        reply: 'Please enter a response.',
      },
      prcaticeGroupComp: {
        addSpeciality: 'Please enter a specialty ',
        addSubSpeciality: 'Please enter a subspecialty',
        addParticipatingDoctor: 'please select a physician',
        addParticipatingDoctor1:
          'Physician has already been added to this Center of Excellence',
        addParticipatingDoctor2:
          'Physician successfully added to this Center of Excellence! ',
        updateCOE: 'To update the COE name please contact your Primary Physician or Primary Admin by sending email.',
        updateParticipating1: 'COE Name updated successfully.',
        StateandcityBlank: 'Enter valid Name and City'
      },
      practiceSpecialitiesComp: {
        saveSpeciality: 'Specialty already exists! ',
        saveSpeciality1: 'Specialty added successfully! ',
        editSpeciality: 'Specialty updated successfully! ',
        saveSubSpeciality: 'Sub-Specialty added successfully!'
      },
      practiceSubSpecialitiesComp: {
        addSubSpeciality: 'Subspecialty already exists! ',
        editSubSpeciality: 'Subspecialty updated successfully! ',
      },
      userDocAcknowledgeComp: {
        saveCanvas: 'Acknowledgement successfully emailed to the patient!',
      },
      adminWelcome: {
        checklog: ' Please log in first! ',
      },
      doctorLogin: {
        signup: ' Please sign up first! ',
      },
      doctorSignComp: {
        npino: 'NPI numbers do not match! Please recheck and try again',
        npidetails:
          'NPI details not found! Please fill out the information below to create an NPI record',
        npiverify: 'Please verify all NPI data is correct and modify as needed',
        npivalid: 'Please provide a valid NPI number',
        doctorEmail:
          'Email addresses do not match! Please recheck and try again',
        doctorMobile:
          'Cell phone numbers do not match! Please recheck and try again',
        doctorNPI: 'NPI numbers do not match! Please recheck and try again',
        doctorPrimaryState:
          'Primary state licenses do not match! Please recheck and try again',
        doctorSecondaryState:
          'Secondary state licenses do not match! Please recheck and try again',
        doctorPrimaryStateLincense: 'Primary state license not confirmed',
        doctorSecondaryStateLincense: 'Secondary state license not confirmed',
        doctorEmailVerify: 'Email address does not work. Please try again',
      },
      confirmationformComp: {
        goTosignatureForm:
          'To proceed, please select all or check all the boxes',
      },
      confirmationFOrm1Comp: {
        uploadDocument: 'File uploaded successfully! ',
        uploadDocument1: 'Upload failed, please try again',
        permission:'Permission not allowed'
      },
      dashboardComp: {
        showAlertMessage:
          'Please Review & Sign to in order to Review Demographic.',
        showAlertMessag1:
          'Please Review & Sign to in order to Select Document.',
      },
      signatureWaiverComp: {
        saveCanvas1: 'Please Sign!',
      },

      documentThoubsComp: {
        signOff: 'Demographics successfully submitted! ',
      },
      editform4Comp: {
        generateSignedFiles: 'Demographic information successfully updated! ',
      },
      httpinterCepter: {
        removeRequest: ' Please contact the AHS Team at support@actualhs.com',
      },
      addDocGroupComp: {
        saveDocGroup: 'Please enter Group Name',
      },
      headerComp: {
        sendLogoutEmail: 'Your practice registration request has been received.',
      },
      onBehalfOfPhysician: {
        workingOnBehlaf: 'You are working on behalf of Physician',
      },
      contactUs: {
        contactUs: "Email sent successfully!"
      },
      transcribeAlert:{
        transcribeSuccess: 'Transcribe is ready',
        transcribeFail: 'Failed to generate transcribe'
      }
    },
    ///////////////////////////////////////////////////////////
    confirmMessages: {

      addCommentForm: {
        addRemarksConfirm: 'Are you sure you want to add remarks',
        addConfirm: 'Are you sure you want to add comment',
        backToComments: 'Are you sure you want to go to comments.',
        addNoteConfirm: 'Are you sure you want to add note',
      },
      adminDashboardComp: {
        goToDoctorProfile:
          'Are you sure you want to review and update your demographics?',
        goToProfile: 'Are you sure you want to return to your profile?',

        editDemographicForm4:
          'Select \'OK\'  to review and update your demographics',
      },
      doctorProfileComp: {
        onSubmit:
          'Are you sure you want to continue with this current information?',
      },
      userListComp: {
        openAddUserPage: 'Are you sure you want to add an administrator?',
        submitMessage: 'Are you sure you want to send message?',
        openAddPhysicianPage: 'Are you sure you want to add a physician?',
        openEditUserPage:
          'Are you sure you want to edit this Physician\'s profile?',
        disableUser:
          'Are you sure you want to disable this doctor\'s access to the AHS Elemrex Platform?',
        enableUser:
          'Are you sure you want to enable this administrator\'s access to the AHS Elemrex Platform?',
        enableCOE:
          'Are you sure you want to activate this doctor to serve as a COE Expert?',
        disableCOE:
          'Are you sure you want to deactivate this doctor from serving as a COE Expert?',
        enableDelegate:
          'Are you sure you want to delegate access to this administrator?',
        disableDelegate:
          'Are you sure you want to  revoke the delegate access to this administrator?',
          actionNotAllowed: 'To update the content please contact your Primary Physician or Primary Admin by sending email.',
      },
      addPhysicianUsercomp: {
        goToAdmins:
          'Are you sure you want to return to the Administrative Access list? ',
      },
      docReviewComp: {
        signOff:
          'Clicking \'Submit\' will forward copies of the document(s) to your email.',
      },
      addAdminUserComp: {
        onSubmit: 'Are you sure?',
        goToAdmins:
          'Are you sure you want to return to the Administrative Access list?',
        EmailAlreadyExist: 'This email already exists!'
      },
      editadminuserComp: {
        onSubmit: 'Are you sure you want to modify these details?',
        goToAdmins:
          'Are you sure you want to go to the Administrative Access list?',
      },
      demographicsComp: {
        goToUserComments: 'Are you sure you want to access the comments?',
        goToUserEmailLog: 'Are you sure you want to access the Email Logs?',
        goToUserEmailDoc: 'Are you sure you want to email these documents?',
        goToSendEmail: 'Are you sure you want to email this patient?',
        goToUserDocGoupComp:
          'Are you sure you want to assign this new Document Group?',
        goToUHF: 'Are you sure you want to send a HIPAA form to this patient?',
        goToDocumentReview:
          'Are you sure you want to review this patient\'s documents?',
        goToDocumentUpload: 'Are you sure you want to upload these documents?',
        goToseconfopinionWf: 'Are you sure you want to request a 2nd Opinion?',
        invitePatient: 'Are you sure you want to invite a new patient?',
        email2All: 'Are you sure you want to send this email?',
        goTo2ndOpinion: 'Are you sure, you want to send email to doctor?',
        addToMyList: 'Are you sure you want to add the patient to your list?',
        addToMyList1:
          'Are you sure you want to remove the patient from your list?',
      },
      demographicsSign: {
        msgSign:
          'Do you want to Sign the AHS Document Now?',
      },


      adminsecoptwfComp: {
        d2d01:
          'By clicking \'OK,\' you are selecting at least one file to be shared by email.',
      },
      email2all: {
        gotoDemographics:
          'Are you certain you want to return to the User Database?',
        onSubmit: ' Are you sure you want to send email?',
      },
      adminInvitePatientComp: {
        onSubmit:
          'Are you sure you want to send your patient an invitation to register for AHS Elemrex?',
      },
      allotDocGrpCOmp: {
        onSubmit: 'Are you sure you want to assign this document group?',
      },
      adminushfComp: {
        onsubmit:
          'HIPAA Form was successfully sent.  Do you want to resend the same HIPAA elsewhere?',
        onPrint: 'No HIPAA form found for the state.',
        onPrint1: 'Are you Sure to Print HIPAA',
        onsubmit1:
          'HIPAA form was successfully sent. Do you want to send this same HIPAA form somewhere else?',
        goToDemographics:
          ' Confirm your desire to return to the \'Patient user\' list. ',
      },
      doctordocComp: {
        editDemographicForm5:
          'Are you sure you want to modify your demographic information?',
        signOff:
          'Clicking \'Submit\' will forward copies of the document(s) to your email.',
        deleteDoc: 'Are you sure, you want to delete',
        clearPreview: 'Are you sure, you want to clear preview?',
        onReset: ' Are you certain you want to return to Physician Home Page?',
      },
      adminUploaddocComp: {
        uploadFile: 'Are you sure you want to upload this file?',
        uploadFile1: 'Do you want to upload any additional files?',
        gotoDemgraphics:
          'Are you sure you want to return to the Patient User list?',
      },

      documenmnetlistComp: {
        disableDoc:
          'Are you sure you want to disable this document? By doing so, you are removing it from an active document group.',
        enableDoc:
          'Are you sure you want to enable this document? By doing so, you are allowing it to be added to a document group.',
        openModifyDoc:
          'Are you sure you wish to replace this document? The original file can still be located in your logs.',
        viewDocGroups:
          'By clicking \'OK,\' you can now rearrange your documents into subgroups.',
        disableUploadDocument:
          'Are you sure you want to disable the ability to upload documents?',
        changesShow:
          ' Are you sure you want to enable the signature requirement?',
        changesShow1:
          'Are you sure you want to disable the signature requirement?',
        openAddDocPage: 'Click to confirm you want to add a new document.',
        openAddCommentPage: 'Click to confirm you want to add a new comment.',
        elctronicallyFill:
          ' Are you sure you want to enable the electronically form filling?',
        disableelctrFill:
          ' Are you sure you want to disable the electronically form filling?',
       
        
      },

      admindualListComp: {
        saveDocGroup: 'Select \'OK\' to save your changes to the document group.',
        setGroupType:
          'To Enter the Reason for making changes of Default Practice Documents Click \'OK\'.',

        submitReason: 'Are you sure to make request?',
      },
      addDocGroupComp: {
        SaveDocComp: 'Are you sure you want to save this Document Group?',
      },
      addDocComp: {
        goToDocuments: 'Are you sure you want to return to the Document list?',
      },
      addAcknowd2dDocComp: {
        clearCanvas: 'Are you sure you want to clear your signature? ',
      },

      adminChangePassComp: {
        onReset: 'Are you sure you want to return to the homepage?',
        onSubmit: 'Are you sure you want to update your password?',
        goToAdmin: 'Are you sure you want to go to Administrator Lists?',
      },
      invitationLog: {
        listLog: 'Are you sure you want to go to invitation log?',
      },
      adminContactUsComp: {
        goToDemographicsComp:
          'Are you sure you want to return to the User Database?',
      },
      njComp: {
        onSubmit: 'Are you sure you want to print this state HIPAA form?',
        goBack:
          'Are you sure you want to return to the Universal State HIPAA Form page?',
        saveCanvas: 'Are you sure you want to replace your existing signature?',
        clearCanvas: 'Are you sure you want to clear your signature?',
        preop:'Are you sure you want to Generate pdf',
      },
      admind2dreviewlogComp: {
        back: 'Are you sure you want to return to the Email and Transfer Log?',
      },
      adminDocReviewComp: {
        editDemographicForm5:
          'Are you sure you want to modify your demographic information?',
        editSignature: 'Are you sure you want to change your signature?',
        signOff:
          'Clicking \'Submit\' will forward copies of the document(s) to your email.',
        deleteDoc: 'Are you sure you want to delete this file?',
        clearViewr: 'Are you sure you want to clear the preview?',
        goToPracticeList:
          'Are you sure you want to return to the Practice list?',
      },

      adminEmailLogComp: {
        resendDocs: 'Are you sure you want to open this email?',
        goTo2ndopinion: 'Are you sure you want to go to 2nd Opinion?',
      },
      adminPhysicianSchedulingLocComp: {
        ChangeStatus:
          'Are you sure you want to change your availability status?',
        fnShowOldCheck:
          'Are you sure you want to enable/disable your event history?',
        fnSubSpecialityHeadCheck:
          'Are you sure you want to assign/unassign this physician as Division Head?',
      },
      adminResendDocComp: {
        onsubmit:
          'Are you sure you want to send this information to the updated email address?',
        onReset: 'Are you sure you want to go to the Email Logs?',
      },

      adminTransferLogComp: {
        fnTransferLog:
          'Are you sure you want to make this addition to the Admin Transfer Log?',
        fnTransferLog1:
          'Are you sure you want to make this revoke to the Admin Transfer Log?',
      },
      prcaticeGroupComp: {
        goToPracticeSpecialities: 'Are you sure you want to go to Specialties?',
        goToPrcaticeSubSpecialities:
          'Are you sure you want to go to Subspecialties?',
        goToCallSchedules: 'Are you sure you want to go to On-Call Schedules?',
        updateParticipating:
          'Are you sure you want to update your Center of Excellence information?',
        addSpeciality: 'Are you sure you want to add a new specialty?',
        editSpeciality: 'Are you sure you want to edit this specialty?',
        addSubSpeciality: 'Are you sure you want to add a new subspecialty? ',
        editSubSpeciality: 'Are you sure you want to edit this subspecialty?',
        addparticipatingDoctor: 'Are you sure you want to continue?',
        ChangeStatus: 'Are you sure you want to change your status?',
      },
      practiceSpecialitiesComp: {
        openAddSpecialityModal: 'Are you sure you want to add a specialty?',
      },

      headerComp: {
        logout: 'Are you sure you want to close the session and log out?',
        SendLogoutEmail1: 'Do you want to register your practice?',
        goToProfile: 'Are you sure you want to go to your profile?',
        goToFaq: 'Are you sure you want to go to the FAQs?',
      },
      confirmationFormComp: {
        goToSignatureForm: 'Are you sure you want to go to the signature page?',
        checkAllHipaaDoc:
          ' Are you sure you want to select all? Doing this will skip the requirement to review each document individually.',
        checkAllHipaaDoc1:
          'Are you sure you want to unselect all? Doing this will require you to review each document individually.',
      },
      dashboardComp: {
        editDemographicForm4:
          'Select \'OK to review and update your demographics.',
      },
      demographicsFormComp: {
        onReset:
          'Are you sure you want to cancel your changes and return to the homepage?',
      },
      practiceGroupComp: {
        openEditParticipatingCenterModal: 'To modify the COE Name Click \'OK\'.',
        openEditParticipatingCenterModal1: 'Request sent Successfully.',

      },

      dictationConfirmComp: {
        dictationModal: 'Are you sure you want to start the dictation?',       
      },

      signaturewaiverComp: {
        saveCanvas: 'Select \'OK\' to sign the document and review it.',
      },
      // uploadDocComp: {
      //   uploadFile: "Do you want to upload any additional files?",
      // },

      markPatientVisited: {
        markPatientVisited: 'Are you sure, you want to mark this patient as visited?'
      },
    },
  },
};
export const Message = message.en;
