import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-admin-email-doc",
  templateUrl: "./admin-email-doc.component.html",
  styleUrls: ["./admin-email-doc.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class AdminEmailDocComponent implements OnInit {
  @ViewChild("fileUploaded", { static: false }) fileUploaded: ElementRef;
  childRoute: string;
  loggedInUserId: string;
  userId: string;
  demographicId: string;
  resultJsonObj: any = {};
  loggedInUserEmail: string;
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  attachmentDocment: any = "";
  isChecked: boolean = false;
  fileToUpload: File = null;
  fSize: any;
  documentsLength: boolean = false;
  documentsList: any = [];
  hippaFile1: any;
  hippaFile2: any;
  hippaFile3: any;
  emailLogTxt: any = "";
  fileDesTxt: any = "";
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  selectedDocsId: any = [];
  selectedHippaUrls: any = [];

  statesArre: any = [];

  stateSelectDisabledOption: boolean = true;
  citySelectDisabledOption: boolean = true;
  physicianSelectDisabledOption: boolean = true;

  cityLength: boolean = false;
  noCityLength: boolean = false;
  citiesList: any = [];

  physicianLength: boolean = false;
  noPhysicianLength: boolean = false;
  physiciansList: any = [];
  selectedPhysician: any = {};
  selectedStateCode: any;
  selectedCityName: any;
  selectedPhysicianId: any;
  docInfo: any = {};
  showDocumentModal: boolean = false;
  choosedFileName: any = "";

  showNewFile: number = 0;
  attachmentDoc: any = {};
  message = Message;
  showLoader = false;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    private uploadService: UploadService
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.docInfo.state = "";
    this.docInfo.city = "";
    this.docInfo.physicianName = "";
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get("demographicId");
    this.userId = this.activatedRoute.snapshot.paramMap.get("userId");

    const finalData = {
      loggedInUserId: this.userId,
      company_slug: this.companySlug,
    };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findMailingDocsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList = apiResponse.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {}
      );

    const finalHippaData = {
      id: this.userId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_findUserDemographicApi,
        finalHippaData,
        this.httpOptions
      )
      .subscribe(
        (apiHippaResponse: any) => {
          this.hippaFile1 =
            apiHippaResponse.commandResult.data.userInfo.form1PdfFileNameUrl;
          this.hippaFile2 =
            apiHippaResponse.commandResult.data.userInfo.form2PdfFileNameUrl;
          this.hippaFile3 =
            apiHippaResponse.commandResult.data.userInfo.form3PdfFileNameUrl;
        },
        (err) => {}
      );

    const finalCityData = {
      id: this.userId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findPhysicianCitiesApi,
        finalCityData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.statesArre = apiResponse.commandResult.data.statesList;
        },
        (err) => {}
      );
  }

  clearSelectedDocs() {
    this.selectedDocsId = [];
  }

  async checkAllHippaDoc(isChecked) {
    if (isChecked == 1) {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.confirmationFormComp.checkAllHipaaDoc
      );
      if (alertStatus) {
        $(".docChecks").each(function () {
          $(this).prop("checked", true);
        });
        $(".hippaChecks").each(function () {
          $(this).prop("checked", true);
        });
      } else {
        this.selectedDocsId = [];
        $(".selectAll").prop("checked", false);
        $(".docChecks").each(function () {
          $(this).prop("checked", false);
        });
        $(".hippaChecks").each(function () {
          $(this).prop("checked", false);
        });
      }
    } else {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.confirmationFormComp.checkAllHipaaDoc1
      );
      if (alertStatus) {
        this.selectedDocsId = [];
        $(".selectAll").prop("checked", false);
        $(".docChecks").each(function () {
          $(this).prop("checked", false);
        });
        $(".hippaChecks").each(function () {
          $(this).prop("checked", false);
        });
      } else {
        $(".selectAll").prop("checked", true);
      }
    }
  }

  getCheckedBoxValue() {
    let allCheck: any = document.getElementsByClassName("docChecks");
    for (let index = 0; index < allCheck.length; index++) {
      const element = allCheck[index];
      if (element.checked) {
        this.selectedDocsId.push(element.value);
      }
    }
  }


  async onReset() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminContactUsComp.goToDemographicsComp
    );
    if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/demographics/" + this.loggedInUserId,
      ]);
    }
  }

  onFileSelected(event) {
    this.showNewFile = 1;
    this.attachmentDocment = "";
    if (this.fileUploaded.nativeElement.value != "") {
      this.fileToUpload = <File>event.target.files.item(0);
      this.fSize = Math.round(this.fileToUpload.size / 1024);
    } else {
    }
  }


  private encodeFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result == 'string') {
          resolve(reader.result.split(',')[1]);
        } else {
          reject(new Error('FileReader returned a non-string result'));
        }
      };
      reader.onerror = error => reject(error);
    });
  }

  async onSubmit() {
    let altEmail = "";

    if (this.docInfo.altEmail) {
      altEmail = this.docInfo.altEmail;
    }
    this.getCheckedBoxValue();
    if (this.selectedDocsId.length <= 0) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminUploadComp.uploadFile
      );
      return false;
    } else if (this.selectedDocsId.length > 5) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_2
      );
      return false;
    } else if (
      this.showNewFile == 1 &&
      this.fileUploaded.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload.name)
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4

      );
      return false;
    } else if (
      this.showNewFile == 1 &&
      this.fileUploaded.nativeElement.value != "" &&
      this.fSize > 61440
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.modifyDocComp.onSubmit1
      );
      return false;
    } else {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.demographicsComp.email2All
      );
      {
        const jsonData: any = {
          loggedInUserId: this.loggedInUserId,
          userId: this.userId,
          personName: this.selectedPhysician.PhysicianName,
          personEmail: this.selectedPhysician.PhysicianEmail,
          selectedDocsId: this.selectedDocsId,
          selectedHippaUrls: this.selectedHippaUrls,
          companySlug: this.companySlug,
          altEmail: altEmail,
          selectedStateCode: this.selectedStateCode,
          selectedCityName: this.selectedCityName,
          selectedPhysicianId: this.selectedPhysicianId,
          emailLogTxt: this.emailLogTxt,
          fileDesTxt: this.fileDesTxt,
          docType: ConstantHelper.DOC_TYPE_ADMIN_EMAILED,
          comapnyId: localStorage.getItem("loggedInUserComapnyId"),
          showNewFile: this.showNewFile,
          attachmentDocObj: this.attachmentDoc,
        }
        
        if (this.fileToUpload) {
          console.log("fileToUpload: ", this.fileToUpload.name);
          const file3Content = await this.encodeFileToBase64(this.fileToUpload);
          jsonData.fileKey = { name: this.fileToUpload.name, content: file3Content };
        }

        this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.addminSendDocs,
        jsonData, {
          reportProgress: true,
          observe: "events",
        }
      )
          .subscribe(
            async (data: any) => {
              this.showLoader = true;
              if (data !== undefined) {
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.adminsecondoptwfCOmp.d2d04_7
                );

                this.router.navigate([
                  "/" +
                    this.childRoute +
                    "/demographics/" +
                    this.loggedInUserId,
                ]);
                if (data.commandResult.success == 1) {
                }
              }
            },
            (error) => {}
          );
      }
    }
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "pdf"
    ) {
      return true;
    } else {
      return false;
    }
  }

  findCitiesByStateCode(stateCode) {
    this.selectedStateCode = stateCode;
    this.stateSelectDisabledOption = false;
    this.citiesList = [];

    const finalData = { stateCode: stateCode };
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.citySelectDisabledOption = true;
          if (apiResponse.commandResult.status == 1) {
            if (apiResponse.commandResult.data.cityList.length > 0) {
              this.cityLength = true;
              this.citiesList = apiResponse.commandResult.data.cityList;
            } else {
              this.noCityLength = true;
            }
          } else {
            this.noCityLength = true;
          }
        },
        (err) => {}
      );
  }

  findPhysicianByStateCode(cityName) {
    this.selectedCityName = cityName;

    this.citySelectDisabledOption = false;
    const finalDataPhysician = { cityName: cityName };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          if (apiResponsePhysician.commandResult.status == 1) {
            this.physicianSelectDisabledOption = true;
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.physicianLength = true;
              this.physiciansList =
                apiResponsePhysician.commandResult.data.physiciansList;
            } else {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminsecondoptwfCOmp
                  .findcoePhysicians
              );
              this.noPhysicianLength = true;
            }
          } else {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findcoePhysicians
            );
            this.noPhysicianLength = true;
          }
        },
        (err) => {}
      );
  }

  updatePhysicianInfo(physicianId) {
    this.selectedPhysicianId = physicianId;

    this.physicianSelectDisabledOption = false;

    this.selectedPhysician = this.physiciansList.find(
      (e) => e.RecordId == physicianId
    );
  }


  setAttachment(documentId) {
    this.fileUploaded.nativeElement.value = "";
    this.showNewFile = 2;
    const docObj = this.documentsList.find((e) => e.RecordId == documentId);
    this.attachmentDoc = docObj;
    this.choosedFileName = JSON.parse(this.attachmentDoc).DocumentName;
  }

  closeDocumentModal() {
    this.showDocumentModal = false;
  }

  openDocumentModal() {
    this.showDocumentModal = true;
  }
}
