import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { DoctorMailService } from "../../_services/doctor-mail.service";
import { UserService } from "../../_services/user.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import {AzureBlobService} from "../../_services/blob.service";
@Component({
  selector: "app-admin-d2d-review-log",
  templateUrl: "./admin-d2d-review-log.component.html",
  styleUrls: ["./admin-d2d-review-log.component.scss"],
})
export class AdminD2dReviewLogComponent implements OnInit {
  @ViewChild("fileUploaded1", { static: false }) fileUploaded1: ElementRef;
  @ViewChild("fileUploaded2", { static: false }) fileUploaded2: ElementRef;
  @ViewChild("fileUploaded3", { static: false }) fileUploaded3: ElementRef;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  showLoader = false;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  childRoute: string;
  pId: string;
  logId: string;
  loggedInUserId: string;
  userOptionId: string;
  logDetails: any = {};
  behaviourId;
  maxChars = 500;
  showDataVal: any;
  message = Message;
  logDetailsDocLength: any;
  fileToUpload1: File = null;
  fSize1: any;
  fileToUpload2: File = null;
  fSize2: any;
  fileToUpload3: File = null;
  fSize3: any;
  fileKey1: any;
  fileKey2: any;
  fileKey3: any;
  requestingDocumnets: any;
  referredDocuments: any;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private uploadService: UploadService,
    public sweetAlertHelper: SweetAlertHelper,
    private doctorMailService: DoctorMailService,
    public userService: UserService,
    private azureBlobService: AzureBlobService
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.pId = this.activatedRoute.snapshot.paramMap.get("pId");
    this.logId = this.activatedRoute.snapshot.paramMap.get("logId");
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.userOptionId =
      this.activatedRoute.snapshot.paramMap.get("userOptionId");
    this.behaviourId = this.activatedRoute.snapshot.paramMap.get("behaviourId");
    this.showDataVal = this.activatedRoute.snapshot.paramMap.get("showDataVal");
  }
  ngOnInit() {
    this.findD2DLogDetail(this.logId);
  }

  findD2DLogDetail(logId) {
    const finalData = {
      logId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.findD2DLogDetail,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.logDetailsDocLength = res.commandResult.data.log.Documents.length;
          this.logDetails = res.commandResult.data.log;
          const documents = res.commandResult.data.log.Documents;
          const referredDoc = documents.filter(doc => doc.ReferredPhysicianDocs == "1");

          // Filter documents where ReferredPhysicianDocs is "0"
          const requestingDoc = documents.filter(doc => doc.ReferredPhysicianDocs == "0");
          // Assign them to separate variables
          this.referredDocuments = referredDoc;
          this.requestingDocumnets = requestingDoc;

          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  private encodeFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result == 'string') {
          resolve(reader.result.split(',')[1]);
        } else {
          reject(new Error('FileReader returned a non-string result'));
        }
      };
      reader.onerror = error => reject(error);
    });
  }
  
  onFileSelected1(event) {
    if (this.fileUploaded1.nativeElement.value != "") {
      this.fileToUpload1 = event.target.files.item(0) as File;
      this.fSize1 = Math.round(this.fileToUpload1.size / 1024);
    }
  }
  
  onFileSelected2(event) {
    if (this.fileUploaded2.nativeElement.value != "") {
      this.fileToUpload2 = event.target.files.item(0) as File;
      this.fSize2 = Math.round(this.fileToUpload2.size / 1024);
    }
  }
  
  onFileSelected3(event) {
    if (this.fileUploaded3.nativeElement.value != "") {
      this.fileToUpload3 = event.target.files.item(0) as File;
      this.fSize3 = Math.round(this.fileToUpload3.size / 1024);
    }
  }
  
  async onSubmit() {
    const jsonData: any = {
      logId: this.logId,
      emailText: this.logDetails.EmailText,
      behaviourId: this.behaviourId,
      docType: 11,
      loggedInUserId:this.loggedInUserId
      
    };
  
    if (this.fileToUpload1) {
      console.log("fileToUpload1: ", this.fileToUpload1.name);
      const file1Content = await this.encodeFileToBase64(this.fileToUpload1);
      jsonData.fileKey1 = { name: this.fileToUpload1.name, content: file1Content };
    }

    if (this.fileToUpload2) {
      console.log("fileToUpload2: ", this.fileToUpload2.name);
      const file2Content = await this.encodeFileToBase64(this.fileToUpload2);
      jsonData.fileKey2 = { name: this.fileToUpload2.name, content: file2Content };
    }

    if (this.fileToUpload3) {
      console.log("fileToUpload3: ", this.fileToUpload3.name);
      const file3Content = await this.encodeFileToBase64(this.fileToUpload3);
      jsonData.fileKey3 = { name: this.fileToUpload3.name, content: file3Content };
    }
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl06() + ApiHelper.sendD2dResponse,
        jsonData, {
          reportProgress: true,
          observe: "events",
        }
      )
      .subscribe(
        async (res: any) => {
          this.showLoader = false;
          const alertStatus = await this.sweetAlertHelper.alertPopUp(
            this.message.alertMessages.admind2dreviewlogComp.onSubmit
          );
  
          if (alertStatus)
            this.router.navigate([
              "/" +
              this.childRoute +
              "/transfer-log/" +
              this.loggedInUserId +
              "/" +
              this.showDataVal,
            ]);
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
  }
  
  async back() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Confirm your desire to return to the transfer log. "
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.admind2dreviewlogComp.back
    // );
    // if (alertStatus) {
      this.router.navigate([
        "/" +
          this.childRoute +
          "/transfer-log/" +
          this.loggedInUserId +
          "/" +
          this.showDataVal,
      ]);
    // }
  }

 async goToDashboard() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to physician Home Page?"
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminChangePassComp.onReset
    // );
    // if (alertStatus)
    //  {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
    // }
  }
}
