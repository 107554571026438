/* tslint:disable:max-line-length */
import {Component, OnInit, Input} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {AppConfig} from './../../../app/app.config';
import {UserService} from './../../_services/user.service';
import {SweetAlertHelper} from 'src/app/_helpers/sweet.alert.helper';
import {Message} from 'src/app/_locale/message';

@Component({
  selector: 'referral-sent',
  templateUrl: './referral-sent.component.html',
  styleUrls: ['./referral-sent.component.scss'],
})
export class ReferralSent implements OnInit {
  @Input() selectedSentEntries!: any;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  childRoute: string;
  loggedInUserId: string;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  showDataVal: string;
  logOne: any = [];
  logTwo: any = [];
  logThree: any = [];
  logFour: any = [];
  logFive: any = [];
  selectedPatient: any = {};
  selectedRequesting: any = {};
  loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');
  message = Message;



  doctorToDoctorEmailReadArray: any = [];


  expertPhysicianOpinionNotRespondedFlag = false;
  doctorToDoctorEmailRespondedFlag = false;
  doctorToDoctorEmailNotRespondedFlag = false;
  doctorToDoctorEmailReadFlag = false;
  secondOpinionLogExpertAllFlag = false;
  OpinionLogRequesteAllFlag = false;
  expertPhysicianOpinionAllFlag = false;
  doctorToDoctorEmailAllFlag = false;

  referralSentDeclined: any = [];
  referralSentApproved: any = [];
  referralSentAwaiting: any = [];
  referralSentRequireMoreInfo: any = [];

  showTableData = 0;
  showLoader = false;
  delegateId = localStorage.getItem('delegateId');
  aknowledgmentEmail = '';
  aknowledgmentLogId = '';
  emailSubject: any = '';
  dtOptions: DataTables.Settings = {
    order: [[2, 'desc']]
  };

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    public sweetAlertHelper: SweetAlertHelper,
    private router: Router,
    private http: HttpClient,
    public userService: UserService
  ) {
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.showDataVal = this.activatedRoute.snapshot.paramMap.get('showDataVal');
    if (this.delegateId) {
      this.findTransferLog(this.delegateId, this.showDataVal);
    } else {
      this.findTransferLog(this.loggedInUserId, this.showDataVal);
    }
    this.doctorToDoctorEmailReadFlag = true;
    this.dtOptions = {
      searching: false,
      paging: false,
      pageLength: 5,
      info: false
    };
    this.showData(2);
  }

  async goToDemographics() {
      this.router.navigate([
        '/' + this.childRoute + '/demographics/' + this.loggedInUserId,
      ]);
  }

  async onReset() {
      this.router.navigate([
        '/' + this.childRoute + '/dashboard/' + this.loggedInUserId,
      ]);
  }

  showData(selectedVal) {
    this.showExpertPhysicianOpinionLogRequest(this.showTableData);
    this.showDataVal = selectedVal;
    if (selectedVal != 5) {
      this.findTransferLog(this.loggedInUserId, this.showDataVal);
    }
    if (selectedVal == 5) {
      this.findTransferredLog(this.loggedInUserId);
    }
  }

  findTransferredLog(userId) {
    if (this.delegateId) {
      userId = this.delegateId;
    }
    const finalData = {
      userId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findTransferLogApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.showLoader = false;
          if (res.commandResult.data.logs.length > 0) {
            this.logFive = res.commandResult.data.logs;
          }
        },
        (err) => {}
      );
  }

  findTransferLog(userId, logType) {
    if (this.delegateId) {
      userId = this.delegateId;
    }
    const finalData = {
      userId,
      logType,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findOpinionLogApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.showLoader = false;
          if (res.commandResult.data.logs.length > 0) {
              this.logTwo = res.commandResult.data.logs;
              const separatedArrays = res.commandResult.data.logs.reduce(
                (acc: any, item:any) => {
                  if (item.CurrentStatus == 0) {
                    acc.declined.push(item);
                  } else if (item.CurrentStatus == 1) {
                    acc.approved.push(item);
                  } else if (item.CurrentStatus == 2) {
                    acc.requireMoreInfo.push(item);
                  } else if (item.CurrentStatus == 3 || item.CurrentStatus == 4) {
                    acc.awaiting.push(item);
                  }
                  return acc;
                },
                {declined: [], approved: [],  requireMoreInfo: [], awaiting: []}
              );
              this.referralSentDeclined = separatedArrays.declined;
              this.referralSentApproved = separatedArrays.approved;
              this.referralSentAwaiting = separatedArrays.awaiting;
              this.referralSentRequireMoreInfo = separatedArrays.requireMoreInfo;
          }
        },
        (err) => {}
      );
  }

  viewDouments(logDetails, showDataVal) {
    this.router.navigate([
      '/' +
        this.childRoute +
        '/user-docs/' +
        logDetails.UniqueKey +
        '/' +
        logDetails.RecordId +
        '/' +
        this.loggedInUserId +
        '/' +
        logDetails.UserOptionId +
        '/' +
        showDataVal,
    ]);
  }

  sendAcknowledgement(logDetails, showDataVal, behaviourId) {
    this.router.navigate([
      '/' +
        this.childRoute +
        '/view-docs-acknowledgement/' +
        logDetails.UniqueKey +
        '/' +
        logDetails.RecordId +
        '/' +
        this.loggedInUserId +
        '/' +
        logDetails.UserOptionId +
        '/' +
        behaviourId +
        '/' +
        showDataVal,
    ]);
  }

  reviewLog(logDetails, showDataVal, behaviourId) {
    if(showDataVal == 1) {
      const reqData = {
      recordId: logDetails.RecordId,
      hasReadFlag: 1
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_sendD2dResponseApi,
        reqData,
        this.httpOptions
      )
      .subscribe(
        async (res: any) => {
          this.showLoader = false;
        },
        (err) => {}
      );
    }

    this.router.navigate([
      "/" +
        this.childRoute +
        "/reply-d2d/" +
        logDetails.UniqueKey +
        "/" +
        logDetails.RecordId +
        "/" +
        this.loggedInUserId +
        "/" +
        logDetails.UserOptionId +
        "/" +
        behaviourId +
        "/" +
        showDataVal,
    ]);
  }

  async fnTransferLog(logDetail) {
    let cnfMsg = "";
    let altMsg = "";

    if (logDetail.IsTransferred == 0) {
      cnfMsg = this.message.confirmMessages.adminTransferLogComp.fnTransferLog;
    } else {
      cnfMsg = this.message.confirmMessages.adminTransferLogComp.fnTransferLog1;
    }

    const confirmStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      cnfMsg
    );

    if (logDetail.IsTransferred == 0) {
      altMsg = this.message.alertMessages.adminTransferLogComp.fnTransferLog;
    } else {
      altMsg = this.message.alertMessages.adminTransferLogComp.fnTransferLog1;
    }

    if (confirmStatus) {
      const finalData = {
        logId: logDetail.RecordId,
        loggedInUserId: this.loggedInUserId,
        isTransferred: logDetail.IsTransferred,
      };
      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_transferOpinionLogApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (res: any) => {
            this.showLoader = false;
            this.sweetAlertHelper.alertPopUp(altMsg);
            window.location.reload();
          },
          (err) => {}
        );
    }
  }



  showExpertPhysicianOpinionLogRequest(value) {
    if (value == 0){
      this.showTableData = value;
      this.expertPhysicianOpinionAllFlag = true;
      this.expertPhysicianOpinionNotRespondedFlag = false;
    } else if (value == 1) {
      this.showTableData = value;
      this.expertPhysicianOpinionAllFlag = false;
      this.expertPhysicianOpinionNotRespondedFlag = false;
    } else if (value == 2) {
      this.showTableData = value;
      this.expertPhysicianOpinionAllFlag = false;
      this.expertPhysicianOpinionNotRespondedFlag = true;
    } else if (value == 3) {
      this.showTableData = value;
      this.expertPhysicianOpinionAllFlag = false;
      this.expertPhysicianOpinionNotRespondedFlag = false;
    }
  }

  calculateDaysDifference(requestDate: string): number {
    const providedDate = new Date(requestDate);
  providedDate.setHours(0, 0, 0, 0); 
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const differenceInTime = currentDate.getTime() - providedDate.getTime();
  const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24));
  return differenceInDays;

  }

  resendreferral(logDetails, showDataVal, patientId) {
    if(showDataVal == 1) {
      const reqData = {
      recordId: logDetails.RecordId,
      hasReadFlag: 1
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_sendD2dResponseApi,
        reqData,
        this.httpOptions
      )
      .subscribe(
        async (res: any) => {
          this.showLoader = false;
        },
        (err) => {}
      );
    }
 
    this.router.navigate([
      "/" +
        this.childRoute +
        "/referral-resend/" +
        this.loggedInUserId +
        "/" +
        patientId + 
        "/" +
        logDetails.RecordId +
        '/1'
    ]);
  }
}
