import { DualListComponent } from "angular-dual-listbox";

// import { Validators } from "@angular/forms";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { UploadService } from "../../_services/upload.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";

@Component({
  selector: "app-admin-dual-list",
  templateUrl: "./admin-dual-list.component.html",
  styleUrls: ["./admin-dual-list.component.scss"],
})
export class AdminDualListComponent implements OnInit {
  loggedInUserId: string;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  createNewGroup = false;
  documentsLength = false;
  documentsList: any;
  documentsLengthRight = false;
  documentsListRight: any = [];
  groupsLength = false;
  groupsList: any;
  recordId: any;
  docReason: any;
  // recordId: number;
  selectedDocGroupId: any = 0;
  groupName = "";
  childRoute: string;
  userRoleId: number;
  isDefaultGroupDisabled = false;
  email: any = "";
  recipientEmail: any = "";
  recipientName: any = "";
  document_group_id: any;
  showLoader = false;
  showComfirmationModal = false;
  admindualObj: any = {};
  descriptionText = "";
  companySlug: string = ConstantHelper.COMPANY_SLUG;

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;
  requiredForm: FormGroup;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    public sweetAlertHelper: SweetAlertHelper,
    private uploadService: UploadService
  ) {
    // console.log(activatedRoute.snapshot.url); // array of states
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  tab = 1;
  keepSorted = true;
  key: string;
  display: any;
  filter = false;
  source: Array<any>;
  confirmed: Array<any>;
  userAdd = "";
  disabled = false;
  enabled = false;
  groupType = "";
  sourceLeft = true;
  initialConfirmedState: any[] = [];
  // format: any = DualListComponent.DEFAULT_FORMAT;
  // format: any = DualListComponent.DEFAULT_FORMAT;
  format = {
    add: "Add a Document to a Group",
    remove: "Remove a Document from a Group",
    all: "Select all",
    none: "Unselect all",
    direction: "left-to-right",
    draggable: true,
    locale: undefined,
  };

  selectedDoc: any;

  private sourceTube: Array<string>;
  private sourceStations: Array<any>;
  private sourceChessmen: Array<any>;

  private confirmedTube: Array<string>;
  private confirmedStations: Array<any>;
  private confirmedChessmen: Array<any>;

  private chessmen: Array<any>;

  arrayType = [
    { name: "Chessmen", detail: "(object array)", value: "chess" },
    // { name: 'Rio Grande', detail: '(object array)', value: 'station' },
    // { name: 'Underground', detail: '(string array)', value: 'tube' }
  ];

  type = this.arrayType[0].value;

  private stations: Array<any> = [
    { key: 1, station: "Antonito", state: "CO" },
    { key: 2, station: "Big Horn", state: "NM" },
    { key: 3, station: "Sublette", state: "NM" },
    { key: 4, station: "Toltec", state: "NM" },
    { key: 5, station: "Osier", state: "CO" },
    { key: 6, station: "Chama", state: "NM" },
    { key: 7, station: "Monero", state: "NM" },
    { key: 8, station: "Lumberton", state: "NM" },
    { key: 9, station: "Duice", state: "NM" },
    { key: 10, station: "Navajo", state: "NM" },
    { key: 11, station: "Juanita", state: "CO" },
    { key: 12, station: "Pagosa Jct", state: "CO" },
    { key: 13, station: "Carracha", state: "CO" },
    { key: 14, station: "Arboles", state: "CO" },
    { key: 15, station: "Solidad", state: "CO" },
    { key: 16, station: "Tiffany", state: "CO" },
    { key: 17, station: "La Boca", state: "CO" },
    { key: 18, station: "Ignacio", state: "CO" },
    { key: 19, station: "Oxford", state: "CO" },
    { key: 20, station: "Florida", state: "CO" },
    { key: 21, station: "Bocea", state: "CO" },
    { key: 22, station: "Carbon Jct", state: "CO" },
    { key: 23, station: "Durango", state: "CO" },
    { key: 24, station: "Home Ranch", state: "CO" },
    { key: 25, station: "Trimble Springs", state: "CO" },
    { key: 26, station: "Hermosa", state: "CO" },
    { key: 27, station: "Rockwood", state: "CO" },
    { key: 28, station: "Tacoma", state: "CO" },
    { key: 29, station: "Needleton", state: "CO" },
    { key: 30, station: "Elk Park", state: "CO" },
    { key: 31, station: "Silverton", state: "CO" },
    { key: 32, station: "Eureka", state: "CO" },
  ];

  private tube: Array<string> = [
    "Harrow & Wealdstone",
    "Kenton",
    "South Kenton",
    "North Wembley",
    "Wembley Central",
    "Stonebridge Park",
    "Harlesden",
    "Willesden Junction",
    "Kensal Green",
    "Queen's Park",
    "Kilburn Park",
    "Maida Vale",
    "Warwick Avenue",
    "Paddington",
    "Edgware Road",
    "Marylebone",
    "Baker Street",
    "Regent's Park",
    "Oxford Circus",
    "Piccadilly Circus",
    "Charing Cross",
    "Embankment",
    "Waterloo",
    "Lambeth North",
    "Elephant & Castle",
  ];

  somedata: any = [
    { RecordId: 1, DocumentName: "Pawn" },
    { RecordId: 2, DocumentName: "Rook" },
    { RecordId: 3, DocumentName: "Knight" },
    { RecordId: 4, DocumentName: "Bishop" },
    { RecordId: 5, DocumentName: "Queen" },
    { RecordId: 6, DocumentName: "King" },
  ];

  ngOnInit() {
    // console.log('typeof ', typeof this.somedata)

    // console.log('typeofchessmen ', typeof this.chessmen)

    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    const finalDataDocGroup = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_findDocGroupsByCompanyApi,
        finalDataDocGroup,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.data.groupList.length > 0) {
            this.groupsLength = true;
            this.groupsList = apiResponse.commandResult.data.groupList;
            this.groupsList.forEach((obj) => {
              if (obj.GroupType == "1") {
                this.recordId = obj.RecordId;
              }
            });
          } else {
            this.groupsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );

    const finalDataDocList = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl02() +
          ApiHelper.name_getAdminDocumentsListApi,
        finalDataDocList,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList = apiResponse.commandResult.data.documentList;
            this.chessmen = this.documentsList;
            this.doReset();
            // console.log('typeofdocumentsList ', typeof this.documentsList)

            // console.log(this.chessmen);
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  private stationLabel(item: any) {
    return item.station + ", " + item.state;
  }

  private useStations() {
    this.key = "key";
    this.display = this.stationLabel;
    this.keepSorted = true;
    this.source = this.sourceStations;
    this.confirmed = this.confirmedStations;
  }

  private useChessmen() {
    this.key = "RecordId";
    this.display = "DocumentName";
    this.keepSorted = false;
    this.source = this.sourceChessmen;
    this.confirmed = this.confirmedChessmen;
  }

  private useTube() {
    this.key = undefined;
    this.display = undefined;
    this.keepSorted = false;
    this.source = this.sourceTube;
    this.confirmed = this.confirmedTube;
  }

  swapSource() {
    // console.log('swapSource');
    switch (this.type) {
      case this.arrayType[0].value:
        this.useChessmen();
        break;
      case this.arrayType[1].value:
        this.useStations();

        break;
      case this.arrayType[2].value:
        this.useTube();
        break;
    }
  }

  doReset() {
    // console.log('doReset');
    this.sourceChessmen = JSON.parse(JSON.stringify(this.chessmen));
    this.sourceStations = JSON.parse(JSON.stringify(this.stations));
    this.sourceTube = JSON.parse(JSON.stringify(this.tube));
    this.confirmedChessmen = new Array<any>();
    this.confirmedStations = new Array<any>();
    this.confirmedTube = new Array<string>();

    // Preconfirm some items.
    this.confirmedStations.push(this.stations[31]);
    this.confirmedTube.push(this.tube[13]);
    this.confirmedTube.push(this.tube[23]);

    switch (this.type) {
      case this.arrayType[0].value:
        this.useChessmen();
        break;
      case this.arrayType[1].value:
        this.useStations();
        break;
      case this.arrayType[2].value:
        this.useTube();
        break;
    }
  }

  doDelete() {
    // console.log('doDelete');
    if (this.source.length > 0) {
      this.source.splice(0, 1);
    }
  }

  doCreate() {
    // console.log('doCreate');
    if (typeof this.source[0] === "object") {
      const o = {};
      o[this.key] = this.source.length + 1;
      o[this.display] = this.userAdd;
      this.source.push(o);
    } else {
      this.source.push(this.userAdd);
    }
    this.userAdd = "";
  }

  doAdd() {
    // console.log('doAdd');
    for (let i = 0, len = this.source.length; i < len; i += 1) {
      const o = this.source[i];
      const found = this.confirmed.find((e: any) => e === o);
      if (!found) {
        this.confirmed.push(o);
        break;
      }
    }
  }

  doRemove() {
    // console.log('doRemove');
    if (this.confirmed.length > 0) {
      this.confirmed.splice(0, 1);
    }
  }

  doFilter() {
    // console.log('doFilter');
    this.filter = !this.filter;
  }

  filterBtn() {
    // console.log('filterBtn');
    return this.filter ? "Hide Filter" : "Show Filter";
  }

  doDisable() {
    // console.log('doDisable');
    this.disabled = !this.disabled;
  }

  disableBtn() {
    // console.log('disableBtn');
    return this.disabled ? "Enable" : "Disabled";
  }

  swapDirection() {
    // console.log('swapDirection');
    this.sourceLeft = !this.sourceLeft;
    this.format.direction = this.sourceLeft
      ? DualListComponent.LTR
      : DualListComponent.RTL;
  }

  findGroupDocs(event) {
    let res = event.target.value.split("*");
    if (res[1] == null || res[1] == "undefined") {
      this.groupType = "";
    } else {
      this.groupType = res[1];
    }
    this.doReset();
    this.selectedDocGroupId = res[0];
    // console.log(res[0]);
    if (res[0] == 0) {
      this.createNewGroup = true;
      this.groupType = "";
      this.groupName = "";
    } else {
      const finalData = {
        loggedInUserId: this.loggedInUserId,
        company_slug: ConstantHelper.COMPANY_SLUG,
        doc_group_id: res[0],
      };

      this.http
        .post(
          this.appConfig.getLambdaUrl03() + ApiHelper.name_findDocsByGroupIdApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            // console.log(apiResponse);
            if (apiResponse.commandResult.data.documentsListRight.length > 0) {
              this.documentsLengthRight = true;
              this.documentsListRight =
                apiResponse.commandResult.data.documentsListRight;
              this.confirmed = this.documentsListRight;
              this.initialConfirmedState = [...this.confirmed];
              this.groupName =
                apiResponse.commandResult.data.groupInfo.GroupTitle;
              this.descriptionText =
                apiResponse.commandResult.data.groupInfo.GroupDescription;
            } else {
              this.confirmed = [];
              this.documentsLengthRight = false;
            }
          },
          (err) => {
            // console.log(err);
          }
        );
      this.createNewGroup = false;
    }
  }

  async saveDocGroup() {
    if (this.groupName == "") {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminDuallistComp.saveDocGroup
      );
      return false;
    } else if (this.descriptionText == "") {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminDuallistComp.saveDocGroupDes
      );
      return false;
    } else if (this.groupType.length <= 0) {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminDuallistComp.saveDocGroup1
      );
      return false;
    } else if (this.confirmed.length <= 0) {
      await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminDuallistComp.saveDocGroup2
      );
      return false;
    } else if (
      JSON.stringify(this.confirmed) ===
      JSON.stringify(this.initialConfirmedState)
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminDuallistComp.noChangesMade
      );
      return false;
    } else {
      // if (confirm("PLEASE CONFIRM: Click OK to save your document changes."))
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.admindualListComp.saveDocGroup
      );
      if (alertStatus) {
        const finalData = {
          userId: this.loggedInUserId,
          companySlug: ConstantHelper.COMPANY_SLUG,
          descriptionText: this.descriptionText,
          groupId: +this.selectedDocGroupId,
          groupName: this.groupName,
          groupType: this.groupType,
          docsArray: this.confirmed,
        };
        this.http
          .post(
            this.appConfig.getLambdaUrl06() + ApiHelper.saveDocGroup,
            finalData,
            this.httpOptions
          )
          .subscribe(
            async (apiResponse: any) => {
              if (apiResponse.commandResult.status === 0) {
                await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.adminDuallistComp
                    .docGrpAlreadyExist
                );
                window.location.reload();
              } else {
                this.initialConfirmedState = [...this.confirmed];
                await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.adminDuallistComp.saveDocGroup3
                );
                window.location.reload();
              }
            },
            (err) => {
              // console.log(err);
            }
          );
      }
    }
  }

  async goToDocuments() {
      this.router.navigate([
        "/" + this.childRoute + "/documents/" + this.loggedInUserId,
      ]);
  }

  closeComfirmationModal() {
    this.showComfirmationModal = false;
    location.reload();
  }

  async submitReason() {
    if (!this.admindualObj.request_reason) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminDuallistComp.submitReason
      );
      return;
    }
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.admindualListComp.submitReason
    );
    if (alertStatus) {
      const httpHeaders = new HttpHeaders({
        "Content-Type": "application/json",
      });
      const options = {
        headers: httpHeaders,
      };
      const finalData = {
        loggedInUserId: this.loggedInUserId,
        company_slug: this.companySlug,
        document_group_id: this.recordId,
        request_reason: this.admindualObj.request_reason,
      };
      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.docGroupRequest,
          finalData,
          options
        )
        .subscribe(
          async (apiResponse: any) => {
            if (apiResponse.commandResult.success === -1) {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminInvitePatientComp.onSubmit
              );
            } else {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminDuallistComp.sentEmail
              );
              if (alertStatus) {
                location.reload();
              }
            }
            this.showLoader = false;
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async setGroupType() {
    if (this.groupType === "1") {
        this.showComfirmationModal = true;
        return;
    }
  }

}
