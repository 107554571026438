import { BrowserModule } from '@angular/platform-browser';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { DatePipe } from '@angular/common';

import { SweetAlertHelper } from './_helpers/sweet.alert.helper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import {ColorFooterComponent} from './color-footer/color-footer.component'

import * as $ from 'jquery';

import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { DemographicsformComponent } from './user/demographicsform/demographicsform.component';
import { HippoReleaseNavigationComponent } from './user/hippo-release-navigation/hippo-release-navigation.component';
import { HippoReleaseFormComponent } from './user/hippo-release-form/hippo-release-form.component';
import { WaiverDisclaimerNavigationComponent } from './user/waiver-disclaimer-navigation/waiver-disclaimer-navigation.component';
import { WaiverDisclaimerFormComponent } from './user/waiver-disclaimer-form/waiver-disclaimer-form.component';
import { SignatureWaiverComponent } from './user/signature-waiver/signature-waiver.component';
import { DocumentThoubsComponent } from './user/document-thoubs/document-thoubs.component';
import { CommonHelper } from './_helpers/common.helper';
import { AppConfig } from './app.config';
import { Editform2Component } from './user/editform2/editform2.component';
import { Editform3Component } from './user/editform3/editform3.component';
import { Editform4Component } from './user/editform4/editform4.component';
import { Editform5Component } from './user/editform5/editform5.component';
import { ThankyouComponent } from './user/thankyou/thankyou.component';
import { ForgotpasswordComponent } from './auth/forgotpassword/forgotpassword.component';
import { ChangepasswordComponent } from './auth/changepassword/changepassword.component';
import { HippaAcceptanceComponent } from './user/hippa-acceptance/hippa-acceptance.component';
import { ConfirmationformComponent } from './user/confirmationform/confirmationform.component';
import { UsersListComponent } from './admin/users-list/users-list.component';
import { ThumbMobileComponent } from './user/thumb-mobile/thumb-mobile.component';
import { AdminLoginComponent } from './auth/admin-login/admin-login.component';
import { DemographicsComponent } from './admin/demographics/demographics.component';
import { HeaderMenuComponent } from './admin/header-menu/header-menu.component';
import { AddAdminUserComponent } from './admin/add-admin-user/add-admin-user.component';
import { AdminForgotpasswordComponent } from './auth/admin-forgotpassword/admin-forgotpassword.component';
import { AdminChangepasswordComponent } from './auth/admin-changepassword/admin-changepassword.component';
import { FaqListComponent } from './admin/faq-list/faq-list.component';
import { DocumentListComponent } from './admin/document-list/document-list.component';
import { AddDocumentComponent } from './admin/add-document/add-document.component';
import { ModifyDocumentComponent } from './admin/modify-document/modify-document.component';
import { ActivityLogComponent } from './admin/activity-log/activity-log.component';
import { WelcomeComponent } from './auth/welcome/welcome.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { AdminWelcomeComponent } from './auth/admin-welcome/admin-welcome.component';
import { DocGroupComponent } from './admin/doc-group/doc-group.component';
import { AddDocGroupComponent } from './admin/add-doc-group/add-doc-group.component';
import { DashboardComponent } from './user/dashboard/dashboard.component';
import { USHFComponent } from './user/ushf/ushf.component';
import { DocReviewComponent } from './user/doc-review/doc-review.component';
import { UploaddocComponent } from './user/uploaddoc/uploaddoc.component';
import { ProfieComponent } from './user/profie/profie.component';
import { SenddocumentComponent } from './user/senddocument/senddocument.component';
import { EmailLogComponent } from './user/email-log/email-log.component';
import { ResendDocsComponent } from './user/resend-docs/resend-docs.component';
import { AdminEmailLogComponent } from './admin/admin-email-log/admin-email-log.component';
import { AdminResendDocsComponent } from './admin/admin-resend-docs/admin-resend-docs.component';

import { PageLoaderComponent } from './page-loader/page-loader.component';
import { LoaderService } from './_services/loader.service';
import { LoaderInterceptor } from './_services/http-inter-cepter.service';
import { AdminDualListComponent } from './admin/admin-dual-list/admin-dual-list.component';
import { AngularDualListBoxModule } from 'angular-dual-listbox';

import { MustMatchDirective } from './_directives/must-match.directive';
import { FaqComponent } from './user/faq/faq.component';
import { DoctorLoginComponent } from './auth/doctor-login/doctor-login.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { DoctorSignupComponent } from './auth/doctor-signup/doctor-signup.component';
import { AdminEmailDocComponent } from './admin/admin-email-doc/admin-email-doc.component';
import { AllotDocGroupComponent } from './admin/allot-doc-group/allot-doc-group.component';
import { ViewUserDocsComponent } from './admin/view-user-docs/view-user-docs.component';
import { ViewDocsComponent } from './user/view-docs/view-docs.component';
import { AdminInvitePatientComponent } from './admin/admin-invite-patient/admin-invite-patient.component';
import { ViewSignDocsComponent } from './admin/view-sign-docs/view-sign-docs.component';
import { DoctorProfileComponent } from './admin/doctor-profile/doctor-profile.component';
import { AdminProfileComponent } from './admin/admin-profile/admin-profile.component';

import { AdminUshfComponent } from './admin/admin-ushf/admin-ushf.component';
import { EditAdminUserComponent } from './admin/edit-admin-user/edit-admin-user.component';
import { UserDocsAcknowledgementComponent } from './admin/user-docs-acknowledgement/user-docs-acknowledgement.component';
import { DoctorViewDocsComponent } from './auth/doctor-view-docs/doctor-view-docs.component';
import { ViewDocsAcknowledgementComponent } from './admin/view-docs-acknowledgement/view-docs-acknowledgement.component';
import { AddPhysicianUserComponent } from './admin/add-physician-user/add-physician-user.component';
import { AdminDocReviewComponent } from './admin/admin-doc-review/admin-doc-review.component';
import { AdminSendEmailComponent } from './admin/admin-send-email/admin-send-email.component';
import { AdminUploaddocComponent } from './admin/admin-uploaddoc/admin-uploaddoc.component';
import { DoctorDocReviewComponent } from './doctor/doctor-doc-review/doctor-doc-review.component';
import { EmailAllPatientComponent } from './admin/email-all-patient/email-all-patient.component';
import { AdminSecondOpinionComponent } from './admin/admin-second-opinion/admin-second-opinion.component';
import { AdminUshf2Component } from './admin/admin-ushf2/admin-ushf2.component';
import { PracticeGroupsComponent } from './admin/practice-groups/practice-groups.component';
import { PracticeSpecialitiesComponent } from './admin/practice-specialities/practice-specialities.component';
import { PracticeSubSpecialitiesComponent } from './admin/practice-sub-specialities/practice-sub-specialities.component';
import { AdminSecondOptionWfComponent } from './admin/admin-second-option-wf/admin-second-option-wf.component';
import { AdminTransferLogComponent } from './admin/admin-transfer-log/admin-transfer-log.component';
import { AdminViewD2dDocsComponent } from './admin/admin-view-d2d-docs/admin-view-d2d-docs.component';
import { AdminAcknowledgementD2dDocsComponent } from './admin/admin-acknowledgement-d2d-docs/admin-acknowledgement-d2d-docs.component';
import { AdminReplyD2dMailComponent } from './admin/admin-reply-d2d-mail/admin-reply-d2d-mail.component';
import { AdminD2dReviewLogComponent } from './admin/admin-d2d-review-log/admin-d2d-review-log.component';
import { AdminPhysicianSchedulingComponent } from './admin/admin-physician-scheduling/admin-physician-scheduling.component';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  OWL_DATE_TIME_LOCALE,
} from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrderByPipe } from './order-by.pipe';
import { DateFilterPipe } from './date-filter.pipe';
import { Email2userComponent } from './admin/email2user/email2user.component';
import { AdminTransferReportComponent } from './admin/admin-transfer-report/admin-transfer-report.component';
import { AdminOnCallScheduleLogComponent } from './admin/admin-on-call-schedule-log/admin-on-call-schedule-log.component';
import { AdminDelegationLogComponent } from './admin/admin-delegation-log/admin-delegation-log.component';
import { ImageViewerModule } from 'ng2-image-viewer';
import { AngularImageViewerModule } from "angular-x-image-viewer";

import { ReviewSignComponent } from './user/review-sign/review-sign.component';
import { Confirmationform1Component } from './user/confirmationform1/confirmationform1.component';
import { LoaderComponent } from './loader/loader.component';
import { AdminContactUsComponent } from './admin/admin-contact-us/admin-contact-us.component';
import { AdminChangePasswordComponent } from './admin/admin-change-password/admin-change-password.component';
import { AdminSecurityQuestions } from './admin/admin-security-questions/admin-security-questions.component';

import { NjComponent } from './hipp/nj/nj.component';
import { NyComponent } from './hipp/ny/ny.component';
import { FlComponent } from './hipp/fl/fl.component';
import { PaComponent } from './hipp/pa/pa.component';
import { CtComponent } from './hipp/ct/ct.component';
import { OhComponent } from './hipp/oh/oh.component';
import { CoComponent } from './hipp/co/co.component';
import { CaComponent } from './hipp/ca/ca.component';
import { AlComponent } from './hipp/al/al.component';
import { AzComponent } from './hipp/az/az.component';
import { ArComponent } from './hipp/ar/ar.component';
import { DeComponent } from './hipp/de/de.component';
import { GaComponent } from './hipp/ga/ga.component';
import { HiComponent } from './hipp/hi/hi.component';
import { IdComponent } from './hipp/id/id.component';
import { IlComponent } from './hipp/il/il.component';
import { AkComponent } from './hipp/ak/ak.component';
import { TxComponent } from './hipp/tx/tx.component';
import { SdComponent } from './hipp/sd/sd.component';
import { NdComponent } from './hipp/nd/nd.component';
import { VaComponent } from './hipp/va/va.component';
import { VtComponent } from './hipp/vt/vt.component';
import { KyComponent } from './hipp/ky/ky.component';
import { WiComponent } from './hipp/wi/wi.component';
import { WvComponent } from './hipp/wv/wv.component';
import { TnComponent } from './hipp/tn/tn.component';
import { LaComponent } from './hipp/la/la.component';
import { MdComponent } from './hipp/md/md.component';
import { MnComponent } from './hipp/mn/mn.component';
import { MoComponent } from './hipp/mo/mo.component';
import { MsComponent } from './hipp/ms/ms.component';
import { NvComponent } from './hipp/nv/nv.component';
import { OkComponent } from './hipp/ok/ok.component';
import { WaComponent } from './hipp/wa/wa.component';
import { IaComponent } from './hipp/ia/ia.component';
import { NhComponent } from './hipp/nh/nh.component';
import { KsComponent } from './hipp/ks/ks.component';
import { OrComponent } from './hipp/or/or.component';
import { NeComponent } from './hipp/ne/ne.component';
import { MtComponent } from './hipp/mt/mt.component';
import { NcComponent } from './hipp/nc/nc.component';
import { ScComponent } from './hipp/sc/sc.component';
import { InComponent } from './hipp/in/in.component';
import { MeComponent } from './hipp/me/me.component';
import { RiComponent } from './hipp/ri/ri.component';
import { UtComponent } from './hipp/ut/ut.component';
import { NmComponent } from './hipp/nm/nm.component';
import { MaComponent } from './hipp/ma/ma.component';
import { AdminCommentsComponent } from './admin/admin-comments/admin-comments.component';
import { AddCommentComponent } from './admin/add-comment/add-comment.component';
import { AdminInvitationLogComponent } from './admin/admin-invitation-log/admin-invitation-log.component';
import { ChatComponent } from './chatBot-window/chat.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { AhsaichatgptComponent } from './ahsaichatgpt/ahsaichatgpt.component';
import { PatientHistory } from './admin/patient-history/patient-history.component';
import { UploadDocument } from './admin/upload-document/upload-document.component';
import { PrincetonSurgicalSpecialtiesPreopNote } from './practice-documents/princeton-surgical-specialties-forms/princeton-surgical-specialties-preop-note/princeton-surgical-specialties-preop-note.component';
import { PrincetonSurgicalSpecialtiesPreopInstructions } from './practice-documents/princeton-surgical-specialties-forms/princeton-surgical-specialties-preop-instructions/princeton-surgical-specialties-preop-instructions.component';
import { PrincetonSurgicalSpecialtiesHealthHistoryForm1 } from './practice-documents/princeton-surgical-specialties-forms/princeton-surgical-specialties-health-history-form-1/princeton-surgical-specialties-health-history-form-1.component';
import { PrincetonSurgicalSpecialtiesDemographics } from './practice-documents/princeton-surgical-specialties-forms/princeton-surgical-specialties-demographics/princeton-surgical-specialties-demographics.component';
import { DocFive } from './practice-documents/doc-five/doc-five.component';
import {PracticeDemographicComponent} from './practice-documents/pract-demographic/pract-demographic.component';
import { ReliantSurgicalCenterAnesthesiaQuestionnariePg2} from './practice-documents/reliant-practice-docs/reliant-surgical-center-anesthesia-questionnarie-pg2/reliant-surgical-center-anesthesia-questionnarie-pg2.comonent'
import {ReliantSurgicalCenterAnesthesiaQuestionnariePg1} from './practice-documents/reliant-practice-docs/reliant-surgical-center-anesthesia-questionnarie-pg1/reliant-surgical-center-anesthesia-questionnarie-pg1.component';
import { PrincetonSurgicalSpecialtiesHealthHistoryForm2 } from './practice-documents/princeton-surgical-specialties-forms/princeton-surgical-specialties-health-history-form-2/princeton-surgical-specialties-health-history-form-2.component';
import { PriNewPatientIntakeSignaturePage } from './practice-documents/princeton-rejuventation-institute-forms/pri-new-patient-intake-signature-page/pri-new-patient-intake-signature-page.component'
import { PriAestheticHistoryForm } from './practice-documents/princeton-rejuventation-institute-forms/pri-aesthetic-history-form/pri-aesthetic-history-form.component';
import { GreenbergCosmeticSurgeryORSchedulingForm } from './practice-documents/greenberg-cosmetic-surgery-forms/greenberg-cosmetic-surgery-or-scheduling-form/greenberg-cosmetic-surgery-or-scheduling-form.component'
import { GreenbergCosmeticSurgeryDemographics } from './practice-documents/greenberg-cosmetic-surgery-forms/greenberg-cosmetic-surgery-demographics/greenberg-cosmetic-surgery-demographics.component'
import { PriHealthHistoryForm2 } from './practice-documents/princeton-rejuventation-institute-forms/pri-health-history-form-2/pri-health-history-form-2.component';
import { PriHealthHistoryForm1 } from './practice-documents/princeton-rejuventation-institute-forms/pri-health-history-form-1/pri-health-history-form-1.component';
import { GreenbergCosmeticSurgeryHealthHistoryPage2 } from './practice-documents/greenberg-cosmetic-surgery-forms/greenberg-cosmetic-surgery-health-history-page-2/greenberg-cosmetic-surgery-health-history-page-2.component';
import { GreenbergCosmeticSurgeryHealthHistoryPage1 } from './practice-documents/greenberg-cosmetic-surgery-forms/greenberg-cosmetic-surgery-health-history-page1/greenberg-cosmetic-surgery-health-history-page1.component';
import { AdminPracticeDictationComponent } from './admin/admin-practice-dictation/admin-practice-dictation.component';
import { AcceptHipaaComponent } from './user/profile/accept-hipaa/accept-hipaa.component';
import { SignHipaaComponent } from './user/profile/sign-hipaa/sign-hipaa.component';
import { ReviewHipaaComponent } from './user/profile/review-hipaa/review-hipaa.component';
import {ReviewSignedDocsComponent} from './user/profile/review-signed-docs/review-signed-docs.component';
import { DoctorDashboardComponent } from './admin/doctor-dashboard/doctor-dashboard.component';
import { ScreenRecorderComponent } from './screen-recorder/screen-recorder.component';
import { ScreenCaptureComponent } from './screen-capture/screen-capture.component';
import { DocToDocEmail } from './doctor/d2d-email/d2d-email.component';
import { PrincetonSurgicalSpecialtiesAssignmentBenefitDAR } from './practice-documents/princeton-surgical-specialties-forms/princeton-surgical-specialties-assigment-of-benefits-DAR/princeton-surgical-specialties-assigment-of-benefits-DAR.component'
import { MedicalRecordInsuranceReleaseAndFinancialResponsibility } from './practice-documents/princeton-surgical-specialties-forms/medical-record-insurance-release-and-financial-responsibility/medical-record-financial-responsibility.component';
import { PriPrivacyPractice } from './practice-documents/princeton-rejuventation-institute-forms/pri-privacy-practices/pri-privacy-practices.component';

@NgModule({
  declarations: [
    AppComponent,
    SignaturePad,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    DemographicsformComponent,
    HippoReleaseNavigationComponent,
    HippoReleaseFormComponent,
    WaiverDisclaimerNavigationComponent,
    WaiverDisclaimerFormComponent,
    SignatureWaiverComponent,
    DocumentThoubsComponent,
    Editform2Component,
    Editform3Component,
    Editform4Component,
    ThankyouComponent,
    Editform5Component,
    ForgotpasswordComponent,
    ChangepasswordComponent,
    HippaAcceptanceComponent,
    ConfirmationformComponent,
    UsersListComponent,
    ThumbMobileComponent,
    AdminLoginComponent,
    DemographicsComponent,
    HeaderMenuComponent,
    AddAdminUserComponent,
    AdminForgotpasswordComponent,
    AdminChangepasswordComponent,
    FaqListComponent,
    DocumentListComponent,
    AddDocumentComponent,
    ModifyDocumentComponent,
    ActivityLogComponent,
    WelcomeComponent,
    AdminWelcomeComponent,
    DocGroupComponent,
    AddDocGroupComponent,
    DashboardComponent,
    USHFComponent,
    DocReviewComponent,
    UploaddocComponent,
    ProfieComponent,
    SenddocumentComponent,
    EmailLogComponent,
    ResendDocsComponent,
    AdminEmailLogComponent,
    AdminResendDocsComponent,
    PageLoaderComponent,
    AdminDualListComponent,
    MustMatchDirective,
    FaqComponent,
    DoctorLoginComponent,
    AdminDashboardComponent,
    DoctorSignupComponent,
    AdminEmailDocComponent,
    AllotDocGroupComponent,
    ViewUserDocsComponent,
    ViewDocsComponent,
    AdminInvitePatientComponent,
    ViewSignDocsComponent,
    DoctorProfileComponent,
    AdminProfileComponent,
    AdminUshfComponent,
    EditAdminUserComponent,
    UserDocsAcknowledgementComponent,
    DoctorViewDocsComponent,
    ViewDocsAcknowledgementComponent,
    AddPhysicianUserComponent,
    AdminDocReviewComponent,
    AdminSendEmailComponent,
    AdminUploaddocComponent,
    DoctorDocReviewComponent,
    EmailAllPatientComponent,
    AdminSecondOpinionComponent,
    AdminUshf2Component,
    PracticeGroupsComponent,
    PracticeSpecialitiesComponent,
    PracticeSubSpecialitiesComponent,
    AdminSecondOptionWfComponent,
    AdminTransferLogComponent,
    AdminViewD2dDocsComponent,
    AdminAcknowledgementD2dDocsComponent,
    AdminReplyD2dMailComponent,
    AdminD2dReviewLogComponent,
    AdminPhysicianSchedulingComponent,
    OrderByPipe,
    DateFilterPipe,
    Email2userComponent,
    AdminTransferReportComponent,
    AdminOnCallScheduleLogComponent,
    AdminDelegationLogComponent,
    ReviewSignComponent,
    Confirmationform1Component,
    LoaderComponent,
    AdminContactUsComponent,
    AdminChangePasswordComponent,
    AdminSecurityQuestions,
    NjComponent,
    NyComponent,
    FlComponent,
    PaComponent,
    CtComponent,
    OhComponent,
    CoComponent,
    CaComponent,
    AlComponent,
    AzComponent,
    ArComponent,
    DeComponent,
    GaComponent,
    HiComponent,
    IdComponent,
    IlComponent,
    AkComponent,
    TxComponent,
    SdComponent,
    NdComponent,
    VaComponent,
    VtComponent,
    KyComponent,
    WiComponent,
    WvComponent,
    TnComponent,
    LaComponent,
    MdComponent,
    MnComponent,
    MoComponent,
    MsComponent,
    NvComponent,
    OkComponent,
    WaComponent,
    IaComponent,
    NhComponent,
    KsComponent,
    OrComponent,
    NeComponent,
    MtComponent,
    NcComponent,
    ScComponent,
    InComponent,
    MeComponent,
    RiComponent,
    UtComponent,
    NmComponent,
    MaComponent,
    AdminCommentsComponent,
    AddCommentComponent,
    AdminInvitationLogComponent,
    ChatComponent,
    ChatbotComponent,
    AhsaichatgptComponent,
    ColorFooterComponent,
    PatientHistory,
    UploadDocument,
    PrincetonSurgicalSpecialtiesPreopNote,
    PrincetonSurgicalSpecialtiesPreopInstructions,
    PrincetonSurgicalSpecialtiesHealthHistoryForm1,
    PrincetonSurgicalSpecialtiesDemographics,
    DocFive,
    PracticeDemographicComponent,
    ReliantSurgicalCenterAnesthesiaQuestionnariePg2,
    ReliantSurgicalCenterAnesthesiaQuestionnariePg1,
    PrincetonSurgicalSpecialtiesHealthHistoryForm2,
    PriNewPatientIntakeSignaturePage,
    PriAestheticHistoryForm,
    GreenbergCosmeticSurgeryORSchedulingForm,
    GreenbergCosmeticSurgeryDemographics,
    PriHealthHistoryForm2,
    PriHealthHistoryForm1,
    GreenbergCosmeticSurgeryHealthHistoryPage2,
    GreenbergCosmeticSurgeryHealthHistoryPage1,
    AdminPracticeDictationComponent,
    AcceptHipaaComponent,
    SignHipaaComponent,
    ReviewHipaaComponent,
    ReviewSignedDocsComponent,
    DoctorDashboardComponent,
    ScreenRecorderComponent,
    ScreenCaptureComponent,
    DocToDocEmail,
    PrincetonSurgicalSpecialtiesAssignmentBenefitDAR,
    MedicalRecordInsuranceReleaseAndFinancialResponsibility,
    PriPrivacyPractice,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxDatatableModule,
    DataTablesModule,
    TooltipModule,
    AngularFontAwesomeModule,
    AngularDualListBoxModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ImageViewerModule,
    AngularImageViewerModule,
    // ToastrModule.forRoot({
    //   timeOut: 3000,
    //   positionClass: 'toast-top-right',
    //   preventDuplicates: true,
    //   closeButton: true,
    //   progressBar: true,
    // }),
    
  ],
  providers: [
    AppConfig,
    CommonHelper,
    LoaderService,
    SweetAlertHelper,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
