import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { UserService } from "../../_services/user.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-senddocument",
  templateUrl: "./senddocument.component.html",
  styleUrls: ["./senddocument.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class SenddocumentComponent implements OnInit {
  @ViewChild("fileUploaded", { static: false }) fileUploaded: ElementRef;
  maxChars = 300;
  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  loggedInUserEmail: string;
  comapnyName: string = ConstantHelper.COMPANY_NAME;

  docInfo: any = {};
  companySlug: string = ConstantHelper.COMPANY_SLUG;

  isChecked = false;
  fileToUpload: File = null;
  fSize: any;
  documentsLength = false;
  documentsList: any = [];
  hippaFile1: any;
  hippaFile2: any;
  hippaFile3: any;
  emailLogTxt: any = "";
  attachmentDocment: any = "";
  fileDesTxt: any = "";
  showDocumentModal = false;
  choosedFileName: any = "";

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  selectedDocsId: any = [];
  selectedHippaUrls: any = [];

  statesArre: any = [];
  hippaAvailable = false;
  selectedDocUrl: any;

  stateSelectDisabledOption = true;
  citySelectDisabledOption = true;
  physicianSelectDisabledOption = true;

  cityLength = false;
  noCityLength = false;
  citiesList: any = [];

  physicianLength = false;
  noPhysicianLength = false;
  physiciansList: any = [];
  selectedPhysician: any = {};
  selectedStateCode: any;
  selectedCityName: any;
  selectedPhysicianId: any;
  showNewFile = 0;
  attachmentDoc: any = {};
  selectedDocumentId: any;
  hasHippaFile = false;
  selectedEmail: any;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    private uploadService: UploadService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: this.companySlug,
    };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findMailingDocsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList = apiResponse.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {}
      );

    const finalHippaData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_findUserDemographicApi,
        finalHippaData,
        this.httpOptions
      )
      .subscribe(
        (apiHippaResponse: any) => {
          this.hippaFile1 =
            apiHippaResponse.commandResult.data.userInfo.form1PdfFileNameUrl;
          this.hippaFile2 =
            apiHippaResponse.commandResult.data.userInfo.form2PdfFileNameUrl;
          this.hippaFile3 =
            apiHippaResponse.commandResult.data.userInfo.form3PdfFileNameUrl;
        },
        (err) => {}
      );

    const finalCityData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findPhysicianCitiesApi,
        finalCityData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.statesArre = apiResponse.commandResult.data.statesList;
        },
        (err) => {}
      );
  }

  clearSelectedDocs() {
    this.selectedDocsId = [];
  }

  async checkAllHippaDoc(isChecked) {
    if (isChecked == 1) {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.confirmationFormComp.checkAllHipaaDoc
      );
      if (alertStatus) {
        $(".docChecks").each(function () {
          $(this).prop("checked", true);
        });
        $(".hippaChecks").each(function () {
          $(this).prop("checked", true);
        });
      } else {
        this.selectedDocsId = [];
        $(".selectAll").prop("checked", false);
        $(".docChecks").each(function () {
          $(this).prop("checked", false);
        });
        $(".hippaChecks").each(function () {
          $(this).prop("checked", false);
        });
      }
    } else {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.confirmationFormComp.checkAllHipaaDoc1
      );
      if (alertStatus) {
        this.selectedDocsId = [];
        $(".selectAll").prop("checked", false);
        $(".docChecks").each(function () {
          $(this).prop("checked", false);
        });
        $(".hippaChecks").each(function () {
          $(this).prop("checked", false);
        });
      } else {
        $(".selectAll").prop("checked", true);
      }
    }
  }

  getCheckedBoxValue() {
    const allCheck: any = document.getElementsByClassName("docChecks");
    for (let index = 0; index < allCheck.length; index++) {
      const element = allCheck[index];
      if (element.checked) {
        this.selectedDocsId.push(element.value);
      }
    }
  }


  async onReset() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }

  onFileSelected(event) {
    this.showNewFile = 1;
    this.attachmentDocment = "";
    this.hippaAvailable = false;

    if (this.fileUploaded.nativeElement.value != "") {
      this.fileToUpload = event.target.files.item(0) as File;
      this.fSize = Math.round(this.fileToUpload.size / 1024);
    } else {}

    if (
      this.hasHippaFile &&
      this.fileUploaded.nativeElement.value == "" &&
      this.selectedDocumentId == ""
    ) {
      this.hippaAvailable = true;
    }
  }

  async onSubmit() {
    let altEmail = "";
    if (this.docInfo.altEmail) {
      altEmail = this.docInfo.altEmail;
    }
    this.getCheckedBoxValue();
    if (this.selectedDocsId.length <= 0) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminUploadComp.uploadFile
      );
      return false;
    } else if (this.selectedDocsId.length > 5) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_2
      );
      return false;
    } else if (
      this.showNewFile == 1 &&
      this.fileUploaded.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload.name)
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4

      );
      return false;
    } else if (
      this.showNewFile == 1 &&
      this.fileUploaded.nativeElement.value != "" &&
      this.fSize > 61440
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_5
      );
      return false;
    } else {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.demographicsComp.email2All
      );
      if (alertStatus) {
        this.uploadService
          .sendDoc(
            this.selectedPhysician.PhysicianName,
            this.selectedEmail,
            this.selectedDocsId,
            this.fileToUpload,
            this.loggedInUserId,
            this.selectedHippaUrls,
            this.companySlug,
            altEmail,
            this.selectedStateCode,
            this.selectedCityName,
            this.selectedPhysicianId,
            this.emailLogTxt,
            this.fileDesTxt,
            ConstantHelper.DOC_TYPE_USER_EMAILED,
            localStorage.getItem("loggedInUserComapnyId"),
            this.showNewFile,
            this.attachmentDoc
          )
          .subscribe(
            async (data: any) => {
              if (data !== undefined) {
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.adminsecondoptwfCOmp.d2d04_8
                );
                if (alertStatus)
                  this.router.navigate(["/dashboard/" + this.loggedInUserId]);
                if (data.commandResult.success == 1) {
                }
              }
            },
            (error) => {}
          );
      }
    }
  }

  validateFile(name: String) {
    const ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "pdf"
    ) {
      return true;
    } else {
      return false;
    }
  }

  findCitiesByStateCode(stateCode) {
    this.selectedStateCode = stateCode;
    this.stateSelectDisabledOption = false;
    this.citiesList = [];
    const finalData = { stateCode };
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.citySelectDisabledOption = true;
          if (apiResponse.commandResult.status == 1) {
            if (apiResponse.commandResult.data.cityList.length > 0) {
              this.cityLength = true;
              this.citiesList = apiResponse.commandResult.data.cityList;
            } else {
              this.noCityLength = true;
            }
          } else {
            this.noCityLength = true;
          }
        },
        (err) => {}
      );
  }

  findPhysicianByStateCode(cityName) {
    this.selectedCityName = cityName;
    this.citySelectDisabledOption = false;
    const finalDataPhysician = { cityName };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          if (apiResponsePhysician.commandResult.status == 1) {
            this.physicianSelectDisabledOption = true;
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.physicianLength = true;
              this.physiciansList = apiResponsePhysician.commandResult.data.physiciansList;
            } else {
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminsecondoptwfCOmp
                  .findcoePhysicians
              );
              this.noPhysicianLength = true;
            }
          } else {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findcoePhysicians
            );
            this.noPhysicianLength = true;
          }
        },
        (err) => {}
      );
  }

  updatePhysicianInfo(physicianId) {
    this.selectedPhysicianId = physicianId;
    this.physicianSelectDisabledOption = false;
    this.selectedPhysician = this.physiciansList.find(
      (e) => e.RecordId == physicianId
    );
    this.selectedEmail = this.selectedPhysician.PhysicianEmail;
    this.selectedPhysician.PhysicianEmail = this.selectedPhysician.PhysicianEmail.substring(0, 4) + "****@***.***";
  }

  onChangeUserState(stateCode) {
    this.userService.findHippaByStateCode(stateCode).subscribe(
      (apiResponse) => {
        this.resultJsonObj = apiResponse;
        if (this.resultJsonObj.commandResult.status == 1) {
          this.hippaAvailable = true;
          this.hasHippaFile = true;
          this.selectedDocUrl =
            this.resultJsonObj.commandResult.data.hippaDetails.DocumentUrl;
        } else {
          this.hippaAvailable = false;
        }
      },
      (err) => {}
    );
  }

  async downloadHipp() {
    if (this.selectedDocUrl) {
      window.open(this.selectedDocUrl);
    } else {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminUshf2Comp.onSubmit
      );
    }
  }

  setAttachment(DocumentId) {
    this.hippaAvailable = false;

    this.fileUploaded.nativeElement.value = "";
    this.selectedDocumentId = DocumentId;
    this.showNewFile = 2;
    const docObj = this.documentsList.find(
      (e) => e.RecordId == this.selectedDocumentId
    );

    this.attachmentDoc = JSON.stringify(docObj);

    if (
      this.hasHippaFile &&
      this.fileUploaded.nativeElement.value == "" &&
      this.selectedDocumentId == ""
    ) {
      this.hippaAvailable = true;
    }
  }

  closeDocumentModal() {
    this.showDocumentModal = false;
  }

  openDocumentModal() {
    this.showDocumentModal = true;
  }
  getMaskedString(email) {
    return email.substring(0, 4);
  }
}
